import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {VariantModel} from '../../../../models/api/variant.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';

@Component({
    selector: 'package-setup-package-data-item-form-component',
    templateUrl: 'package-setup-package-data-item-form.component.html',
    styleUrls: ['package-setup-package-data-item-form.component.scss']
})

export class PackageSetupPackageDataItemFormComponent implements OnInit {
    public readonly VALIDATOR_MESSAGES = RLValidatorConstants.MESSAGES;

    @Input() public form: UntypedFormGroup;
    @Input() public properties: RulePropertyModel[] = [];
    @Input() public variants: VariantModel[] = [];

    public propertyOptions: RulePropertyModel[] = [];

    public ngOnInit(): void {
        this.propertyOptions = [...this.properties];
        if (this.form.controls.property) {
            this.form.controls.property.valueChanges.subscribe((v) => {
                if (!this.form.controls.variant && v.enableVariants)
                    this.form.addControl('variant', new UntypedFormControl(null, Validators.required));
                if (this.form.controls.variant && !v.enableVariants)
                    this.form.removeControl('variant');
            });
        }
    }

    public searchProperties(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.propertyOptions = [];
        const regex = new RegExp(event.search, 'i');
        this.propertyOptions = event.search
            ? this.properties.filter((ruleProperty) => ruleProperty.name.match(regex)?.length > 0)
            : this.properties;
    }
}
