<nuc-master-detail-view class="products-container"
                        [open]="!!selectedProduct"
                        [detailWidth]="DEFAULT_SIDEBAR_WIDTH"
                        (closeClicked)="selectedProduct = null"
                        [closeLocationClass]="'detail-header'"
                        *ngIf="permissions.GET_PRODUCTS | userIsAllowedTo else noPermissionState">
    <div master class="master">

        <div class="header-row">
            <h1>Product overview</h1>
            <nuc-button-bar>
                <nuc-button-secondary text="Import products"
                                      (click)="openImportProductsModal()"
                                      *ngIf="permissions.POST_PRODUCT_JOB | userIsAllowedTo" i18n>
                </nuc-button-secondary>
                <nuc-button-primary text="Add product"
                                    (click)="onAddProductClicked()"
                                    *ngIf="permissions.POST_PRODUCTS | userIsAllowedTo" i18n>
                </nuc-button-primary>
            </nuc-button-bar>
        </div>

        <!-- TODO: Fix this by refactoring collection-views-->
        <rl-collection-view [items]="products"
                            [propertySettingsContext]="EPropertySettingsContext.PRODUCT"
                            (collectionViewOptionsChanged)="onCollectionViewOptionsChanged()"
                            [selectedItem]="selectedProduct"
                            (collectionViewItemClicked)="onCollectionViewItemClicked($event)"
                            (collectionViewItemDoubleClicked)="onCollectionViewItemDoubleClicked($event)"
                            [(sort)]="sort"
                            [(sortDescending)]="sortDescending"
                            [sortingOptions]="sortingOptions"
                            [disableSort]="!!searchValue"
                            [isLoading]="!productsSubscription?.closed"
                            [isSearching]="isSearching"
                            [searchPlaceholder]="'Search products'"
                            [searchValue]="searchValue"
                            (onSearchValueUpdated)="onSearch($event)"
                            [advancedFilter]="true"
                            [disableNextPage]="disableNextPage"
                            [disablePageOptions]="!!searchValue"
                            [viewId]="tableId"
                            class="smooth-animated">
        </rl-collection-view>
    </div>

    <!--  TODO: [DATAFIELDS] Find a way to configure what dataField should be used as name/title -->
    <div detail *ngIf="selectedProduct" class="detail-sidebar" @myRemoveTrigger>
        <div class="detail-header">
            <h2>Product details</h2>
        </div>

        <div class="detail-sidebar__asset"
             [ngStyle]="selectedProduct.getThumbnailURL() && { 'background-image' : 'url(' + selectedProduct.getThumbnailURL() +')'}">
            <div *ngIf="!selectedProduct.assets[0]" class="nucicon nucicon_image" aria-hidden="true"></div>
            <div *ngIf="selectedProduct.assets[0]?.processing" class="nucicon nucicon_gear" aria-hidden="true"></div>
        </div>

        <div class="secondary-assets" *ngIf="productAssets.length > 1">
            <div *ngFor="let asset of productAssets" class="cell"
                 [ngStyle]="(asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL)
                 && { 'background-image' : 'url(' +(asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL) +')'}">
            </div>
            <hr class="secondary-assets-hr">
        </div>

        <information-details-view-component
            title="Information"
            [item]="selectedProduct"
            [dataFields]="dataFields"
            [sidebar]="true">
        </information-details-view-component>

        <nuc-button-bar>
            <nuc-button-secondary text="View"
                                  [routerLink]="['/products/' + selectedProduct._id]">
            </nuc-button-secondary>
        </nuc-button-bar>
    </div>
</nuc-master-detail-view>


<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
