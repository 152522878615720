<div [formGroup]="form">
    <div class="link-assets-container" [class.collapsed]="isLinkAssetsCollapsed">
        <div class="link-assets-header">
            <div>
                <p class="section-header">Link asset</p>
                <p>You can link existing assets to this product.</p>
            </div>
            <nuc-button-secondary [icon]="isLinkAssetsCollapsed ? 'nucicon_chevron_down' : 'nucicon_chevron_up'"
                                  (click)="isLinkAssetsCollapsed = !isLinkAssetsCollapsed">
            </nuc-button-secondary>
        </div>
        <link-asset-view class="link-assets-view"
                         [class.collapsed]="isLinkAssetsCollapsed"
                         (linkAsset)="linkAsset($event)">
        </link-asset-view>
    </div>

    <ng-container *ngIf="assets?.length > 0; else emptyState">
        <p class="added-assets section-header">Added assets</p>
        <div class="assets" [class.three-assets]="assets?.length === 3" [class.four-assets]="assets?.length > 3">
            <nuc-medium-card-view *ngFor="let asset of assets; index as i"
                                  [title]="asset | assetDisplay:EAssetDisplayProperties.TITLE"
                                  [image]="asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL"
                                  [badgeText]="i === 0 ? 'Primary' : null">

                <div content>
                </div>

                <div footer class="footer">
                    <nuc-button-secondary
                        *ngIf="i !== 0"
                        text="Primary"
                        (click)="setAssetPrimary(asset)">
                    </nuc-button-secondary>

                    <nuc-button-secondary
                        icon="nucicon_trash_fill"
                        nucTooltip="Delete"
                        (click)="unlinkAsset(asset)">
                    </nuc-button-secondary>
                </div>
            </nuc-medium-card-view>
        </div>
    </ng-container>
</div>

<ng-template #emptyState>
    <nuc-empty-state-component
        imageUrl="assets/images/empty_states/no-image.svg"
        title="No assets"
        subtitle="First, link assets to the product.">
    </nuc-empty-state-component>
</ng-template>
