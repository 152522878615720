<div *ngIf="(permissions.GET_INVITES | userIsAllowedTo); else noPermissionState" class="roles-management">
    <nuc-table [useButtonBar]="true"
               [useEmptyState]="true"
               [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : 'No pending invites'"
               [items]="invites"
               [columns]="inviteColumns"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [loading]="!inviteSubscription?.closed"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">

        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search invites">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!inviteSubscription?.closed">
        </paginator>
    </nuc-table>
</div>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
