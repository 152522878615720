import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    EColumnSize,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IItemClickEvent,
    ITableAction,
    ITableColumn
} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../app.constants';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {IPaginator, PaginatorService} from '../../../../components/paginator/paginator.service';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {catchError, takeUntil} from 'rxjs/operators';
import {
    IActionClickEvent,
    ISortOptionEvent,
    ITableItem
} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';
import {WorkflowConfigurationModel} from '../../../../models/api/workflow-configuration.model';
import {publicationTypeDisplayNames} from '../../../../models/api/channel.model';
import {CursorArray} from '../../../../api/api-cursor';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    IWorkflowConfigurationFormData,
    WorkflowConfigurationFormComponent
} from '../../../../forms/workflow-configuration-form/workflow-configuration-form.component';
import {WorkflowConfigurationsService} from '../../../../api/services/workflow-configurations.service';

@Component({
    selector: 'workflow-configuration-overview-component',
    templateUrl: 'workflow-configuration-overview.component.html',
    styleUrls: ['workflow-configuration-overview.component.scss'],
    providers: [PaginatorService]
})
export class WorkflowConfigurationOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'workflow-configurations-table';
    public readonly selectionMode: ESelectionMode = ESelectionMode.EMIT;

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Publication type',
            key: 'publicationType',
            sortProperty: 'publicationType',
            format: (value) => publicationTypeDisplayNames.get(value),
            size: EColumnSize.SMALL,
            sortDuplicates: true
        },
        {
            title: 'Number of components',
            key: 'numberOfComponents',
            size: EColumnSize.SMALL
        },
        {
            title: 'Number of steps',
            key: 'numberOfSteps',
            size: EColumnSize.SMALL
        },
        {
            title: 'Layout',
            key: 'layout.name',
            sortProperty: 'layout.name',
            size: EColumnSize.SMALL,
            sortDuplicates: true
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            size: EColumnSize.SMALL,
            sortDuplicates: true
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            size: EColumnSize.SMALL,
            sortDuplicates: true
        }
    ];

    public workflows: WorkflowConfigurationModel[];
    public disableNextPage = true;
    public subscription: Subscription;
    private onDestroySubject = new Subject<void>();

    public allowedActions: ITableAction[] = [];
    public pageIndex: number;
    public pageSize: number;
    public search: string;

    private apiCursor: CursorArray;
    private sortColumn: ITableColumn;

    constructor(private fullModalService: FullModalService,
                private route: ActivatedRoute,
                private router: Router,
                private paginatorService: PaginatorService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.listenToPagination();

        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION)) {
            this.allowedActions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : '';
        this.setPageIndex(pageIndex);
        this.search = params['search'] || '';
        // we don't set the pageSize from route
        this.sortColumn = this.columns.find(column => column.sortProperty === sortProperty);
        // Init the cursors array with the current page index
        this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result: IPaginator) => {
                if (result.pageIndex === 1 || result.pageSize !== this.pageSize) { // reset cursor when needed
                    this.apiCursor.reset(result.pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
                }
                this.pageIndex = result.pageIndex;
                this.pageSize = result.pageSize;
                this.updateUrl();
                this.getWorkflowConfigurations();
            });
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.WORKFLOWS,
            AppConstants.CONTEXT_URL.CONFIGURATIONS,
            new MatrixUrlParams(this.pageIndex, null, this.sortColumn?.sortProperty, this.sortOrder, this.search)
        ]);
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getWorkflowConfigurations(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_CONFIGURATIONS)) return;
        this.subscription?.unsubscribe();

        // If we don't have a cursor value (if we reload the page for example), fall back to offset
        const cursor = this.apiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.workflowConfigurationService.find(
            this.pageSize, offset, this.sortColumn?.sortProperty, this.sortOrder, cursor, this.search
        ).pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result: ARPagedResponseDataModel<WorkflowConfigurationModel>) => {
                    this.workflows = result.items;
                    this.disableNextPage = !result.hasNext;

                    if (this.workflows.length > 0) {
                        const workflow = this.workflows[this.workflows.length - 1];
                        this.apiCursor.setCursor(this.pageIndex, this.sortColumn?.sortProperty, workflow);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortColumn = sortEvent.column;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortColumn = null;
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    // eslint-disable-next-line no-unused-vars
    public handleAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.openEditWorkflowConfigurationForm(event.item);
        }
    }

    public onSearchBarValueUpdated(event: string): void {
        if (this.search !== event) {
            this.search = event;
            this.setPageIndex();
        }
    }

    public itemClicked(event: IItemClickEvent): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.WORKFLOWS,
            AppConstants.CONTEXT_URL.CONFIGURATIONS,
            event.item._id
        ], {skipLocationChange: false});
    }


    private getWorkflowConfigurationObservable(itemId: string): Observable<WorkflowConfigurationModel> {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_CONFIGURATION)) return;

        return this.workflowConfigurationService.findOne(itemId)
            .pipe(
                catchError((error) => {
                    Toaster.handleApiError(error);
                    return of(null);
                }),
                takeUntil(this.onDestroySubject)
            );
    }

    private openEditWorkflowConfigurationForm(item: ITableItem) {
        this.getWorkflowConfigurationObservable(item._id)
            .subscribe(workflowConfiguration => {
                const modalData = {
                    workflowConfiguration,
                    workflowConfigurationSteps: workflowConfiguration.steps
                } as IWorkflowConfigurationFormData;

                const modalConfig = new FullModalConfig('Edit workflow configuration',
                    'Enter the information to edit a workflow configuration.',modalData);
                modalConfig.confirmClose = true;

                this.fullModalService.open(WorkflowConfigurationFormComponent, modalConfig)
                    .afterClosed()
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe(result => {
                        if (result) this.getWorkflowConfigurations();
                    });
            });
    }
}
