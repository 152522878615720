<nuc-label>{{title}}</nuc-label>
<rl-reactive-file-input
    inactive-title="{{subtitle}}"
    (filesChanged)="onFilesChanged($event)">
</rl-reactive-file-input>

<div class="file-card">

    <div class="file-type-icon" [style.background-image]="'url(' + fileTypeImage + ')'"></div>

    <ng-container *ngIf="upload && upload.progress$ | async as progress; else placeholderText">
        <div>
            <p class="body-strong">{{upload.filename}}</p>
            <p>{{upload.fileType}} - {{upload.fileSize}}</p>
        </div>
        <nuc-button-secondary icon="nucicon_trash_fill" (click)="onDeleteClicked()"></nuc-button-secondary>

        <div class="upload-status" [ngSwitch]="progress">

            <ng-container *ngSwitchCase="EUploadStatus.Failed">
                <img class="status-icon" src="/assets/images/icon_failed.svg" />
                <p class="body-strong">Upload failed</p>
            </ng-container>

            <ng-container *ngSwitchCase="EUploadStatus.Done">
                <img class="status-icon" src="/assets/images/icon_success.svg" />
                <p class="body-strong">Upload complete</p>
            </ng-container>

            <nuc-progress-bar *ngSwitchDefault [value]="progress" [showPercentage]="true"></nuc-progress-bar>

        </div>
    </ng-container>

    <ng-template #placeholderText>
        <div>
            <p class="body-strong">Nothing has been uploaded yet</p>
            <p *ngIf="fileTypeExtensionsString">{{fileTypeExtensionsString}} - 0.00kb</p>
            <p *ngIf="!fileTypeExtensionsString">0.00kb</p>
        </div>
    </ng-template>

</div>
