import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {ActivatedRoute, Router} from '@angular/router';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    EColumnSize,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {DataFieldFormComponent, IDataFieldFormData} from '../../forms/data-field-form/data-field-form.component';
import {DataFieldModel} from '../../models/api/data-field.model';
import {DataFieldsService, EDataFieldJobTypes, IDeleteDataFieldJobData} from '../../api/services/data-fields.service';
import {EBooleanText, EDataFieldCollectionName} from '../../app.enums';
import {SortDirection} from '@angular/material/sort';
import {DataFieldUsageModel} from '../../models/api/data-field-usage.model';
import {IListDialogData, IListGroup, ListDialogComponent} from '../dialog/list/list-dialog.component';
import {EJobStatus, JobModel} from '../../models/api/job.model';
import {Subject, Subscription} from 'rxjs';
import {MonitoredJobsService} from '../../api/services/monitored-jobs.service';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {PaginatorService} from '../paginator/paginator.service';
import {takeUntil} from 'rxjs/operators';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';

@Component({
    selector: 'rl-data-fields-overview',
    templateUrl: 'data-fields-overview.component.html',
    styleUrls: ['data-fields-overview.component.scss'],
    providers: [PaginatorService]
})

export class DataFieldsOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public collectionName: EDataFieldCollectionName;
    private onDestroySubject: Subject<void> = new Subject<void>();

    public readonly tableId = 'data-fields-table';
    public columns: ITableColumn[] = [
        {
            title: 'Field name',
            key: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Data type',
            key: 'dataType.type'
        },
        {
            title: 'Data type formatter',
            key: 'dataType.formatter'
        },
        {
            title: 'Data type enumeration',
            key: 'dataType.enumeration.items',
            format: (value) => value?.join(', ')
        },
        {
            title: 'Data type enumeration multiselect',
            key: 'dataType.enumeration.multiSelect',
            format: (value) => value ? EBooleanText.TRUE : EBooleanText.FALSE
        },
        {
            title: 'Show in filter',
            key: 'showInFilter',
            format: (value) => value ? EBooleanText.TRUE : EBooleanText.FALSE
        },
        {
            title: 'Enable variants',
            key: 'enableVariants',
            format: (value) => value ? EBooleanText.TRUE : EBooleanText.FALSE
        },
        {
            title: 'Enable autocomplete',
            key: 'enableAutocomplete',
            format: (value) => value ? EBooleanText.TRUE : EBooleanText.FALSE
        }
    ];

    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;

    public sortProperty: string;
    public sortOrder: SortDirection;
    public disableNextPage: boolean;

    public items: DataFieldModel[];
    public actions: ITableAction[];

    public subscription: Subscription;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsService,
                private dataFieldsApiService: DataFieldsApiService,
                private monitoredJobsService: MonitoredJobsService,
                private dialogService: NucDialogService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.collectionName = this.route.snapshot.data['collectionName'];
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getDataFields();
            });

        this.setPageIndex();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public getDataFields(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.dataFieldsApiService.getDataFields(this.collectionName,
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            this.sortProperty,
            this.sortOrder)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: ARPagedResponseDataModel<DataFieldModel>) => {
                    this.items = res.items;
                    this.disableNextPage = res.total <= this.pageIndex * this.pageSize;
                },
                error: Toaster.handleApiError
            });
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public openDataFieldForm(dataField?: DataFieldModel): void {
        const data: IDataFieldFormData = {collectionName: this.collectionName, dataField};
        const config: FullModalConfig = dataField
            ? new FullModalConfig('Edit data field', 'Edit the details of the data field.', data)
            : new FullModalConfig('Add data field', 'Enter the details of the data field.', data);
        config.confirmClose = true;

        this.fullModalService.open(DataFieldFormComponent, config).afterClosed().subscribe((results) => {
            // On Cancel/Close there are no results
            if (!Array.isArray(results)) return;

            for (const result of results) {
                if (result instanceof JobModel) {
                    this.monitoredJobsService.getJobMonitor(result._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.getDataFields();
                            }
                        });
                } else if (result instanceof DataFieldModel) {
                    this.getDataFields();
                }
            }
        });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.ORDER_DATA_FIELDS)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.MOVE_UP, AppConstants.TABLE_ACTION_TYPES.MOVE_DOWN];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_DATA_FIELD)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.EDIT) : [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_DATA_FIELD)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const dataField = event.item as DataFieldModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.dataFieldsService.getDataFieldUsage(event.item._id).subscribe({
                next: (result: DataFieldUsageModel) => {
                    const inUse = Object.keys(result).reduce((acc, val) => acc + result[val].length, 0) > 0;
                    inUse ? this.openCannotDeleteDialog(dataField, result) : this.openDeleteDialog(dataField);
                },
                error: Toaster.handleApiError
            });
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.dataFieldsService.getDataField(dataField._id).subscribe({
                next: (result: DataFieldModel) => this.openDataFieldForm(result),
                error: Toaster.handleApiError
            });
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.MOVE_UP || event.action === AppConstants.TABLE_ACTION_TYPES.MOVE_DOWN) {
            const direction = event.action === AppConstants.TABLE_ACTION_TYPES.MOVE_UP ? 'MOVE_UP' : 'MOVE_DOWN';
            this.dataFieldsService.updateOrder(event.item._id, direction).subscribe({
                next: () => this.getDataFields(),
                error: Toaster.handleApiWarning
            });
        }
    }

    private openDeleteDialog(dataField: DataFieldModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete data field',
            `Please confirm that you wish to delete ${dataField.name}.
             All data in this data field will be deleted and cannot be recovered.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.postJob(dataField);
        });
    }

    private openCannotDeleteDialog(dataField: DataFieldModel, usage: DataFieldUsageModel): void {
        const collections = [
            {name: 'ruleSets', title: 'Format rulesets'},
            {name: 'workflows', title: 'Workflows'},
            {name: 'packageSetups', title: 'Package setups'},
            {name: 'workflowAutomations', title: 'Workflow automations'},
        ];

        const dialogData: IListDialogData = {listGroups: []};
        for (const collection of collections) {
            if (usage[collection.name]?.length > 0) {
                dialogData.listGroups.push({
                    title: collection.title,
                    list: usage[collection.name].map((item) => item.name),
                } as IListGroup);
            }
        }

        const cannotDeleteDialogConfig = new DialogCustomContentConfig('Cannot delete',
            `The data field: ${dataField.name} is being used. Please contact the Relayter Customer Support
            if you still want to proceed to delete it. The data field is used in the following areas:`, dialogData);
        this.dialogCustomContentService.open(ListDialogComponent, cannotDeleteDialogConfig);
    }

    public postJob(dataField): void {
        const jobData = {
            dataFieldId: dataField._id
        } as IDeleteDataFieldJobData;

        this.dataFieldsService.postJob(EDataFieldJobTypes.DELETE_DATA_FIELD_JOB, jobData).subscribe({
            next: (scheduleJob: JobModel) => {
                ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                this.monitoredJobsService.getJobMonitor(scheduleJob._id)
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.setPageIndex();
                        }
                    });
            },
            error: Toaster.handleApiError
        });
    }
}
