import {Directive, OnDestroy, OnInit} from '@angular/core';
import {CustomWorkflowFilterOptionModel} from '../../../../../models/api/custom-workflow-filter-option.model';
import {CustomWorkflowFilterModel} from '../../../../../models/api/custom-workflow-filter.model';
import {combineLatest, ReplaySubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../../../../app.constants';
import {CustomWorkflowService} from './custom-workflow.service';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {CustomWorkflowActionModel} from '../../../../../models/api/custom-workflow-action.model';
import {PublicationsService} from '../../../../../api/services/publications.service';
import {Toaster} from '../../../../../classes/toaster.class';
import {ESignOffStatus, PublicationItemModel} from '../../../../../models/api/publication-item.model';
import {PrintPublicationItemModel} from '../../../../../models/api/custom-workflow/print-publication-item.model';
import {
    BUTTON_TYPE,
    FullModalConfig,
    FullModalService,
    ITableAction,
    NucDialogConfigModel,
    NucDialogService,
    NucPopOutContentService
} from '@relayter/rubber-duck';
import {
    IPublicationItemFormComponentData,
    PublicationItemFormComponent
} from './custom-workflow-item-actions/publication-item-form/publication-item-form.component';
import {
    AssignToPackageFormComponent,
    IAssignToPackageFormData
} from './custom-workflow-item-actions/assign-to-package-form/assign-to-package-form.component';
import {
    CustomWorkflowAssignableItemsOverviewComponent,
    IAssignableItemsOverviewModalData
} from './custom-workflow-item-actions/custom-workflow-assignable-items-overview/custom-workflow-assignable-items-overview.component';
import {CampaignItemModel} from '../../../../../models/api/campaign-item.model';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {CampaignModel} from '../../../../../models/api/campaign.model';
import {WorkflowConfigurationModel} from '../../../../../models/api/workflow-configuration.model';
import {PublicationModel} from '../../../../../models/api/publication.model';
import {CustomWorkflowStepModel} from '../../../../../models/api/custom-workflow-step.model';
import {CustomWorkflowComponentModel} from '../../../../../models/api/custom-workflow-component.model';
import {AssignTableActionComponent} from './custom-workflow-briefing-actions/assign-table-action/assign-table-action.component';
import {
    CustomWorkflowSelectionModalComponent,
    ICustomWorkflowSelectionModalData
} from './custom-workflow-briefing-actions/custom-workflow-selection-modal/custom-workflow-selection-modal.component';
import {IWorkflowModalData} from '../../../../../models/interfaces/workflow-modal-data.interface';
import {VariantModel} from '../../../../../models/api/variant.model';
import {MonitoredTransitionsService} from '../../../../../api/services/monitored-transitions.service';
import {DownloadPackageComponent, IDownloadPackageTypeModalData} from '../../../../../components/download-package/download-package.component';
import {EPackageType} from './custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {CUSTOM_WORKFLOW_DESTRUCTIVE_RECIPE_TASK_NAMES} from '../../../../../models/api/custom-workflow-transition.model';
import {ETransitionStatus} from '../../../../../models/api/transition-item.model';
import {EWorkflowConfigurationActionType} from '../../../../../app.enums';

// item here refers to publication item
export enum EItemActionName {
    ASSIGN_TO_PACKAGE = 'ASSIGN_TO_PACKAGE',
    EDIT = 'EDIT', // edit publication item tags & template ...
    SIGN_OFF = 'SIGN_OFF',
    ASSIGN = 'ASSIGN', // assign briefing items to publication item
    DOWNLOAD = 'DOWNLOAD',
    UPLOAD_ITEM_FILES = 'UPLOAD_ITEM_FILES'
}

export const multiCompatibleItemActions = [EItemActionName.DOWNLOAD];

// Actions done from a Campaign/Briefing item
export enum EBriefingActionName {
    ASSIGN = 'ASSIGN', // assign briefing items to publication item
    GENERATE = 'GENERATE' // generate items from campaign item selection (single/multi)
}

// a base component where we handle item actions and transition actions to publication items
// also briefing actions
@Directive()
export abstract class CustomWorkflowBaseComponent implements OnInit, OnDestroy {
    public readonly permissions = AppConstants.PERMISSIONS;
    protected onDestroySubject = new Subject<void>();

    public campaign: CampaignModel;
    public workflow: WorkflowConfigurationModel;
    public publication: PublicationModel;
    protected step: CustomWorkflowStepModel;
    public component: CustomWorkflowComponentModel;

    public allowedActions: CustomWorkflowActionModel[];
    public briefingActions: CustomWorkflowActionModel[];

    protected activeFilters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>;
    protected itemActions: CustomWorkflowActionModel[];
    public iterableActions: CustomWorkflowActionModel[]; // item actions && transition actions

    public multiCompatibleActions: CustomWorkflowActionModel[]; // multi compatible item actions && transition actions

    private allPrintPublicationItems$: ReplaySubject<PrintPublicationItemModel[]>;
    public activeVariant: VariantModel;

    public closeSubscription: Subject<boolean> = new Subject<boolean>();

    protected constructor(
        protected userIsAllowedToPipe: UserIsAllowedToPipe,
        protected customWorkflowService: CustomWorkflowService,
        protected publicationService: PublicationsService,
        protected dialogService: NucDialogService,
        protected fullModalService: FullModalService,
        protected monitoredTransitionsService: MonitoredTransitionsService,
        protected popOutService?: NucPopOutContentService,
        protected modalData?: IWorkflowModalData
    ) {
    }

    public ngOnInit(): void {
        this.initComponent();
    }

    protected initComponent(): void {
        this.campaign = this.customWorkflowService.campaign;
        this.customWorkflowService.addCloseCheck(this.closeSubscription);

        this.customWorkflowService.requestClose$.pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                this.requestClose();
            });

        if (this.modalData) { // Custom workflow component opened in a modal view
            this.publication = this.modalData.publication;
            this.workflow = this.modalData.workflow;
            this.component = this.modalData.component;

            this.updateActions();
            this.setupData();
        } else {
            combineLatest([
                this.customWorkflowService.publication$,
                this.customWorkflowService.workflow$,
                this.customWorkflowService.activeStep$,
                this.customWorkflowService.activeComponent$,
                this.customWorkflowService.activeFilters$
            ]).pipe(
                takeUntil(this.onDestroySubject)
            ).subscribe(([publication, workflow, step, component, filters]) => {
                this.publication = publication;
                this.workflow = workflow;
                this.step = step;
                this.component = component;
                this.activeFilters = filters;
                this.updateActions();
                this.setupData();
            });

            this.customWorkflowService.activeVariant$.pipe(takeUntil(this.onDestroySubject)).subscribe((variant) => {
                this.activeVariant = variant;
                this.activeVariantChanged();
            });
        }

        this.customWorkflowService.startTransition$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((transition: {transitionId: string, data: Record<string, any>}) => {
                this.postTransition(transition.transitionId, transition.data);
            })
    }

    public ngOnDestroy(): void {
        this.customWorkflowService.removeCloseCheck(this.closeSubscription);
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    protected findWorkflowActionByName(name: string): CustomWorkflowActionModel {
        return this.allowedActions.find((action) => action.name === name);
    }

    protected abstract setupData(): void;

    protected abstract refreshData(afterTransition: boolean): void;

    protected updateActions(): void {
        this.allowedActions = this.component.actions.filter((action) => this.userIsAllowedToPipe.transform(action.permissions));
        const transitionActions = this.allowedActions
            .filter((action) => action.type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER)
            .sort((action1, action2) => {
                const transition1 = this.workflow.transitions.find((transition) => transition._id === action1.transition);
                const transition2 = this.workflow.transitions.find((transition) => transition._id === action2.transition);
                const index1 = this.workflow.steps.findIndex((step) => step._id === transition1.to);
                const index2 = this.workflow.steps.findIndex((step) => step._id === transition2.to);
                return index1 - index2;
            });
        this.itemActions = this.allowedActions
            .filter((action) => action.type === EWorkflowConfigurationActionType.ITEM_ACTION);
        this.iterableActions = this.itemActions.concat(transitionActions);

        this.multiCompatibleActions = this.itemActions
            .filter((action) => multiCompatibleItemActions.includes(action.name as EItemActionName))
            .concat(transitionActions);

        this.briefingActions = this.allowedActions
            .filter((action) => action.type === EWorkflowConfigurationActionType.BRIEFING_ACTION);
    }

    public handleAction(action: CustomWorkflowActionModel, tableItems: PublicationItemModel[] | CampaignItemModel,
                        origin?: CdkOverlayOrigin, data?: Record<string, any>): void {
        switch (action.type) {
            case EWorkflowConfigurationActionType.TRANSITION_TRIGGER: {
                if (this.workflow.hasDestructiveTransitionRecipeTask(action.transition)) {
                    // Show confirm dialog before schedule destructive transition
                    this.openDestructiveTransitionConfirmationDialog(action.transition, tableItems as PublicationItemModel[]);
                } else {
                    const data = {itemIds: (tableItems as PublicationItemModel[]).map(item => item._id)};
                    this.postTransition(action.transition, data);
                }
                break;
            }
            case EWorkflowConfigurationActionType.ITEM_ACTION: {
                this.handleItemAction(action.name as EItemActionName, tableItems as PublicationItemModel[]);
                break;
            }
            case EWorkflowConfigurationActionType.BRIEFING_ACTION: {
                this.handleBriefingAction(action.name as EBriefingActionName, tableItems as CampaignItemModel, origin, data);
                break;
            }
            default:
                Toaster.notYetImplementedError();
        }
    }

    private handleItemAction(itemActionName: EItemActionName, publicationItems: PublicationItemModel[]): void {
        if (!multiCompatibleItemActions.includes(itemActionName) && publicationItems.length > 1) {
            Toaster.notYetImplementedError();
            return;
        }

        switch (itemActionName) {
            case EItemActionName.ASSIGN_TO_PACKAGE: { // not supporting multi selection
                this.openAssignToPackageModal(publicationItems[0]);
                break;
            }
            case EItemActionName.EDIT: { // not supporting multi selection
                this.openConfirmEditDialog(publicationItems[0]);
                break;
            }
            case EItemActionName.SIGN_OFF: { // not supporting multi selection
                this.openSignOffDialog(publicationItems[0]);
                break;
            }
            case EItemActionName.ASSIGN: { // not supporting multi selection
                this.openAssignableItemsModal(publicationItems[0] as PrintPublicationItemModel);
                break;
            }
            case EItemActionName.DOWNLOAD: {
                this.openDownloadModal(publicationItems);
                break;
            }
            default:
                Toaster.notYetImplementedError();
        }
    }

    private handleBriefingAction(actionName: EBriefingActionName, campaignItem: CampaignItemModel, origin?: CdkOverlayOrigin,
                                 data?: Record<string, any>): void {
        switch (actionName) {
            case EBriefingActionName.ASSIGN:
                this.openAssignAction(campaignItem, origin);
                break;
            case EBriefingActionName.GENERATE:
                this.openSelectionModal(campaignItem, actionName, data);
                break;
            default:
                Toaster.notYetImplementedError();
        }
    }

    private openDestructiveTransitionConfirmationDialog(transitionId: string, publicationItems: PublicationItemModel[]): void {
        const itemsTxt = publicationItems.length > 1 ? 'items' : 'item';
        const pubItemIds = publicationItems.map(item => item.publicationItemId).join(', ');

        const transition = this.workflow.getTransitionById(transitionId);

        const destructiveRecipeTask = this.workflow.findFirstDestructiveRecipeTask(transitionId);
        const dialogDescription  = CUSTOM_WORKFLOW_DESTRUCTIVE_RECIPE_TASK_NAMES[destructiveRecipeTask.name](itemsTxt, pubItemIds);
        const deleteDialogConfig = new NucDialogConfigModel(transition.name, dialogDescription);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Ok', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            const data = {itemIds: publicationItems.map(item => item._id)};
            this.postTransition(transitionId, data);
        });
    }

    protected postTransition(transitionId: string, data: Record<string, any>): void {
        this.publicationService.postTransitionItem(this.publication._id, transitionId, data)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (transitionItem) => {
                    this.refreshData(true);
                    this.monitorTransition(transitionItem._id);
                },
                error: Toaster.handleApiError
            });
    }

    private openAssignAction(campaignItem: CampaignItemModel, origin: CdkOverlayOrigin): void {
        const subject = this.getAllPrintPublicationItems();
        const ref = this.popOutService.open(AssignTableActionComponent, origin, {
            campaignId: this.campaign._id,
            publicationId: this.publication._id, campaignItem, publicationItemsSubject: subject
        });
        ref.afterClosed().subscribe((result) => {
            if (result) {
                this.refreshData(false);
            }
        });
    }

    private openSelectionModal(campaignItem: CampaignItemModel, actionName: EBriefingActionName, data?: Record<string, any>): void {
        const action = this.findWorkflowActionByName(actionName);
        const modalData: ICustomWorkflowSelectionModalData = {
            campaignItemIds: [campaignItem._id],
            campaignItem,
            publication: this.publication,
            action,
            campaign: this.campaign,
            multiSelection: false,
            hasNext: data?.hasNext,
            activeVariant: this.activeVariant
        };

        const config = new FullModalConfig(
            `Add ${this.publication.channel.getTitle()} item`,
            null,
            modalData
        );
        config.confirmClose = true;
        config.ellipsisDescription = true;

        this.fullModalService.open(CustomWorkflowSelectionModalComponent, config);
    }

    private openConfirmEditDialog(publicationItem: PublicationItemModel): void {
        if (publicationItem instanceof PrintPublicationItemModel && publicationItem.content?.length > 0) {
            const editDialogConfig = new NucDialogConfigModel('Items in layout',
                'The selected page has items in the layout. Proceeding with this action may remove all the items from the layout.');
            const editDialog = this.dialogService.openDialog(editDialogConfig);
            editDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => editDialog.close());
            editDialogConfig.addAction('I understand', BUTTON_TYPE.PRIMARY).subscribe(() => {
                editDialog.close();
                this.openEditModal(publicationItem);
            });
        } else {
            this.openEditModal(publicationItem);
        }
    }

    private openEditModal(publicationItem: PublicationItemModel): void {
        const model = publicationItem.getModel();
        this.publicationService.getPublicationItem(model, this.publication._id, publicationItem._id)
            .subscribe({
                next: (item) => {
                    const modalData: IPublicationItemFormComponentData = {
                        publication: this.publication,
                        publicationItem: item
                    };
                    const modalConfig = new FullModalConfig('Edit publication item', 'Edit your publication item tags.', modalData);
                    modalConfig.confirmClose = true;
                    const modal = this.fullModalService.open(PublicationItemFormComponent, modalConfig);
                    modal.afterClosed().pipe(filter((refresh: boolean) => refresh)).subscribe(() => {
                        this.refreshData(false);
                    });
                },
                error: Toaster.handleApiError
            });
    }

    private openSignOffDialog(publicationItem: PublicationItemModel): void {
        const confirmDialogConfig = new NucDialogConfigModel(`Sign off`,
            `Are you sure you want to approve this publication item?`);
        const confirmDialog = this.dialogService.openDialog(confirmDialogConfig);
        confirmDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
        confirmDialogConfig.addAction('Approve', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
            this.postSignOffUser(publicationItem);
        });
    }

    private postSignOffUser(publicationItem: PublicationItemModel): void {
        this.publicationService.postSignOffUser(this.publication._id, publicationItem._id, ESignOffStatus.APPROVED)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => {
                    Toaster.success('Publication item approved', 'Sign off');
                    this.refreshData(false);
                    this.customWorkflowService.setActiveFilters(this.activeFilters);
                },
                error: Toaster.handleApiError
            });
    }

    private openAssignToPackageModal(publicationItem: PublicationItemModel): void {
        const modalTitle = 'Assign to package';
        const modalConfig = new FullModalConfig(modalTitle, 'You can assign the selected Publication item to a package.');
        modalConfig.confirmClose = true;
        modalConfig.data = {
            campaignId: this.campaign._id,
            publicationId: this.publication._id,
            publicationItemId: publicationItem._id
        } as IAssignToPackageFormData;

        this.fullModalService.open(AssignToPackageFormComponent, modalConfig);
    }

    protected getTableActions(): ITableAction[] {
        // loop through all the iterable actions
        return this.iterableActions.map((action) => {
            // we use `title` as identifier for now, because `name` can be wrong in some workflow configurations
            return {title: action.title, icon: action.icon} as ITableAction;
        });
    }

    private openAssignableItemsModal(publicationItem: PrintPublicationItemModel): void {
        const modalTitle = `Page ${publicationItem.formattedPageNumbers}`;
        const modalSubtitle = 'Add or remove briefing items.';
        const modalData: IAssignableItemsOverviewModalData = {
            publicationItem,
            publication: this.publication,
            campaignId: this.campaign._id,
        };
        const config = new FullModalConfig(modalTitle, modalSubtitle, modalData);
        config.confirmClose = true;

        this.fullModalService.open(CustomWorkflowAssignableItemsOverviewComponent, config)
            .afterClosed().pipe(filter((refresh) => refresh))
            .subscribe(() => this.refreshData(false));
    }

    private openDownloadModal(publicationItems: PublicationItemModel[]): void {
        const downloadAction =
            this.iterableActions.find((action) => action.name === EItemActionName.DOWNLOAD);

        const data: IDownloadPackageTypeModalData = {
            actions: this.itemActions,
            stepId: this.step._id,
            publicationId: this.publication?._id,
            publicationItemIds: publicationItems.map((item) => item._id),
            variantId: this.activeVariant?._id,
            workflowLayoutId: this.workflow.layout?._id,
            downloadAction
        };

        // Allow only to download source files if one of pub items doesn't have a template
        if (publicationItems.some((item) => !item.template)) {
            data.allowedPackageTypes = [EPackageType.SOURCE];
        }
        const config = new FullModalConfig(
            'Download publication ' + `${publicationItems?.length === 1 ? 'item' : 'items'}`,
            'Choose the package type',
            data);

        this.fullModalService.open(DownloadPackageComponent, config);
    }

    /**
     * Get all the campaignItem data fields using a Subject to prevent doing unnecessary requests.
     * @return {ReplaySubject<PublicationItemModel[]>}
     */
    public getAllPrintPublicationItems(): ReplaySubject<PrintPublicationItemModel[]> {

        if (this.allPrintPublicationItems$) {
            return this.allPrintPublicationItems$;
        }
        this.allPrintPublicationItems$ = new ReplaySubject<PrintPublicationItemModel[]>(1);
        this.publicationService.getAllItemsForPublication(PrintPublicationItemModel, this.publication._id, null, null).pipe(
            takeUntil(this.onDestroySubject))
            .subscribe((publicationItems: PrintPublicationItemModel[]) => {
                    this.allPrintPublicationItems$.next(publicationItems);
                }
            );
        return this.allPrintPublicationItems$;
    }

    protected requestClose(): void {
        // Base implementation will always allow close of the component
        this.closeSubscription.next(true);
    }

    /**
     * extend function to do updates after variant changes
     * @protected
     */
    protected activeVariantChanged(): void {

    }

    protected monitorTransition(transitionItemId: string): void {
        // Listen to transition progress
        this.monitoredTransitionsService.getTransitionMonitor(transitionItemId)
            .pipe(
                filter((transitionItem) => [ETransitionStatus.FAILED, ETransitionStatus.DONE].includes(transitionItem.status)),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((transitionItem) => {
                const finishedTransition = this.workflow.transitions.find(transition => transition._id === transitionItem.transition);
                // Transition FAILED or DONE with a self transition, call refreshData
                if (transitionItem.status === ETransitionStatus.FAILED ||
                    (transitionItem.status === ETransitionStatus.DONE && finishedTransition.from === finishedTransition.to)) {
                    this.refreshData(false);
                }
            });
    }
}
