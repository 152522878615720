<form *ngIf="formGroup" [formGroup]="formGroup">
    <div>
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Step from">
            <nuc-dropdown formControlName="from"
                          [total]="steps.length"
                          [items]="steps"
                          placeholder="Select a step">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Step to">
            <nuc-dropdown formControlName="to"
                          [total]="steps.length"
                          [items]="steps"
                          placeholder="Select a step">
            </nuc-dropdown>
        </nuc-form-field>

        <div formArrayName="recipe">
            <p class="body-strong">Recipe tasks</p>
            <p>Optional, add one or more recipe tasks to the transition</p>
            <hr>

            <ng-container *ngFor="let control of recipeFormArray?.controls; let index = index">
                <div class="recipe" [formGroup]="control">
                    <div class="recipe-form">
                        <nuc-form-field label="Task">
                            <nuc-dropdown formControlName="name"
                                          placeholder="Select recipe task"
                                          [total]="recipeTasks.length"
                                          [items]="recipeTasks"
                                          [required]="true">
                            </nuc-dropdown>
                            <nuc-error *ngIf="control.errors">{{control.errors.message}}</nuc-error>
                        </nuc-form-field>

                        <nuc-form-field *ngIf="control.value.name?.getValue().recipeTaskType === ERecipeTaskType.PER_VARIANT" label="Variants">
                            <nuc-dropdown-multiselect
                                formControlName="parameters"
                                placeholder="Select specific variants"
                                [items]="variants">
                            </nuc-dropdown-multiselect>
                        </nuc-form-field>
                    </div>
                    <div class="button">
                        <div></div>
                        <nuc-button-secondary icon="nucicon_trash_fill"
                                              (click)="deleteRecipeTask(index)">
                        </nuc-button-secondary>
                    </div>
                </div>
            </ng-container>
            <div class="add-button">
                <nuc-button-secondary text="Add recipe task" icon="nucicon_add" iconPosition="end"
                                      (click)="addRecipeTask()">
                </nuc-button-secondary>
            </div>
        </div>
    </div>
</form>
