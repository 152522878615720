<ng-container *ngIf="permissions.GET_CAMPAIGN_ITEMS | userIsAllowedTo; else noPermissionState">
    <nuc-table [useButtonBar]="true"
               [useEmptyState]="true"
               [emptyStateTitle]="filtersSet || phraseValue ? 'No item matches your search criteria.' : 'You have to add a briefing item.'"
               [items]="campaignItems"
               [columns]="columns"
               [actions]="actions"
               [selectionMode]="selectionMode"
               [editMode]="!tableLocked"
               [loading]="!campaignItemsSubscription?.closed || isLoading"
               (itemEdited)="onItemEdited($event)"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="actionClicked.emit($event)"
               [selection]="selection"
               [stickyHeader]="stickyHeader">
        <ng-container button-bar-left>
            <nuc-suggestive-input
                    [options]="autocompleteValues"
                    prefix="nucicon_search"
                    [enableNewValue]="false"
                    (searchTermChange)="onSearchTermChanged($event)"
                    (selectionChanged)="onSearchSelectionChanged($event)"
                    placeholder="Search briefing">
            </nuc-suggestive-input>
            <rl-data-filter [addDataFieldsContext]="EDataFieldCollectionName.CAMPAIGN_ITEM"
                            [variantKey]="selectedVariant?.getValue()"
                            [requestOptions]="requestOptions">
            </rl-data-filter>
        </ng-container>
        <ng-container button-bar-left-after
                      *ngIf="(briefingTableOptions.inlineEditing && (permissions.PUT_CAMPAIGN_ITEM | userIsAllowedTo)) || variantEnabled">
            <nuc-button-secondary
                    *ngIf="briefingTableOptions.inlineEditing && (permissions.PUT_CAMPAIGN_ITEM | userIsAllowedTo)"
                    [icon]="tableLocked ? 'nucicon_lock_fill': 'nucicon_unlock'"
                    (mouseenter)="unlockTableTooltipText = tableLocked ? 'Unlock table' : 'Lock table'"
                    (click)="tableLocked = !tableLocked"
                    [nucTooltip]="unlockTableTooltipText">
            </nuc-button-secondary>
            <nuc-dropdown *ngIf="variantEnabled"
                          icon="nucicon_variant"
                          class="variant variant-highlight"
                          placeholder="Select variant"
                          [nullOption]=false
                          [data]="selectedVariant"
                          (dataChange)="selectVariant($event)"
                          [items]="variants">
            </nuc-dropdown>
        </ng-container>
        <paginator button-bar-right
                   [viewId]="viewId"
                   [disableNextPage]="!hasNext"
                   [loading]="!campaignItemsSubscription?.closed || isLoading">
        </paginator>
        <nuc-badge-warning button-bar-footer @unlockedBadgeAnimation class="table-unlocked-label" *ngIf="!tableLocked">
            Table data is
            unlocked
        </nuc-badge-warning>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
