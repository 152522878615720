import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS,
    CustomWorkflowTransitionModel,
    CustomWorkflowTransitionRecipeTaskModel,
    ERecipeTaskType,
    RecipeTaskConfig
} from '../../models/api/custom-workflow-transition.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {Toaster} from '../../classes/toaster.class';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';

export interface IWorkflowConfigurationTransitionFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    workflowConfigurationSteps: CustomWorkflowStepModel[];
    workflowConfigurationTransition?: CustomWorkflowTransitionModel;
}

@Component({
    selector: 'workflow-configuration-transition-form-component',
    templateUrl: 'workflow-configuration-transition-form.component.html',
    styleUrls: ['workflow-configuration-transition-form.component.scss']
})
export class WorkflowConfigurationTransitionFormComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;
    private saveButton: ButtonConfig;

    private onDestroySubject = new Subject<void>();
    private workflowConfiguration: WorkflowConfigurationModel;
    public workflowConfigurationTransition: CustomWorkflowTransitionModel;
    public steps: DropdownItem<string>[];
    public recipeTasks: DropdownItem<RecipeTaskConfig>[];
    public variants: VariantModel[];

    public readonly ERecipeTaskType = ERecipeTaskType;

    get recipeFormArray() {
        return this.formGroup.controls['recipe'] as FormArray;
    }

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationTransitionFormData) {
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationStep());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.workflowConfigurationTransition = this.modalData.workflowConfigurationTransition || new CustomWorkflowTransitionModel();
        this.steps = this.modalData.workflowConfigurationSteps.map(step =>
            new DropdownItem(step.name, step._id, false, step.icon));
        // Filter recipe tasks on publication type
        this.recipeTasks = CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS
            // Filter out recipe tasks not allowed for the publication type, except when recipe task already configured (this
            // will generate an invalid form)
            .filter(config =>
                !config.publicationTypes ||
                config.publicationTypes.includes(this.workflowConfiguration.publicationType.name) ||
                this.workflowConfigurationTransition.recipe?.find(recipe => recipe.name === config.task)
            )
            .map(config => new DropdownItem(config.title, config));

        this.variantService.getVariants()
            .pipe(takeUntil((this.onDestroySubject)))
            .subscribe({
                next: result => {
                    this.variants = result.items;

                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {
        const stepFrom = this.steps.find(step => step.getValue() === this.workflowConfigurationTransition.from);
        const stepTo = this.steps.find(step => step.getValue() === this.workflowConfigurationTransition.to);
        const recipeTasks = this.workflowConfigurationTransition.recipe?.map(
            recipe => {
                const task: {name: IDropdownItem<RecipeTaskConfig>; parameters?: Record<string, any>} = {
                    name: this.recipeTasks.find(recipeTask => recipe.name === recipeTask.getValue().task)
                };

                if (task.name.getValue().recipeTaskType === ERecipeTaskType.PER_VARIANT) {
                    task.parameters = this.variants.filter(variant => recipe.parameters?.variants?.includes(variant._id));
                } else {
                    task.parameters = recipe.parameters;
                }

                return task;
            }
        ) || [];


        this.formGroup = new FormGroup<any>({
            name: new FormControl(this.workflowConfigurationTransition.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            from: new FormControl(stepFrom, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            to: new FormControl(stepTo, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            recipe: new FormArray(
                recipeTasks.map(task => new FormGroup({
                    name: new FormControl(task.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                    parameters: new FormControl(task.parameters)
                })), [(recipeArray: FormArray) => {
                    // Check for every recipe task its conditions
                    for (const [index, recipeTask] of recipeArray.controls.entries()) {
                        this.validateRecipeTasks(recipeTask as FormGroup, index, recipeArray);
                    }
                    return null;
                }]
            )
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((status: string) => this.saveButton.disabled = status !== 'VALID');

        this.formGroup.patchValue({
            name: this.workflowConfigurationTransition.name,
            from: stepFrom,
            to: stepTo,
            recipe: recipeTasks
        });
    }

    private saveWorkflowConfigurationStep(): void {
        const transition = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            from: this.formGroup.value.from?.getValue(),
            to: this.formGroup.value.to?.getValue(),
            recipe: this.recipeFormArray.controls.map(recipeControl => {
                const recipe = {
                    name: recipeControl.value.name.getValue().task
                } as CustomWorkflowTransitionRecipeTaskModel;
                if (recipeControl.value.name.getValue().recipeTaskType === ERecipeTaskType.PER_VARIANT) {
                    recipe.parameters = recipe.parameters || {};
                    recipe.parameters.variants = recipeControl.value.parameters?.map(variant => variant._id);
                } else if (this.workflowConfigurationTransition._id) {
                    // TODO: Keep current value, till it has its own form to update
                    recipe.parameters = recipeControl.value.parameters ? recipeControl.value.parameters : undefined;
                }

                return recipe;
            })
        }, this.workflowConfigurationTransition._id);

        if (this.workflowConfigurationTransition._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationTransition(this.workflowConfiguration._id,
                this.workflowConfigurationTransition._id, transition)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Transition updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationTransition(this.workflowConfiguration._id, transition)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Transition created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public addRecipeTask(): void {
        this.recipeFormArray.push(new FormGroup({
            name: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            parameters: new FormControl()
        }));
    }

    public deleteRecipeTask(index: number): void {
        this.recipeFormArray.removeAt(index);
    }

    private validateRecipeTasks(task: FormGroup, index: number, tasksArray: FormArray): void  {
        const recipeName = task.controls['name'] as FormControl;
        if (recipeName.dirty && recipeName.invalid) {
            task.setErrors({message: 'Recipe task is required'});
            return;
        }

        const config = recipeName.value?.getValue();
        if (!config) return; // Recipe task still empty (only added)

        if (config.publicationTypes && !config.publicationTypes.includes(this.workflowConfiguration.publicationType.name)) {
            task.setErrors({message: `Recipe task not allowed for publication type '${this.workflowConfiguration.publicationType.name}'`});
            return;
        }

        for (const [otherIndex, recipeTask] of tasksArray.controls.entries()) {
            const otherTask = (recipeTask as FormGroup).controls['name'] as FormControl;
            const otherTaskValue = otherTask.value?.getValue();
            if (otherTaskValue && config.task === otherTaskValue.task && index !== otherIndex) {
                task.setErrors({message: `Duplicated recipe task ${config.title}`});
                return;
            }
        }

        if (config.allowedTasksBefore?.length === 0 && index !== 0) { // means no task is allowed before this task
            task.setErrors({message: 'This recipe task must be the first one in the list'});
            return;
        }

        if (config.allowedTasksBefore?.length > 0 && index !== 0) { // means there are tasks before this
            // check if the recipe task before this one is allowed
            const taskBeforeFormGroup = tasksArray.controls[index - 1] as FormGroup;
            const taskBeforeValue = taskBeforeFormGroup.controls['name'].value.getValue();

            if (!config.allowedTasksBefore.includes(taskBeforeValue.task)) {
                task.setErrors({message: `This task cannot be after ${taskBeforeValue.title}`});
                return;
            }
        }

        for (const recipeTask of tasksArray.controls.values()) {
            const otherTask = (recipeTask as FormGroup).controls['name'] as FormControl;
            const otherTaskValue = otherTask.value?.getValue();
            if (otherTaskValue?.excludeTasks?.includes(config.task)) {
                task.setErrors({message: `This task can not be combined with recipe task ${otherTaskValue.title}`});
                return;
            }
        }

        if (config.onlyTask && tasksArray.controls.length > 1) {
            task.setErrors({message: 'This recipe task cannot be selected with other recipe tasks'});
            return;
        }

        task.setErrors(null);
    }
}
