import {autoserialize, autoserializeAs} from 'cerialize';
import {CustomWorkflowStepModel} from './custom-workflow-step.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ICustomWorkflowCardNavigationItem} from '../interfaces/custom-workflow-card-navigation-item.interface';
import {TemplateModel} from './template.model';
import {PublicationItemContentModel} from './publication-item-content.model';
import {CampaignItemModel} from './campaign-item.model';
import {ITableItem} from '@relayter/rubber-duck';
import {UserModel} from './user.model';
import {ETemplateType} from '../../pages/relayter/templates/template-detail/template-size/template-size.model';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {AppConstants} from '../../app.constants';

/**
 * publication-item.model
 * app-relayter-web
 * Created by borisnelissen on 11/02/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
export class PublicationItemFilesModel {
    @autoserialize public thumb: string;
    @autoserialize public preview: string;
    @autoserialize public export: string;
    @autoserialize public source: string;
    @autoserialize public sourceCreatedAt: string;
}

// only used here
class PackageItemDataModel {
    @autoserialize public _id: string;
    @autoserializeAs(Object) public dataFields: Record<string, any>;
}

export enum ESignOffStatus {
    APPROVED = 'APPROVED'
}

export class SignOffUserModel {
    @autoserializeAs(UserModel) public user: UserModel;
    @autoserialize public date: Date;
    @autoserialize public status: ESignOffStatus;
}

export class SignOffModel {
    @autoserializeAs(SignOffUserModel) public signOffUsers: SignOffUserModel[];
}

export class ContentRevisionModel implements IDropdownItem<PublicationItemContentModel[]> {
    @autoserialize public date: Date;
    @autoserialize public revision: string;
    @autoserializeAs(PublicationItemContentModel) public content: PublicationItemContentModel[];

    getTitle(): string {
        return `${this.revision} - ${RLDatePipe.format(this.date, RLDatePipe.dateFormats.TABLE_DETAILED)}`;
    }

    getValue(): PublicationItemContentModel[] {
        return this.content;
    }
}

export class VariantsFileModel {
    @autoserializeAs(PublicationItemFilesModel) public files: PublicationItemFilesModel;
    @autoserialize public variant: string;
}

export enum EContentChangeAction {
    REMOVED = 'REMOVED',
    ADDED = 'ADDED',
    EDITED = 'EDITED'
}

export class ContentChangeModel {
    public type: string;
    public action: EContentChangeAction;
    // for Added content, contentItem is the newly added item.
    // for Removed content, contentItem is the removed item.
    // for Edited content, contentItem is the item before edited.
    public contentItem: PublicationItemContentModel;
}

export class FilesRevisionModel implements IDropdownItem<PublicationItemFilesModel> {
    @autoserialize public date: Date;
    @autoserialize public revision: string;
    @autoserializeAs(PublicationItemFilesModel) public files: PublicationItemFilesModel;
    @autoserialize public variant: string;

    getTitle(): string {
        return `${this.revision} - ${RLDatePipe.format(this.date, RLDatePipe.dateFormats.TABLE_DETAILED)}`;
    }

    getValue(): PublicationItemFilesModel {
        return this.files;
    }
}

export class PublicationItemAttachmentFiles {
    @autoserialize public thumbnail: string;
    @autoserialize public source: string;
}

export class PublicationItemAttachment {
    @autoserialize public _id: string;
    @autoserialize public fileName: string;
    @autoserialize public size: string;
    @autoserializeAs(PublicationItemAttachmentFiles) public files: PublicationItemAttachmentFiles;
}

export class PublicationItemModel implements IDropdownItem, ICustomWorkflowCardNavigationItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public publicationItemId: string; // TODO: We probably want to refactor this to filename
    @autoserialize public publication: string;
    @autoserializeAs(TemplateModel) public template: TemplateModel;
    @autoserializeAs(PublicationItemContentModel) public content: PublicationItemContentModel[];
    @autoserializeAs(VariantsFileModel, 'files') public files: VariantsFileModel[];
    @autoserialize public firstPageNumber: number; // TODO: Move to Interface?
    @autoserialize public numberOfPages: number;
    @autoserializeAs(CustomWorkflowStepModel) public step: CustomWorkflowStepModel;
    @autoserialize public inTransition: boolean;
    @autoserialize public tags: string[];
    @autoserialize public createdAt: Date;
    @autoserialize public updatedAt: Date;
    @autoserializeAs(SignOffModel) public signOff: SignOffModel;
    @autoserializeAs(CampaignItemModel) public campaignItems: CampaignItemModel[];
    @autoserializeAs(ContentRevisionModel) public contentRevisions: ContentRevisionModel[];
    @autoserializeAs(FilesRevisionModel, 'filesRevisions') public filesRevisions: FilesRevisionModel[];
    @autoserializeAs(Date) public deadline: Date;
    @autoserializeAs(PublicationItemAttachment) public attachments: PublicationItemAttachment[] = [];

    public previewImage = '';
    public sourceFile = '';

    // calculated field, DO NOT USE PackageItemModel to avoid Circular dependency
    @autoserializeAs(PackageItemDataModel) public packageItem: PackageItemDataModel;
    // field that's used to link briefing changes, not from backend
    public changedCampaignItems: CampaignItemModel[] = [];

    // Normally only used for print publications, but added to the base PublicationItem class, because we cannot deserialize generic types
    // (and used as reference in other models, like the StickyNoteModel)
    get formattedPageNumbers(): string {
        return this.numberOfPages === 1
            ? this.firstPageNumber.toString() : `${this.firstPageNumber}-${this.firstPageNumber + this.numberOfPages - 1}`;
    }

    get pageType(): ETemplateType {
        return this.numberOfPages === 1 ? ETemplateType.Single : ETemplateType.Spread;
    }

    public getVariantFilesRevisions(variant?: string): FilesRevisionModel[] {
        if (variant) {
            return this.filesRevisions?.filter((filesRevisions: FilesRevisionModel) => filesRevisions.variant === variant) || [];
        }
        return this.filesRevisions || [];
    }

    public getVariantFiles(variant?: string): PublicationItemFilesModel {
        if (variant) {
            const variantFile = this.files.filter((files: VariantsFileModel) => files.variant === variant)[0];
            return variantFile?.files;
        } else {
            return this.files[0]?.files;
        }
    }

    /**
     * Used as value for the cursor
     * Default sort is _id, so we get _id value as sortValue
     */
    public get sortValue(): string {
        return this._id;
    }

    public getModel(): typeof PublicationItemModel {
        return PublicationItemModel;
    }

    /**
     * Returns a display title for use in the custom workflow upload component
     * @return {string}
     */
    public getUploadDisplayName(): string {
        // For now, we pick the first content campaign item and get the briefing item id
        if (Array.isArray(this.content)) {
            for (const content of this.content) {
                if (content.campaignItem) {
                    return content.campaignItem.briefingItemId;
                }
            }
        }

        return null;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getTitle(): string {
        return this.publicationItemId;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getValue(): string {
        return this._id;
    }

    public getThumbnailURL(variant?: string): string {
        return this.getVariantFiles(variant)?.thumb;
    }

    /**
     * Get the variant specific thumbnail if variant available
     * @param {string} variantId
     * @param {VariantsFileModel[]} files
     * @return {string}
     * @private
     */
    public static getThumbnailUrl(variantId: string, files: VariantsFileModel[]): string {
        if (!(Array.isArray(files) && files.length)) {
            return AppConstants.ICONS.IMAGE_MAIN;
        }

        if (variantId) {
            const variantFile = files?.find((item) => item.variant === variantId);
            return variantFile?.files.thumb ? variantFile.files.thumb : AppConstants.ICONS.IMAGE_MAIN;
        } else {
            return files[0]?.files.thumb ? files[0].files.thumb : AppConstants.ICONS.IMAGE_MAIN;
        }
    }

    /**
     * Interface method
     * @return {string}
     */
    public getNavigationItemTitle(): string {
        return this.publicationItemId;
    }

    /**
     * Interface method
     * @return {string}
     */
    public getNavigationItemSubtitle(): string {
        return null;
    }

    /**
     * Checks differences between 2 layouts and returns an array of changes
     * @param contentOld
     * @param contentNew
     */
    public static diffRevision(contentOld: PublicationItemContentModel[],
                               contentNew: PublicationItemContentModel[]): ContentChangeModel[] {
        const changes = [];

        contentOld.forEach((cItemOld) => {
            const change = new ContentChangeModel();
            change.type = cItemOld.contentType;

            const cItemNew = PublicationItemModel.findContentItem(cItemOld, contentNew);

            if (cItemNew === undefined) {
                change.action = EContentChangeAction.REMOVED;
                change.contentItem = cItemOld;
                changes.push(change);
            } else if (
                cItemNew.area._id !== cItemOld.area._id
                || !cItemOld.area.isEqual(cItemNew.area)
            ) {
                change.action = EContentChangeAction.EDITED;
                change.contentItem = cItemOld;
                changes.push(change);
            }
        });

        contentNew.forEach((cItemNew) => {
            const cItemOld = PublicationItemModel.findContentItem(cItemNew, contentOld);

            if (cItemOld === undefined) {
                const change = new ContentChangeModel();
                change.type = cItemNew.contentType;
                change.action = EContentChangeAction.ADDED;
                change.contentItem = cItemNew;
                changes.push(change);
            }
        });

        return changes;
    }

    /**
     * Finds contentItem in array of content. Note matching will be done by note message. If an asset or briefing item
     * is in the layout twice it will also check the area _id.
     * @param contentItem
     * @param content
     */
    public static findContentItem(contentItem, content: PublicationItemContentModel[]): PublicationItemContentModel {
        switch (contentItem.contentType) {
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM: {
                const item = content.find((c) =>
                    c.contentType === contentItem.contentType &&
                    c.campaignItem && contentItem.campaignItem &&
                    c.campaignItem._id === contentItem.campaignItem._id &&
                    c.area._id === contentItem.area._id);

                if (item) {
                    return item;
                } else {
                    return content.find((c) =>
                        c.contentType === contentItem.contentType &&
                        c.campaignItem === contentItem.campaignItem);
                }
            }
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET: {
                const item = content.find((c) =>
                    c.contentType === contentItem.contentType &&
                    c.asset && contentItem.asset &&
                    c.asset._id === contentItem.asset._id &&
                    c.area._id === contentItem.area._id);

                if (item) {
                    return item;
                } else {
                    return content.find((c) =>
                        c.contentType === contentItem.contentType &&
                        c.asset && contentItem.asset &&
                        c.asset._id === contentItem.asset._id);
                }
            }
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.NOTE:
                return content.find((c) =>
                    c.contentType === contentItem.contentType &&
                    c.note.message === contentItem.note.message);
        }
    }
}
