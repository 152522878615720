<ng-container *ngIf="librarySubscription?.closed; else loading">

    <ng-container *ngIf="library; else error">

        <h1>{{library.name}}</h1>

        <div class="grid" *ngIf="library.items.length; else empty">

            <nuc-large-card-view *ngFor="let item of library.items"
                                 [title]="item.name"
                                 [image]="item.files.preview">

                <div content>
                    <p class="body-strong">Tags</p>
                    <span>{{item.tags | join}}</span>
                </div>

            </nuc-large-card-view>

        </div>

    </ng-container>

</ng-container>

<ng-template #loading>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #empty>
    <nuc-empty-state-component
        imageUrl="assets/images/empty_states/indesign-library.svg"
        title="Empty InDesign library"
        subtitle="It appears that this library has no items. Please reupload the library.">
    </nuc-empty-state-component>
</ng-template>

<ng-template #error>

    <nuc-empty-state-component
        imageUrl="assets/images/error_states/indesign-library.svg"
        title="Cannot load the InDesign library"
        subtitle="Please return to the InDesign library overview page.">
    </nuc-empty-state-component>

</ng-template>
