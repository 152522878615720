import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    BUTTON_TYPE,
    NucDialogConfigModel, NucDialogService
} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../../models/api/campaign-item.model';
import {CampaignItemsService} from '../../../api/services/campaign-items.service';
import {ProductModel} from '../../../models/api/product.model';
import {EFormContext} from '../../../models/api/form.model';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {
    CustomWorkflowService
} from '../../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow.service';
import {VariantModel} from '../../../models/api/variant.model';
import {VariantService} from '../../../api/services/variant.service';
import {CampaignItemFormComponentDirective} from '../campaign-item-form-component.directive';

@Component({
    selector: 'campaign-item-inline-form',
    templateUrl: './campaign-item-inline-form.component.html',
    styleUrls: ['./campaign-item-inline-form.component.scss']
})
export class CampaignItemInlineFormComponent extends CampaignItemFormComponentDirective implements OnInit, OnChanges {
    public readonly = false;
    public readonly FORM_CONTEXT_BRIEFING = EFormContext.BRIEFING;

    public title: string = 'Edit briefing item';

    public buttonTypes = BUTTON_TYPE;

    @Output()
    public closeEvent: EventEmitter<CampaignItemModel> = new EventEmitter();

    @Input() protected campaignId: string;
    @Input() public isLoading: boolean = true;

    public dataFields: Record<string, any>;
    public selectedProducts: ProductModel[] = [];
    public variants: VariantModel[];

    constructor(private dialogService: NucDialogService,
                private variantService: VariantService,
                campaignItemService: CampaignItemsService,
                userIsAllowedToPipe: UserIsAllowedToPipe,
                private customWorkflowDataService: CustomWorkflowService) {
        super(campaignItemService, userIsAllowedToPipe);
    }

    public ngOnInit(): void {
        this.variantService.getVariants(this.campaignId)
            .pipe(
                switchMap((data: {items: VariantModel[]}) => {
                    this.variants = data.items;

                    return this.customWorkflowDataService.editCampaignItemDetails$;
                }),
                takeUntil(this.onDestroySubject)
            )
            .subscribe(
                (campaignItem: CampaignItemModel) => {
                    this.campaignItem = campaignItem;
                    this.setupForm();
                });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // Disable Next/Save button on loading
        if (changes.isLoading && this.confirmButton && !this.readonly) {
            this.confirmButton.disabled = this.isLoading;
        }
    }

    protected initButtons(): void {
        super.initButtons();

        if (this.readonly) {
            this.title = 'View briefing item';
        } else {
            this.title = 'Edit briefing item';
        }
    }

    protected initFormData(): void {
        this.form.reset();
        this.selectedProducts = this.campaignItem?.products || [];
        this.dataFields = this.campaignItem?.dataFields || {};
    }

    /** Announces user clicks on action buttons */
    public buttonClicked(actionSubject: Subject<void>): void {
        actionSubject.next();
    }

    public beforeCloseForm(): void {
        if (this.isFormTouched()) {
            const dialogConfig = new NucDialogConfigModel('Edit briefing item.', 'There are unsaved changes that will be lost.');
            const dialog = this.dialogService.openDialog(dialogConfig);
            dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
            dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                dialog.close();
                this.closeForm();
            });
        } else {
            this.closeForm();
        }
    }

    public isFormTouched(): boolean {
        return this.form.touched && !this.readonly;
    }

    protected closeForm(result?: CampaignItemModel) {
        this.closeEvent.emit(result);
    }
}
