import {Component, Input, Renderer2} from '@angular/core';
import {
    AbstractControlOptions,
    AsyncValidatorFn, FormArray,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {BaseRulesetItemFormComponent} from '../base-ruleset-item-form.component';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {v4} from 'uuid';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {BUTTON_TYPE} from '@relayter/rubber-duck';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {AppConstants} from '../../../../app.constants';

export class ConditionGroups extends FormGroup {

    constructor(
        controls?: { [key: string]: any },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        const defaultControls = {
            operator: new FormControl(BaseRulesetItemFormComponent.GROUP_OPTIONS[0], Validators.required),
            groups: new FormArray<ConditionGroup>([], Validators.minLength(1))
        };
        super(defaultControls, validatorOrOpts, asyncValidator);
    }

    get groups() {
        return this.controls['groups'] as FormArray<ConditionGroup>;
    }

    set groups(groups: FormArray<ConditionGroup>) {
        this.controls['groups'] = groups;
    }

    get operator() {
        return this.controls['operator'] as FormControl;
    }

    setOperator(value: IDropdownItem) {
        this.operator.patchValue(value);
    }

    addGroup(group: ConditionGroup) {
        this.groups.push(group);
    }

    validate(): void {
        this.groups.updateValueAndValidity();
    }
}

export class ConditionGroup extends FormGroup {
    public id = v4();

    get rules() {
        return this.controls['rules'] as FormArray<FormGroup>;
    }

    get operator() {
        return this.controls['operator'] as FormControl;
    }

    setOperator(value: IDropdownItem) {
        this.operator.patchValue(value);
    }

    constructor(operator?: IDropdownItem, rule?: any) {
        const controls = {
            operator: new FormControl(BaseRulesetItemFormComponent.GROUP_OPTIONS[0], Validators.required),
            rules: new FormArray<FormGroup>([], Validators.minLength(1))
        };
        super(controls);
        if (operator) {
            this.setOperator(operator);
        }
        if (rule) {
            this.rules.push(rule);
        }

    }

    addRule(rule: FormGroup): void {
        this.rules.push(rule);
    }
}

@Component({
    selector: 'condition-group-form',
    templateUrl: './condition-group-form.component.html',
    styleUrls: ['./condition-group-form.component.scss']
})
export class ConditionGroupFormComponent{
    @Input()
    public conditionGroups: ConditionGroups;

    @Input()
    public ruleSetProperties: RulePropertyModel[];
    public BUTTON_TYPES = BUTTON_TYPE;
    public groupOptions = BaseRulesetItemFormComponent.GROUP_OPTIONS;

    public RULESET_OPERATORS = AppConstants.RULESET_OPERATORS;

    private mouseUpListener;
    private mouseLeaveListener;

    private afterDragConditionsFormHandler = () => {
        this.removeEmptyGroups();
        if (this.mouseUpListener) this.mouseUpListener();
        if (this.mouseLeaveListener) this.mouseLeaveListener();
    };

    constructor(private renderer2: Renderer2) {

    }
    protected removeEmptyGroups(): void {
        this.conditionGroups.groups.controls = this.conditionGroups.groups.controls.filter((con) => {
            return con['rules'].controls.length > 0;
        });
        this.conditionGroups.validate();
    }

    public getConnectedList(): any[] {
        return this.conditionGroups.groups.controls.map(x => {
            return x.id;
        });
    }

    public deleteConditionClicked(index: number, groupId: string) {
        const conditionGroup: ConditionGroup = this.conditionGroups.groups.controls.find(group => group['id'] === groupId) as ConditionGroup;
        conditionGroup.rules.controls.splice(index, 1);
        this.removeEmptyGroups();
    }

    public moveArrayItem<T = any>(
        currentArray: T[],
        targetArray: T[],
        currentIndex: number,
        targetIndex: number,
    ): void {
        const from = this.clamp(currentIndex, currentArray.length - 1);
        const to = this.clamp(targetIndex, targetArray.length);

        if (currentArray.length) {
            targetArray.splice(to, 0, currentArray.splice(from, 1)[0]);
        }
        this.removeEmptyGroups();
    }

    /** Clamps a number between zero and a maximum. */
    private clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    public dropItem(event: CdkDragDrop<any>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            this.moveArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    public addNewContainer(): void {
        const newGroup = new ConditionGroup();
        this.conditionGroups.addGroup(newGroup);

        this.mouseUpListener = this.renderer2.listen('body', 'mouseup', this.afterDragConditionsFormHandler);
        this.mouseLeaveListener = this.renderer2.listen('body', 'mouseleave', this.afterDragConditionsFormHandler);
    }
}
