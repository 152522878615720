import {Component, OnDestroy, OnInit} from '@angular/core';
import {PermissionModel} from '../../../../models/api/permission.model';
import {ActivatedRoute, Router} from '@angular/router';
import {RolesService} from '../../../../api/services/roles.service';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {ErrorConstants} from '../../../../api/error.constants';
import {RoleModel} from '../../../../models/api/role.model';
import {UserService} from '../../../../api/services/users.service';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode, ESortOrder,
    ISortOptionEvent,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {PermissionsService} from '../../../../api/services/permissions.service';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {SelectionModel} from '@angular/cdk/collections';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {RoleDetailContainerService} from '../role-detail-container.service';
import {IDeactivateComponent} from '../../routes/guards/base/deactivate-component.interface';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';

@Component({
    selector: 'rl-role-permissions.component',
    templateUrl: 'role-permissions.component.html',
    styleUrls: ['role-permissions.component.scss'],
    providers: [PaginatorService]
})

export class RolePermissionsComponent extends RLTableComponent implements OnInit, OnDestroy, IDeactivateComponent {
    public tableId = 'role-permissions';
    public roleId: string;
    public role: RoleModel;
    public items: PermissionModel[] = [];
    public pageIndex: number;
    public pageSize: number;
    public total: number;
    public rowClickMode: string = AppConstants.TABLE_ROW_CLICK_MODES.NON_CLICKABLE;
    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        type: EColumnType.DEFAULT,
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Description',
        key: 'description',
        type: EColumnType.DEFAULT,
        sortProperty: 'description',
        size: EColumnSize.LARGE
    }];
    public ESelectionMode = ESelectionMode;
    public selection = new SelectionModel<string>(true, [], false);
    private onDestroySubject = new Subject<void>();
    public permissionsSubscription = new Subscription();
    public requestSubscription = new Subscription();

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private rolesService: RolesService,
                private permissionsService: PermissionsService,
                private dialogService: NucDialogService,
                private paginatorService: PaginatorService,
                private roleDetailContainerService: RoleDetailContainerService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.roleId = this.roleDetailContainerService.getRoleId();

        this.getRoleDetails();

        this.subscribeToRouteParams();

        this.paginatorService.getPagination(this.tableId).subscribe((result: { pageIndex: number; pageSize: number }) => {
            this.pageIndex = result.pageIndex;
            this.pageSize = result.pageSize;

            this.updateUrl();

            this.getPermissions();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Check if the confirm dialog needs to be shown on leaving the page
     * @return {boolean}
     */
    public canDeactivate(): boolean {
        if (!this.role) return true;

        const selectedValues = this.selection.selected;

        return selectedValues.every(permission => this.role.permissions.includes(permission)) &&
            this.role.permissions.every(permission => selectedValues.includes(permission));
    }

    private subscribeToRouteParams(): void {
        this.route.params.pipe(takeUntil(this.onDestroySubject)).subscribe(params => {
            const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            const sortProperty = params['sortProperty'];
            const sortOrder = params['sortOrder'] ? params['sortOrder'] : null;
            const searchValue = params['search'] ? params['search'] : null;

            // Only on change get new data
            if (pageIndex !== this.pageIndex ||
                sortProperty !== this.sortProperty ||
                sortOrder !== this.sortOrder ||
                searchValue !== this.searchValue) {
                this.pageIndex = pageIndex;
                this.sortProperty = sortProperty;
                this.sortOrder = sortOrder;
                this.searchValue = searchValue;

                this.setPageIndex(this.pageIndex);
            }

            this.updateUrl();
        });
    }

    private updateUrl(): void {
        this.router.navigate([this.getMatrixUrl()], {relativeTo: this.route, replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    /**
     * Gets details for role
     */
    private getRoleDetails(): void {
        this.requestSubscription = this.rolesService.getRoleWithId(this.roleId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe( // Front end page index starts from 1 instead of 0, api starts from 0.
                (role: RoleModel) => {
                    this.setRoleDetails(role);
                },
                (err: ARApiError) => {
                    Toaster.handleApiError(err);
                    if (err.code === ErrorConstants.API_ERROR_CODES.NOT_FOUND_ROLE) {
                        this.router.navigateByUrl(AppConstants.ROLES_PATH);
                    }
                }
            );
    }

    /**
     * Gets all permissions
     */
    private getPermissions(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PERMISSIONS)) return;

        if (this.permissionsSubscription && !this.permissionsSubscription.closed) {
            this.permissionsSubscription.unsubscribe();
        }

        this.permissionsSubscription = this.permissionsService.getTeamsPermissions(this.pageSize,
            this.pageSize * (this.pageIndex - 1),
            this.sortProperty,
            this.sortOrder,
            this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result: ARPagedResponseDataModel<PermissionModel>) => {
                    this.items = result.items;
                    this.total = result.total;
                },
                (err: ARApiError) => {
                    Toaster.handleApiError(err);
                    if (err.code === ErrorConstants.API_ERROR_CODES.NOT_FOUND_PERMISSIONS) {
                        this.router.navigateByUrl(AppConstants.ROLES_PATH);
                    }
                }
            );
    }

    /**
     * Puts all buffered permission changes
     * Clears permission change buffer on success
     */
    private updateRolePermissions(): void {
        this.requestSubscription = this.rolesService.patchRole(this.roleId, {permissions: this.selection.selected} as RoleModel)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (role: RoleModel) => {
                    this.setRoleDetails(role);
                    Toaster.success('Successfully updated permissions for this role');
                },
                Toaster.handleApiError
            );
    }

    private setRoleDetails(role: RoleModel): void {
        this.role = role;
        this.selection.clear();
        this.selection.select(...this.role.permissions);
    }

    public openConfirmDialog(): void {
        const confirmDialogConfig = new NucDialogConfigModel('Update permissions', 'You are about to change permissions for a role. Are you sure?');
        const confirmDialog = this.dialogService.openDialog(confirmDialogConfig);
        confirmDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
        confirmDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
            this.updateRolePermissions();
        });

    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }
}
