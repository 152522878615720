import {autoserialize, autoserializeAs} from 'cerialize';
import {CustomWorkflowActionModel} from './custom-workflow-action.model';
import {CustomWorkflowOptionModel} from './custom-workflow-option.model';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';

export class CustomWorkflowComponentModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public componentType: string;
    @autoserializeAs(CustomWorkflowActionModel) public actions: CustomWorkflowActionModel[];
    @autoserializeAs(CustomWorkflowOptionModel) public options: CustomWorkflowOptionModel[];
}

export enum ECustomWorkflowComponentType {
    ADD_PAGES = 'ADD_PAGES',
    MULTI_SELECTION = 'MULTI_SELECTION',
    AUTOMATION = 'AUTOMATION',
    PACKAGE_MANAGEMENT = 'PACKAGE_MANAGEMENT',
    PREVIEW = 'PREVIEW',
    FILES = 'FILES',
    STICKY_LIST = 'STICKY_LIST',
    STICKY_LOG = 'STICKY_LOG',
    LAYOUT = 'LAYOUT',
    OVERVIEW = 'OVERVIEW',
    STEP_OVERVIEW = 'STEP_OVERVIEW',
    LIST_VIEW = 'LIST_VIEW',
    BRIEFING = 'BRIEFING',
    UPLOAD_ASSETS = 'UPLOAD_ASSETS',
    UPLOAD = 'UPLOAD'
}

export enum EComponentTypeContext {
    STEP = 'STEP',
    WORKFLOW = 'WORKFLOW'
}

export class ComponentTypeConfig {
    public type: ECustomWorkflowComponentType;
    public context: EComponentTypeContext;
    public publicationTypes?: EPublicationType[];
    public canBeReplacedBy?: ECustomWorkflowComponentType
}

export const CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS: ComponentTypeConfig[] =
    [
        {
            type: ECustomWorkflowComponentType.OVERVIEW,
            context: EComponentTypeContext.WORKFLOW
        },
        {
            type: ECustomWorkflowComponentType.STICKY_LOG,
            context: EComponentTypeContext.WORKFLOW
        },
        {
            // only SPAR POS uses this one, and still used on acceptance
            // TODO: write migration for this
            type: ECustomWorkflowComponentType.MULTI_SELECTION,
            context: EComponentTypeContext.STEP,
            publicationTypes: [EPublicationType.POS, EPublicationType.WEB],
            canBeReplacedBy: ECustomWorkflowComponentType.BRIEFING
        },
        {
            // we might have publicationType specific allowed actions
            type: ECustomWorkflowComponentType.BRIEFING,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.PREVIEW,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.FILES,
            context: EComponentTypeContext.STEP,
            canBeReplacedBy: ECustomWorkflowComponentType.LIST_VIEW
        },
        {
            type: ECustomWorkflowComponentType.STICKY_LIST,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.ADD_PAGES,
            context: EComponentTypeContext.STEP,
            publicationTypes: [EPublicationType.PRINT_MAGAZINE]
        },
        {
            type: ECustomWorkflowComponentType.LAYOUT,
            context: EComponentTypeContext.STEP,
            publicationTypes: [EPublicationType.PRINT_MAGAZINE]
        },
        {
            type: ECustomWorkflowComponentType.STEP_OVERVIEW,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.LIST_VIEW,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.AUTOMATION,
            context: EComponentTypeContext.STEP,
            publicationTypes: [EPublicationType.POS, EPublicationType.WEB]
        },
        {
            type: ECustomWorkflowComponentType.PACKAGE_MANAGEMENT,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.UPLOAD_ASSETS,
            context: EComponentTypeContext.STEP
        },
        {
            type: ECustomWorkflowComponentType.UPLOAD,
            context: EComponentTypeContext.STEP
        }
    ];
