<ng-container *ngIf="permissions.GET_WORKFLOW_CONFIGURATION | userIsAllowedTo; else noPermissionEmptyState">
    <ng-container *ngIf="workflowConfiguration; else loadingIndicator">
        <div class="header">
            <h1>{{workflowConfiguration.name}}</h1>

            <nuc-button-bar>
                <div class="popout-button"
                     *ngIf="permissions.POST_WORKFLOW_CONFIGURATION | userIsAllowedTo">
                    <nuc-button-primary text="Add item"
                                        icon="nucicon_dots_vertical"
                                        iconPosition="end"
                                        [matMenuTriggerFor]="menu">
                    </nuc-button-primary>

                    <mat-menu #menu="matMenu" class="menu-items-container">
                        <button *ngFor="let menuItem of menuItems"
                                mat-menu-item
                                (click)="onMenuItemClicked(menuItem.property)"
                                [disableRipple]="true">
                            Add {{menuItem.title}}
                        </button>
                    </mat-menu>
                </div>
            </nuc-button-bar>
        </div>

        <rl-workflow-indicator *ngIf="workflowConfiguration?.steps?.length; else noSteps"
                               [workflowConfiguration]="workflowConfiguration"
                               (activeStepChange)="setActiveStepId($event)"
                               [activeStep]="activeStep"
                               [counts]="counts"
                               (click)="onWorkflowIndicatorClicked()">
        </rl-workflow-indicator>

        <!--Content here should be based on if we have an active step selected-->
        <h2 class="detail-header">{{activeStep ? 'Step' : 'Workflow'}} settings</h2>
        <nuc-tab-bar [tabs]="tabBarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>
        <hr class="full-width-tab-hr">

        <workflow-configuration-detail-property-component [property]="selectedTab?.data">
        </workflow-configuration-detail-property-component>
    </ng-container>
</ng-container>

<ng-template #noSteps>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/empty-states-workflow.svg"
                               [small]="true"
                               title="No workflow configuration steps. Add your first step to show the workflow indicator">
    </nuc-empty-state-component>
</ng-template>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
