import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {ConnectionService} from '../../api/services/connection.service';
import {EConnectionType, ConnectionApiModel, ConnectionModel} from '../../models/api/connection.model';
import {Toaster} from '../../classes/toaster.class';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConnectionDataService} from './connection.data-service';

export interface IConnectionFormData {
    connection?: ConnectionModel;
}

@Component({
    selector: 'connection-form',
    templateUrl: 'connection-form.component.html',
    styleUrls: ['connection-form.component.scss'],
    providers: [ConnectionDataService]
})

export class ConnectionFormComponent implements OnInit, OnDestroy {
    public readonly CONNECTIONS = {
        MEDIA_VALET: 'MEDIA_VALET',
        WEBHOOK_CONSUMER: 'WEBHOOK_CONSUMER'
    };

    @ViewChild('connectionTypeDropdown') public connectionTypDropdown;
    public connections: DropdownItem<string>[] = [
        new DropdownItem<string>('MediaValet', EConnectionType.MEDIA_VALET),
        new DropdownItem<string>('Webhook Consumer', EConnectionType.WEBHOOK_CONSUMER)
    ];
    public selectedConnectionType: string = '';
    public selectedConnection: IDropdownItem;
    private onDestroySubject = new Subject<void>();
    private saveButton: ButtonConfig;

    constructor(private fullModalService: FullModalService, private connectionService: ConnectionService,
                private connectionDataService: ConnectionDataService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData?: IConnectionFormData) {
    }

    public ngOnInit(): void {
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        cancelAction.observable.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.fullModalService.close(null, true));
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const saveAction = new FullModalActionModel(this.saveButton);

        saveAction.observable.pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.connectionDataService.saveButtonClicked());
        this.fullModalService.setModalActions([cancelAction, saveAction]);

        if (this.modalData.connection) {
            this.selectedConnection = this.connections.find((item) => {
                return item.getValue() === this.modalData.connection.connectionType;
            });
            this.connectionDataService.setSaveButtonStateDisabled(false);
        }
        this.connectionDataService.saveButtonStateDisabled$.subscribe((disabled: boolean) => {
            this.saveButton.disabled = disabled;
        });
        this.connectionDataService.saveButtonStateLoading$.subscribe((loading: boolean) => {
            this.saveButton.loading = loading;
        });
    }

    public selectionChanged(dropdownItem: IDropdownItem<string>): void {
        this.selectedConnectionType = dropdownItem?.getValue();
        if (!this.selectedConnectionType) {
            this.connectionDataService.setSaveButtonStateDisabled(true);
        }
    }

    public createOrUpdateConnection(formResult: Record<string, any>): void {
        if (this.modalData.connection?._id) {
            this.connectionService.editConnection(this.modalData.connection._id,
                new ConnectionApiModel(formResult.name,
                    this.modalData.connection.connectionType,
                    formResult.clientId, formResult.clientSecret,
                    formResult.subscriptionKey))
                .pipe(
                    finalize(() => this.connectionDataService.setSaveButtonStateLoading(false)),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe({
                    next: (result: ConnectionModel) => {
                        this.fullModalService.close(result);
                        Toaster.success('Connection updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.connectionService.createConnection(
                new ConnectionApiModel(formResult.name,
                    this.selectedConnectionType as EConnectionType,
                    formResult.clientId, formResult.clientSecret,
                    formResult.subscriptionKey))
                .pipe(
                    finalize(() => this.connectionDataService.setSaveButtonStateLoading(false)),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe({
                    next: (result: ConnectionModel) => {
                        this.fullModalService.close(result);
                        Toaster.success('Connection created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }

    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
