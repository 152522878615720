import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA, NucIcons} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {Toaster} from '../../classes/toaster.class';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {CustomWorkflowActionModel} from '../../models/api/custom-workflow-action.model';
import {EStickyNoteStatus, EWorkflowConfigurationActionType} from '../../app.enums';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {PermissionsService} from '../../api/services/permissions.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModelUtil} from '../../classes/model.util';

export interface IWorkflowConfigurationActionFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    workflowConfigurationAction?: CustomWorkflowActionModel;
    componentAction?: boolean;
}

@Component({
    selector: 'workflow-configuration-action-form-component',
    templateUrl: 'workflow-configuration-action-form.component.html',
    styleUrls: ['workflow-configuration-action-form.component.scss']
})
export class WorkflowConfigurationActionFormComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);

    public readonly transitions: DropdownItem<string>[];
    public transitionOptions: DropdownItem<string>[];
    public readonly nucIcons: DropdownItem<string>[];
    public nucIconOptions: DropdownItem<string>[] = [];
    public permissions: DropdownItem<string>[];
    public actionTypeOptions = Object.values(EWorkflowConfigurationActionType)
        .sort().map(type => new DropdownItem<EWorkflowConfigurationActionType>(type, type));

    public formGroup: FormGroup;
    private saveButton: ButtonConfig;

    private workflowConfiguration: WorkflowConfigurationModel;
    public workflowConfigurationAction: CustomWorkflowActionModel;
    public noteStatus = Object.values(EStickyNoteStatus)
        .filter(status => status !== EStickyNoteStatus.NEW)
        .sort().map(status => new DropdownItem<EStickyNoteStatus>(status, status));

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private permissionService: PermissionsService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationActionFormData) {
        this.transitions = this.modalData.workflowConfiguration.transitions.map(transition =>
            new DropdownItem(transition.name, transition._id));

        this.nucIcons = [...NucIcons].sort().map(nucIcon =>
            new DropdownItem(
                nucIcon,
                `nucicon_${nucIcon}`,
                null,
                `nucicon_${nucIcon}`
            ));
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationAction());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.workflowConfigurationAction = this.modalData.workflowConfigurationAction || new CustomWorkflowActionModel();
        this.transitionOptions = this.workflowConfiguration.transitions.map(transition =>
            new DropdownItem(transition.name, transition._id));
        this.nucIconOptions = [...this.nucIcons];

        this.permissionService.getAllPermissions('key', 'asc')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (permissions) => {
                    this.permissions = permissions.map(permission => new DropdownItem(
                        permission._id,
                        permission._id
                    ));

                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {
        const statusFrom = this.noteStatus.filter(status => this.workflowConfigurationAction.from?.includes(status.getValue()));
        const statusTo = this.noteStatus.find(step => step.getValue() === this.workflowConfigurationAction.to);
        const transition = this.transitionOptions.find(transition => transition.getValue() === this.workflowConfigurationAction.transition);
        const icon = this.nucIcons.find(icon => icon.getValue() === this.workflowConfigurationAction.icon);
        const type = this.actionTypeOptions.find(type => type.getValue() === this.workflowConfigurationAction.type);
        const permissions = this.permissions.filter(permission => this.workflowConfigurationAction.permissions?.includes(permission.getValue()));

        this.formGroup = new FormGroup<any>({
            name: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            title: new FormControl(),
            type: new FormControl(),
            from: new FormControl(),
            to: new FormControl(),
            transition: new FormControl(),
            permissions: new FormControl(),
            icon: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: string) => this.saveButton.disabled = status !== 'VALID');

        // Reset note from/to when changing type (from/to only possible when no action type set)
        this.formGroup.get('type').valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(value => {
                if (!value) {
                    this.formGroup.patchValue({from: null, to: null});
                }
            })
        this.formGroup.patchValue({
            name: this.workflowConfigurationAction.name,
            title: this.workflowConfigurationAction.title,
            from: statusFrom,
            to: statusTo,
            transition,
            icon,
            type,
            permissions
        });
    }

    private saveWorkflowConfigurationAction(): void {
        const formValue = this.formGroup.value;

        const action = ModelUtil.createApiBody({
            name: formValue.name,
            title: formValue.title,
            type: formValue.type?.getValue(),
            from: formValue.from?.map(status => status.getValue()) || [],
            to: formValue.to?.getValue(),
            transition: formValue.transition?.getValue(),
            icon: formValue.icon?.getValue(),
            permissions: formValue.permissions?.map(permission => permission.getValue()) || []
        }, this.workflowConfigurationAction._id);

        if (this.workflowConfigurationAction._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationAction(this.workflowConfiguration._id,
                this.workflowConfigurationAction._id, action)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Action updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationAction(this.workflowConfiguration._id, action)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Action created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public searchTransitions(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.transitionOptions = [];
        const regex = new RegExp(event.search, 'i');
        this.transitionOptions = this.transitions.filter((icon) => icon.getTitle().match(regex)?.length > 0);
    }

    public searchIcons(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.nucIconOptions = [];
        const regex = new RegExp(event.search, 'i');
        this.nucIconOptions = this.nucIcons.filter((icon) => icon.getTitle().match(regex)?.length > 0);
    }
}
