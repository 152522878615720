/**
 * sticky-note-comment.model
 * app-relayter-web
 * Created by borisnelissen on 04/12/2018.
 * Copyright © 2018 Creative Media Network. All rights reserved.
 */
import {autoserialize, autoserializeAs} from 'cerialize';
import {UserModel} from './user.model';
import {AttachmentModel} from './attachment.model';

export class StickyNoteCommentModel {

    @autoserialize public _id: string;
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserialize public message: string;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserialize public attachments: AttachmentModel[];
}
