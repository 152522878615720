<nuc-tab-bar *ngIf="tabBarItems?.length > 0"
             [tabs]="tabBarItems"
             [disabledTabs]="disabledTabs"
             [(activeTab)]="selectedTab">
</nuc-tab-bar>
<hr class="full-width-tab-hr"/>

<div class="content">
    <form [formGroup]="form" *ngIf="variants else loadingIndicator">
        <ng-container [ngSwitch]="selectedTab.index">
            <data-fields-information-form
                *ngSwitchCase="TAB_INFORMATION"
                [form]="form"
                [(dataFields)]="dataFields"
                [isNew]="!campaignItem?._id"
                [formContext]="FORM_CONTEXT_BRIEFING"
                [variants]="variants"
                [readonly]="readonly">
            </data-fields-information-form>
            <campaign-item-products-form
                *ngSwitchCase="TAB_PRODUCTS"
                [form]="form"
                [(selectedProducts)]="selectedProducts"
                [readonly]="readonly">
            </campaign-item-products-form>
            <campaign-item-assets-form
                *ngSwitchCase="TAB_ASSETS"
                [selectedAssets]="campaignItem?.assets"
                [selectedProducts]="selectedProducts"
                [form]="form"
                [readonly]="readonly">
            </campaign-item-assets-form>
            <campaign-item-changelog
                *ngSwitchCase="TAB_CHANGES"
                [campaignItemId]="campaignItem?._id"
                [showTimeLine]="true">
            </campaign-item-changelog>
        </ng-container>
    </form>
</div>

<ng-template #loadingIndicator>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
