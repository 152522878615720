<nuc-tab-bar [tabs]="tabBarItems"
             [(activeTab)]="selectedTab">
</nuc-tab-bar>
<hr class="full-width-tab-hr"/>

<form [formGroup]="form" *ngIf="variants; else loadingIndicator">
    <ng-container [ngSwitch]="selectedTab.index">
        <data-fields-information-form *ngSwitchCase="TAB_INFORMATION"
                                      [form]="form"
                                      [(dataFields)]="dataFields"
                                      [isNew]="false"
                                      [variants]="variants"
                                      [formContext]="FORM_CONTEXT_ASSET"></data-fields-information-form>
        <asset-assets-form *ngSwitchCase="TAB_ASSETS"
                           [form]="form"
                           [asset]="asset"
                           (assetUploadUpdate)="updateState($event)"></asset-assets-form>
    </ng-container>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
