import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {AssetModel} from '../../models/api/asset.model';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {AssetProductModel} from '../../models/api/asset-product.model';
import {AssetPutModel} from '../../models/api/asset.model';
import {QueryParams} from '../../classes/query-params';
import {CampaignModel} from '../../models/api/campaign.model';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-jobs.service';
import {Cursor} from '../api-cursor';

export enum EAssetJobType {
    EXPORT_IMAGE_JOB = 'EXPORT_IMAGE_JOB',
    EXPORT_ASSETS_JOB = 'EXPORT_ASSETS_JOB',
    ADD_DEFAULT_ASSETS_NEW = 'ADD_DEFAULT_ASSETS_NEW' // default s3Keys job to add assets
}

@Injectable({providedIn: 'root'})
export class AssetService extends BaseApiRequestService {

    private monitoredJobsService = inject(MonitoredJobsService);

    /**
     * Gets a single asset by id
     * @return {Observable<AssetProductModel>}
     */
    public getAsset(id: string): Observable<AssetProductModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_ASSETS, id]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, AssetProductModel);
        });
    }

    /**
     * Gets a paged response with an asset collection from the api server
     * @param {boolean} sortDescending
     * @param {string} sort
     * @param {string} search
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [filters]
     * @param {Cursor} [cursor]
     * @param {string[]} [productIds]
     * @returns {Observable<ARPagedResponseDataModel<AssetModel>>}
     */
    public getAssets(sortDescending: boolean, sort: string, search: string,
                     limit?: number, offset?: number, filters?: Record<string, any>,
                     cursor?: Cursor, productIds?: string[]): Observable<ARPagedResponseDataModel<AssetModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sort, sortDescending ? ApiConstants.API_QUERY_VALUE_DESC : ApiConstants.API_QUERY_VALUE_ASC)
            .setSearchParams(search)
            .addObject(filters)
            .addParam('productIds', productIds);

        if (cursor) queryParams.setCursor(cursor);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_ASSETS], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, AssetModel);
        });
    }

    /**
     * Finds all 'not DONE' campaigns that are using an asset
     * @param {string} assetId
     * @param {number} [limit]
     * @param {number} [offset]
     * @returns {Observable<ARPagedResponseDataModel<CampaignModel>>}
     */
    public findCampaignsUsingAsset(assetId: string, limit?: number, offset?: number): Observable<ARPagedResponseDataModel<CampaignModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_ASSETS,
            assetId, ApiConstants.API_METHOD_ASSET_USAGE_IN_CAMPAIGNS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, CampaignModel);
        });
    }

    /**
     * Deletes an asset
     * @param {string} assetId
     * @returns {Observable<boolean>}
     */
    public deleteAsset(assetId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_ASSETS,
            assetId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public postJob(jobType: EAssetJobType, jobData: Record<string, any>): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_ASSETS,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    public putAsset(id: string, asset: AssetPutModel): Observable<AssetModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_ASSETS, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = asset;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, AssetModel);
        });
    }
}
