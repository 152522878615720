import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConnectionModel, ConnectionEventModel} from '../../../../../../models/api/connection.model';
import {ConnectionService} from '../../../../../../api/services/connection.service';
import {Toaster} from '../../../../../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RLBaseComponent} from '../../../../../../components/rl-base-component/rl-base.component';
import {BUTTON_TYPE, FullModalConfig, FullModalService, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {ConnectionFormComponent} from '../../../../../../forms/connection-form/connection-form.component';
import {
    ConsumerWebhookEventFormComponent, IConsumerWebhookEventFormComponentData
} from '../../../../../../forms/connection-form/consumer-webhook/consumer-webhook-event-form/consumer-webhook-event-form.component';

@Component({
    selector: 'connection-detail-webhook-consumer',
    templateUrl: './connection-detail-webhook-consumer.component.html',
    styleUrls: ['./connection-detail-webhook-consumer.component.scss']
})
export class ConnectionDetailWebhookConsumerComponent extends RLBaseComponent implements OnInit, OnDestroy {
    private connectionId: string;
    public connection: ConnectionModel;
    public events: ConnectionEventModel[];
    public consumerEventsSubscription: Subscription;
    public loading: boolean;
    private onDestroySubject = new Subject<void>();

    constructor(private activeRoute: ActivatedRoute,
                private connectionService: ConnectionService,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService) {
        super();
    }

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.connectionId = params.connectionId;
            this.getConnection();
            this.getEvents();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private getConnection(): void {
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (connection: ConnectionModel) => {
                    this.connection = connection;
                },
                error: Toaster.handleApiError
            });
    }

    private getEvents(): void {
        if (this.consumerEventsSubscription) this.consumerEventsSubscription.unsubscribe();
        this.consumerEventsSubscription = this.connectionService.getEvents(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    this.events = result.items;
                },
                error: Toaster.handleApiError
            });
    }

    public editConnection(): void {
        // Get fresh copy of the connection
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: connection => {
                    this.connection = connection;

                    const modalConfig = new FullModalConfig('Edit connection',
                        'You can edit basic connection information.', {connection});
                    modalConfig.confirmClose = true;

                    const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public addEvent(): void {
        const modalConfig = new FullModalConfig('Add event',
            'Configure event.', {connectionId: this.connectionId} as IConsumerWebhookEventFormComponentData);
        const modalRef = this.fullModalService.open(ConsumerWebhookEventFormComponent, modalConfig);
        modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
            return res ? this.getEvents() : null;
        });
    }

    public onEditEventClicked(eventId: string): void {
        // Get fresh copy of the event
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: connection => {
                    this.connection = connection;

                    const currentEvent = connection.events.find(event => event._id === eventId);
                    if (!currentEvent) {
                        Toaster.error('Event not found');
                        return;
                    }
                    const modalConfig = new FullModalConfig('Edit event',
                        'Configure event.', {connectionId: this.connectionId, eventData: currentEvent} as IConsumerWebhookEventFormComponentData);
                    const modalRef = this.fullModalService.open(ConsumerWebhookEventFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
                        return res ? this.getEvents() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    /**
     * On delete button clicked, open delete event dialog
     */
    public openDeleteDialog(eventId: string): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete event',
            'Please confirm that you wish to delete this event.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteEvent(eventId);
        });
    }

    private deleteEvent(eventId: string) {
        this.connectionService.deleteEvent(this.connectionId, eventId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => {
                    Toaster.success('Event removed');
                    this.getEvents();
                },
                error: Toaster.handleApiError
            });
    }
}
