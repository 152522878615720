<form *ngIf="formGroup; else error" [formGroup]="formGroup">
    <p class="title">
        #{{valueIndex + 1}}
        <span *ngFor="let description of valueDescription" [ngClass]="{bold: description.bold}">
            {{description.text}}
        </span>
    </p>
    <nuc-button-secondary class="action" icon="nucicon_trash_fill" (click)="deleteClicked.emit()"></nuc-button-secondary>

    <nuc-form-field class="tag" label="Tag">
        <nuc-dropdown [items]="tags" placeholder="Select a tag" formControlName="tag"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field class="property" label="Property">
        <div formArrayName="property" class="properties">
            <ng-container *ngFor="let control of propertiesFormArray.controls; index as index; first as first"
                          class="property-item" [class.first-item]="first">
                <div class="condition-field">
                    <div [ngSwitch]="propertiesInputs[index]?.type">
                        <nuc-dropdown-badge *ngSwitchCase="PROPERTY_TYPE.FIELD"
                                            [items]="propertiesInputs[index].properties"
                                            [formControlName]="index"
                                            [minWidth]="minimalBadgeWidth"
                                            placeholder="Select a property field"
                                            icon="nucicon_grid">
                        </nuc-dropdown-badge>
                        <nuc-badge *ngSwitchCase="PROPERTY_TYPE.ARRAY"
                                   icon="nucicon_progress"
                                   cdkOverlayOrigin
                                   [badgeType]="isOpen[index] ? BUTTON_TYPE.PRIMARY : control.value && control.valid ? BUTTON_TYPE.SELECTED : BUTTON_TYPE.UNSELECTED"
                                   (click)="showOperatorOverlay(index, isOpen[index])"
                                   #trigger="cdkOverlayOrigin">
                            {{control.value !== null && control.value > 0 ? ('Item at ' + control.value) : 'Select an operator'}}
                            <ng-template
                                cdkConnectedOverlay
                                [cdkConnectedOverlayOrigin]="trigger"
                                [cdkConnectedOverlayOpen]="isOpen[index]"
                                (overlayOutsideClick)="isOpen[index] = false; $event.stopPropagation();">
                                <div class="operator">
                                    <div class="title">
                                        <nuc-label>Select an<span class="nucicon_progress"></span>operator</nuc-label>
                                        <nuc-button-secondary class="end" nucTooltip="Remove operator"
                                                              icon="nucicon_close" (click)="removeOperator(index)">
                                        </nuc-button-secondary>
                                        <nuc-button-primary class="end" nucTooltip="Close"
                                                              icon="nucicon_check" (click)="isOpen[index] = false;">
                                        </nuc-button-primary>
                                    </div>
                                    <nuc-radiobutton [data]="'Item at'" [dataItems]="operators" disabled="true">Item at</nuc-radiobutton>
                                    <nuc-form-field label="Index">
                                        <nuc-input type="number"
                                                   placeholder="Select an array index"
                                                   [formControlName]="index"></nuc-input>
                                    </nuc-form-field>
                                </div>
                            </ng-template>
                        </nuc-badge>
                    </div>
                    <div class="connector" [ngSwitch]="getConnectionType(index)">
                        <div *ngSwitchCase="EConnectionType.CONNECT" class="connector-image connector-connect"></div>
                        <div *ngSwitchCase="EConnectionType.END"  class="connector-image connector-end"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </nuc-form-field>

    <div>
        <nuc-form-field class="format" label="Format">
            <nuc-dropdown [items]="formatOptions" [total]="formatOptions.length" formControlName="format"
                          placeholder="Choose optional format"></nuc-dropdown>
        </nuc-form-field>

        <ng-container class="format-string" [ngSwitch]="formatFormControl.value">
            <div class="format-string" *ngSwitchCase="FORMAT_OPTION.DATE_FORMAT">
                <span><i class="nucicon_connector"></i></span>
                <nuc-form-field class="date-format">
                    <nuc-input formControlName="formatString"
                               placeholder="Date format string"></nuc-input>
                </nuc-form-field>
                <nuc-button-secondary icon="nucicon_open" (click)="openDateFormatLink()" nucTooltip="Go to date format article"></nuc-button-secondary>
            </div>
            <div class="format-string" *ngSwitchCase="FORMAT_OPTION.TO_STRING">
                <span><i class="nucicon_connector"></i></span>
                <nuc-form-field class="separator-format">
                    <nuc-input formControlName="formatString"
                               placeholder="Separator string"></nuc-input>
                </nuc-form-field>
            </div>
        </ng-container>
    </div>
</form>

<ng-template #error>
    <p>Please pass a FormGroup as Input</p>
</ng-template>
