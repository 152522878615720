<div class="asset-detail-page">

    <div class="page-header">
        <div class="page-title">
            <h1>Asset details</h1>
        </div>

        <nuc-button-bar class="header-buttons">
            <nuc-button-secondary
                *ngIf="[permissions.DELETE_ASSET, permissions.GET_ASSET_USAGE_IN_CAMPAIGNS] | userIsAllowedTo"
                icon="nucicon_trash_fill" [nucTooltip]="'Remove'"
                (click)="handleAssetDeletion()"></nuc-button-secondary>
            <nuc-button-secondary icon="nucicon_edit" (click)="onEditAssetClicked()" [nucTooltip]="'Edit'"
                                  *ngIf="permissions.PUT_ASSET_DETAILS | userIsAllowedTo" i18n></nuc-button-secondary>
        </nuc-button-bar>
    </div>
    <ng-container *ngIf="dataSubscription?.closed; else loading">
        <div class="asset-info-container" @rlLoadTrigger>
            <nuc-extra-large-card-view [hoverImage]="true" *ngIf="assetDetails.asset"
                                       [title]="assetDetails.asset | assetDisplay:EAssetDisplayProperties.TITLE"
                                       [subTitle]="assetDetails.asset | assetDisplay:EAssetDisplayProperties.SUBTITLE"
                                       [image]="assetDetails.asset | assetDisplay: EAssetDisplayProperties.THUMBNAIL_URL">
                <nuc-button-secondary imageOptions
                                      *ngIf="assetDetails.asset?.getSourceURL()"
                                      icon="nucicon_download" [nucTooltip]="'Download asset'"
                                      (click)="onDownloadOriginalAssetClicked(assetDetails.asset.getSourceURL())"></nuc-button-secondary>
                <nuc-button-secondary imageOptions
                                      *ngIf="getAssetFileCategory(assetDetails.asset?.getSourceURL()) === IMAGE"
                                      icon="nucicon_images_line" [nucTooltip]="'Export asset'"
                                      (click)="onExportAssetClicked(assetDetails.asset)"></nuc-button-secondary>
            </nuc-extra-large-card-view>

            <information-details-view-component [item]="assetDetails?.asset"
                                                [dataFields]="dataFields"></information-details-view-component>
        </div>

        <div *ngIf="assetDetails.products && assetDetails.products.length > 0" @rlLoadTrigger>
            <hr>

            <div class="product-info-header">
                <p class="section-header">Related products</p>
                <p>Products the asset is linked to</p>
            </div>

            <div class="row">
                <div class="item" *ngFor="let product of assetDetails.products; let index = index">
                    <nuc-small-card-view [hoverCard]="true"
                                         [image]="product.getThumbnailURL()"
                                         [routerLink]="'/products/' + product._id">

                        <div content>
                            <p class="body-strong">RAN</p>
                            <p>{{product.ran}}</p>
                        </div>
                    </nuc-small-card-view>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>

<ng-template #assetUsageDialog>
    <div *ngIf="totalCampaignsUsingAsset > 0" class="usage-container"
         [style.height.px]="130 + ((totalCampaignsUsingAsset > 5 ? 5 : totalCampaignsUsingAsset) * 32)">
        <nuc-table [useButtonBar]="true"
                   [items]="campaignsUsingAsset"
                   [columns]="dialogTableColumns"
                   [tableId]="tableId"
                   [loading]="!campaignsUsingAssetSubscription?.closed"
                   [prefixStorageKey]="storageKey">
            <paginator button-bar-right
                       [disableNextPage]="disableNextPage"
                       [pageSizeOptions]="assetUsagePageSizeOptions"
                       [viewId]="tableId"
                       [loading]="!campaignsUsingAssetSubscription?.closed">
            </paginator>
        </nuc-table>
    </div>
</ng-template>
