<nuc-form-field label="Upload a new asset">
    <rl-reactive-file-input
        inactive-title="Drag your asset here"
        [multiple]="false"
        (filesChanged)="onFilesChanged($event)">
    </rl-reactive-file-input>
</nuc-form-field>

<nuc-form-field label="Uploaded asset">
    <div *ngIf="previousUploadAsset; else uploadAsset" class="asset-container">
        <nuc-extra-large-card-view [title]="previousUploadAsset.fileProperties.fileName"
                                   [image]="previousUploadAsset.fileProperties.fileType | getFilePathPipe: previousUploadAsset.signedUrl">
            <ng-container content>
                <div *ngIf="previousUploadAsset.status === EUploadStatus.Done" class="footer">
                    <nuc-button-secondary icon="nucicon_trash_fill"
                                          (click)="onDeleteClicked()">
                    </nuc-button-secondary>
                </div>
            </ng-container>
        </nuc-extra-large-card-view>
    </div>

    <ng-template #uploadAsset>
        <div *ngIf="upload?.progress$ | async as progress; else emptyState" class="asset-container">
            <nuc-extra-large-card-view [title]="upload.filename"
                                       [image]="upload.fileType | getFilePathPipe: newPath">
                <ng-container content [ngSwitch]="progress">
                    <nuc-progress-bar *ngSwitchDefault [value]="progress" [showPercentage]="true"></nuc-progress-bar>

                    <div *ngSwitchCase="EUploadStatus.Done" class="footer">
                        <nuc-button-secondary icon="nucicon_trash_fill"
                                              (click)="onDeleteClicked()">
                        </nuc-button-secondary>
                    </div>

                </ng-container>
            </nuc-extra-large-card-view>
        </div>
    </ng-template>

    <ng-template #emptyState>
        <nuc-empty-state-component
            imageUrl="assets/images/empty_states/no-image.svg"
            title="No asset to replace"
            subtitle="The current asset won't be replaced with a new asset">
        </nuc-empty-state-component>
    </ng-template>
</nuc-form-field>
