<nuc-table [items]="items"
           [columns]="columns"
           [actions]="actions"
           [loading]="!subscription?.closed"
           [useButtonBar]="true"
           [tableId]="tableId"
           [useEmptyState]="true"
           [emptyStateTitle]="search ? 'No matching results.' : 'This section is empty. Add the first item.'"
           [prefixStorageKey]="storageKey"
           (sortOptionChange)="onSortOptionChanged($event)"
           [orderRows]="true"
           (actionClicked)="handleTableRowAction($event)"
           (enableOrderRows)="startOrderRules()"
           (orderChanged)="orderChanged($event)">
    <nuc-input button-bar-left
               [data]="search"
               prefix="nucicon_search"
               (debounceValueChanged)="onSearchBarValueUpdated($event)"
               [placeholder]="'Search items by name'">
    </nuc-input>

    <paginator button-bar-right
               [viewId]="tableId"
               [disableNextPage]="disableNextPage"
               [loading]="!subscription?.closed">
    </paginator>
</nuc-table>
