<ng-container *ngIf="permissions.GET_PACKAGE_SETUPS | userIsAllowedTo; else noPermissionEmptyState">

    <ng-container *ngIf="packageSetupSubscription?.closed; else loadingIndicator">

        <div class="page-header">
            <h1>{{packageSetup.name}}</h1>

            <nuc-button-bar>
                <nuc-button-primary
                    *ngIf="permissions.POST_PACKAGE_SETUP_RULE | userIsAllowedTo"
                    text="Add package rule"
                    (click)="onCreatePackageRuleClicked()">
                </nuc-button-primary>
            </nuc-button-bar>
        </div>

        <div class="package-setup-info">
            <nuc-label>Description</nuc-label>
            <p>{{packageSetup.description}}</p>
        </div>

        <ng-container *ngIf="permissions.GET_PACKAGE_SETUP_RULES | userIsAllowedTo; else noPermissionEmptyState">
            <nuc-table [useButtonBar]="true"
                       [items]="rules"
                       [columns]="columns"
                       [actions]="actions"
                       [loading]="!packageSetupRulesSubscription?.closed || loading"
                       [tableId]="tableId"
                       [useEmptyState]="true"
                       [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
                       [prefixStorageKey]="storageKey"
                       [selectionMode]="ESelectionMode.EXPAND"
                       (actionClicked)="handleTableRowAction($event)"
                       (sortOptionChange)="onSortOptionChange($event)">

                <nuc-input button-bar-left
                           class="search"
                           [(data)]="searchValue"
                           prefix="nucicon_search"
                           (debounceValueChanged)="setPageIndex()"
                           placeholder="Search package setup rules">
                </nuc-input>

                <paginator button-bar-right
                           [viewId]="viewId"
                           [loading]="!packageSetupRulesSubscription?.closed || loading"
                           [disableNextPage]="disableNextPage">
                </paginator>
            </nuc-table>
        </ng-container>

    </ng-container>

</ng-container>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
