<div class="content" *ngIf="publicationItem; else loadingIndicator">
    <div class="upload-container">
        <div class="upload-files">
            <upload-item-file-input-component
                fileUploadTitle="Preview image"
                dragTitle="preview file"
                [multiple]="false"
                [active]="!!publicationItemFiles?.preview"
                (filesChanged)="onFilesChanged($event, EUploadCategory.EXPORT)">
            </upload-item-file-input-component>

            <upload-item-file-input-component
                fileUploadTitle="Source file"
                dragTitle="source file"
                [multiple]="false"
                [active]="!!publicationItemFiles?.source"
                (filesChanged)="onFilesChanged($event, EUploadCategory.SOURCE)">
            </upload-item-file-input-component>

            <upload-item-file-input-component
                fileUploadTitle="Files"
                dragTitle="files"
                [active]="!!publicationItem?.attachments.length"
                (filesChanged)="onFilesChanged($event, EUploadCategory.FILE)">
            </upload-item-file-input-component>
        </div>
        <div class="upload-details" *ngIf="exportFile || sourceFile || files?.length">
            <hr>
            <div class="upload-row preview-source-uploads" *ngIf="exportFile || sourceFile">
                <div *ngIf="exportFile">
                    <nuc-label>Preview image</nuc-label>
                    <file-upload-card-view class="item"
                                           [itemWidth]="200"
                                           [uploadFile]="exportFile.uploads[0]"
                                           (deleteAssetClicked)="onDeleteUploadItemClicked(exportFile)">
                    </file-upload-card-view>
                </div>

                <div *ngIf="sourceFile">
                    <nuc-label>Source file</nuc-label>
                    <file-upload-card-view class="item"
                                           [itemWidth]="200"
                                           [uploadFile]="sourceFile.uploads[0]"
                                           (deleteAssetClicked)="onDeleteUploadItemClicked(sourceFile)">
                    </file-upload-card-view>
                </div>
            </div>
            <div class="upload-row" *ngIf="files?.length">
                <nuc-label>Files</nuc-label>
                <div class="upload-items">
                    <file-upload-card-view *ngFor="let file of files"
                                           class="item"
                                           [itemWidth]="200"
                                           [uploadFile]="file.uploads[0]"
                                           (deleteAssetClicked)="onDeleteUploadItemClicked(file)">
                    </file-upload-card-view>
                </div>
            </div>
        </div>
    </div>

    <div class="sidebar">
        <custom-workflow-publication-item-details-component
            *ngIf="publicationItem"
            [dataFields]="dataFields"
            [isForm]="true"
            [selectedPublicationItemId]="publicationItem._id">
        </custom-workflow-publication-item-details-component>
    </div>
</div>

<ng-template #loadingIndicator>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
