import {SortDirection} from '@angular/material/sort';
import {Cursor} from '../api-cursor';
import {ApiRequest} from './api.request';
import {QueryParams} from '../../classes/query-params';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {AppConstants} from '../../app.constants';

export class ApiPagedRequest extends ApiRequest {
    constructor(public pathComponents: string[] = [],
                public model: IResponseModel,
                public limit?: number,
                public offset: number = 0,
                public sortProperty?: string,
                public sortOrder?: SortDirection,
                public cursor?: Cursor,
                public search?: string,
                public filters?: Record<string, any>) {
        super(pathComponents, model);

        this.limit = this.limit || AppConstants.PAGE_SIZE_MAX;
    }

    protected getQueryParams(): QueryParams {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(this.limit, this.offset)
            .setSortAndSortDirectionParams(this.sortProperty, this.sortOrder)
            .addObject(this.filters);

        if (this.search) queryParams.setSearchParams(this.search);
        if (this.cursor) queryParams.setCursor(this.cursor);

        queryParams.addObject(super.getQueryParams().getParams());

        return queryParams;
    }
}
