export enum EDataFieldTypes {
    STRING = 'string',
    NUMBER = 'number',
    DATE = 'date',
    BOOLEAN = 'boolean',
    ENUM = 'enum',
    LIST = 'list',
    OBJECT_ID = 'ObjectId'
}

export enum  EDataFieldFormatter {
    NONE = 'none',
    CURRENCY = 'currency'
}

export enum  EDataFieldCollectionName {
    CAMPAIGN_ITEM = 'RLCampaignItem',
    PRODUCT = 'RLProduct',
    ASSET = 'RLAsset'
}

export enum  EDataCollectionName {
    STICKY_NOTE = 'RLStickyNote',
    PUBLICATION_ITEM = 'RLPublicationItem',
    INDESIGN_RULE_SET = 'RLIndesignRuleSet',
    PUBLICATION = 'RLPublication'
}

export enum  EChangeLogCollectionName {
    CAMPAIGN_ITEM = 'RLCampaignItem',
    NOTE = 'RLStickyNote'
}

export enum EBooleanText {
    TRUE = 'Yes',
    FALSE = 'No',
    EMPTY = 'Empty'
}

export enum RULESET_OPERATORS  {
    OR = 'OR',
    AND = 'AND'
}

export enum EStickyNoteStatus {
    NEW = 'NEW',
    CREATED = 'CREATED',
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
    PROCESSED = 'PROCESSED',
    REJECTED = 'REJECTED',
    COMPLETED = 'COMPLETED',
    DUPLICATE = 'DUPLICATE',
    DELETED = 'DELETED'
}

export enum ERequestMethod {
    POST =  'POST',
    GET = 'GET',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}

export enum EWorkflowConfigurationActionType {
    TRANSITION_TRIGGER =  'TRANSITION_TRIGGER',
    ITEM_ACTION = 'ITEM_ACTION',
    BRIEFING_ACTION = 'BRIEFING_ACTION'
}
