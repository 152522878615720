
<div class="image-container">
    <img class="logo" [src]="logo">
</div>
<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   type="string"
                   placeholder="Fill in your connection name">
        </nuc-input>
    </nuc-form-field>
</form>
