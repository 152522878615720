import {Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WorkflowConfigurationModel} from '../../../../../models/api/workflow-configuration.model';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ITableItem,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {RLTableComponent} from '../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {AppConstants} from '../../../../../app.constants';
import {Toaster} from '../../../../../classes/toaster.class';
import {distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {combineLatest, forkJoin} from 'rxjs';
import {CustomWorkflowStepModel} from '../../../../../models/api/custom-workflow-step.model';
import {
    IWorkflowConfigurationStepFormData,
    WorkflowConfigurationStepFormComponent
} from '../../../../../forms/workflow-configuration-step-form/workflow-configuration-step-form.component';
import {
    EWorkflowConfigurationProperty,
    IPropertyConfig,
    WorkflowConfigurationPropertyConfigs
} from './workflow-configuration-property.config';
import {CustomWorkflowTransitionModel} from '../../../../../models/api/custom-workflow-transition.model';
import {
    IWorkflowConfigurationTransitionFormData,
    WorkflowConfigurationTransitionFormComponent
} from '../../../../../forms/workflow-configuration-transition-form/workflow-configuration-transition-form.component';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {WorkflowConfigurationsService} from '../../../../../api/services/workflow-configurations.service';
import {IListDialogData, ListDialogComponent} from '../../../../../components/dialog/list/list-dialog.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
    IWorkflowConfigurationStepDeletionDialogData,
    WorkflowConfigurationStepDeletionDialogComponent
} from './workflow-configuration-step-deletion-dialog/workflow-configuration-step-deletion-dialog.component';
import {EJobStatus} from '../../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../../api/services/monitored-jobs.service';
import {CustomWorkflowActionModel} from '../../../../../models/api/custom-workflow-action.model';
import {TransitionItemsService} from '../../../../../api/services/transition-items.service';
import {WorkflowConfigurationsDetailsService} from '../workflow-configurations-details.service';
import {
    IWorkflowConfigurationActionFormData,
    WorkflowConfigurationActionFormComponent
} from '../../../../../forms/workflow-configuration-action-form/workflow-configuration-action-form.component';
import {CustomWorkflowComponentModel} from '../../../../../models/api/custom-workflow-component.model';
import {
    IWorkflowConfigurationComponentFormData,
    WorkflowConfigurationComponentFormComponent
} from '../../../../../forms/workflow-configuration-component-form/workflow-configuration-component-form.component';

@Component({
    selector: 'workflow-configuration-detail-property-component',
    templateUrl: 'workflow-configuration-detail-property.component.html',
    styleUrls: ['workflow-configuration-detail-property.component.scss']
})
export class WorkflowConfigurationDetailPropertyComponent extends RLTableComponent implements OnInit, OnChanges {
    @Input() public property: EWorkflowConfigurationProperty;
    protected readonly ESelectionMode = ESelectionMode;
    public workflowConfiguration: WorkflowConfigurationModel;
    public selectedPropertyConfig: IPropertyConfig;
    public mainEntity: WorkflowConfigurationModel | CustomWorkflowStepModel;
    public tableId: string;

    private destroyRef = inject(DestroyRef);

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private transitionItemsService: TransitionItemsService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private dialogService: NucDialogService,
                private monitoredJobsService: MonitoredJobsService,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService) {
        super(userSettingsStorageService);
    }


    public ngOnInit(): void {
        combineLatest([
            this.workflowConfigurationsDetailsService.workflowConfiguration,
            this.workflowConfigurationsDetailsService.activeStepId.pipe(distinctUntilChanged())
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([workflowConfiguration, activeStepId]) => {
                    this.workflowConfiguration = workflowConfiguration;
                    const activeStep =
                        this.workflowConfiguration.steps.find((step) => step._id === activeStepId);
                    this.mainEntity = activeStep || this.workflowConfiguration;
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.property) {
            this.selectedPropertyConfig = new WorkflowConfigurationPropertyConfigs(this.workflowConfiguration).getConfig(this.property);
            // map actions
            this.selectedPropertyConfig.tableView.actions =
                this.selectedPropertyConfig.tableView.actionsConfig?.filter((actionConfig) => {
                    return actionConfig.permission ? this.userIsAllowedToPipe.transform(actionConfig.permission) : true;
                }).map((actionConfig) => actionConfig.type);

            this.tableId = 'workflow-configuration-table-' + this.property;
        }
    }

    public handleTableRowAction(actionEvent: IActionClickEvent): void {
        switch (this.property) {
            case EWorkflowConfigurationProperty.STEPS:
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
                    this.editStep(actionEvent.item);
                }
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
                    this.deleteStep(actionEvent.item);
                }
                break;
            case EWorkflowConfigurationProperty.COMPONENTS:
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
                    this.editComponent(actionEvent.item);
                }
                break;
            case EWorkflowConfigurationProperty.TRANSITIONS:
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
                    this.editTransition(actionEvent.item);
                }
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
                    this.preDeleteTransition(actionEvent.item as CustomWorkflowTransitionModel);
                }
                break;
            case EWorkflowConfigurationProperty.ACTIONS:
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
                    this.editAction(actionEvent.item);
                }
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
                    this.preDeleteAction(actionEvent.item as CustomWorkflowActionModel);
                }
                break;
            default:
                Toaster.notYetImplementedError();
                break;
        }
    }

    private editStep(item: ITableItem) {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (configuration) => {
                    const configurationStep = configuration.steps.find(step => step._id === item._id);
                    if (!configurationStep) {
                        Toaster.error('Could not find workflow configuration step');
                        return;
                    }

                    this.openStepModal(configurationStep);
                },
                error: Toaster.handleApiError
            });
    }

    private deleteStep(step: ITableItem) {
        const transitionsByStep =
            this.workflowConfiguration.transitions.filter(
                (transition) => transition.from === step._id || transition.to === step._id);

        transitionsByStep.length > 0
            ? this.openStepInUseDialog(transitionsByStep)
            : this.openDeleteStepDialog(step as CustomWorkflowStepModel);
    }

    private openStepInUseDialog(transitions: CustomWorkflowTransitionModel[]): void {
        const dialogData: IListDialogData = {
            listGroups: [{
                list: transitions.map((transition) => transition.name)
            }]
        };
        const stepInUseConfig = new DialogCustomContentConfig('Cannot delete step',
            'The step is currently used in transitions.', dialogData);
        this.dialogCustomContentService.open(ListDialogComponent, stepInUseConfig);
    }

    private openDeleteStepDialog(step: CustomWorkflowStepModel): void {
        const dialogConfig = new DialogCustomContentConfig(
            'Delete step',
            'To delete this step, move the existing publication items to another step.',
            {
                workflowConfiguration: this.workflowConfiguration,
                step
            } as IWorkflowConfigurationStepDeletionDialogData
        );
        this.dialogCustomContentService
            .open(WorkflowConfigurationStepDeletionDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((job) => !!job),
                switchMap((job) => this.monitoredJobsService.getJobMonitor(job._id)),
                filter((job) => job.status === EJobStatus.DONE),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration()
            });
    }

    public openStepModal(workflowConfigurationStep: CustomWorkflowStepModel): void {
        const modalConfig = new FullModalConfig('Edit workflow configuration step',
            'Edit the information of the workflow configuration step.',
            {
                workflowConfigurationId: this.workflowConfiguration._id,
                workflowConfigurationStep
            } as IWorkflowConfigurationStepFormData);
        modalConfig.confirmClose = true;
        this.fullModalService.open(WorkflowConfigurationStepFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    private editComponent(item: ITableItem) {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (configuration) => {
                    let component: CustomWorkflowComponentModel, step: CustomWorkflowStepModel;

                    if (this.mainEntity instanceof WorkflowConfigurationModel) {
                        component = configuration.components.find((c) => c._id === item._id);
                    } else {
                        step = configuration.steps.find((s) => s._id === this.mainEntity._id);
                        if (!step) {
                            Toaster.error('Could not find workflow configuration step');
                            return;
                        }
                        component = step.components.find((c) => c._id === item._id);
                    }

                    if (!component) {
                        Toaster.error('Could not find workflow configuration component');
                        return;
                    }
                    this.openComponentModal(component, step);
                },
                error: Toaster.handleApiError
            });
    }

    public openComponentModal(component: CustomWorkflowComponentModel, step: CustomWorkflowStepModel): void {

        const modalConfig = new FullModalConfig('Edit workflow configuration component',
            'Edit the information of the workflow configuration component.',
            {
                workflowConfiguration: this.workflowConfiguration,
                workflowConfigurationStep: step,
                component
            } as IWorkflowConfigurationComponentFormData);
        modalConfig.confirmClose = true;

        this.fullModalService.open(WorkflowConfigurationComponentFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    private editTransition(item: ITableItem) {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (configuration) => {
                    const configurationTransition = configuration.transitions.find(transition => transition._id === item._id);
                    if (!configurationTransition) {
                        Toaster.error('Could not find workflow configuration transition');
                        return;
                    }
                    this.openTransitionModal(configurationTransition);
                },
                error: Toaster.handleApiError
            });
    }

    public openTransitionModal(workflowConfigurationTransition: CustomWorkflowTransitionModel): void {
        const modalConfig = new FullModalConfig('Edit workflow configuration transition',
            'Edit the information of the workflow configuration transition.',
            {
                workflowConfiguration: this.workflowConfiguration,
                workflowConfigurationSteps: this.workflowConfiguration.steps,
                workflowConfigurationTransition
            } as IWorkflowConfigurationTransitionFormData);

        modalConfig.confirmClose = true;
        this.fullModalService
            .open(WorkflowConfigurationTransitionFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result) this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    private preDeleteTransition(item: CustomWorkflowTransitionModel) {
        forkJoin([
            this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable(),
            this.transitionItemsService.findActiveTransitions(item._id)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([configuration, transitionItemsResponse]) => {
                    const configurationTransition = configuration.transitions.find(transition => transition._id === item._id);
                    if (!configurationTransition) {
                        Toaster.error('Could not find workflow configuration transition');
                        return;
                    }

                    if (transitionItemsResponse.items.length) {
                        Toaster.error('There are still items in transition for this workflow configuration transition, try again later.');
                        return;
                    }

                    const usedActions = this.getTransitionActions(item, configuration);
                    this.openTransitionDialog(item, !!usedActions.length);
                },
                error: Toaster.handleApiError
            });
    }

    public openTransitionDialog(item: CustomWorkflowTransitionModel, deleteActions: boolean): void {
        const dialogConfig = new NucDialogConfigModel(
            `Delete transition '${item.name}'`,
            deleteActions ? 'There are actions assigned to this transition and they will also be deleted. ' +
                'Are you sure you want to delete the transition?' : 'The transition will be deleted. Are you sure?'
        );
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.deleteTransition(item);
        });
    }

    private deleteTransition(item: CustomWorkflowTransitionModel): void {
        this.workflowConfigurationService.deleteWorkflowConfigurationTransition(this.workflowConfiguration._id, item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Transition deleted successfully');
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                },
                error: Toaster.handleApiError
            });

    }

    private getTransitionActions(item: CustomWorkflowTransitionModel, configuration: WorkflowConfigurationModel): CustomWorkflowActionModel[] {
        const transitionActions: CustomWorkflowActionModel[] = [];
        // Actions defined in components
        transitionActions.push(...configuration.components.reduce((actions, component) => {
            actions.push(...component.actions.filter(action => action.transition === item._id))
            return actions;
        }, []));
        // Actions defined in actions
        transitionActions.push(...configuration.actions.filter(action => action.transition === item._id));
        // Actions defined in step components
        for (const step of configuration.steps) {
            transitionActions.push(...step.components.reduce((actions, component) => {
                actions.push(...component.actions.filter(action => action.transition === item._id))
                return actions;
            }, []));
        }

        return transitionActions;
    }

    private editAction(item: ITableItem) {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (configuration) => {
                    const configurationAction = configuration.actions.find(action => action._id === item._id);
                    if (!configurationAction) {
                        Toaster.error('Could not find workflow configuration action');
                        return;
                    }

                    this.openActionModal(configurationAction);
                },
                error: Toaster.handleApiError
            });
    }

    public openActionModal(workflowConfigurationAction: CustomWorkflowActionModel): void {
        const modalConfig = new FullModalConfig('Edit workflow configuration action',
            'Edit the information of the workflow configuration action.',
            {
                workflowConfiguration: this.workflowConfiguration,
                workflowConfigurationAction
            } as IWorkflowConfigurationActionFormData);
        modalConfig.confirmClose = true;
        this.fullModalService
            .open(WorkflowConfigurationActionFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result) this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    private preDeleteAction(item: CustomWorkflowActionModel) {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (configuration) => {
                    const configurationAction = configuration.actions.find(action => action._id === item._id);
                    if (!configurationAction) {
                        Toaster.error('Could not find workflow configuration action');
                        return;
                    }

                    const dialogConfig = new NucDialogConfigModel(
                        `Delete action '${item.name}'`,
                        'The action will be deleted. Are you sure?'
                    );
                    const dialog = this.dialogService.openDialog(dialogConfig);
                    dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
                    dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                        dialog.close();
                        this.deleteAction(item);
                    });
                },
                error: Toaster.handleApiError
            });
    }

    private deleteAction(item: ITableItem): void {
        this.workflowConfigurationService.deleteWorkflowConfigurationAction(this.workflowConfiguration._id, item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Action deleted successfully');
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                },
                error: Toaster.handleApiError
            });
    }
}
