<div class="header-row">
    <h1>Content rule overview</h1>
    <nuc-button-bar>
        <nuc-button-secondary *ngIf="permissions.POST_CONTENT_RULE_JOBS | userIsAllowedTo" (click)="onApplyRulesButtonClicked()" text="Apply content rules"></nuc-button-secondary>
        <nuc-button-primary *ngIf="permissions.POST_CONTENT_RULE | userIsAllowedTo" (click)="openContentRuleForm()" text="Add content rule"></nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_CONTENT_RULES | userIsAllowedTo else noPermissionState">
    <nuc-table [useButtonBar]="true"
               [useEmptyState]="true"
               [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
               [items]="contentRules"
               [columns]="columns"
               [loading]="!contentRulesSubscription?.closed"
               [actions]="actionTypes"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">
        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="updateUrl()"
                   placeholder="Search content rules">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!contentRulesSubscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>


<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
