<ng-container *ngIf="workflowConfiguration">
    <ng-container *ngFor="let step of workflowConfiguration.steps; let first = first">

        <rl-transition *ngIf="!first"
                       [hasNext]="hasPreviousStep(step)"
                       [forwardCount]="getItemCountAroundStep(step, true)"
                       [backwardCount]="getItemCountAroundStep(step, false)"
                       [forwardTransitions]="getTransitionForStep(step, true)"
                       [backwardTransitions]="getTransitionForStep(step, false)"
                       [backwardsTransitionName]="getNameForBackwardsTransitionFromStep(step)"
                       [hasBackwardsTransition]="hasBackwardsTransition(step) || hasFlyByTransition(step)">
        </rl-transition>

        <rl-step (click)="setActiveStep(step, $event)"
                 [step]="step"
                 [active]="activeStep === step"
                 [hasPrevious]="hasPreviousStep(step)"
                 [hasNext]="hasNextStep(step)"
                 [class.disabled]="!(step.permissions | userIsAllowedTo)"
                 [hasBackwardsTransition]="hasBackwardsTransition(step)"
                 [hasIncomingBackwardsTransition]="hasIncomingBackwardsTransition(step)"
                 [hasFlyByTransition]="hasFlyByTransition(step)"
                 [itemCount]="getItemCountForStep(step)">
        </rl-step>

    </ng-container>
</ng-container>

<ng-container *ngIf="!workflowConfiguration">
    <rl-loading-indicator></rl-loading-indicator>
</ng-container>
