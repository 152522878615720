import {autoserialize, inheritSerialization} from 'cerialize';
import {PublicationItemModel} from '../publication-item.model';

@inheritSerialization(PublicationItemModel)
export class PrintPublicationItemModel extends PublicationItemModel {
    @autoserialize public numberOfAssignedItems: number;

    public originalPageNumber: number;

    public getModel(): typeof PublicationItemModel {
        return PrintPublicationItemModel;
    }

    /**
     * Used as value for the cursor
     * Print publication items are sorted by firstPageNumber, so we get firstPageNumber value as sortValue
     */
    public get sortValue(): string {
        return this.firstPageNumber.toString();
    }

    /**
     * Returns a display title for use in the custom workflow upload component
     * @return {string}
     */
    public getUploadDisplayName(): string {
        return this.getTitle();
    }

    /**
     * Interface method
     * @return {string}
     */
    public getTitle(): string {
        return `Page ${this.formattedPageNumbers}`;
    }

    public getThumbnailURL(variant?: string): string {
        const files = this.getVariantFiles(variant);
        if (files && files.thumb) {
            return files.thumb;
        }

        if (this.numberOfPages === 1) {
            return '/assets/images/page_preview_placeholder_single-page.svg';
        } else {
            return '/assets/images/page_preview_placeholder_spread.svg';
        }
    }

    /**
     * Inteface method
     * @return {string}
     */
    public getNavigationItemTitle(): string {
        if (this.originalPageNumber && this.originalPageNumber !== this.firstPageNumber) {
            const previousPageNumber = this.numberOfPages > 1 ? `${this.originalPageNumber} - ${this.originalPageNumber + this.numberOfPages -1}` :
                this.originalPageNumber;
            return `${this.getTitle()} (previous ${previousPageNumber})`;
        } else {
            return this.getTitle();
        }
    }

    /**
     * Inteface method
     * @return {string}
     */
    public getNavigationItemSubtitle(): string {
        return this.publicationItemId;
    }

}
