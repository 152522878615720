<div class="actions">
    <nuc-button-bar>
        <div class="left-bar">
            <nuc-button-secondary icon="nucicon_document---continues"
                                  [disabled]="!publicationItems || publicationItems.length === 0 || loading"
                                  nucTooltip="Item selection"
                                  [operating]="selectionOpen"
                                  (click)="onClickViewSelection()"></nuc-button-secondary>

            <nuc-button-secondary *ngIf="!!workflow.layout"
                                  icon="nucicon_multi-pages"
                                  [disabled]="!publicationItems || publicationItems.length === 0 || loading"
                                  nucTooltip="Items in layout"
                                  [operating]="layoutOpen"
                                  (click)="onClickViewLayout()"></nuc-button-secondary>

            <div class="vertical-line"></div>

            <nuc-button-secondary
                [disabled]="(publicationItemSelectionService.isFirstItem$ | async) || loading"
                icon="nucicon_chevron_left"
                (click)="publicationItemSelectionService.onBackClick()">
            </nuc-button-secondary>

            <nuc-button-secondary [disabled]="(publicationItemSelectionService.isLastItem$ | async) || loading"
                                  icon="nucicon_chevron_right"
                                  (click)="publicationItemSelectionService.onNextClick()">
            </nuc-button-secondary>
        </div>

        <div class="right-bar">
            <nuc-button-secondary [disabled]="disableAction[action.name]"
                                  *ngFor="let action of iterableActions"
                                  [text]="action.title"
                                  (click)="handleAction(action, publicationItems)"></nuc-button-secondary>
            <div class="vertical-line"></div>

            <nuc-button-secondary icon="nucicon_information"
                                  [disabled]="!publicationItems || publicationItems.length === 0 || loading"
                                  nucTooltip="Item details"
                                  [operating]="selectedRightPanel === ERightPanel.ITEM_DETAILS"
                                  (click)="onToggleRightPanel(ERightPanel.ITEM_DETAILS)"></nuc-button-secondary>

            <nuc-button-secondary icon="nucicon_data-change"
                                  [disabled]="!publicationItems || publicationItems.length === 0 || loading || !showBriefingChangesAction"
                                  nucTooltip="Briefing changes"
                                  [operating]="selectedRightPanel === ERightPanel.BRIEFING_CHANGES"
                                  (click)="onToggleRightPanel(ERightPanel.BRIEFING_CHANGES)"></nuc-button-secondary>

            <nuc-button-secondary icon="nucicon_comments_show"
                                  [disabled]="!publicationItems || publicationItems.length === 0 || loading || !allowedComponentActions.showStickyNotes"
                                  nucTooltip="Notes"
                                  [operating]="selectedRightPanel === ERightPanel.STICKY"
                                  (click)="onToggleRightPanel(ERightPanel.STICKY)"></nuc-button-secondary>
        </div>
    </nuc-button-bar>
</div>

<div class="content">
    <!--We still need access to the component for navigation, so keep it alive by using hidden-->
    <div class="item-selection" [hidden]="!selectionOpen && !layoutOpen">
        <rl-custom-workflow-item-selection-component>
        </rl-custom-workflow-item-selection-component>
    </div>

    <div class="preview-container">
        <rl-custom-workflow-sticky-notes-view
            *ngIf="publicationItems?.length > 0; else emptyState"
            [horizontal]="isHorizontal"
            [itemSelectionType]="currentItemSelectionType"
            [previewLoading]="loading">
        </rl-custom-workflow-sticky-notes-view>

    </div>

    <div class="form-container" *ngIf="showBriefingForm">
        <campaign-item-inline-form (closeEvent)="closeEditBriefing($event)" [campaignId]="campaign._id"
                                   *fullscreenForm="editCampaignItemEvents; viewId: 'campaignItemWorkflowForm'; btnContainer: 'right'; modalEventSubject: fullscreenModalEventSubject"
                                   [isLoading]="!campaignDetailsSubscription.closed"></campaign-item-inline-form>
    </div>

    <div class="sidebar" *ngIf="selectedRightPanel">
        <ng-container [ngSwitch]="selectedRightPanel">
            <custom-workflow-publication-item-details-component
                *ngSwitchCase="ERightPanel.ITEM_DETAILS"
                [dataFields]="campaignItemDataFields"
                [loading]="loading">
            </custom-workflow-publication-item-details-component>
            <custom-workflow-briefing-changes-component
                *ngSwitchCase="ERightPanel.BRIEFING_CHANGES"
                [component]="component"
                [publicationItems]="publicationItems"
                [dataFields]="campaignItemDataFields"
                [publication]="publication"
                [action]="showBriefingChangesAction"
                [loading]="loading">
            </custom-workflow-briefing-changes-component>
            <custom-workflow-sticky-notes-sidebar-container
                *ngSwitchCase="ERightPanel.STICKY"
                [publication]="publication"
                [loading]="loading"
                [component]="component"
                [campaignId]="campaign._id"
                [actions]="allowedActions"
                [campaignItemDataFields]="campaignItemDataFields">
            </custom-workflow-sticky-notes-sidebar-container>
        </ng-container>
    </div>

</div>

<ng-template #emptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/preview.svg"
                               title="No publication items"
                               subtitle="Move publication items to this step.">
    </nuc-empty-state-component>
</ng-template>
