import {Component, Inject, OnInit, Optional} from '@angular/core';
import {
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../../models/api/campaign-item.model';
import {CampaignItemsService} from '../../../api/services/campaign-items.service';
import {ProductModel} from '../../../models/api/product.model';
import {EFormContext} from '../../../models/api/form.model';
import {takeUntil} from 'rxjs/operators';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {VariantModel} from '../../../models/api/variant.model';
import {VariantService} from '../../../api/services/variant.service';
import {CampaignItemFormComponentDirective} from '../campaign-item-form-component.directive';

export interface ICampaignItemFormData {
    campaignId: string;
    campaignItem?: CampaignItemModel;
}

@Component({
    selector: 'campaign-item-modal-form',
    templateUrl: './campaign-item-modal-form.component.html',
    styleUrls: ['./campaign-item-modal-form.component.scss']
})
export class CampaignItemModalFormComponent extends CampaignItemFormComponentDirective implements OnInit {
    public readonly FORM_CONTEXT_BRIEFING = EFormContext.BRIEFING;

    public dataFields: Record<string, any>;
    public selectedProducts: ProductModel[] = [];
    public variants: VariantModel[];

    constructor(private fullModalService: FullModalService,
                campaignItemService: CampaignItemsService,
                private variantService: VariantService,
                userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: ICampaignItemFormData) {
        super(campaignItemService, userIsAllowedToPipe);

        this.campaignItem = this.modalData.campaignItem;
        this.campaignId = this.modalData.campaignId;
    }

    public ngOnInit(): void {
        this.setupForm();
    }

    protected initButtons(): void {
        super.initButtons();

        if (this.readonly) {
            this.fullModalService.setTitle('View briefing item');
            this.fullModalService.setConfirmClose(false);
        }
        this.fullModalService.setModalActions(this.actions);
    }

    protected initFormData(): void {
        this.variantService.getVariants(this.campaignId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((data: {items: VariantModel[]}) => {
                this.variants = data.items;
                this.selectedProducts = this.campaignItem?.products || [];
                this.dataFields = this.campaignItem?.dataFields || {};
            });
    }

    public beforeCloseForm(result?: CampaignItemModel, confirmClose?: boolean) {
        this.closeForm(result, confirmClose);
    }

    protected closeForm(result?: CampaignItemModel, confirmClose?: boolean) {
        this.fullModalService.close(result, confirmClose);
    }
}
