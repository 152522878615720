import {EDataFieldTypes} from '../../app.enums';
import {DropdownItem} from '../../models/ui/dropdown-item.model';

export class FormatRulesetConstants {
    public static MIN_VALUE_ARRAY_INDEX = 1;
}

export class FormatOption extends DropdownItem<string> {
    public static CURRENCY = new FormatOption('Currency (Dot)', 'CURRENCY');
    public static CURRENCY_COMMA = new FormatOption('Currency (Comma)', 'CURRENCY_COMMA');
    public static CURRENCY_DOT_STRIPE = new FormatOption('Currency (Dot stripe)', 'CURRENCY_DOT_STRIPE');
    public static CURRENCY_COMMA_STRIPE = new FormatOption('Currency (Comma stripe)', 'CURRENCY_COMMA_STRIPE');
    public static OPTIONAL = new FormatOption('Optional', 'OPTIONAL');
    public static SEMICOLON_TO_LINE_BREAK = new FormatOption('Semicolon to line break', 'SEMICOLON_TO_LINE_BREAK');
    public static IGNORE_EMPTY = new FormatOption('Ignore empty', 'IGNORE_EMPTY');
    public static DATE_FORMAT = new FormatOption('Date format', 'DATE_FORMAT');
    public static LOWERCASE = new FormatOption('Lowercase', 'LOWERCASE');
    public static TO_STRING = new FormatOption('To string', 'TO_STRING');

    public static OPTIONS = [FormatOption.CURRENCY, FormatOption.CURRENCY_COMMA, FormatOption.CURRENCY_DOT_STRIPE,
        FormatOption.CURRENCY_COMMA_STRIPE, FormatOption.OPTIONAL, FormatOption.SEMICOLON_TO_LINE_BREAK, FormatOption.IGNORE_EMPTY,
        FormatOption.DATE_FORMAT, FormatOption.LOWERCASE, FormatOption.TO_STRING];

    public static getByValue(value: string): FormatOption {
        return FormatOption.OPTIONS.find((option) => option.getValue() === value);
    }
}

export class ScaleOption extends DropdownItem<string> {
    public static PERCENTAGE = new ScaleOption('By percentage', 'PERCENTAGE');
    public static DIMENSIONS = new ScaleOption('By dimensions', 'DIMENSIONS');

    public static OPTIONS = [ScaleOption.DIMENSIONS, ScaleOption.PERCENTAGE];

    public static getByValue(value: string): AnchorOption {
        return ScaleOption.OPTIONS.find((option) => option.getValue() === value);
    }
}

export class AssetFormatOption extends DropdownItem<string> {
    public static GRID = new AssetFormatOption('Grid', 'GRID', true,
        'nucicon_display-format---grid');
    public static STAIRS_UP = new AssetFormatOption('Stairs up', 'STAIRS_UP', true,
        'nucicon_display-format---stairs-up');
    public static STAIRS_DOWN = new AssetFormatOption('Stair down', 'STAIRS_DOWN', true,
        'nucicon_display-format---stairs-down');
    public static WAVE_UP = new AssetFormatOption('Wave up', 'WAVE_UP', true,
        'nucicon_display-format---wave-up');
    public static WAVE_DOWN = new AssetFormatOption('Wave down', 'WAVE_DOWN', true,
        'nucicon_display-format---wave-down');

    public static OPTIONS = [
        AssetFormatOption.GRID,
        AssetFormatOption.STAIRS_UP,
        AssetFormatOption.STAIRS_DOWN,
        AssetFormatOption.WAVE_UP,
        AssetFormatOption.WAVE_DOWN
    ];

    public static getByValue(value: string): AnchorOption {
        return AssetFormatOption.OPTIONS.find((option) => option.getValue() === value);
    }
}

export class AnchorOption extends DropdownItem<string> {
    public static TOP_LEFT = new AnchorOption('Top left', 'TOP_LEFT');
    public static TOP_CENTER = new AnchorOption('Top center', 'TOP_CENTER');
    public static TOP_RIGHT = new AnchorOption('Top right', 'TOP_RIGHT');
    public static CENTER_LEFT = new AnchorOption('Center left', 'CENTER_LEFT');
    public static CENTER_CENTER = new AnchorOption('Center', 'CENTER');
    public static CENTER_RIGHT = new AnchorOption('Center right', 'CENTER_RIGHT');
    public static BOTTOM_LEFT = new AnchorOption('Bottom left', 'BOTTOM_LEFT');
    public static BOTTOM_CENTER = new AnchorOption('Bottom center', 'BOTTOM_CENTER');
    public static BOTTOM_RIGHT = new AnchorOption('Bottom right', 'BOTTOM_RIGHT');

    public static OPTIONS = [
        AnchorOption.TOP_LEFT, AnchorOption.TOP_CENTER, AnchorOption.TOP_RIGHT,
        AnchorOption.CENTER_LEFT, AnchorOption.CENTER_CENTER, AnchorOption.CENTER_RIGHT,
        AnchorOption.BOTTOM_LEFT, AnchorOption.BOTTOM_CENTER, AnchorOption.BOTTOM_RIGHT,
    ];

    public static getByValue(value: string): AnchorOption {
        return AnchorOption.OPTIONS.find((option) => option.getValue() === value);
    }
}

export class StackOption extends DropdownItem<string> {
    public static STACK_UP_ALIGN_LEFT = new StackOption('Stack up - align left', 'STACK_UP_ALIGN_LEFT',
        true, 'nucicon_stack-up-align-left');
    public static STACK_UP_ALIGN_RIGHT = new StackOption('Stack up - align right', 'STACK_UP_ALIGN_RIGHT',
        true, 'nucicon_stack-up-align-right');
    public static STACK_DOWN_ALIGN_LEFT = new StackOption('Stack down - alight left', 'STACK_DOWN_ALIGN_LEFT',
        true, 'nucicon_stack-down-align-left');
    public static STACK_DOWN_ALIGN_RIGHT = new StackOption('Stack down - align right', 'STACK_DOWN_ALIGN_RIGHT',
        true, 'nucicon_stack-down-align-right');
    public static STACK_RIGHT_ALIGN_TOP = new StackOption('Stack right - align top', 'STACK_RIGHT_ALIGN_TOP',
        true,'nucicon_stack-right-align-top');
    public static STACK_RIGHT_ALIGN_BOTTOM = new StackOption('Stack right - align bottom', 'STACK_RIGHT_ALIGN_BOTTOM',
        true, 'nucicon_stack-right-align-bottom');
    public static STACK_LEFT_ALIGN_TOP = new StackOption('Stack left - align top', 'STACK_LEFT_ALIGN_TOP',
        true, 'nucicon_stack-left-align-top');
    public static STACK_LEFT_ALIGN_BOTTOM = new StackOption('Stack left - align bottom', 'STACK_LEFT_ALIGN_BOTTOM',
        true, 'nucicon_stack-left-align-bottom');

    public static OPTIONS = [
        StackOption.STACK_UP_ALIGN_LEFT, StackOption.STACK_UP_ALIGN_RIGHT,StackOption.STACK_DOWN_ALIGN_LEFT,
        StackOption.STACK_DOWN_ALIGN_RIGHT,StackOption.STACK_RIGHT_ALIGN_TOP,StackOption.STACK_RIGHT_ALIGN_BOTTOM,
        StackOption.STACK_LEFT_ALIGN_TOP,StackOption.STACK_LEFT_ALIGN_BOTTOM,
    ];

    public static getByValue(value: string): StackOption {
        return StackOption.OPTIONS.find((option) => option.getValue() === value);
    }
}

export class PropertyOperator extends DropdownItem<string> {
    public static LENGTH = new PropertyOperator('Length', 'LENGTH', true);
    public static EVERY = new PropertyOperator('Every', 'EVERY', true);
    public static SOME = new PropertyOperator('Any', 'SOME', true);
    public static EXISTS = new PropertyOperator('Exists', 'EXISTS', false);
    public static NOT_EXISTS = new PropertyOperator('Not exists', 'NOT_EXISTS', false);

    public static OPERATORS = [
        PropertyOperator.LENGTH,
        PropertyOperator.SOME,
        PropertyOperator.EVERY,
        PropertyOperator.EXISTS,
        PropertyOperator.NOT_EXISTS
    ];

    public static getByValue(value: string): PropertyOperator {
        return PropertyOperator.OPERATORS.find((operator) => operator.getValue() === value);
    }

    constructor(title: string, value: string, public readonly typeRequired: boolean) {
        super(title, value);
    }
}

export class ConditionsConstants {
    public static readonly DATA_TYPE_LENGTH = 'length';
}

export class ConditionType extends DropdownItem<string> {
    public static EXISTS = new ConditionType('Exists', 'EXISTS', false);
    public static NOT_EXISTS = new ConditionType('Not exists', 'NOT_EXISTS', false);
    public static EQUALS = new ConditionType('Equals', 'EQUALS', true);
    public static NOT_EQUALS = new ConditionType('Not equals', 'NOT_EQUALS', true);
    public static INCLUDES = new ConditionType('Contains', 'INCLUDES', true);
    public static NOT_INCLUDES = new ConditionType('Does not contain', 'NOT_INCLUDES', true);
    public static GREATER_THAN = new ConditionType('Greater than', 'GREATER_THAN', true);
    public static GREATER_OR_EQUAL = new ConditionType('Greater or equal', 'GREATER_OR_EQUAL', true);
    public static LOWER_THAN = new ConditionType('Lower than', 'LOWER_THAN', true);
    public static LOWER_OR_EQUAL = new ConditionType('Lower or equal', 'LOWER_OR_EQUAL', true);
    public static LENGTH = new ConditionType('Length of', 'LENGTH', true);
    public static LENGTH_GREATER_THAN = new ConditionType('Length greater than', 'LENGTH_GREATER_THAN', true);
    public static LEADING_LENGTH = new ConditionType('Leading length of', 'LEADING_LENGTH', true);
    public static LEADING_LENGTH_GREATER_THAN = new ConditionType('Leading length greater than', 'LEADING_LENGTH_GREATER_THAN', true);

    public static TYPES = [
        ConditionType.EXISTS, ConditionType.NOT_EXISTS,
        ConditionType.EQUALS, ConditionType.NOT_EQUALS,
        ConditionType.INCLUDES, ConditionType.NOT_INCLUDES,
        ConditionType.GREATER_THAN, ConditionType.GREATER_OR_EQUAL,
        ConditionType.LOWER_THAN, ConditionType.LOWER_OR_EQUAL,
        ConditionType.LENGTH, ConditionType.LENGTH_GREATER_THAN,
        ConditionType.LEADING_LENGTH, ConditionType.LEADING_LENGTH_GREATER_THAN,
    ];

    public static getTypesForProperty(property?: string): ConditionType[] {
        switch (property) {
            case EDataFieldTypes.STRING:
                return [
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.INCLUDES,
                    ConditionType.NOT_INCLUDES
                ];
            case EDataFieldTypes.ENUM:
            case EDataFieldTypes.BOOLEAN:
                return [
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                ];
            case ConditionsConstants.DATA_TYPE_LENGTH:
                return [
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.GREATER_THAN,
                    ConditionType.GREATER_OR_EQUAL,
                    ConditionType.LOWER_THAN,
                    ConditionType.LOWER_OR_EQUAL
                ];
            case EDataFieldTypes.DATE:
                return [
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.GREATER_THAN,
                    ConditionType.GREATER_OR_EQUAL,
                    ConditionType.LOWER_THAN,
                    ConditionType.LOWER_OR_EQUAL
                ];
            case EDataFieldTypes.LIST:
                return [
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.INCLUDES,
                    ConditionType.NOT_INCLUDES,
                    ConditionType.GREATER_THAN,
                    ConditionType.GREATER_OR_EQUAL,
                    ConditionType.LOWER_THAN,
                    ConditionType.LOWER_OR_EQUAL,
                    ConditionType.LENGTH,
                    ConditionType.LENGTH_GREATER_THAN,
                    ConditionType.LEADING_LENGTH,
                    ConditionType.LEADING_LENGTH_GREATER_THAN,
                ];
            case EDataFieldTypes.NUMBER:
            case EDataFieldTypes.OBJECT_ID:
                return ConditionType.TYPES;
            default:
                return [];
        }
    }

    public static getByValue(value: string): ConditionType {
        return ConditionType.TYPES.find((type) => type.getValue() === value);
    }

    constructor(title: string, value: string, public readonly valueRequired: boolean) {
        super(title, value);
    }
}

export class BooleanOption extends DropdownItem<boolean> {
    public static TRUE = new BooleanOption('Yes', true);
    public static FALSE = new BooleanOption('No', false);

    public static OPTIONS = [
        BooleanOption.TRUE,
        BooleanOption.FALSE
    ];

    constructor(title: string, value: boolean) {
        super(title, value);
    }
}
