<div class="container" *ngIf="stickyNote" @myInsertTrigger>
    <div class="header-bar">
        <div class="left-bar">
            <img src="/assets/images/icon_profile.svg"/>
            <div class="info-container">
                <p class="body-strong black text-ellipsis"
                   [nucTooltip]="getUserName()">{{getUserName() | uppercase}}</p>
                <span
                    class="body-strong">{{stickyNote.createdAt || currentDate | RLDatePipe:dateFormats.STICKY_DATE}}</span>
            </div>
        </div>
        <div class="right-bar">
            <img class="pin"
                 [src]="stickyNote.status | getNotePinImagePipe"
                 nucTooltipPosition="left"
                 [nucTooltip]="stickyNote.status"/>
            <nuc-button-secondary icon="nucicon_backward"
                                  nucTooltip="Return"
                                  nucTooltipPosition="left"
                                  (click)="unsetStickyNote()"></nuc-button-secondary>
        </div>
    </div>

    <nuc-tab-bar [tabs]="tabBarItems" [disabledTabs]="disabledTabBarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>

    <div class="content-container" [ngSwitch]="selectedTab.index">
        <ng-container *ngSwitchCase="TAB_DETAILS">
            <div class="sticky-container">

                <form [formGroup]="formGroup"
                      *ngIf="editMessageAction || (addStickyNoteAction && isNewStickyNote) else message">
                    <nuc-textarea placeholder="Note message"
                                  [noHorizontalResize]="true"
                                  [autofocus]="true"
                                  [minRows]="2"
                                  #textarea
                                  [disabled]="!!activeAction"
                                  formControlName="message">
                    </nuc-textarea>

                    <div class="checkbox-container"
                         *ngIf="((addForRelated && stickyNote.campaignItem) || addToAllVariants) && isNewStickyNote">
                        <div class="gap-line"></div>

                        <div class="checkbox" *ngIf="addForRelated && stickyNote.campaignItem">
                            <div class="line-container">
                                <div class="line"></div>
                                <img
                                    [src]="addToAllVariants ? '/assets/images/icon_tracker_middle.svg' : '/assets/images/icon_tracker_bottom.svg'"/>
                                <div class="line" *ngIf="addToAllVariants"></div>
                            </div>
                            <p>Add note to all publication items that are linked to the same briefing item.</p>
                            <nuc-checkbox nucTooltip="This only applies to items in your current workflow step."
                                          nucTooltipPosition="left"
                                          formControlName="addForRelated">
                            </nuc-checkbox>
                        </div>
                        <div class="checkbox" *ngIf="addToAllVariants">
                            <div class="line-container">
                                <div class="line"></div>
                                <img src="/assets/images/icon_tracker_bottom.svg"/>
                            </div>
                            <p>Add note to all variants of the publication item.</p>
                            <nuc-checkbox nucTooltip="This only applies to items in your current workflow step."
                                          nucTooltipPosition="left"
                                          formControlName="addToAllVariantsControl">
                            </nuc-checkbox>
                        </div>
                    </div>
                </form>

                <ng-template #message>
                    <p class="message">{{stickyNote.message}}</p>
                </ng-template>

                <div class="sticky-button-bar">
                    <div class="left-bar"
                         *ngIf="(editMessageAction && messageControl.value !== stickyNote.message) || (addStickyNoteAction && isNewStickyNote)">
                        <nuc-button-secondary text="Cancel"
                                              [disabled]="!!activeAction"
                                              (click)="onCancelClicked()">
                        </nuc-button-secondary>

                        <!--edit existing sticky note-->
                        <nuc-button-primary *ngIf="editMessageAction && messageControl.value !== stickyNote.message"
                                            [text]="editMessageAction.title"
                                            [disabled]="!formGroup.valid || !!activeAction"
                                            (click)="onStickyNoteActionClicked(editMessageAction)"
                                            [loading]="loading || activeAction === editMessageAction">
                        </nuc-button-primary>

                        <!--create new sticky note-->
                        <nuc-button-primary *ngIf="addStickyNoteAction && isNewStickyNote"
                                            [text]="addStickyNoteAction.title"
                                            [disabled]="!formGroup.valid || !!activeAction"
                                            (click)="onStickyNoteActionClicked(addStickyNoteAction)"
                                            [loading]="loading || activeAction === addStickyNoteAction">
                        </nuc-button-primary>
                    </div>

                    <div class="left-bar"
                         *ngIf="messageControl.value === stickyNote.message && editStatusActions.length > 0">
                        <nuc-button-primary *ngFor="let action of editStatusActions"
                                            [text]="action.title"
                                            [disabled]="activeAction && activeAction !== action"
                                            (click)="onStickyNoteActionClicked(action)"
                                            [loading]="loading || action === activeAction">
                        </nuc-button-primary>
                    </div>
                    <div class="right-bar" *ngIf="deleteAction || isNewStickyNote || markDuplicateAction">
                        <nuc-button-secondary icon="nucicon_trash_fill" *ngIf="deleteAction || isNewStickyNote"
                                              [nucTooltip]="TOOLTIPS.DELETE"
                                              nucTooltipPosition="left"
                                              [loading]="loading || (activeAction && activeAction === deleteAction)"
                                              [disabled]="isNewStickyNote ? !!activeAction : activeAction && activeAction !== deleteAction"
                                              (click)="isNewStickyNote ? unsetStickyNote() : onStickyNoteActionClicked(deleteAction)">
                        </nuc-button-secondary>
                        <nuc-button-secondary icon="nucicon_duplicate" *ngIf="markDuplicateAction"
                                              [nucTooltip]="TOOLTIPS.DUPLICATE"
                                              nucTooltipPosition="left"
                                              [loading]="loading || activeAction === markDuplicateAction"
                                              [disabled]="activeAction && activeAction !== markDuplicateAction"
                                              (click)="onStickyNoteActionClicked(markDuplicateAction)">
                        </nuc-button-secondary>
                    </div>
                </div>
            </div>

            <div class="briefing-container">
                <hr/>
                <p class="section-header">{{stickyNote.campaignItem ? 'Linked briefing item' : 'No linked briefing item'}}</p>
                <div class="briefing-data" *ngIf="stickyNoteInfo.length">
                    <div class="briefing-entry" *ngFor="let property of stickyNoteInfo">
                        <p class="body-strong">{{property.label}}</p>
                        <p class="description">{{property.value | nullUndefinedFormatter}}</p>
                    </div>
                </div>

                <div class="briefing-button-bar">
                    <nuc-button-secondary
                        [icon]="editBriefingItemAction?.icon ? editBriefingItemAction.icon : 'nucicon_edit'"
                        *ngIf="stickyNote.campaignItem && editBriefingItemAction"
                        nucTooltipPosition="left"
                        [loading]="loading || activeAction === editBriefingItemAction"
                        [disabled]="activeAction && activeAction !== editBriefingItemAction"
                        (click)="onStickyNoteActionClicked(editBriefingItemAction)"
                        [nucTooltip]="TOOLTIPS.EDIT"></nuc-button-secondary>

                    <nuc-button-secondary icon="nucicon_trash_fill"
                                          *ngIf="stickyNote.campaignItem && unlinkAction"
                                          (click)="onStickyNoteActionClicked(unlinkAction)"
                                          nucTooltipPosition="left"
                                          [loading]="loading || activeAction === unlinkAction"
                                          [disabled]="activeAction && activeAction !== unlinkAction"
                                          [nucTooltip]="TOOLTIPS.REMOVE"></nuc-button-secondary>
                    <nuc-button-secondary icon="nucicon_link"
                                          *ngIf="linkAction"
                                          (click)="onStickyNoteActionClicked(linkAction)"
                                          nucTooltipPosition="left"
                                          [loading]="loading || activeAction === linkAction"
                                          [disabled]="activeAction && activeAction !== linkAction"
                                          [nucTooltip]="TOOLTIPS.LINK"></nuc-button-secondary>
                </div>
            </div>

            <div class="comments-container" *ngIf="!isNewStickyNote">
                <hr/>
                <rl-sticky-comment [addCommentAction]="addCommentAction"
                                   [showCommentsAction]="showCommentsAction"
                                   [stickyNote]="stickyNote"
                                   (commentAdded)="onCommentAdded()"
                                   [publicationId]="publicationId"
                                   [component]="component"
                                   [confirmOnDelete]="true">
                </rl-sticky-comment>
            </div>

        </ng-container>

        <campaign-item-changelog *ngSwitchCase="TAB_BRIEFING"
                                 [campaignItemId]="stickyNote.campaignItem?._id">
        </campaign-item-changelog>

        <rl-note-changes *ngSwitchCase="TAB_NOTE_CHANGES"
                         [note]="stickyNote">
        </rl-note-changes>
    </div>
</div>
