/**
 *  rl-permission.model
 *  app-relayter-web
 *  Created by nandamhuis on 23/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '../interfaces/table-item.interface';

export class PermissionModel implements ITableItem {
    @autoserializeAs('key') public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserialize public active: boolean;
}
