<ng-container *ngIf="permissions.GET_WORKFLOW_AUTOMATIONS | userIsAllowedTo; else noPermissionState">
    <nuc-button-bar class="generate-button" *ngIf="automations && transitionAction">
        <nuc-button-secondary
            [disabled]="selection.isEmpty() || !subscription?.closed"
            [text]="transitionAction.title"
            icon="nucicon_gear"
            iconPosition="end"
            (click)="openDialog()">
        </nuc-button-secondary>
    </nuc-button-bar>

    <nuc-button-bar>
        <paginator [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!subscription?.closed">
        </paginator>
    </nuc-button-bar>

    <nuc-table [loading]="!subscription?.closed"
               [items]="automations"
               [columns]="columns"
               [useEmptyState]="true"
               emptyStateTitle="No workflow automations"
               [selectionMode]="selectionMode"
               [selection]="selection"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
