import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {Cursor} from '../api-cursor';
import {ETransitionStatus, TransitionItemDataModel} from '../../models/api/transition-item.model';
import {SortDirection} from '@angular/material/sort';
import {PublicationModel} from '../../models/api/publication.model';

@Injectable({
    providedIn: 'root'
})
export class PublicationsApiService extends ApiService<PublicationModel> {
    constructor() {
        super([ApiConstants.API_GROUP_PUBLICATIONS], PublicationModel);
    }

    // TODO: Add api calls from publications.service.ts
    public getTransitionItemsData(publication: string,
                                  recipeTasks: string[],
                                  cursor: Cursor,
                                  limit?: number,
                                  offset?: number,
                                  sort?: string,
                                  sortType?: SortDirection): Observable<ARPagedResponseDataModel<TransitionItemDataModel>> {
        const request = new ApiPagedRequest([
            ...this.groups,
            publication,
            ApiConstants.API_METHOD_TRANSITION_ITEMS
        ], TransitionItemDataModel, limit, offset, sort, sortType);
        request.additionalQueryParams
            .addParam('recipeTasks', recipeTasks)
            .addParam('status', ETransitionStatus.DONE);
        return this.apiRequestService.find(request);
    }
}
