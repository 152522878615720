import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {RLCountsModel} from '../../api/response/rl-counts.model';
import {RLCountModel} from '../../api/response/rl-count.model';
import {CustomWorkflowTransitionModel} from '../../models/api/custom-workflow-transition.model';

@Component({
    selector: 'rl-workflow-indicator',
    templateUrl: './workflow-indicator.component.html',
    styleUrls: ['./workflow-indicator.component.scss']
})
export class WorkflowIndicatorComponent {
    @Input() public counts: RLCountsModel;
    @Input() public workflowConfiguration: WorkflowConfigurationModel;
    @Input() public activeStep: CustomWorkflowStepModel;
    @Output() public activeStepChange = new EventEmitter<CustomWorkflowStepModel>();

    /**
     * Returns the name of a backwards transition
     * Currently this only returns the name of the transition between step and previous step
     * Could be improved to handle multiple steps backwards (Not required for current workflow-configurations)
     * @param {CustomWorkflowStepModel} step
     * @returns {string}
     */
    public getNameForBackwardsTransitionFromStep(step: CustomWorkflowStepModel): string {
        const previousStep = this.getPreviousStep(step);
        const transitions = this.getTransitionsForSteps(step, previousStep);
        return transitions.length > 0 ? transitions[0].name : null;
    }

    /**
     * Returns the previous step if it exists (index - 1)
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {CustomWorkflowStepModel}
     */
    private getPreviousStep(currentStep: CustomWorkflowStepModel): CustomWorkflowStepModel {
        const index = this.workflowConfiguration.steps.indexOf(currentStep);

        if (index === 0 || index === -1) { // Step is the first step, or doesn't exist
            return null;
        }

        return this.workflowConfiguration.steps[index - 1];
    }

    /**
     * Returns the next step if it exists (index + 1)
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {CustomWorkflowStepModel}
     */
    private getNextStep(currentStep: CustomWorkflowStepModel): CustomWorkflowStepModel {
        const index = this.workflowConfiguration.steps.indexOf(currentStep);

        if (index === this.workflowConfiguration.steps.length - 1 || index === -1) { // Step is the last step, or doesn't exist
            return null;
        }

        return this.workflowConfiguration.steps[index + 1];
    }

    /**
     * Returns the transitions between two steps
     * @param {CustomWorkflowStepModel} fromStep
     * @param {CustomWorkflowStepModel} toStep
     * @returns {CustomWorkflowTransitionModel[]}
     */
    private getTransitionsForSteps(fromStep: CustomWorkflowStepModel, toStep: CustomWorkflowStepModel): CustomWorkflowTransitionModel[] {
        return this.workflowConfiguration.transitions.filter((transition) => transition.from === fromStep._id && transition.to === toStep._id);
    }

    /**
     * Checks if there is a transition from the previous step (index - 1) to this step
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {boolean}
     */
    public hasPreviousStep(currentStep: CustomWorkflowStepModel): boolean {
        const previousStep = this.getPreviousStep(currentStep);
        return previousStep ? this.getTransitionsForSteps(previousStep, currentStep).length > 0 : false;
    }

    /**
     * Checks if there is a transition from this step to the next step (index + 1)
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {boolean}
     */
    public hasNextStep(currentStep: CustomWorkflowStepModel): boolean {
        const nextStep = this.getNextStep(currentStep);
        return nextStep ? this.getTransitionsForSteps(currentStep, nextStep).length > 0 : false;
    }

    /**
     * Checks if there is a transition from this step to a step with a lower index
     * @param {CustomWorkflowStepModel} step
     * @returns {boolean}
     */
    public hasBackwardsTransition(step: CustomWorkflowStepModel): boolean {
        // First find the index of the step, so we can use it to exclude steps
        const index = this.workflowConfiguration.steps.indexOf(step);

        if (index === 0) { // If step is the initial step we can stop
            return false;
        }

        // Get all stepsIds with a lower step index
        const stepOptions = this.workflowConfiguration.steps.slice(0, index).map((stepOption) => stepOption._id);

        // Check if there exists a transition from step to stepOption
        return this.workflowConfiguration.transitions.some((transition) => transition.from === step._id && stepOptions.includes(transition.to));
    }

    /**
     * Checks if there is a transition from a step with a higher index to this step
     * @param {CustomWorkflowStepModel} step
     * @returns {boolean}
     */
    public hasIncomingBackwardsTransition(step: CustomWorkflowStepModel): boolean {
        // First find the index of the step, so we can use it to exclude steps
        const index = this.workflowConfiguration.steps.indexOf(step);

        if (index === this.workflowConfiguration.steps.length - 1) { // If step is the last step we can stop
            return false;
        }

        // Get all stepsIds with a higher step index
        const stepOptions = this.workflowConfiguration.steps.slice(index + 1).map((stepOption) => stepOption._id);

        // Check if there exists a transition from step to stepOption
        return this.workflowConfiguration.transitions.some((transition) => transition.to === step._id && stepOptions.includes(transition.from));
    }

    /**
     * Checks if there is a "flyby" transition
     * Checks for a transition from a step with a higher index to a step to a lower index
     * @param {CustomWorkflowStepModel} step
     * @returns {boolean}
     */
    public hasFlyByTransition(step: CustomWorkflowStepModel): boolean {
        // First find the index of the step, so we can use it to exclude steps
        const index = this.workflowConfiguration.steps.indexOf(step);

        if (index === 0 || index === this.workflowConfiguration.steps.length - 1) { // If step is the initial or last step we can stop
            return false;
        }

        // Get all stepsIds with a lower step index
        const stepOptionsBefore = this.workflowConfiguration.steps.slice(0, index).map((stepOption) => stepOption._id);

        // Get all stepsIds with a higher step index
        const stepOptionsAfter = this.workflowConfiguration.steps.slice(index + 1).map((stepOption) => stepOption._id);

        return this.workflowConfiguration.transitions
            .some((transition) => stepOptionsBefore.includes(transition.to) && stepOptionsAfter.includes(transition.from));
    }

    /**
     * Returns the number of items in a step if item counts are set
     * @param {CustomWorkflowStepModel} step
     * @return {number}
     */
    public getItemCountForStep(step: CustomWorkflowStepModel): number {
        if (this.counts) {
            const stepCountDetails = this.counts.steps.find((count: RLCountModel) => count._id === step._id);
            return stepCountDetails ? stepCountDetails.count : 0;
        }
        return 0;
    }

    /**
     * Returns transitions between 2 steps going forward or backward.
     * @param {CustomWorkflowStepModel} step
     * @param {boolean} forward
     * @returns {CustomWorkflowTransitionModel[]}
     */
    public getTransitionForStep(step: CustomWorkflowStepModel, forward: boolean): CustomWorkflowTransitionModel[] {
        if (this.counts) {
            const previousStep = this.getPreviousStep(step);
            return forward
                ? this.getTransitionsForSteps(previousStep, step)
                : this.getTransitionsForSteps(step, previousStep);
        }
        return [];
    }

    public getItemCountAroundStep(step: CustomWorkflowStepModel, forward: boolean): number {
        if (this.counts) {
            const previousStep = this.getPreviousStep(step);
            const transitions = forward
                ? this.getTransitionsForSteps(previousStep, step)
                : this.getTransitionsForSteps(step, previousStep);
            return transitions.reduce((acc, transition) => {
                const transitionCountDetails = this.counts.transitions.find((count: RLCountModel) => count._id === transition._id);
                return acc + (transitionCountDetails?.count || 0);
            }, 0);
        }
        return 0;
    }

    public setActiveStep(step: CustomWorkflowStepModel, event: MouseEvent): void {
        event.stopPropagation();
        this.activeStepChange.emit(step);
    }
}
