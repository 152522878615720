import {Component, DestroyRef, inject, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    ConnectionModel,
    ConnectionPatchModel,
    EConnectionType,
    WebhookModel
} from '../../../../../../models/api/connection.model';
import {ConnectionService} from '../../../../../../api/services/connection.service';
import {Toaster} from '../../../../../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../../../../../../environments/environment';
import {ApiConstants} from '../../../../../../api/api.constant';
import {RLBaseComponent} from '../../../../../../components/rl-base-component/rl-base.component';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {ConnectionFormComponent} from '../../../../../../forms/connection-form/connection-form.component';
import {
    IMediaValetWebhookFormComponentData,
    MediaValetWebhookFormComponent
} from '../../../../../../forms/connection-form/media-valet/media-valet-webhook-form/media-valet-webhook-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'standard-connection-detail-mediavalet',
    templateUrl: './connection-detail-mediavalet.component.html',
    styleUrls: ['./connection-detail-mediavalet.component.scss']
})
export class ConnectionDetailMediavaletComponent extends RLBaseComponent implements OnInit, OnDestroy {
    private connectionId: string;
    public connection: WritableSignal<ConnectionModel>;
    public webhooks: WebhookModel[];
    public webhooksSubscription: Subscription;
    public loading: boolean;
    private onDestroySubject = new Subject<void>();
    private destroyRef: DestroyRef = inject(DestroyRef);
    constructor(private activeRoute: ActivatedRoute, private connectionService: ConnectionService,
                private fullModalService: FullModalService,) {
        super();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.connectionId = params.connectionId;
            this.getConnection();
            this.getWebhooks();
        });
    }

    private getConnection(): void {
        this.connectionService.getConnection(this.connectionId).pipe(takeUntil(this.onDestroySubject)).subscribe((connection: ConnectionModel) => {
                if (this.connection) {
                    this.connection.set(connection)
                } else {
                    this.connection = signal(connection);
                }
            },
            (error) => {
                Toaster.handleApiError(error);
            });
    }

    private getWebhooks(): void {
        if (this.webhooksSubscription) this.webhooksSubscription.unsubscribe();
        this.webhooksSubscription = this.connectionService.getWebhooks(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
                this.webhooks = result.items;
            },
            (error) => {
                Toaster.handleApiError(error);
            });
    }

    public requestApiKey(connection: ConnectionModel): void {
        switch (connection.connectionType) {
            case EConnectionType.MEDIA_VALET:
                this.generateMediaValetApiKey(connection);
                break;
        }
    }

    private generateMediaValetApiKey(connection: ConnectionModel): void {
        const params = new HttpParams().appendAll({
            client_id: connection.clientId,
            response_type: 'code',
            scope: 'openid api offline_access',
            redirect_uri:
                `${environment.API_SERVER}/${ApiConstants.API_BASE_PATH}/${ApiConstants.API_GROUP_OAUTH}/${ApiConstants.API_METHOD_CALLBACK}`,
            state: connection._id
        });
        const url = connection.authUrl + '?' + params.toString();
        window.open(url, '_blank');
    }

    public refreshApiKey(connection: ConnectionModel): void {
        this.loading = true;
        this.connectionService.refreshToken(connection._id)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe(() => {
                Toaster.success('Api key refreshed successfully');
                this.getConnection();
            }, ((error) => {
                if (error.code === 6002) {
                    this.generateMediaValetApiKey(connection);
                }
                Toaster.handleApiError(error);
            }));
    }

    public editConnection(): void {
        // Get fresh copy of the connection
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: connection => {
                    this.connection.set(connection);

                    const modalConfig = new FullModalConfig('Edit connection',
                        'You can edit basic connection information.', {connection});
                    modalConfig.confirmClose = true;

                    const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public addWebhook(): void {
        const modalConfig = new FullModalConfig('Add webhook',
            'Configure webhook for mediaValet.', {connectionId: this.connectionId} as IMediaValetWebhookFormComponentData);
        const modalRef = this.fullModalService.open(MediaValetWebhookFormComponent, modalConfig);
        modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
            return res ? this.getConnection() : null;
        });
    }

    public onEditWebhookClicked(webhookId: string): void {
        // Get fresh copy of the event
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: connection => {
                    this.connection.set(connection);

                    const currentWebhook = this.connection().webhooks.find(webhook => webhook._id === webhookId);
                    if (!currentWebhook) {
                        Toaster.error('Webhook not found');
                        return;
                    }
                    const modalConfig = new FullModalConfig('Edit webhook',
                        'Configure webhook for mediaValet.',
                        {connectionId: this.connectionId, webhookData: currentWebhook} as IMediaValetWebhookFormComponentData);
                    const modalRef = this.fullModalService.open(MediaValetWebhookFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    protected unsetApiKey() {
        const patchBody = new ConnectionPatchModel(null);
        this.connectionService.patchConnection(this.connectionId, patchBody)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => {
                    this.connection.set(connection);
                },
                error: Toaster.handleApiError
        });
    }
}
