import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormatOption} from '../../format-ruleset.constants';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {BUTTON_TYPE} from '@relayter/rubber-duck';
import {toWordsOrdinal} from 'number-to-words';
import {ETransformAction, StringTransformPipe} from '../../pipes/string-transform.pipe';
import {format as dateFormatter} from 'date-fns-tz';
import {nl as DEFAULT_LOCALE} from 'date-fns/locale';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../../../app.constants';
import {MinOptionalNumberValidator} from '../../../../classes/validators/min-optional-number.validator';

class TitleElement {
    public text: string;
    public bold = false;

    constructor(text: string, bold = false) {
        this.text = text;
        this.bold = bold;
    }
}

enum EPropertyType {
    ARRAY,
    FIELD
}

enum EConnectionType {
    CONNECT,
    END
}

@Component({
    selector: 'values-form',
    templateUrl: './values-form.component.html',
    styleUrls: ['./values-form.component.scss']
})
export class ValuesFormComponent implements OnInit, OnDestroy {
    @Output() public deleteClicked = new EventEmitter<void>();
    @Input() public formGroup: FormGroup;
    @Input() public valueIndex = 0;
    @Input() public tags: DropdownItem<string>[] = [];
    @Input() public valueProperties: RulePropertyModel[] = [];
    @Input() public tag: string;

    public readonly BUTTON_TYPE = BUTTON_TYPE;
    public readonly PROPERTY_TYPE = EPropertyType;
    public readonly EConnectionType = EConnectionType;

    public formatOptions = FormatOption.OPTIONS;
    public FORMAT_OPTION = FormatOption;
    public onDestroySubject = new Subject<void>();

    public readonly minimalBadgeWidth = 150;

    public propertiesInputs: { type: EPropertyType; properties: RulePropertyModel[] }[] = [];

    get tagFormControl(): FormControl {
        return this.formGroup.get('tag') as FormControl;
    }

    get propertiesFormArray(): FormArray {
        return this.formGroup.get('property') as FormArray;
    }

    get formatFormControl(): FormControl {
        return this.formGroup.get('format') as FormControl;
    }

    get formatStringFormControl(): FormControl {
        return this.formGroup.get('formatString') as FormControl;
    }

    public isOpen: boolean[] = [];
    public operators = ['Item at'];
    public valueDescription: TitleElement[];

    public ngOnInit(): void {
        this.addPropertySelector(EPropertyType.FIELD, this.valueProperties, true);

        if (this.propertiesFormArray.controls.length === 0) {
            this.propertiesFormArray.push(new FormControl(null, Validators.required));
        }

        this.propertiesFormArray.controls.forEach((control, index) => {
            if (control.value instanceof RulePropertyModel || index === 0) {
                control.valueChanges
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe(value => this.dropDownChange(index, value));

                // Add optional array operator
                if (control.value?.isArray) {
                    this.addPropertySelector(EPropertyType.ARRAY, [], true);
                }
                if (control.value?.properties?.length > 0) {
                    this.addPropertySelector(EPropertyType.FIELD, control.value.properties, true);
                }
            }
        });

        this.formGroup.valueChanges
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.updateValueDescription());

        this.formatFormControl.valueChanges
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((value) => {
                if (![FormatOption.DATE_FORMAT.getValue(), FormatOption.TO_STRING.getValue()].includes(value?.getValue())) {
                    this.formatStringFormControl.patchValue(null);
                }
            });

        this.updateValueDescription();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private addPropertySelector(type: EPropertyType, properties: RulePropertyModel[], init = false): void {
        this.propertiesInputs.push({type, properties});

        if (init) return;

        const index = this.propertiesFormArray.length;
        const validators = [];
        if (type === EPropertyType.ARRAY) {
            validators.push(MinOptionalNumberValidator(1));
        } else if (type === EPropertyType.FIELD) {
            validators.push(Validators.required);
        }
        if (this.propertiesInputs[index - 1]?.type === EPropertyType.ARRAY) {
            this.propertiesFormArray.controls[index - 1].clearValidators();
            this.propertiesFormArray.controls[index - 1].addValidators([Validators.required, MinOptionalNumberValidator(1)]);
            this.propertiesFormArray.controls[index - 1].updateValueAndValidity();
        }

        const control = new FormControl(null);
        control.clearValidators();
        control.addValidators(validators);
        control.updateValueAndValidity();

        if (type === EPropertyType.FIELD) {
            control.valueChanges.subscribe(value => this.dropDownChange(index, value));
        }

        this.propertiesFormArray.push(control);
    }

    public dropDownChange(index: number, item: RulePropertyModel): void {
        // Remove all above this index
        this.propertiesInputs.splice(index + 1);
        const numControls = this.propertiesFormArray.length;
        for (let i = index + 1; i < numControls; i++) {
            this.propertiesFormArray.removeAt(index + 1);
        }

        if (item?.isArray) {
            this.addPropertySelector(EPropertyType.ARRAY, []);
        }
        if (item?.properties?.length > 0) {
            this.addPropertySelector(EPropertyType.FIELD, item.properties);
        }
    }

    public showOperatorOverlay(overlayIndex: number, open: boolean): void {
        this.isOpen = Array.from({length: Math.max(overlayIndex + 1, this.isOpen.length)}, (value, index) => {
            if (index === overlayIndex) {
                return !open;
            }
            return false;
        });
    }

    public updateValueDescription(): void {
        this.valueDescription = null;
        if (!this.formGroup.valid) {
            return;
        }

        const title: TitleElement[] = [];
        const numberOfControls = this.propertiesFormArray.length;
        for (let index = numberOfControls; index > 0; index--) {
            const control = this.propertiesFormArray.at(index - 1);
            if (control.value instanceof RulePropertyModel) {
                title.push(new TitleElement('the'));
                title.push(new TitleElement(control.value.getTitle(), true));
                if (index > 1) {
                    title.push(new TitleElement('of'));
                }
            } else if (control.value !== null) {
                title.push(new TitleElement(`the ${toWordsOrdinal(control.value)} item from`));
            }
        }

        title.push(new TitleElement('will be set in tag'));
        title.push(new TitleElement(this.tagFormControl.value.getTitle(), true));

        if (this.formatFormControl.value?.getTitle()) {
            title.push({text: 'and formatted with', bold: false});
            title.push({text: this.formatFormControl.value.getTitle(), bold: true});
        }

        if (this.formatStringFormControl.value) {
            let formattedString = null;
            switch (this.formatFormControl.value?.getValue()) {
                case FormatOption.DATE_FORMAT:
                    title.push({text: this.formatStringFormControl.value, bold: true});
                    const options = {
                        timeZone: AppConstants.DEFAULT_TIMEZONE,
                        locale: DEFAULT_LOCALE,
                        useAdditionalWeekYearTokens: true,
                        useAdditionalDayOfYearTokens: true
                    };
                    formattedString = dateFormatter(new Date(), this.formatStringFormControl.value, options);
                    break;
                case FormatOption.TO_STRING.getValue():
                    formattedString = `separator '${this.formatStringFormControl.value}'`;
                    break;
            }
            if (formattedString) title.push({text: `(${formattedString})`, bold: false});
        }

        if (title.length) {
            title[0].text = StringTransformPipe.format(title[0].text, ETransformAction.FIRST_LETTER);
        }

        this.valueDescription = title;
    }

    public openDateFormatLink(): void {
        window.open('https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table', 'default');
    }

    public removeOperator(index: number): void {
        this.propertiesFormArray.controls[index].patchValue(null);
        // If this is the last one, set Format to TO_STRING
        if (this.propertiesFormArray.controls.length === index + 1) {
            this.formGroup.patchValue({
                format: FormatOption.TO_STRING,
                formatString: this.formatStringFormControl.value || ', '
            });
        }
        this.isOpen[index] = false;
    }

    public getConnectionType(index: number): EConnectionType {
        if ((this.propertiesInputs[index + 1]?.type === EPropertyType.ARRAY) &&
            (this.propertiesInputs[index + 2] || this.propertiesFormArray.controls[index + 1]?.value)) {
            return EConnectionType.CONNECT;
        }
        if (this.propertiesInputs[index + 1]?.properties?.length > 0) {
            return EConnectionType.CONNECT;
        }
        if (this.propertiesInputs[index].type === EPropertyType.ARRAY && ((this.propertiesFormArray.controls[index]?.value &&
            !this.propertiesInputs[index + 1]) || this.propertiesInputs[index + 2])) {
            return EConnectionType.END;
        }
        if (this.propertiesInputs[index + 1]?.type === EPropertyType.ARRAY && !this.propertiesInputs[index + 2]) {
            return EConnectionType.END;
        }
        if (this.propertiesInputs[index].type === EPropertyType.FIELD) {
            return EConnectionType.END;
        }
        if (!this.propertiesInputs[index + 1] && this.propertiesFormArray.controls[index]?.value) {
            return EConnectionType.END;
        }

        return null;
    }
}
