<div class="container">
    <div class="canvas-container" #container>
        <div class="sticky-notes-canvas" cdkDrag (cdkDragStarted)="startDragging()"
             [class.horizontal-scale]="horizontal"
             [cdkDragStartDelay]="1" [class.disable-zoom-transition]="disableZoomTransition"
             (cdkDragReleased)="stopDragging()"
             [hidden]="loadingImage">
            <div class="images-container" #imagesContainer cdkDragHandle>
                <ng-container *ngFor="let item of publicationItems$ | async; let index = index">
                    <ng-container *ngIf="item.previewImage; else placeHolderImage">
                        <div class="image">
                            <img #image
                                 [src]="item.previewImage"
                                 (click)="onImageClicked($event, item, index)"
                                 [class.horizontal-scale]="horizontal"
                                 (load)="fitToContainer(); loadingImage = false"/>
                            <rl-loading-indicator [hide]="!loadingImage" [contain]="true"></rl-loading-indicator>
                            <div class="briefing-changes-container" [class.highlight]="selectedBriefingChange"
                                 [class.hidden]="overlay === previewOverlayEnum.NONE">
                                <div class="standout_layer" *ngIf="overlay === previewOverlayEnum.NOTES_BACKGROUND"></div>
                                <div *ngFor="let campaignItem of item.changedCampaignItems"
                                     [class.selected]="selectedBriefingChange?.campaignItemId === campaignItem._id
                                 && selectedBriefingChange?.publicationItemId === item._id"
                                     class="change" (click)="onBriefingChangeClicked(item._id, campaignItem._id)"
                                     [ngStyle]="{'left.%': campaignItem.position.x, 'top.%': campaignItem.position.y}">
                                    <img [src]="selectedBriefingChange?.campaignItemId === campaignItem._id
                                            && selectedBriefingChange?.publicationItemId === item._id
                                            ? 'assets/images/icon_pin_briefing_changes_selected.svg'
                                            : 'assets/images/icon_pin_briefing_changes.svg'"
                                         class="pin"/>
                                </div>
                            </div>
                            <div class="sticky-notes-container" [class.highlight]="selectedStickyNote"
                                 [class.hidden]="overlay === previewOverlayEnum.NONE">
                                <div class="standout_layer" *ngIf="overlay === previewOverlayEnum.NOTES_BACKGROUND"></div>
                                <ng-container *ngFor="let stickyNote of stickyNotes">
                                    <div *ngIf="stickyNote.publicationItem._id === item._id"
                                         [class.selected]="selectedStickyNote?._id === stickyNote._id"
                                         class="sticky" (click)="onStickyNoteClicked(stickyNote)"
                                         [ngStyle]="{'left.%': stickyNote.position.x, 'top.%': stickyNote.position.y}">
                                        <img
                                            [src]="stickyNote.status | getNotePinImagePipe:'':selectedStickyNote?._id === stickyNote._id"
                                            class="pin"/>
                                        <div class="with-comments" *ngIf="stickyNote.totalComments > 0">
                                            <span>{{stickyNote.totalComments}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="sticky new" *ngIf="newStickyNote && newStickyNote.publicationItem._id === item._id"
                                     [ngStyle]="{'left.%': newStickyNote.position.x, 'top.%': newStickyNote.position.y}">
                                    <img [src]="newStickyNote.status | getNotePinImagePipe:'':true"
                                         class="pin selected" @newStickyNote/>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="controls">
        <nuc-button-secondary (click)="zoomIn()" icon="nucicon_add"
                              [nucTooltip]="'Zoom in'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
        <mat-slider step="1" [min]="((minZoomLevel | async) * 100)" vertical="true" max="300"
                    [value]="(zoomLevel * 100)"
                    (input)="valueChanged($event)" [nucTooltip]="getZoomValue()" [nucTooltipPosition]="'right'"
                    (change)="sliderValueChanged()"></mat-slider>
        <nuc-button-secondary (click)="zoomOut()" icon="nucicon_remove"
                              [nucTooltip]="'Zoom out'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
        <nuc-button-secondary (click)="fitToContainer()" icon="nucicon_images_line"
                              [nucTooltip]="'Fit to canvas'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
        <nuc-button-secondary [disabled]="previewLoading"
                              [icon]="overlay === previewOverlayEnum.NOTES ? 'nucicon_comments_show':
                                          overlay === previewOverlayEnum.NOTES_BACKGROUND ? 'nucicon_comments_popout'
                                          :'nucicon_comments_hide'"
                              [matMenuTriggerFor]="menu"
                              nucTooltip="Note view" nucTooltipPosition="right">
        </nuc-button-secondary>
        <mat-menu #menu="matMenu" xPosition="before" class="menu-items-container">
            <button mat-menu-item
                    *ngFor="let item of previewOverlays"
                    (click)="setOverlay(item.getValue())">
                {{item.getTitle()}}
            </button>
        </mat-menu>
    </div>
    <form class="file-versions" *ngIf="filesVersions" [formGroup]="formGroup">
        <nuc-dropdown formControlName="filesVersion"
                      [nullOption]="false"
                      [total]="filesVersions.length"
                      [items]="filesVersions">
        </nuc-dropdown>
    </form>

</div>

<ng-template #placeHolderImage>
    <div class="image">
        <img #image
             (load)="fitToContainer(); loadingImage = false;"
             [class.horizontal-scale]="horizontal"
             [src]="getPlaceHolderImage()">
        <nuc-empty-state-component
            class="empty-state"
            [small]="true"
            title="No preview available. Please upload or (re)generate files for this publication item."
            imageUrl="/assets/images/general_empty_state.svg">
        </nuc-empty-state-component>
    </div>
</ng-template>
