/**
 * custom-workflow-action.model
 * app-relayter-web
 * Created by borisnelissen on 12/02/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {autoserialize, autoserializeAs} from 'cerialize';
import {CustomWorkflowOptionModel} from './custom-workflow-option.model';
import {EStickyNoteStatus, EWorkflowConfigurationActionType} from '../../app.enums';
import {ITableItem} from '@relayter/rubber-duck';

export class CustomWorkflowActionModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public from: EStickyNoteStatus[];
    @autoserialize public to: EStickyNoteStatus;
    @autoserialize public title: string;
    @autoserialize public icon: string = 'nucicon_question_fill'; // default icon
    @autoserialize public permissions: string[];
    @autoserialize public transition: string;
    @autoserialize public type: EWorkflowConfigurationActionType;
    @autoserializeAs(CustomWorkflowOptionModel) public options: CustomWorkflowOptionModel[];

    constructor(name?: string, permissions?: string[]) {
        this.name = name;
        this.permissions = permissions;
    }
}
