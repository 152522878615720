<multi-item-selection-component *ngIf="allColumns"
                                [tableId]="tableId"
                                [columns]="allColumns"
                                [loading]="!productsSubscription?.closed"
                                [selectedItems]="selectedProducts"
                                (selectedItemsChange)="onSelectionChanged($event)"
                                (onSortOptionChange)="onSortOptionChanged($event)"
                                [items]="products"
                                [disableNextPage]="disableNextPage"
                                [noResultsTitle]="searching ? 'No item matches your search criteria' : 'There are no products available to add'"
                                [readOnly]="readonly"
                                [disableSort]="disableSort"
                                selectionTitle="products">
    <ng-container button-bar-left>
        <nuc-input prefix="nucicon_search" class="search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   [placeholder]="'Search'">
        </nuc-input>

        <rl-data-filter [addDataFieldsContext]="EDataFieldCollectionName.PRODUCT"
                        [filters]="dataFilters"></rl-data-filter>
    </ng-container>
</multi-item-selection-component>
