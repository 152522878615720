import {NgModule} from '@angular/core';
import {RDModule} from '@relayter/rubber-duck';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from '../../../components/components.module';
import {ContentRulesComponent} from './content-rules.component';
import {ContentRuleFormComponent} from './content-rule-form/content-rule-form.component';
import {PipesModule} from '../../../pipes/pipes.module';

@NgModule({
    imports: [RDModule, FormsModule, ReactiveFormsModule, ComponentsModule, PipesModule],
    providers: [],
    declarations: [ContentRulesComponent, ContentRuleFormComponent],
    exports: [ContentRulesComponent]
})
export class ContentRulesModule {
}
