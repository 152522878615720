import {Injectable} from '@angular/core';
import {ARLogger} from '@relayter/core';
import {UserModel} from '../../models/api/user.model';
import {ISegmentService} from './segment.service.interface';

@Injectable({
    providedIn: 'root'
})
export class RLMockSegmentService implements ISegmentService {
    public static TRACK_ADD_PUBLICATION(publicationType?: string): string {
        return `Add Publication - ${publicationType}`;
    }

    public static TRACK_REMOVE_PUBLICATION(publicationType?: string): string {
        return `Remove Publication - ${publicationType}`;
    }

    protected user: UserModel;

    /**
     * @constructor
     */
    constructor() {
        this.init();
    }

    public init(): void {

    }

    public setUser(user: UserModel): void {
        this.user = user;
        this.identify();
    }

    public identify(): void {
        ARLogger.debug(`mocked identify event called for Segment`);
        if (this.user && !this.user.acceptPolicy) {
            return ARLogger.warn('the user did not accept tracking, identify events will not be logged');
        }
        if (this.user) {
            ARLogger.info('Segment Identify Done');
        } else {
            const traits = {};
            if (this.user) {
                traits['name'] = this.user.fullName;
                traits['email'] = this.user.email;
            }
            const debugUserId = this.user && this.user._id ? this.user._id : '';
            ARLogger.debug(`Segment Debug IDENTIFY event -- parameters: userId: [${debugUserId}], traits: [${traits}]`);
        }
    }

    public page(category?: string, name?: string, properties?: any, options?: any): void {
        ARLogger.debug('mocked page event called for Segment');
        if (this.user && !this.user.acceptTracking) {
            return ARLogger.warn('the user did not accept tracking, page events will not be logged');
        }
        // eslint-disable-next-line max-len
        ARLogger.debug(`Segment Debug PAGE event ( -- parameters: category: [${category}], name: [${name}], properties: [${properties}], options: [${options}]`);
    }

    public reset(): void {
        ARLogger.debug('mocked reset event called fro Segment');
    }

    public track(event: string, properties?: any, options?: any): void {
        ARLogger.debug('mocked track event called for Segment');
        if (this.user && !this.user.acceptTracking) {
            ARLogger.warn('the user did not accept tracking, track events will not be logged');
        } else {
            ARLogger.debug(`Segment Debug TRACK event (${event}) -- parameters: : properties: [${properties}], options: [${options}]`);
        }
    }
}
