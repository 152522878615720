import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {ETabFormGroup} from '../package-rule-form/package-rule-form.component';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {VariantModel} from '../../models/api/variant.model';

@Component({
    selector: 'rule-conditions-form-component',
    templateUrl: 'rule-conditions-form.component.html',
    styleUrls: ['rule-conditions-form.component.scss']
})

export class RuleConditionsFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public ruleConditions: RuleConditionModel[] = [];

    @Input() public ruleProperties: RulePropertyModel[];
    @Input() public variants: VariantModel[];
    @Input() public imageName: string;

    public readonly FORM_GROUP_NAME = ETabFormGroup.PACKAGE_RULE_CONDITIONS;

    get conditionsFormArray(): UntypedFormArray {
        return this.form.get(this.FORM_GROUP_NAME) as UntypedFormArray;
    }

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        if (!this.form.contains(this.FORM_GROUP_NAME)) {
            const controls = [];
            if (this.ruleConditions) this.ruleConditions.forEach(() => controls.push(new UntypedFormGroup({})));
            this.form.addControl(this.FORM_GROUP_NAME, new UntypedFormArray(controls));
        } else {
            this.ruleConditions = Object.values(this.form.value[this.FORM_GROUP_NAME]);
        }
    }

    public onDeleteCondition(index: number): void {
        if (this.conditionsFormArray.controls[index]) {
            this.ruleConditions.splice(index, 1);
            this.conditionsFormArray.removeAt(index);
        }

    }

    public onAddConditionClicked(): void {
        // Add field to the form, initial empty value
        this.ruleConditions.push(new RuleConditionModel());
        this.conditionsFormArray.push(new UntypedFormGroup({}));
    }
}
