<div class="header-row">
    <h1>Campaign overview</h1>
    <nuc-button-bar>
        <nuc-button-primary text="Add campaign"
                            *ngIf="permissions.POST_CAMPAIGN | userIsAllowedTo"
                            (click)="openCampaignForm()" i18n>
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_CAMPAIGNS | userIsAllowedTo else noPermissionState">
    <nuc-table [items]="items"
               [columns]="columns"
               [actions]="actions"
               [selectionMode]="selectionMode"
               [loading]="!subscription?.closed"
               [useEmptyState]="true"
               [disableSort]="!!applyLastViewedItemIds"
               [emptyStateTitle]="searchValue || formGroup.value.status ? 'No matching results.' : 'This section is empty. Add the first item.'"
               (itemClicked)="onTableRowClicked($event)"
               [tableId]="tableId"
               useButtonBar="true"
               [prefixStorageKey]="storageKey"
               (actionClicked)="handleTableAction($event)">
        <ng-container button-bar-left>

            <div class="button-bar-left-container">
                <nuc-input [(data)]="searchValue"
                           prefix="nucicon_search"
                           (debounceValueChanged)="onSearchBarValueUpdated()"
                           placeholder="Search campaigns by name">
                </nuc-input>

                <form [formGroup]="formGroup">
                    <nuc-dropdown formControlName="status"
                                  placeholder="Select campaign status"
                                  [items]="statuses">
                    </nuc-dropdown>
                </form>

                <last-viewed-items-checkbox *ngIf="lastViewedItemIds?.length"
                                            [text]="'Only show last viewed campaigns'"
                                            [context]="context"></last-viewed-items-checkbox>

                <div class="vertical-line"></div>
            </div>

        </ng-container>
        <ng-container button-bar-right>
            <paginator [viewId]="tableId"
                       [disableNextPage]="disableNextPage"
                       [loading]="!subscription?.closed">
            </paginator>
        </ng-container>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
