import {Cursor} from '../api/api-cursor';
import {Serialize} from 'cerialize';

export interface IQueryParam {
    queryParam: string;
    value: string | string[];
}

/**
 * Query parameters
 * Class to maintain request query parameters
 */
export class QueryParams {
    private params: Record<string, any> = {};
    private static ALLOWED_CURSOR_DATA_TYPES = ['number', 'date', 'objectid', 'boolean', 'string'];

    /**
     * Set limit and offset parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {number} limit - Max number of results to return
     * @param {number} offset - Returns results from the offset
     * @returns {QueryParams}
     */
    public setLimitAndOffsetParams(limit?: number, offset?: number): QueryParams {
        this.params['limit'] = limit && limit > 0 ? limit : 100;
        this.params['offset'] = offset && offset > 0 ? offset : 0;
        this.params['includeTotals'] = true;

        return this;
    }

    /**
     * Set sort field and sort direction parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} [sort] - The name of the field to sort on
     * @param {string} [sortDirection] - The direction of the sort (asc/desc)
     * @returns {QueryParams}
     */
    public setSortAndSortDirectionParams(sort?: string, sortDirection?: string): QueryParams {
        if (sort && sortDirection) {
            this.params['sort'] = sort;
            this.params['sortType'] = sortDirection.toUpperCase();
        }
        return this;
    }

    /**
     * Set value to search on. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} [search] = Value to search on within the results
     * @returns {QueryParams}
     */
    public setSearchParams(search?: string): QueryParams {
        if (search) {
            this.params['search'] = search;
        }
        return this;
    }

    /**
     * Set the cursor id and value
     * @param {Cursor} cursor - Cursor
     * @returns {QueryParams}
     */
    public setCursor(cursor?: Cursor): QueryParams {
        if (cursor?.duplicatedValues) this.params['cursorDuplicates'] = true;
        if (cursor?._id) this.params['cursorId'] = cursor._id;
        // Value only set for duplicated values
        if (cursor?.duplicatedValues) this.addParam('cursorValues', cursor.value);
        if (cursor?.dataType && QueryParams.ALLOWED_CURSOR_DATA_TYPES.includes(cursor.dataType)) this.addParam('cursorDataTypes', cursor.dataType);
        if (cursor) this.params['includeTotals'] = false;

        return this;
    }

    /**
     * Add a query parameter by key and value. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} key - Name of parameter
     * @param {any} value - The value of the parameter
     * @returns {QueryParams}
     */
    public addParam(key: string, value: any): QueryParams {
        if (key && (value || value === false || value === 0)) {
            this.params[key] = Array.isArray(value) ? value.join('|') : value;
        }
        return this;
    }

    /**
     * Add parameters by the keys of a object. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {object} object - Object to add to the query parameters
     * @returns {QueryParams}
     */
    public addObject(object: Record<string, any>): QueryParams {
        if (object) {
            for (const key of Object.keys(object)) {
                this.addParam(key, object[key]);
            }
        }
        return this;
    }

    /**
     * Get query parameters as an object
     * @returns {object}
     */
    public getParams(): Record<string, any> {
        return Serialize(this.params);
    }
}
