import {autoserialize} from 'cerialize';
import {UserModel} from './user.model';
import {environment} from '../../../environments/environment';

export class LoginResponseModel {
    @autoserialize public user: UserModel;
    @autoserialize public token: string;
}

export class LoginBodyModel {
    @autoserialize private email: string;
    @autoserialize private password: string;
    private client_id: string;

    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
        this.client_id = environment.OAUTH_CLIENT_ID
    }
}
