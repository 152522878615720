import {AfterViewInit, Component} from '@angular/core';
import {TabBarRoutableItem} from '../team-settings/tab-bar-routable-item.model';
import {AppConstants} from '../../../app.constants';
import {Router} from '@angular/router';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';

@Component({
    selector: 'rl-product-data-management',
    templateUrl: 'product-data-management.component.html',
    styleUrls: ['product-data-management.component.scss']
})

export class ProductDataManagementComponent implements AfterViewInit {
    public dataFieldsTab = new TabBarRoutableItem(
        'Data fields',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.PRODUCT_DATA_MANAGEMENT, AppConstants.CONTEXT_URL.DATA_FIELDS],
        []
    );

    public productFormTab = new TabBarRoutableItem(
        'Product form',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.PRODUCT_DATA_MANAGEMENT, AppConstants.CONTEXT_URL.PRODUCT_FORM],
        []
    );

    public tabs = [this.dataFieldsTab, this.productFormTab];
    public activeTab: TabBarRoutableItem;

    constructor(private router: Router,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public checkTabPermissions(): void {
        this.tabs = this.tabs.filter((tab) => this.userIsAllowedToPipe.transform(tab.permissions));
        this.activeTab = this.tabs[0];
        this.updateUrl();
    }

    public onActiveTabChanged(newTab: ITabBarItem): void {
        this.activeTab = this.tabs.find((tab) => tab.title === newTab?.title) || this.tabs[0];

        this.updateUrl();
    }

    public updateUrl(): void {
        if (this.activeTab) {
            this.router.navigate(this.activeTab.url, {skipLocationChange: false});
        }
    }

    public ngAfterViewInit(): void {
        // TODO fix tab bar by using material one "mat-tab-nav-bar"
        setTimeout(() => {
            switch (this.router.url) {
                /* eslint-disable-next-line max-len */
                case `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.PRODUCT_DATA_MANAGEMENT}/${AppConstants.CONTEXT_URL.DATA_FIELDS}`:
                    this.activeTab = this.tabs[0];
                    break;
                /* eslint-disable-next-line max-len */
                case `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.PRODUCT_DATA_MANAGEMENT}/${AppConstants.CONTEXT_URL.PRODUCT_FORM}`:
                    this.activeTab = this.tabs[1];
                    break;
                default:
                    this.checkTabPermissions();
            }
        });
    }
}
