import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnSize,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {
    ConnectionFormComponent
} from '../../../../forms/connection-form/connection-form.component';
import {Subject, Subscription} from 'rxjs';
import {ConnectionService} from '../../../../api/services/connection.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {EConnectionType, ConnectionModel} from '../../../../models/api/connection.model';
import {Toaster} from '../../../../classes/toaster.class';
import {CursorArray} from '../../../../api/api-cursor';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {takeUntil} from 'rxjs/operators';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {AppConstants} from '../../../../app.constants';
import {Router} from '@angular/router';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';

const DELETE_CONNECTION = 'Delete connection';

@Component({
    selector: 'standard-connections',
    templateUrl: './connections.component.html',
    styleUrls: ['./connections.component.scss'],
    providers: [PaginatorService]
})
export class ConnectionsComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'connection-overview-table';

    public columns: ITableColumn[] = [
        {
            title: 'Type',
            key: 'connectionType',
            sortProperty: 'connectionType',
            size: EColumnSize.LARGE
        },
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        }
    ];
    public actions: ITableAction[] = [
        {
            title: DELETE_CONNECTION,
            icon: 'nucicon_trash_fill',
        },
    ];
    public connections: ConnectionModel[] = [];
    public readonly viewId: string = 'connection-overview';
    public disableNextPage = true;
    public subscription: Subscription;
    public apiCursor: CursorArray;
    public pageIndex: number;
    public pageSize: number;
    public sortColumn: ITableColumn;
    public loading: boolean;
    public selectionMode: ESelectionMode = ESelectionMode.EMIT;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private connectionService: ConnectionService,
                private paginatorService: PaginatorService,
                private dialogService: NucDialogService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private router: Router,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates);

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getConnections();
            });
        this.paginatorService.setPageIndex(this.viewId, 1);
    }

    private getConnections(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_CONNECTIONS)) return;

        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.apiCursor.getCursor(this.pageIndex);
        const offset = cursor?._id ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.connectionService.getConnections(
            this.pageSize,
            offset,
            cursor,
            this.sortProperty,
            this.sortOrder
        )
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result: ARPagedResponseDataModel<ConnectionModel>) => {
                    this.connections = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.connections?.length > 0) {
                        const item = this.connections[this.connections.length - 1];
                        this.apiCursor.setCursor(this.pageIndex, this.sortColumn?.sortProperty, item);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public addConnectionClicked(): void {
        const modalConfig = new FullModalConfig('Add connection',
            'Select and configure a connection.', {});
        modalConfig.confirmClose = true;

        const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
        modalRef.afterClosed().pipe(takeUntil(this.onDestroySubject)).subscribe((res) => {
            return res ? this.getConnections() : null;
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public onActionClicked(event: IActionClickEvent): void {
        if (event.action.title === DELETE_CONNECTION) {
            this.deleteConnection(event.item._id);
        }
    }

    public onItemClicked(event: IItemClickEvent): void {
        const item = event.item as ConnectionModel;
        let connectionTypePath;
        switch (item.connectionType) {
            case EConnectionType.MEDIA_VALET:
                connectionTypePath = 'mediavalet';
                break;
            case EConnectionType.WEBHOOK_CONSUMER:
                connectionTypePath = 'webhook-consumer';
                break;
            default:
                Toaster.warn(`Unknown connection type ${item.connectionType}`);
                return;
        }
        this.router.navigate([AppConstants.CONNECTION_PATH, event.item._id, connectionTypePath], {skipLocationChange: false});
    }

    public deleteConnection(id: string): void {
        const deleteDialogConfig = new NucDialogConfigModel(DELETE_CONNECTION,
            'Please confirm that you wish to delete this connection.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.connectionService.deleteConnection(id)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: () => {
                        Toaster.success('Connection removed successfully');
                        this.getConnections();
                    },
                    error: Toaster.handleApiError
                });
        });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }

    private setPageIndex(pageIndex = 1) {
        this.paginatorService.setPageIndex(this.viewId, pageIndex);
    }
}
