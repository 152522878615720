import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BooleanOption, ConditionsConstants, ConditionType, PropertyOperator} from '../../format-ruleset.constants';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {Subscription} from 'rxjs';
import {EDataFieldTypes} from '../../../../app.enums';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';

@Component({
    selector: 'conditions-form',
    templateUrl: './conditions-form.component.html',
    styleUrls: ['./conditions-form.component.scss']
})
export class ConditionsFormComponent implements OnInit, OnDestroy {
    @Output() public deleteClicked = new EventEmitter<void>();

    @Input() public formGroup: FormGroup;
    @Input() public ruleProperties: RulePropertyModel[];
    @Input() public valueIndex = 0;

    public conditionTypes: ConditionType[];
    public operators = PropertyOperator.OPERATORS;

    private propertySubscription: Subscription;
    private operatorSubscription: Subscription;
    private typeSubscription: Subscription;

    public inputType: string = null;
    public dropdownItems: IDropdownItem<boolean | string | number>[];
    public propertyOptions: RulePropertyModel[] = [];

    /**
     * Subscribe to the change of the property and type values
     */
    public ngOnInit(): void {
        this.propertyOptions = this.ruleProperties;
        this.propertySubscription = this.formGroup.get('property').valueChanges
            .subscribe((property: RulePropertyModel) => {
                // When changing to enum
                const isDropDownValue = property?.dataType.type === EDataFieldTypes.ENUM ||
                    property?.dataType.type === EDataFieldTypes.BOOLEAN;
                if (isDropDownValue || (this.inputType === 'dropdown' && !isDropDownValue)) {
                    this.formGroup.get('value').patchValue(null);
                }
                if (property?.isArray) {
                    this.formGroup.get('operator').enable();
                } else {
                    this.formGroup.get('operator').patchValue(null);
                    this.formGroup.get('operator').disable();
                }

                this.setTypes();
                this.setInputType();
            });

        // Subscribe to value change of the property to update inputType
        this.operatorSubscription = this.formGroup.get('operator').valueChanges
            .subscribe((operator: PropertyOperator) => {
                // Reset value if no longer compatible
                let value = this.formGroup.get('value').value;
                let type = this.formGroup.get('type').value;
                switch (operator) {
                    case PropertyOperator.LENGTH:
                        value = parseInt(value, 10);
                        value = isNaN(value) ? null : value;
                        break;
                    case PropertyOperator.EVERY:
                    case PropertyOperator.SOME:
                        const property = this.formGroup.get('property').value;
                        if (property?.dataType?.type !== EDataFieldTypes.ENUM) {
                            value = value?.toString();
                        } else if (!(value instanceof DropdownItem)) {
                            value = null;
                        }
                        break;
                    case PropertyOperator.EXISTS:
                    case PropertyOperator.NOT_EXISTS:
                    default:
                        value = null;
                        type = null;
                        break;
                }
                this.formGroup.get('value').patchValue(value);
                this.formGroup.get('type').patchValue(type);
                this.setTypes();
                if (!this.conditionTypes.find(conditionType => this.formGroup.get('type').value === conditionType)) {
                    this.formGroup.get('type').patchValue(null);
                }
                this.setInputType();
            });

        this.typeSubscription = this.formGroup.get('type').valueChanges.subscribe((type: ConditionType) => {
            if (type?.valueRequired) {
                this.formGroup.get('value').enable();
            } else {
                this.formGroup.get('value').patchValue(null);
                this.formGroup.get('value').disable();
            }

            this.setInputType();
        });

        this.setTypes();
        this.setInputType();
    }

    public ngOnDestroy(): void {
        this.propertySubscription.unsubscribe();
        this.operatorSubscription.unsubscribe();
        this.typeSubscription.unsubscribe();
    }

    public setDropdownItems(dataType: string, enumList?: string[]): void {
        switch (dataType) {
            case EDataFieldTypes.ENUM:
                this.dropdownItems = enumList.map((item) => new DropdownItem(item, item));
                break;
            case EDataFieldTypes.BOOLEAN:
                this.dropdownItems = BooleanOption.OPTIONS;
                break;
            default:
                this.dropdownItems = [];
                break;
        }
    }

    /**
     * Set possible typeOperators that can be used for selected property dataType
     */
    public setTypes(): void {
        let property;

        switch (this.formGroup.get('operator')?.value) {
            case PropertyOperator.LENGTH:
                property = ConditionsConstants.DATA_TYPE_LENGTH;
                break;
            case PropertyOperator.EXISTS:
            case PropertyOperator.NOT_EXISTS:
                property = null;
                break;
            case PropertyOperator.EVERY:
            case PropertyOperator.SOME:
                property = this.formGroup.get('property').value?.dataType.type;
                break;
            default:
                if (!this.formGroup.get('property').value?.isArray) {
                    property = this.formGroup.get('property').value?.dataType.type;
                }
                break;
        }

        this.conditionTypes = ConditionType.getTypesForProperty(property);
        // No conditions, no value
        if (this.conditionTypes.length === 0) {
            this.formGroup.get('value').patchValue(null);
        }
    }

    /**
     * Set input type of the value fields based on the property datatype, operator and condition type
     */
    public setInputType(): void {
        const property = this.formGroup.get('property').value;
        const operator = this.formGroup.get('operator').value;
        const type = this.formGroup.get('type').value;

        // Reset input type if selected type is not selectable
        if (!this.conditionTypes.find(conditionType => conditionType === type)) {
            this.inputType = null;
            return;
        }

        switch (type) {
            case ConditionType.LEADING_LENGTH:
            case ConditionType.LEADING_LENGTH_GREATER_THAN:
            case ConditionType.LENGTH_GREATER_THAN:
            case ConditionType.LENGTH:
                this.inputType = EDataFieldTypes.NUMBER;
                break;
            case ConditionType.LOWER_OR_EQUAL:
            case ConditionType.LOWER_THAN:
            case ConditionType.GREATER_OR_EQUAL:
            case ConditionType.GREATER_THAN:
                this.inputType = property?.dataType?.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : EDataFieldTypes.NUMBER;
                break;
            case ConditionType.NOT_EQUALS:
            case ConditionType.EQUALS:
            case ConditionType.INCLUDES:
            case ConditionType.NOT_INCLUDES:
                if (operator === PropertyOperator.LENGTH) {
                    this.inputType = EDataFieldTypes.NUMBER;
                } else {
                    const propertyDataType = property?.dataType?.type;
                    if (propertyDataType === EDataFieldTypes.ENUM) {
                        this.inputType = 'dropdown';
                        this.setDropdownItems(EDataFieldTypes.ENUM, property.dataType.enumeration?.items);
                    } else if (propertyDataType === EDataFieldTypes.BOOLEAN) {
                        this.inputType = 'dropdown';
                        this.setDropdownItems(EDataFieldTypes.BOOLEAN);
                    } else if (propertyDataType === EDataFieldTypes.LIST) {
                        this.inputType = operator ? EDataFieldTypes.STRING : null;
                    } else if (propertyDataType === EDataFieldTypes.OBJECT_ID) {
                        // TODO: Type ObjectId should show a drop down with values from the collection this data type refers too
                        this.inputType = EDataFieldTypes.STRING;
                    } else if (propertyDataType === ConditionsConstants.DATA_TYPE_LENGTH) {
                        // Normally, propertyDataType will only be length for array operator length
                        this.inputType = EDataFieldTypes.NUMBER;
                    } else {
                        this.inputType = propertyDataType;
                    }
                }
                break;
            case ConditionType.EXISTS:
            case ConditionType.NOT_EXISTS:
            default:
                this.inputType = null;
                break;
        }

        // Update validators, based on the inputType
        if (this.inputType) {
            if (this.inputType === EDataFieldTypes.NUMBER) {
                this.formGroup.get('value').setValidators(RLValidatorConstants.VALIDATOR_SETS.REQUIRED_NUMBER);
            } else {
                this.formGroup.get('value').setValidators(RLValidatorConstants.VALIDATOR_SETS.REQUIRED);
            }
        } else {
            this.formGroup.get('value').clearValidators();
        }
        // Apply the new validators
        this.formGroup.get('value').updateValueAndValidity();
    }

    public searchProperties(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.propertyOptions = [];
        const regex = new RegExp(event.search, 'i');
        this.propertyOptions = event.search
            ? this.ruleProperties.filter((ruleProperty) => ruleProperty.name.match(regex)?.length > 0)
            : this.ruleProperties;
    }
}
