<nuc-table [items]="items"
           [columns]="columns"
           [loading]="!subscription?.closed"
           [actions]="tableActions"
           [tableId]="tableId"
           useButtonBar="true"
           [selection]="selection"
           [selectionMode]="tableSelectionMode"
           [prefixStorageKey]="storageKey"
           (actionClicked)="handleTableRowAction($event)"
           (sortOptionChange)="onSortOptionChange($event)">
    <ng-container button-bar-right>
        <div class="popout-button" *ngIf="selection.hasValue()">
            <nuc-button-secondary
                text="{{selectedItems.length}} selected {{selectedItems.length === 1 ? 'item' : 'items'}}"
                icon="nucicon_dots_vertical"
                iconPosition="end"
                [matMenuTriggerFor]="menu">
            </nuc-button-secondary>
            <mat-menu #menu="matMenu" class="menu-items-container">
                <ng-container *ngIf="multiCompatibleActions?.length else noActionsAvailable">
                    <ng-container *ngFor="let action of multiCompatibleActions">
                        <button mat-menu-item
                                (click)="handleMultiSelectionAction(action)"
                                [disableRipple]="true">{{action.title}}</button>
                    </ng-container>
                </ng-container>
                <ng-template #noActionsAvailable>
                    <button mat-menu-item [disabled]="true" [disableRipple]="true">No actions available</button>
                </ng-template>
            </mat-menu>
        </div>

        <div class="vertical-line" *ngIf="selection.hasValue()"></div>

        <paginator [viewId]="tableId"
                   [loading]="!subscription?.closed"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </ng-container>
</nuc-table>

<ng-template #otherState>
    <ng-container *ngIf="!subscription?.closed; else emptyState">
        <rl-loading-indicator></rl-loading-indicator>
    </ng-container>
    <ng-template #emptyState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/overview.svg"
                                  title="No publication items"
                                  subtitle="Move publication items to this step.">
        </nuc-empty-state-component>
    </ng-template>
</ng-template>
