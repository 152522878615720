import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AssetModel} from '../../../models/api/asset.model';
import {
    EColumnDataType,
    ESelectionMode,
    ESortOrder,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    ITableItem
} from '@relayter/rubber-duck';
import {AssetService} from '../../../api/services/asset.service';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {AppConstants} from '../../../app.constants';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {TableConfigService} from '../../../api/services/table-config.service';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {CAMPAIGN_ITEM_ASSETS_SELECTED_ICON_COLUMN, CAMPAIGN_ITEM_ASSETS_TABLE_DEFAULT_COLUMNS} from './campaign-item-assets-table-columns';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../../app.enums';
import {DataFieldsComponentUtil} from '../../../classes/data-fields-component.util';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {CursorArray} from '../../../api/api-cursor';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {DataFieldModel} from '../../../models/api/data-field.model';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {ProductModel} from '../../../models/api/product.model';
import {ApiConstants} from '../../../api/api.constant';
import {DataFilterModel} from '../../../models/ui/data-filter.model';
import {AdvancedFiltersDataService} from '../../../api/services/advanced-filters.data-service';
import {FileTypeUtil} from '../../../classes/file-type.util';

@Component({
    selector: 'campaign-item-assets-form',
    templateUrl: './campaign-item-assets-form.component.html',
    styleUrls: ['./campaign-item-assets-form.component.scss'],
    providers: [PaginatorService, AdvancedFiltersDataService]
})
export class CampaignItemAssetsFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'campaign-item-form-assets-table';
    @Input() public readonly = false;

    @Input() public form: UntypedFormGroup;
    @Input() public selectedAssets: AssetModel[];
    @Input() public selectedProducts: ProductModel[] = [];

    public allAssets: AssetModel[] = [];

    public allColumns: ITableColumn[];
    public allSelectedColumns: ITableColumn[];

    public selectionMode = ESelectionMode.MULTI;
    public actionTypes: ITableAction[] = [AppConstants.TABLE_ACTION_TYPES.DOWNLOAD];

    public pageSizeOptions = AppConstants.PAGE_SIZE_OPTIONS.map((opt) => new DropdownItem(`${opt}`, opt));
    public pageSize: number;
    public pageIndex = 1;
    public disableNextPage = true;
    private cursorArray: CursorArray;
    private sortColumn: ITableColumn;
    public showLinkedAssetsOnly = false;
    public disableSort = false;

    private onDestroySubject = new Subject<void>();

    public assetsSubscription: Subscription;
    public searching: boolean = false;

    public filterContext = EDataFieldCollectionName.ASSET;
    public dataFilters: DataFilterModel[] = [];

    private dataFieldComponentUtil: DataFieldsComponentUtil;

    constructor(private assetService: AssetService,
                private tableConfigService: TableConfigService,
                private dataFieldsService: DataFieldsApiService,
                private paginatorService: PaginatorService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);

        this.dataFieldComponentUtil = inject(DataFieldsComponentUtil);
    }

    public ngOnInit(): void {
        this.initForm();
        this.initDataFilters();

        this.selectedProducts = this.form.contains('products') ? this.form.get('products').value : this.selectedProducts;

        this.getTableData();

        this.cursorArray = new CursorArray(this.pageIndex);

        this.paginatorService.getPagination(this.tableId).pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }

                this.getAssets();
            });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(filterValues => {
                if (this.filterValues !== filterValues) {
                    this.filterValues = filterValues;

                    this.setPageIndex();
                }
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public actionClicked(actionEvent: IActionClickEvent): void {
        if (actionEvent.action.title === AppConstants.TABLE_ACTION_TYPES.DOWNLOAD.title) {
            window.open(actionEvent.item['files'].source);
        }
    }

    private initDataFilters(): void {
        this.dataFilters.push(new DataFilterModel('RIN', 'rin', EDataFieldTypes.STRING));
        const items = FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES);

        const dropdownItems = items.map(item => new DropdownItem(item, item));
        this.dataFilters.push(new DataFilterModel('File type', 'type', EDataFieldTypes.ENUM, dropdownItems, {multiSelect: true, items}));
    }

    private initForm(): void {
        this.form.contains('assets') ?
            this.selectedAssets = this.form.get('assets').value || [] :
            this.form.addControl('assets', new UntypedFormControl(this.selectedAssets));
    }

    private getTableData(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (dataFields) => {
                    this.initializeTable(dataFields);
                    this.setPageIndex();
                },
                Toaster.handleApiError);
    }

    private initializeTable(dataFields: DataFieldModel[]): void {
        this.allColumns = [
            ...CAMPAIGN_ITEM_ASSETS_TABLE_DEFAULT_COLUMNS,
            ...this.dataFieldComponentUtil.getDataFieldsColumnSelection(dataFields)
        ];

        this.allSelectedColumns = [...this.allColumns, CAMPAIGN_ITEM_ASSETS_SELECTED_ICON_COLUMN]
            .map(column => ({...column, sortProperty: undefined}));
    }

    private setPageIndex(pageIndex = 1): void {
        this.cursorArray.reset(pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getAssets(): void {
        if (this.assetsSubscription) this.assetsSubscription.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        if (this.showLinkedAssetsOnly && this.selectedProducts?.length === 0) { // no need to do an api call when products are empty
            this.allAssets = [];
            this.disableNextPage = true;
            return;
        }

        const productIds = this.showLinkedAssetsOnly ? this.selectedProducts.map((product) => product._id) : null;

        this.assetsSubscription =
            this.assetService.getAssets(this.sortOrder === 'desc',
                !this.searchValue ? this.sortProperty : null,
                this.searchValue,
                this.pageSize,
                0,
                this.filterValues,
                cursor,
                productIds)
                .pipe(
                    tap(() => {
                        this.searching = !!this.searchValue;
                    }),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe(
                    (res: ARPagedResponseDataModel<AssetModel>) => {
                        this.allAssets = res.items;
                        this.disableNextPage = !res.hasNext;

                        if (res.items.length > 0) {
                            this.setCursor();
                        }
                    },
                    (err: ARApiError) => Toaster.handleApiError(err));
    }

    public onSelectionChanged(selectedAssets: ITableItem[]): void {
        this.selectedAssets = selectedAssets as AssetModel[];
        this.form.get('assets').setValue(this.selectedAssets);
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.searchValue) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, this.sortColumn?.sortDuplicates);
        }
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.allAssets[this.allAssets.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sortProperty, this.allAssets[this.allAssets.length - 1]);
        }
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.searching = !!this.searchValue;
            this.disableSort = !!this.searchValue;

            this.setPageIndex();
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column?.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.sortColumn = sortEvent.column;

        // Ignore sort events when sort disabled
        if (!this.disableSort) this.setPageIndex();
    }

    public onCheckboxValueChange(value: boolean): void {
        this.showLinkedAssetsOnly = value;
        this.setPageIndex();
    }
}
