import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormatRulesetsOverviewComponent} from './format-rulesets-overview/format-rulesets-overview.component';
import {RDModule} from '@relayter/rubber-duck';
import {ComponentsModule} from '../../components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormatRulesetDetailComponent} from './format-ruleset-detail/format-ruleset-detail.component';
import {
    FormatRulesetItemGroupTableComponent
} from './format-ruleset-detail/format-ruleset-item-table/format-ruleset-item-group-table/format-ruleset-item-group-table.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {FormatRulesetItemFormComponent} from './format-ruleset-item-form/format-ruleset-item-form.component';
import {FormatRulesetAssetItemFormComponent} from './format-ruleset-asset-item-form/format-ruleset-asset-item-form.component';
import {RulesetFormComponent} from './ruleset-form/ruleset-form.component';
import {ConditionsFormComponent} from './rule-form/conditions-form/conditions-form.component';
import {ValuesFormComponent} from './rule-form/values-form/values-form.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {StringTransformPipe} from './pipes/string-transform.pipe';
import {PipesModule} from '../../pipes/pipes.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ConditionGroupFormComponent} from './rule-form/condition-group-form/condition-group-form.component';
import {
    FormatRulesetAssetItemTableComponent
} from './format-ruleset-detail/format-ruleset-item-table/format-ruleset-asset-item-table/format-ruleset-asset-item-table.component';
import {
    FormatRulesetItemGroupFormComponent
} from './format-ruleset-item-group-form/format-ruleset-item-group-form.component';
import {
    FormatRulesetLibItemTableComponent
} from './format-ruleset-detail/format-ruleset-item-table/format-ruleset-lib-item-table/format-ruleset-lib-item-table.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    declarations: [
        FormatRulesetsOverviewComponent,
        FormatRulesetDetailComponent,
        FormatRulesetAssetItemTableComponent,
        FormatRulesetItemGroupTableComponent,
        FormatRulesetItemFormComponent,
        FormatRulesetAssetItemFormComponent,
        FormatRulesetLibItemTableComponent,
        RulesetFormComponent,
        ValuesFormComponent,
        ConditionsFormComponent,
        StringTransformPipe,
        ConditionGroupFormComponent,
        FormatRulesetItemGroupFormComponent
    ],
    exports: [
        StringTransformPipe
    ],
    imports: [
        CommonModule,
        RDModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        MatExpansionModule,
        MatMenuModule,
        OverlayModule,
        DragDropModule,
        PipesModule
    ]
})
export class FormatRulesetsModule {
}
