import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MonitoredTransitionsService} from '../../../api/services/monitored-transitions.service';
import {TransitionItemModel} from '../../../models/api/transition-item.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
    CustomWorkflowTransitionModel,
    ECustomWorkflowTransitionRecipeTaskName
} from '../../../models/api/custom-workflow-transition.model';

@Component({
    selector: 'rl-transition',
    templateUrl: './transition.component.html',
    styleUrls: ['./transition.component.scss']
})
export class TransitionComponent implements OnInit, OnDestroy {

    @Input() public hasBackwardsTransition: boolean = false;
    @Input() public hasNext: boolean = false;
    @Input() public backwardsTransitionName: string;

    @Input() public forwardCount: number;
    @Input() public backwardCount: number;
    @Input() public forwardTransitions: CustomWorkflowTransitionModel[] = [];
    @Input() public backwardTransitions: CustomWorkflowTransitionModel[] = [];

    public forwardProgress = 0;
    public backwardProgress = 0;
    public forwardCountOverview: string;
    public backwardCountOverview: string;

    private onDestroySubject = new Subject<void>();

    /**
     * @param {MonitoredTransitionsService} monitoredTransitionsService
     */
    constructor(private monitoredTransitionsService: MonitoredTransitionsService) {
    }

    public ngOnInit(): void {
        this.monitoredTransitionsService.monitoredTransitionsSubject
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((transitionItems: TransitionItemModel[]) => this.updateProgress(transitionItems));
    }

    /**
     * Update the progress on the transitions
     * @param transitionItems
     * @private
     */
    private updateProgress(transitionItems): void {
        // find the transitionItems for this transition
        const forwardTransitionItems: TransitionItemModel[] = transitionItems.filter(
            item => this.forwardTransitions.find(
                transition => transition._id === item.transition));

        if (forwardTransitionItems.length > 0) {
            this.forwardProgress = this.calculateProgress(forwardTransitionItems, this.forwardCount);
            this.forwardCountOverview = this.getCountOverviewPerTask(forwardTransitionItems);
        }

        // find the transitionItems for this transition
        const backwardTransitionItems: TransitionItemModel[] = transitionItems.filter(
            item => this.backwardTransitions.find(
                transition => transition._id === item.transition));

        if (backwardTransitionItems.length > 0) {
            this.backwardProgress = this.calculateProgress(backwardTransitionItems, this.backwardCount);
            this.backwardCountOverview = this.getCountOverviewPerTask(backwardTransitionItems);
        }
    }

    /**
     * Calculate the item total progress of all the active transition items.
     * @param transitionItems
     * @param totalItemCount
     * @private
     */
    private calculateProgress(transitionItems: TransitionItemModel[], totalItemCount: number): number {
        let totalProgress = 0;
        for (const transitionItem of transitionItems) {
            totalProgress += Object.values(transitionItem.progress).reduce((a, b) => a + b, 0) / Object.keys(transitionItem.progress).length;
        }
        return totalItemCount === 0 ? 0 : totalProgress / totalItemCount * 100;
    }

    /**
     * Totals all the transition recipe tasks into a string for the tooltip.
     * @param transitionItems
     * @private
     */
    private getCountOverviewPerTask(transitionItems: TransitionItemModel[]): string {
        const recipeTaskTotals = {};
        for (const transitionItem of transitionItems) {
            for (const key in transitionItem.progress) {
                if (recipeTaskTotals[key]) {
                    recipeTaskTotals[key] += transitionItem.progress[key];
                } else {
                    recipeTaskTotals[key] = transitionItem.progress[key];
                }
            }
        }

        let overview = '';
        for (const key of Object.keys(recipeTaskTotals)) {
            if (overview !== '') overview += ', ';
            overview += `${CustomWorkflowTransitionModel.getRecipeTaskProgressTitle(key as ECustomWorkflowTransitionRecipeTaskName)}: 
                            ${recipeTaskTotals[key]} `;
        }
        return overview;
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
