<div class="items-container" [class.no-tab-bar]="noTabBar">
    <p class="section-header">Selected {{selectionTitle}}</p>
    <nuc-table *ngIf="selectedItems?.length && selectedColumns?.length; else noSelection"
               [items]="selectedItems"
               [columns]="selectedColumns"
               (actionClicked)="handleTableAction($event)"
               [tableId]="tableId"
               (columnsChanged)="onColumnsChanged(true)"
               [prefixStorageKey]="storageKey"
               [actions]="tableActions">
    </nuc-table>

    <ng-template #noSelection>
        <nuc-empty-state-component [title]="noSelectedItemsTitle || 'You have not selected any item'"
                                   [small]="true"
                                   imageUrl="assets/images/general_empty_state.svg">
        </nuc-empty-state-component>
    </ng-template>

    <p class="section-header">Available {{selectionTitle}}</p>
    <nuc-table [useButtonBar]="true"
               [useEmptyState]="true"
               [emptyStateTitle]="noResultsTitle"
               [loading]="loading"
               [items]="items"
               [columns]="columns"
               (columnsChanged)="onColumnsChanged()"
               [selectionMode]="selectionMode"
               [selection]="selection"
               (sortOptionChange)="onSortOptionChange.emit($event)"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [disableSort]="disableSort">
        <ng-container button-bar-left>
            <ng-content select="[button-bar-left]"></ng-content>
        </ng-container>
        <ng-container button-bar-left-after>
            <ng-content select="[button-bar-left-after]"></ng-content>
        </ng-container>
        <ng-container button-bar-right>
            <ng-content select="[button-bar-right]"></ng-content>
            <paginator [viewId]="viewId"
                       [loading]="loading"
                       [disableNextPage]="disableNextPage"
                       [disablePageSizeOptions]="disablePageSizeOptions">
            </paginator>
        </ng-container>
    </nuc-table>
</div>
