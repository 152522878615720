<form [formGroup]="form">
    <div class="asset-container">
            <nuc-extra-large-card-view [title]="asset.name"
                                       [subTitle]="asset.rin"
                                       [image]="asset | assetDisplay: EAssetDisplayProperties.THUMBNAIL_URL">
            </nuc-extra-large-card-view>
    </div>

    <hr>

    <upload-asset-component [previousUploadAsset]="formGroup?.get('uploadedAsset')?.value"
                            [fileTypeCategories]="fileTypeCategories"
                            (onUploadUpdate)="onUploadUpdate($event)">
    </upload-asset-component>
</form>
