import {ApiRequest} from './api.request';
import {ERequestMethod} from '../../app.enums';

export class ApiDeleteRequest extends ApiRequest {
    public apiMethod = ERequestMethod.DELETE;

    protected get urlComponents() {
        return [
            ...super.urlComponents,
            this.itemId
        ];
    };

    constructor(public pathComponents: string[] = [],
                public itemId: string) {
        super(pathComponents, null);
    }
}
