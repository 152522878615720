import {Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import {CustomWorkflowStickyListComponent} from '../../../custom-workflow-sticky-list/custom-workflow-sticky-list.component';
import {PublicationsService} from '../../../../../../../../api/services/publications.service';
import {UserService} from '../../../../../../../../api/services/users.service';
import {UserIsAllowedToPipe} from '../../../../../../../../pipes/user-is-allowed-to.pipe';
import {FullModalService, NUC_FULL_MODAL_DATA, NucDialogService} from '@relayter/rubber-duck';
import {ICustomWorkflowStickyLogModalData} from '../../../custom-workflow-sticky-list/custom-workflow-sticky-log.component';
import {CustomWorkflowService} from '../../../custom-workflow.service';
import {UserSettingsStorageService} from '../../../../../../../../api/services/user-settings-storage.service';
import {AdvancedFiltersDataService} from '../../../../../../../../api/services/advanced-filters.data-service';
import {StickyNoteModel} from '../../../../../../../../models/api/sticky-note.model';
import {StickyNotesDataService} from '../sticky-notes-data.service';
import {RLDatePipe} from '../../../../../../../../pipes/rl-date.pipe';
import {DataFieldModel} from '../../../../../../../../models/api/data-field.model';
import {DropdownItem} from '../../../../../../../../models/ui/dropdown-item.model';
import {EPublicationType} from '../../../../../../templates/template-detail/publication-type.enum';
import {takeUntil} from 'rxjs/operators';
import {PublicationItemSelectionService} from '../../../custom-workflow-item-selection/publication-item-selection.service';
import {WorkflowFiltersUtil} from '../../../workflow-filters.util';
import {EComponentActions, EShowStickyNotesActionOptions, IShowStickyNotesActionOptions} from '../../custom-workflow-preview.component';
import {IQueryParam} from '../../../../../../../../classes/query-params';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {DataFilterModel} from '../../../../../../../../models/ui/data-filter.model';
import {EDataCollectionName, EDataFieldTypes, EStickyNoteStatus} from '../../../../../../../../app.enums';
import {CampaignItemModel} from '../../../../../../../../models/api/campaign-item.model';
import {PaginatorService} from '../../../../../../../../components/paginator/paginator.service';
import {CustomWorkflowActionModel} from '../../../../../../../../models/api/custom-workflow-action.model';
import {DataFieldsComponentUtil} from '../../../../../../../../classes/data-fields-component.util';
import {FormatFunction} from '@relayter/rubber-duck/lib/pipes/property.pipe';
import {DataFieldsApiService} from '../../../../../../../../api/services/data-fields.api.service';

@Component({
    selector: 'custom-workflow-preview-sticky-list-view',
    templateUrl: './sticky-list-view.component.html',
    styleUrls: ['./sticky-list-view.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class StickyListViewComponent extends CustomWorkflowStickyListComponent implements OnInit, OnChanges {
    public tableId = 'custom-workflow-preview-sticky-list-view';
    public readonly dateFormats = RLDatePipe.dateFormats;
    public briefingItemFields: { label: string; property: string }[] = [];
    @Input() private campaignItemDataFields: DataFieldModel[];
    @Input() private actions: CustomWorkflowActionModel[];
    public sortOptions: DropdownItem<string>[] = [];
    public sortValue: DropdownItem<any>;
    public sortAsc: boolean = true;
    public stickies: StickyNoteModel[] = [];

    public showStickyNotesActionOptions: IShowStickyNotesActionOptions;
    public dataFieldFormatter: FormatFunction;

    constructor(protected publicationService: PublicationsService,
                protected userService: UserService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) protected modalData: ICustomWorkflowStickyLogModalData,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                public customWorkflowService: CustomWorkflowService,
                userSettingsStorageService: UserSettingsStorageService,
                filtersDataService: AdvancedFiltersDataService,
                paginatorService: PaginatorService,
                private stickyNotesDataService: StickyNotesDataService,
                private publicationItemSelectionService: PublicationItemSelectionService,
                protected dataFieldService: DataFieldsApiService) {
        super(publicationService, userService, userIsAllowedToPipe, modalData, dialogService, fullModalService, customWorkflowService,
            userSettingsStorageService, filtersDataService, paginatorService, dataFieldService);
    }

    public selectStickyNote(note: StickyNoteModel): void {
        // TODO: find workflowItem of this sticky
        this.publicationItemSelectionService.navigateToPublicationItem(note.publicationItem._id);
        this.stickyNotesDataService.setSelectedStickyNote(note);
    }

    public selectPubItem(note: StickyNoteModel, event: MouseEvent): void {
        // TODO: find workflowItem of this sticky
        this.publicationItemSelectionService.navigateToPublicationItem(note.publicationItem._id);
        event.stopPropagation();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.setSortOptions();
        this.setupPaginatorService();

        this.stickyNotesDataService.refreshStickyNotes$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(() => {
            this.refreshData();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['actions']) {
            const stickyNoteActions = {
                showStickyNotes: this.actions.find((action) => action.name === EComponentActions.ShowStickyNotes)
            };
            if (stickyNoteActions.showStickyNotes && stickyNoteActions.showStickyNotes.options) {
                this.showStickyNotesActionOptions = {
                    filter: stickyNoteActions.showStickyNotes.options.find((option) => option.name === EShowStickyNotesActionOptions.FILTER),
                    display: stickyNoteActions.showStickyNotes.options.find((option) => option.name === EShowStickyNotesActionOptions.DISPLAY)
                } as IShowStickyNotesActionOptions;
            }
            this.setCampaignItemData();
            this.setupStickyNoteSubscription();
            this.dataFilters = this.getDataFilters();
        }
    }

    protected getFilterValues(): IQueryParam[] {
        const componentFilters = this.showStickyNotesActionOptions?.filter ?
            WorkflowFiltersUtil.fromActionOptionFilter(this.showStickyNotesActionOptions.filter.value) : [];
        const filters = [];
        if (this.filterValues && this.filterValues.length > 0) {
            this.filterValues.forEach((filterValue) => {
                const foundFilter = componentFilters.find(item => item.queryParam === filterValue.queryParam);
                if (foundFilter) {
                    // check if the selected values are available in the workflow configured filters.
                    if (Array.isArray(filterValue.value) && filterValue.value.every(value => foundFilter.value.includes(value))) {
                        filters.push(filterValue);
                    } else if (foundFilter.value.includes(filterValue.value as string)) {
                        filters.push(filterValue);
                    } else {
                        Toaster.error('Filters used are not allowed by the workflow config');
                    }
                } else {
                    filters.push(filterValue);
                }
            });
            // Add component filter if it is not overwritten by the user configured filters
            this.addComponentFilters(componentFilters, filters);
            return filters;
        } else {
            return componentFilters;
        }
    }

    private addComponentFilters(componentFilters: IQueryParam[], filters: IQueryParam[]): void {
        componentFilters.forEach((componentFilter) => {
            if (!filters.find(filter => filter.queryParam === componentFilter.queryParam)) {
                filters.push(componentFilter);
            }
        });
    }

    private setupStickyNoteSubscription(): void {

        this.stickyNotes$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((results: StickyNoteModel[]) => {
            if (this.pageIndex === 1) {
                this.stickies = results;
            } else {
                this.stickies.push(...results);
            }
        });

        this.stickyNotesDataService.updatedLinkedCampaignItem$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((updatedCampaignItem: CampaignItemModel) => {
            if (this.stickies.length > 0) {
                this.stickies.forEach((sticky) => {
                    if (sticky.campaignItem && sticky.campaignItem._id === updatedCampaignItem._id) {
                        sticky.campaignItem = updatedCampaignItem;
                    }
                });
            }
        });
    }

    private setSortOptions(): void {
        this.sortValue = this.publication.channel.name === EPublicationType.PRINT_MAGAZINE
            ? new DropdownItem('Page number', 'firstPageNumber')
            : new DropdownItem('Filename', 'publicationItemId');
        this.sortOptions = [this.sortValue,
            new DropdownItem<string>('User', 'createdBy.fullName'),
            new DropdownItem<string>('Message', 'message'),
            new DropdownItem<string>('Date created', 'createdAt'),
            new DropdownItem<string>('Status', 'status')];
    }

    private setCampaignItemData(): void {
        const campaignItemDisplayOption = this.showStickyNotesActionOptions?.display;

        if (campaignItemDisplayOption && this.campaignItemDataFields.length > 0) {
            for (const displayProperty of campaignItemDisplayOption.value) {
                const label = this.campaignItemDataFields.find(item => item.fieldName === displayProperty);
                if (label) {
                    this.briefingItemFields.push({
                        label: label.name,
                        property: displayProperty
                    });
                }
            }
        }
    }

    public setSortValue(sortOption: DropdownItem<string>): void {
        this.sortValue = sortOption;
        this.onSortValueChanged();
    }

    public onSortValueChanged(): void {
        this.sortOption = {sortProperty: this.sortValue.getValue(), sortDuplicates: true};
        this.sortOrder = this.sortAsc ? 'asc' : 'desc';
        this.refreshData();
    }

    public loadMore(): void {
        this.pageIndex++;
        this.refresh$.next();
    }

    protected getDataFilters(): DataFilterModel[] {
        const componentFilter = this.showStickyNotesActionOptions?.filter ?
            WorkflowFiltersUtil.fromActionOptionFilter(this.showStickyNotesActionOptions.filter.value) : null;
        const statusesFilter = componentFilter?.find(filter => filter.queryParam === 'statuses');
        const filters = [];
        if (statusesFilter) {
            filters.push(new DataFilterModel(
                'Status',
                'statuses',
                EDataFieldTypes.ENUM,
                Object.keys(EStickyNoteStatus).filter(key =>
                    componentFilter[0].value.includes(key)).map(key => new DropdownItem(key, key))
            ));
        }
        filters.push(new DataFilterModel(
            'User name',
            'createdBy.fullName',
            EDataFieldTypes.LIST,
            null,
            null,
            {publication: this.publication._id, ['publicationItem.step']: this.step?._id},
            EDataCollectionName.STICKY_NOTE
        ));
        return filters;
    }

    private setupPaginatorService() {
        this.paginatorService.getStoredPageSize(this.viewId).subscribe((pageSize: number) => {
            this.pageSize = pageSize;
        });
    }

    public refreshData(): void {
        super.refreshData();
        this.dataFieldFormatter = DataFieldsComponentUtil.getDataFieldFormatter(null, this.activeVariant?.key);
    }
}
