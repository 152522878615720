import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../app.constants';
import {SortDirection} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {EWorkflowAutomationJobType, WorkflowAutomationsService} from '../../../../api/services/workflow-automations.service';
import {WorkflowAutomationModel, WorkflowAutomationRuleModel} from '../../../../models/api/workflow-automation.model';
import {Subject, Subscription} from 'rxjs';
import {CursorArray} from '../../../../api/api-cursor';
import {ErrorConstants} from '../../../../api/error.constants';
import {
    IWorkflowAutomationRuleFormData,
    WorkflowAutomationRuleFormComponent
} from '../../../../forms/workflow-automation-rule-form/workflow-automation-rule-form.component';
import {ApiConstants} from '../../../../api/api.constant';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {takeUntil} from 'rxjs/operators';
import {PaginatorService} from '../../../../components/paginator/paginator.service';

@Component({
    selector: 'workflow-automation-details',
    templateUrl: './workflow-automation-details.component.html',
    styleUrls: ['./workflow-automation-details.component.scss'],
    providers: [PaginatorService]
})
export class WorkflowAutomationDetailsComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'workflow-automation-details-table';

    public workflowAutomationId: string;
    public workflowAutomation: WorkflowAutomationModel;

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        type: EColumnType.DEFAULT,
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Template',
        key: 'template.name',
        sortProperty: 'template.name',
        sortDuplicates: true,
        size: EColumnSize.BASE
    }];

    public pageIndex: number;
    public pageSize: number;
    public hasNext: boolean;
    public sortColumn: ITableColumn;
    public sortOrder: SortDirection;
    public items: WorkflowAutomationRuleModel[];
    public actions: ITableAction[];
    private apiCursor: CursorArray;
    public serviceSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private router: Router,
                private route: ActivatedRoute,
                private dialogService: NucDialogService,
                private workflowAutomationsService: WorkflowAutomationsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private monitoredJobsService: MonitoredJobsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();
        this.getWorkflowAutomation();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();

                this.getWorkflowAutomationRules();
            }));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_WORKFLOW_AUTOMATION_RULE)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.POST_WORKFLOW_AUTOMATION_JOB,
            AppConstants.PERMISSIONS.COPY_WORKFLOW_AUTOMATION_RULE])) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.COPY) : [AppConstants.TABLE_ACTION_TYPES.COPY];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_WORKFLOW_AUTOMATION_RULE)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.workflowAutomationId = params['workflowAutomationId'];
        const sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.sortOrder = params['sortOrder'] && ([ApiConstants.API_QUERY_VALUE_ASC, ApiConstants.API_QUERY_VALUE_DESC]
            .includes(params['sortOrder'])) ? params['sortOrder'] : '';
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;

        this.sortColumn = this.columns.find(column => column.sortProperty === sortProperty);
        // Init the cursors array with the current page index
        this.apiCursor = new CursorArray(pageIndex, this.sortColumn?.sortDuplicates);
    }

    private setPageIndex(pageIndex = 1): void {
        this.apiCursor.reset(pageIndex, this.sortColumn?.sortDuplicates);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getWorkflowAutomation(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_AUTOMATION_DETAILS)) {
            return;
        }

        this.workflowAutomationsService.findOne(this.workflowAutomationId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (workflowAutomation) => {
                    this.workflowAutomation = workflowAutomation;
                    this.setPageIndex();
                },
                error: (error) => {
                    if (error.code === ErrorConstants.API_ERROR_CODES.NOT_FOUND_WORKFLOW_AUTOMATION) {
                        this.router.navigate([AppConstants.WORKFLOW_AUTOMATION_PATH]);
                    }
                    Toaster.handleApiError(error);
                }
            });
    }

    public getWorkflowAutomationRules(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_AUTOMATION_RULES)) {
            return;
        }

        this.serviceSubscription?.unsubscribe();

        // If we don't have a cursor value (if we reload the page for example), fall back to offset
        const cursor = this.apiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.serviceSubscription = this.workflowAutomationsService.getWorkflowAutomationRules(
            this.workflowAutomation._id,
            this.pageSize,
            offset,
            cursor,
            this.sortColumn?.sortProperty,
            this.sortOrder
        ).pipe(takeUntil(this.onDestroySubject)).subscribe(
            (results: ARPagedResponseDataModel<WorkflowAutomationRuleModel>) => {
                [this.hasNext, this.items] = [results.hasNext, results.items];

                if (this.items.length > 0) {
                    const item = this.items[this.items.length - 1];
                    this.apiCursor.setCursor(this.pageIndex, this.sortColumn?.sortProperty, item);
                }
            },
            (err: ARApiError) => Toaster.handleApiError(err));
    }

    public updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.WORKFLOWS,
            AppConstants.CONTEXT_URL.WORKFLOW_AUTOMATIONS,
            this.workflowAutomationId,
            this.createMatrixUrl()]);
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const clickedItem = event.item as WorkflowAutomationRuleModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.serviceSubscription?.unsubscribe();

            this.workflowAutomationsService.getAutomationRule(this.workflowAutomationId, clickedItem._id)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe((result) => {
                    this.openRuleModal(result);
                }, Toaster.handleApiError);
        }

        if (event.action === AppConstants.TABLE_ACTION_TYPES.COPY) {
            this.openCopyDialog(clickedItem);
        }

        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteDialog(clickedItem);
        }
    }

    /**
     * Create a MatrixUrlParams so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortColumn?.sortProperty, this.sortOrder);
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortColumn = sortEvent.column;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortColumn = null;
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public onAddClicked(): void {
        this.openRuleModal();
    }

    public openRuleModal(rule?: WorkflowAutomationRuleModel): void {
        const modalConfig = rule
            ? new FullModalConfig('Edit automation rule',
                'Edit the information of the workflow automation rule.',
                {workflowAutomation: this.workflowAutomation, rule} as IWorkflowAutomationRuleFormData)
            : new FullModalConfig('Add automation rule',
                'Enter the information to create a new workflow automation rule.',
                {workflowAutomation: this.workflowAutomation} as IWorkflowAutomationRuleFormData);
        modalConfig.confirmClose = true;
        this.fullModalService.open(WorkflowAutomationRuleFormComponent, modalConfig).afterClosed().subscribe((result) => {
            if (result) {
                this.setPageIndex();
            }
        });
    }

    private openCopyDialog(rule: WorkflowAutomationRuleModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Duplicate ${rule.name}`,
            'You are about to duplicate an automation rule. Are you sure?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('No', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyWorkflowAutomationRule(rule._id);
        });
    }

    private copyWorkflowAutomationRule(ruleId: string): void {
        const jobData = {
            workflowAutomationId: this.workflowAutomationId,
            ruleId
        };

        this.serviceSubscription = this.workflowAutomationsService.postJob(EWorkflowAutomationJobType.WORKFLOW_AUTOMATION_RULE_COPY_JOB, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (job: JobModel) => this.monitoredJobsService.getJobMonitor(job._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.getWorkflowAutomationRules();
                        }
                    }),
                error: Toaster.handleApiError
            });
    }

    private openDeleteDialog(rule: WorkflowAutomationRuleModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete automation rule',
            `Please confirm that you wish to delete ${rule.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteWorkflowAutomationRule(rule._id);
        });
    }

    private deleteWorkflowAutomationRule(ruleId: string): void {
        this.serviceSubscription = this.workflowAutomationsService.deleteWorkflowAutomationRule(this.workflowAutomation._id, ruleId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                () => {
                    Toaster.success('Workflow automation rule removed successfully');
                    this.getWorkflowAutomationRules();
                },
                (error) => Toaster.handleApiError(error));
    }

}
