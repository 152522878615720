import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {StickyNoteModel} from '../../../../../../../models/api/sticky-note.model';
import {PointModel} from '../../../../../../../models/api/point.model';
import {AppConstants} from '../../../../../../../app.constants';
import {CustomWorkflowPreviewDataService} from '../custom-workflow-preview-data.service';
import {CustomWorkflowActionModel} from '../../../../../../../models/api/custom-workflow-action.model';
import {combineLatest, ReplaySubject, Subject} from 'rxjs';
import {CustomWorkflowComponentModel} from '../../../../../../../models/api/custom-workflow-component.model';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {DropdownItem} from '../../../../../../../models/ui/dropdown-item.model';
import {MatLegacySliderChange as MatSliderChange} from '@angular/material/legacy-slider';
import {
    FilesRevisionModel,
    PublicationItemModel
} from '../../../../../../../models/api/publication-item.model';
import {StickyNotesDataService} from '../custom-workflow-sticky-notes-sidebar-container/sticky-notes-data.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {EItemSelectionType} from '../../custom-workflow-item-selection/custom-workflow-item-selection.component';
import {FormControl, FormGroup} from '@angular/forms';
import {
    CustomWorkflowBriefingChangesDataService,
    IBriefingChange
} from '../custom-workflow-briefing-changes/custom-workflow-briefing-changes-data.service';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {SizeModel} from '../../../../../../../models/api/size.model';
import {StickyNoteUtil} from '../../sticky-note.util';
import {TemplateModel} from '../../../../../../../models/api/template.model';
import {EStickyNoteStatus} from '../../../../../../../app.enums';

export enum EPreviewOverlay {
    NONE = 'NONE',
    NOTES = 'NOTES',
    NOTES_BACKGROUND = 'NOTES_BACKGROUND'
}

@Component({
    selector: 'rl-custom-workflow-sticky-notes-view',
    templateUrl: 'custom-workflow-sticky-notes-view.component.html',
    styleUrls: ['custom-workflow-sticky-notes-view.component.scss'],
    animations: [
        trigger('newStickyNote', [
            transition(':enter', [
                style({transform: 'scale(0,0)'}),
                animate('0.25s cubic-bezier(.41,.05,.48,1.46)', style({transform: 'scale(1,1)'})),
            ])
        ]),
    ]
})
export class CustomWorkflowStickyNotesViewComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('imagesContainer') public imagesContainer: ElementRef;
    @ViewChild('container', {static: true}) public container: ElementRef;
    @ViewChildren('image') public images: QueryList<ElementRef>;
    @ViewChild(CdkDrag, {static: true}) public drag: CdkDrag;

    @Input() public previewLoading = false;
    @Input() public itemSelectionType: EItemSelectionType;
    @Input() public horizontal: boolean = false;

    public loadingImage = false;
    public actions: CustomWorkflowActionModel[] = [];
    public publicationItems: PublicationItemModel[];

    public activeVariant: VariantModel = null;

    public component: CustomWorkflowComponentModel;
    public stickyNotes: StickyNoteModel[] = [];
    public publicationId: string;
    private publicationType: string;
    public newStickyNote: StickyNoteModel;
    public selectedStickyNote: StickyNoteModel;
    public selectedBriefingChange: IBriefingChange;

    public filesVersionControl = new FormControl();
    public formGroup = new FormGroup({filesVersion: this.filesVersionControl});
    public selectedFilesVersion: FilesRevisionModel;
    public filesVersions: FilesRevisionModel[];

    public publicationItems$ = new ReplaySubject<PublicationItemModel[]>(1);

    private readonly MIN_ZOOM_MARGE = 0.05;
    private readonly MIN_EDGE_MARGIN = 55;

    private onDestroySubject = new Subject<void>();

    public previewOverlays: DropdownItem<string>[] = [
        new DropdownItem('Show notes', EPreviewOverlay.NOTES),
        new DropdownItem('Highlight notes', EPreviewOverlay.NOTES_BACKGROUND),
        new DropdownItem('Hide notes', EPreviewOverlay.NONE)];
    public overlay: string = EPreviewOverlay.NOTES;
    public previewOverlayEnum = EPreviewOverlay;

    public zoomLevel = 1;
    public dragging: boolean = false;
    public disableZoomTransition: boolean = false;
    private containerSizeObservable: ResizeObserver = new ResizeObserver(() => {
        this.containerResized();
    });

    public minZoomLevel = new Subject<number>();
    public placeHolderImage = '';

    constructor(private previewDataService: CustomWorkflowPreviewDataService,
                private stickyNotesDataService: StickyNotesDataService,
                private customWorkflowService: CustomWorkflowService,
                private customWorkflowBriefingChangesDataService: CustomWorkflowBriefingChangesDataService) {
    }

    public ngOnInit(): void {
        combineLatest([
            this.previewDataService.actions$,
            this.customWorkflowService.publication$,
            this.customWorkflowService.activeComponent$
        ]).pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(([actions, publication, component]) => {
            this.actions = actions;
            this.publicationId = publication._id;
            this.component = component;
            this.publicationType = publication.channel.name;
        });

        this.previewDataService.stickyNotes$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(stickyNotes => this.stickyNotes = stickyNotes);

        this.filesVersionControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((value) => {
                this.selectedFilesVersion = value;
                this.previewDataService.setSelectedFilesRevision(value);

                this.publicationItems$.next(this.publicationItems.map((pubItem) => {
                    const itemFiles = this.selectedFilesVersion.getValue();

                    pubItem.previewImage = itemFiles?.preview;
                    pubItem.sourceFile = itemFiles?.source;

                    return pubItem;
                }));
            });

        this.previewDataService.publicationItems$
            .pipe(map((publicationItems) => {
                publicationItems.forEach((item: PublicationItemModel) => {
                    item.previewImage = item.getVariantFiles(this.activeVariant?._id)?.preview;
                });
                this.publicationItems$.next(publicationItems);
                return publicationItems;
            }),takeUntil(this.onDestroySubject))
            .subscribe((publicationItems) => {
                this.loadingImage = true;
                this.newStickyNote = null;
                this.publicationItems = publicationItems;
                this.setupFilesRevisionsForm();
                this.createImagePlaceHolder();
            });

        this.customWorkflowService.activeVariant$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((variant: VariantModel) => {
                this.activeVariant = variant;
                this.setupFilesRevisionsForm();
                this.publicationItems.forEach((item) => {
                    item.previewImage = item.getVariantFiles(this.activeVariant?._id)?.preview;
                });
                this.createImagePlaceHolder();
            });

        // watch size changes of canvas
        this.containerSizeObservable.observe(this.container.nativeElement);

        // we should listen to what is selected, that's right
        this.stickyNotesDataService.selectedStickyNote$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((stickyNote: StickyNoteModel) => {
            if (stickyNote?.status !== EStickyNoteStatus.NEW) this.newStickyNote = null;
            this.selectedStickyNote = stickyNote;
        });

        this.customWorkflowBriefingChangesDataService.selectedBriefingChange$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((briefingChange: IBriefingChange) => {
            this.selectedBriefingChange = briefingChange;
        });
    }

    private setupFilesRevisionsForm(): void {
        if (this.itemSelectionType === EItemSelectionType.ITEM_SELECTION && this.publicationItems?.length === 1) {
            const publicationItem = this.publicationItems[0];
            const currentVersion = new FilesRevisionModel();
            currentVersion.getTitle = () => 'Current version';
            currentVersion.date = publicationItem.updatedAt;
            currentVersion.files = publicationItem.getVariantFiles(this.activeVariant?._id);
            currentVersion.variant = this.activeVariant?._id;

            // concat the current version
            const itemFilesVersions = publicationItem.getVariantFilesRevisions(this.activeVariant?._id);
            this.filesVersions = itemFilesVersions.length > 1
                ? [currentVersion].concat([...itemFilesVersions].reverse())
                : [currentVersion];
            this.filesVersionControl.patchValue(currentVersion); // always select the current one first
        } else {
            this.filesVersions = null;
            this.selectedFilesVersion = null;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.itemSelectionType && !changes.itemSelectionType.firstChange) {
            this.setupFilesRevisionsForm();
        }
    }

    public ngOnDestroy(): void {
        this.containerSizeObservable.disconnect();
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Responds too clicks on the asset
     * If allowed, created a new sticky not on the location
     * Converts the click's coordinates to a percentage coordinate system
     * @param {MouseEvent} event
     * @param {object} publicationItem
     * @param {number} index
     */
    public onImageClicked(event: MouseEvent, publicationItem: PublicationItemModel, index: number): void {
        if (!this.dragging) {
            this.newStickyNote = null;
            const clickedImage = this.images.get(index);
            if (this.canCreateStickyNote()) {
                const percentageCoordinate = new PointModel(
                    (event.offsetX / clickedImage.nativeElement.clientWidth) * 100,
                    (event.offsetY / clickedImage.nativeElement.clientHeight) * 100);
                const campaignItem = StickyNoteUtil.findCampaignItemForPosition(percentageCoordinate, publicationItem);
                this.newStickyNote = new StickyNoteModel(percentageCoordinate, '', campaignItem, EStickyNoteStatus.NEW,
                    publicationItem, this.activeVariant);
                this.stickyNotesDataService.setSelectedStickyNote(this.newStickyNote);
            }
        }
    }

    /**
     * Check if currently logged-in user has permissions to create sticky notes
     * @returns {boolean}
     */
    public canCreateStickyNote(): boolean {
        return this.actions.some((action) => action.name === AppConstants.CUSTOM_WORKFLOW_PREVIEW_COMPONENT_ACTIONS.CREATE_STICKY_NOTE);
    }

    /**
     * Zoom in with increments of 25% till the max zoom of 3x is reached.
     */
    public zoomIn(): void {
        this.zoomLevel = Math.min(this.zoomLevel + .25, 3);
        this.zoom();
    }

    /**
     * Zoom out with increments of 25% till zoom level of 95% is reached.
     */
    public zoomOut(): void {
        this.zoomLevel = Math.max(this.zoomLevel - .25, this.getMinimumZoomLevel());
        this.zoom();
    }

    /**
     * Will return zoom so the image is either 100% for small images or less for larger images, so it fits the container
     * @return {number}
     */
    public getMinimumZoomLevel() {
        const naturalSize = this.getNaturalSize();
        const zoom = Math.min(this.container.nativeElement.clientWidth / naturalSize.width,
            this.container.nativeElement.clientHeight / naturalSize.height);


        let edgeCaseMargin = 0;
        // if the image is smaller than the container but too large to fit within the margins, add some extra margin.
        if (this.container.nativeElement.clientWidth - naturalSize.width < this.MIN_EDGE_MARGIN ||
            this.container.nativeElement.clientHeight / naturalSize.height < this.MIN_EDGE_MARGIN) {
            edgeCaseMargin = this.MIN_ZOOM_MARGE;
        }

        // smaller images will be set to 100%
        return zoom > 1 ? 1.0 - edgeCaseMargin : zoom - this.MIN_ZOOM_MARGE;
    }

    private containerResized(): void {
        this.fitToContainer();
    }

    public fitToContainer(): void {
        this.zoomLevel = this.getMinimumZoomLevel();
        this.minZoomLevel.next(this.zoomLevel);
        this.zoom();
        this.drag.reset();
    }

    /**
     * @private
     * Apply height based on the zoom level
     */
    private zoom(): void {
        if (this.horizontal) {
            const width = this.zoomLevel * this.getNaturalSize().width;
            this.imagesContainer.nativeElement.style.setProperty('width', `${width}px`);
            this.imagesContainer.nativeElement.style.setProperty('height', 'auto');
        } else {
            const height = this.zoomLevel * this.getNaturalSize().height;
            this.imagesContainer.nativeElement.style.setProperty('width', 'auto');
            this.imagesContainer.nativeElement.style.setProperty('height', `${height}px`);
        }
    }

    public getZoomValue(): string {
        return Math.round(this.zoomLevel * 100) + '%';
    }

    /**
     * Start registering panning of the preview, during this action block creation of new notes
     */
    public startDragging(): void {
        this.dragging = true;
    }

    /**
     * When panning ends wait 100ms before allowing to create notes to prevent creation of note when you release your mouse.
     */
    public stopDragging(): void {
        setTimeout(() => {
            this.dragging = false;
        }, 100);
    }

    /**
     * Set the overlay on selection of dropdown item
     * @param {string} overlay
     */
    public setOverlay(overlay: string) {
        this.overlay = overlay;
    }

    /**
     * Update the zoom level realtime if the slider is moved
     * @param {MatSliderChange} sliderChange
     */
    public valueChanged(sliderChange: MatSliderChange): void {
        if (!this.disableZoomTransition) {
            this.disableZoomTransition = true;
        }
        this.zoomLevel = sliderChange.value / 100;
        this.zoom();
    }

    /**
     * Enable transition again after 200ms so the transition can finish when the slider is released
     */
    public sliderValueChanged(): void {
        setTimeout(() => {
            this.disableZoomTransition = false;
        }, 200);
    }

    public onStickyNoteClicked(stickyNote: StickyNoteModel): void {
        this.stickyNotesDataService.setSelectedStickyNote(stickyNote);
    }

    public onBriefingChangeClicked(publicationItemId: string, campaignItemId: string): void {
        this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange({publicationItemId, campaignItemId} as IBriefingChange);
    }

    private getNaturalSize(): { width: number; height: number } {
        if (!this.images) return {width: 0, height: 0};
        const width = this.images.reduce((elementWidth, element) => elementWidth + element.nativeElement.naturalWidth, 0);
        const height = Math.max(...this.images.map(element => element.nativeElement.naturalHeight));

        return {width, height};
    }

    private createImagePlaceHolder(): void {
        this.placeHolderImage = '';
        if (this.publicationItems?.length > 0 && !this.publicationItems.every(item =>
            item.files.find(files => files.variant === this.activeVariant?._id))) {
            const publicationItem = this.publicationItems[0];
            this.placeHolderImage = this.createCustomImage(publicationItem.template?.pageSize || TemplateModel.defaultPageSize(this.publicationType));
        }
    }

    public createCustomImage(itemSize: SizeModel): string {
        // create an off-screen canvas (default size from A4)
        const width = itemSize.width || 1414;
        const height = itemSize.height || 2000;
        const factor = Math.min(2000 / width, 2000 / height);

        const canvas = document.createElement('canvas');

        // set its dimension to target size (boxed into 2000 x 2000)
        canvas.width = width * factor;
        canvas.height = height * factor;

        // encode image to data-uri with base64 version of compressed image
        const encodedData = canvas.toDataURL();
        canvas.remove();

        return encodedData;
    }

    public getPlaceHolderImage(): string {
        return this.placeHolderImage;
    }
}
