<ng-container *ngIf="items.length > 0 && !isLoading; else loadingState">
    <div class="item" *ngFor="let item of items">
        <nuc-medium-card-view [hoverImage]="false"
                              [hoverCard]="true"
                              [isSelected]="checkIfItemIsSelected(item)"
                              [image]="item.getThumbnailAsset() | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL"
                              (dblclick)="onCardDoubleClicked(item._id)"
                              (click)="onCardClicked(item._id)">
            <div content>
                <div class="field" *ngFor="let propertySetting of properties">
                    <p class="body-strong text-ellipsis">{{propertySetting.title}}</p>
                    <p class="text-ellipsis">{{item | propertySettingDisplay: propertySetting}}</p>
                </div>
            </div>
        </nuc-medium-card-view>
    </div>
</ng-container>

<ng-template #loadingState>
    <rl-loading-indicator *ngIf="isLoading"></rl-loading-indicator>
    <nuc-empty-state-component *ngIf="!isLoading"
                               imageUrl="assets/images/general_empty_state.svg"
                               [small]="true"
                               [title]="isSearching ? 'No items matches your search criteria.' : 'This section is empty.'">
    </nuc-empty-state-component>
</ng-template>
