import {autoserialize, autoserializeAs} from 'cerialize';
import {UserModel} from '../../../../models/api/user.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {InDesignLibraryItemModel} from './indesign-library-item.model';

export class IndesignLibraryFiles {
    @autoserialize public readonly library: string;
}

export class IndesignLibraryModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(IndesignLibraryFiles) public readonly files: IndesignLibraryFiles;
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserializeAs(InDesignLibraryItemModel) public readonly items: InDesignLibraryItemModel[];

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class IndesignLibraryPatchModel {
    public name: string;
}
