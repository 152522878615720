import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {FormBuilderComponent, IFormBuilderModalData} from '../form-builder/form-builder.component';
import {EFormContext, FormDetailModel} from '../../../../models/api/form.model';
import {FormService} from '../../../../api/services/form.service';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {AppConstants} from '../../../../app.constants';
import {ActivatedRoute} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {FormPreviewComponent} from './form-preview/form-preview.component';
import {VariantService} from '../../../../api/services/variant.service';
import {VariantModel} from '../../../../models/api/variant.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';

@Component({
    selector: 'rl-form-overview',
    templateUrl: 'form-overview.component.html',
    styleUrls: ['form-overview.component.scss']
})

export class FormOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('formPreview') public formPreviewComponent: TemplateRef<FormPreviewComponent>;

    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    private context: EFormContext;

    public form: FormDetailModel;
    public formSubscription: Subscription;

    public variants: VariantModel[];
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private formService: FormService,
                private variantService: VariantService,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {
        this.context = this.route.snapshot.data['context'];
        this.getForm();
        this.getVariants();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private getForm(): void {
        this.formSubscription = this.formService.getForms(this.context)
            .pipe(
                map((result) => result.items.find((item) => item.context === this.context)),
                filter((result) => !!result),
                switchMap((result) => this.formService.getForm(result._id))
            )
            .subscribe(
                (result) => this.form = result,
                (error) => Toaster.handleApiError(error));
    }

    private getVariants(): void {
        if (!this.userIsAllowedToPipe.transform(this.PERMISSIONS.GET_VARIANTS)) return;

        this.variantService.getVariants()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => this.variants = result.items,
                error: (error) => Toaster.handleApiError(error)
            });
    }

    public onEditFormClick(): void {
        const modalData = {context: this.context, formId: this.form?._id} as IFormBuilderModalData;

        let modalTitle;
        let modalDescription;
        switch (this.context) {
            case EFormContext.PRODUCT:
                modalTitle = 'Edit product form';
                modalDescription = 'Customize your product form.';
                break;
            case EFormContext.BRIEFING:
                modalTitle = 'Edit briefing form';
                modalDescription = 'Customize your briefing form.';
                break;
            case EFormContext.ASSET:
                modalTitle = 'Edit asset form';
                modalDescription = 'Customize your asset form.';
                break;
            default:
                modalTitle = `Unknown context for form-builder: ${this.context}`;
                modalDescription = 'Not implemented';
                break;
        }

        const config = new FullModalConfig(modalTitle, modalDescription, modalData);
        config.confirmClose = true;

        this.fullModalService.open(FormBuilderComponent, config)
            .afterClosed()
            .pipe(
                filter((result) => !!result)
            )
            .subscribe(() => this.getForm());
    }

    public openFormPreviewModal(): void {
        const config = new FullModalConfig(
            `Preview ${this.form.context} form`,
            'This form is only viewable. Filling out this form has no use.');

        const closeAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Close'));
        closeAction.observable.subscribe(() => this.fullModalService.close());
        config.addAction(closeAction);

        this.fullModalService.open(this.formPreviewComponent, config);
    }
}
