import {Component, EventEmitter, Inject, Input, Optional, Output} from '@angular/core';
import {WorkflowConfigurationModel} from '../../../../../../../models/api/workflow-configuration.model';
import {CustomWorkflowComponentModel} from '../../../../../../../models/api/custom-workflow-component.model';
import {EPublicationJobType, IArrangePagesJobData, PublicationsService} from '../../../../../../../api/services/publications.service';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {PrintPublicationItemModel} from '../../../../../../../models/api/custom-workflow/print-publication-item.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {AppConstants} from '../../../../../../../app.constants';
import {CustomWorkflowActionModel} from '../../../../../../../models/api/custom-workflow-action.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UserIsAllowedToPipe} from '../../../../../../../pipes/user-is-allowed-to.pipe';
import {EJobStatus, JobModel} from '../../../../../../../models/api/job.model';
import {finalize, takeUntil} from 'rxjs/operators';
import {FullModalService, NUC_FULL_MODAL_DATA, NucDialogService, NucPopOutContentService} from '@relayter/rubber-duck';
import {EPublicationType} from '../../../../../templates/template-detail/publication-type.enum';
import {EPublicationDisplayProperties} from '../../../../../../../pipes/publication-item-display.pipe';
import {Cursor} from '../../../../../../../api/api-cursor';
import {MonitoredJobsService} from '../../../../../../../api/services/monitored-jobs.service';
import {CustomWorkflowBaseComponent} from '../../custom-workflow-base.component';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {IWorkflowModalData} from '../../../../../../../models/interfaces/workflow-modal-data.interface';
import {PublicationItemSelectionService} from '../../custom-workflow-item-selection/publication-item-selection.service';
import {MonitoredTransitionsService} from '../../../../../../../api/services/monitored-transitions.service';
import {EWorkflowConfigurationActionType} from '../../../../../../../app.enums';

enum EMoveType {
    InsertBefore = 'Insert before',
    InsertAfter = 'Insert after',
    Swap = 'Swap'
}

enum EComponentActions {
    ArrangePages = 'ARRANGE_PAGES'
}

@Component({
    selector: 'rl-custom-workflow-overview-items-component',
    templateUrl: './custom-workflow-overview-items.component.html',
    styleUrls: ['./custom-workflow-overview-items.component.scss']
})
export class CustomWorkflowOverviewItemsComponent extends CustomWorkflowBaseComponent {
    public publicationItems: PublicationItemModel[] = [];

    public pageSize = AppConstants.PAGE_SIZE_MAX;
    public readonly MIN_CARD_SIZE: number = 120;
    public readonly DEFAULT_CARD_SIZE: number = 180;
    public readonly MAX_CARD_SIZE: number = 426;
    public readonly ZOOM_STEP: number = 31;
    public hasNext = false;
    public total = 0;
    public isLoading = false;
    private cursor: Cursor;
    public sortProperty = '_id';

    public publicationItemSubscription: Subscription;
    public arrangePagesJobSubscription: Subscription;

    public swapping: boolean;
    public itemControl = new UntypedFormControl();
    public whereControl = new UntypedFormControl();
    public moveTypeControl = new UntypedFormControl(EMoveType.InsertBefore);
    public formGroup = new UntypedFormGroup({
        item: this.itemControl,
        where: this.whereControl,
        moveType: this.moveTypeControl
    });

    public moveTypes: string[] = Object.values(EMoveType);
    public arrangePagesAction: CustomWorkflowActionModel;

    public EPublicationDisplayProperties = EPublicationDisplayProperties;
    public EPublicationType = EPublicationType;
    public originalPageNumbers: Map<string, number> = new Map();
    public itemTransitionActions: CustomWorkflowActionModel[];

    // mat-slider related properties
    private _sliderValue: number;
    @Output() sliderValueChange = new EventEmitter<number>();
    protected readonly Math = Math;

    @Input()
    public set sliderValue(value: number) {
        this._sliderValue = value;
        this.sliderValueChange.emit(value);
    }

    public get sliderValue() {
        return this._sliderValue;
    }

    constructor(protected userIsAllowedToPipe: UserIsAllowedToPipe,
                protected customWorkflowService: CustomWorkflowService,
                protected publicationsService: PublicationsService,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                private publicationItemSelectionService: PublicationItemSelectionService,
                private monitoredJobsService: MonitoredJobsService,
                protected monitoredTransitionsService: MonitoredTransitionsService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowModalData,
                protected popOutService: NucPopOutContentService) {

        super(userIsAllowedToPipe, customWorkflowService, publicationsService, dialogService, fullModalService,
            monitoredTransitionsService, popOutService, modalData);
    }

    public setupData(): void {
        if (this.publication.channelIsPrintPublication()) {
            // The sort property is set to firstPageNumber for PRINT by the API, so keep it in sync
            this.sortProperty = 'firstPageNumber';
        }

        this.sliderValue = this.DEFAULT_CARD_SIZE; // start with the medium card size.

        this.arrangePagesAction = this.findWorkflowActionByName(EComponentActions.ArrangePages);
        this.refreshData();
        // Reset after init
        this.publicationItemSelectionService.itemsChanged = false;

    }

    public refreshData(): void {
        this.publicationItemSelectionService.itemsChanged = true;
        this.cursor = null;
        this.publicationItems = [];
        this.getPublicationItems();
    }

    /**
     * Get (next) publication items from API
     */
    public getPublicationItems(): void {
        if (this.publicationItemSubscription) {
            this.publicationItemSubscription.unsubscribe();
        }

        this.isLoading = true;
        this.publicationItemSubscription = this.publicationsService.getItemsForPublication(this.publication.getItemModel(),
            this.publication._id,
            this.step?._id,
            this.activeFilters,
            this.pageSize,
            0,
            this.cursor,
            this.sortProperty,
            'asc',
            this.publication.getItemModel() === PrintPublicationItemModel && !this.cursor)
            .pipe(finalize(() => this.isLoading = false), takeUntil(this.onDestroySubject))
            .subscribe(
                (res: ARPagedResponseDataModel<PublicationItemModel>) => {
                    // We get the total number of items from the first call for PRINT. We need the total for the swap dropdowns
                    this.total = res.total ? res.total : this.total;

                    this.hasNext = !!res.hasNext || (!!res.total && res.total > this.pageSize);
                    this.publicationItems = this.publicationItems.concat(res.items);

                    if (this.publication.channelIsPrintPublication()) {
                        this.originalPageNumbers = new Map([...this.originalPageNumbers, ...new Map(res.items
                            .filter(item => !this.originalPageNumbers.has(item._id))
                            .map((item: PublicationItemModel) => {
                                return [item._id, item.firstPageNumber];
                            }))]);
                        this.publicationItems.forEach((item: PrintPublicationItemModel) => {
                            item.originalPageNumber = this.originalPageNumbers.get(item._id);
                        });
                    }

                    if (this.hasNext) {
                        this.cursor = new Cursor(this.publicationItems[this.publicationItems.length - 1][this.sortProperty]);
                    }

                    this.itemTransitionActions = {
                        ...this.itemTransitionActions,
                        ...this.getAllowedTransitionActionsForItems(res.items, this.workflow, this.component)
                    };
                },
                (err: ARApiError) => Toaster.handleApiError(err));
    }

    public onRearrangeClicked(): void {
        const movedItem: PublicationItemModel = this.itemControl.value;
        const toItem: PublicationItemModel = this.whereControl.value;

        const rearrangedItems = [...this.publicationItems];

        const fromIndex = rearrangedItems.indexOf(movedItem);
        let toIndex = rearrangedItems.indexOf(toItem);
        switch (this.moveTypeControl.value) {
            case EMoveType.InsertBefore:
                rearrangedItems.splice(fromIndex, 1);
                toIndex = rearrangedItems.indexOf(toItem);
                rearrangedItems.splice(toIndex, 0, movedItem);
                break;
            case EMoveType.InsertAfter:
                rearrangedItems.splice(fromIndex, 1);
                toIndex = rearrangedItems.indexOf(toItem) + 1;
                rearrangedItems.splice(toIndex, 0, movedItem);
                break;
            case EMoveType.Swap:
                rearrangedItems[toIndex] = movedItem;
                rearrangedItems[fromIndex] = toItem;
                break;
        }

        const itemIds = rearrangedItems.map((item) => item._id);
        const jobData = {
            publicationId: this.publication._id,
            publicationItems: itemIds
        } as IArrangePagesJobData;

        this.publicationsService
            .postJob(EPublicationJobType.ARRANGE_PAGES, jobData)
            .subscribe({
                next: (job) => {
                    if (this.arrangePagesJobSubscription) {
                        this.arrangePagesJobSubscription.unsubscribe();
                    }

                    this.arrangePagesJobSubscription = this.monitoredJobsService.getJobMonitor(job._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.resetForm();
                                this.refreshData();
                            }
                        });
                },
                error: Toaster.handleApiError
            });
    }

    public openArrangeForm(item: PublicationItemModel): void {
        this.swapping = true;
        this.formGroup.patchValue({item});
    }

    private resetForm(): void {
        this.formGroup.reset({moveType: this.moveTypeControl.value});
        this.formGroup.markAsPristine();
    }

    public closeArrangeForm(): void {
        this.swapping = false;
        this.resetForm();
    }

    private getAllowedTransitionActionsForItems(items: PublicationItemModel[],
                                                workflow: WorkflowConfigurationModel,
                                                component: CustomWorkflowComponentModel): Record<string, any> {
        const result = {};
        for (const item of items) {
            result[item._id] = this.getAllowedTransitionActionsForItem(item, workflow, component);
        }
        return result;
    }

    private getAllowedTransitionActionsForItem(item: PublicationItemModel,
                                               workflow: WorkflowConfigurationModel,
                                               component: CustomWorkflowComponentModel): CustomWorkflowActionModel[] {
        // when the item is being created, it doesn't have step attached
        const allowedActions = [];
        const itemStep = item.step?._id;
        for (const action of component.actions) {
            let isAllowed = false;
            if (this.userIsAllowedToPipe.transform(action.permissions) &&
                (action.type !== EWorkflowConfigurationActionType.TRANSITION_TRIGGER ||
                    workflow.transitions.find((transition) =>
                        transition._id === action.transition && transition.from === itemStep))) {
                isAllowed = true;
            }
            if (isAllowed && action !== this.arrangePagesAction) {
                // Not all workflow actions have    set the icon
                if (action.name === AppConstants.CUSTOM_WORKFLOW_OVERVIEW_COMPONENT_ACTIONS.DELETE_PUBLICATION_ITEMS) {
                    action.icon = 'nucicon_trash_fill';
                }
                allowedActions.push(action);
            }
        }
        return allowedActions;
    }

    public handleAction(action: CustomWorkflowActionModel, tableItems: PublicationItemModel[]): void {
        if (action.name === AppConstants.CUSTOM_WORKFLOW_OVERVIEW_COMPONENT_ACTIONS.ARRANGE_PAGES) {
            this.openArrangeForm(tableItems[0]);
        } else {
            super.handleAction(action, tableItems);
        }
    }
}
