import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {TemplateTypeModel} from '../../models/api/template-type.model';
import {QueryParams} from '../../classes/query-params';

@Injectable({
    providedIn: 'root'
})
export class TemplateTypeService extends BaseApiRequestService {

    /**
     * Get a pages array of template types from the API
     * @param limit
     * @param offset
     * @param search
     * @returns {any}
     */
    public getTemplateTypes(limit?: number, offset?: number, search?: string): Observable<ARPagedResponseDataModel<TemplateTypeModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSearchParams(search);
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_POS_TYPES], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, TemplateTypeModel);
        });
    }

    /**
     * Post a new template type to the API
     * @param posName
     */
    public addNewTemplateType(posName: string): Observable<TemplateTypeModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_POS_TYPES]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {
            name: posName
        };
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateTypeModel);
        });
    }
}
