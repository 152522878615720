<div class="header-row">
    <h1>Content group overview</h1>
    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_GROUP | userIsAllowedTo" (click)="openGroupForm()"
                            text="Add content group"></nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_GROUPS | userIsAllowedTo else noPermissionState">
    <nuc-table [useButtonBar]="true"
               [useEmptyState]="true"
               [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
               [items]="groups"
               [columns]="columns"
               [loading]="!groupSubscription?.closed"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)"
               (itemClicked)="onTableRowClicked($event)"
               (actionClicked)="handleTableRowAction($event)">
        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="updateUrl()"
                   placeholder="Search content groups">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!groupSubscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
