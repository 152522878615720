<ng-container *ngIf="permissions.GET_PACKAGES | userIsAllowedTo; else noPermissionState">
    <nuc-table [useButtonBar]="true"
               [items]="packages"
               [selectionMode]="ESelectionMode.MULTI"
               [columns]="columns"
               (itemClicked)="onItemClicked($event)"
               [loading]="!packagesSubscription?.closed"
               [useEmptyState]="true"
               [emptyStateTitle]="searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selection]="selection"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">

        <nuc-input button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated()"
                   placeholder="Search packages by name or description">
        </nuc-input>

        <ng-container button-bar-right>
            <div class="popout-button" *ngIf="selection.hasValue()">
                <nuc-button-secondary
                    text="{{selection.selected.length}} selected {{selection.selected.length === 1 ? 'item' : 'items'}}"
                    icon="nucicon_dots_vertical"
                    iconPosition="end"
                    [matMenuTriggerFor]="menu">
                </nuc-button-secondary>
                <mat-menu #menu="matMenu" class="menu-items-container">
                    <button *ngIf="permissions.POST_CAMPAIGN_JOB | userIsAllowedTo else noActionsAvailable"
                            mat-menu-item
                            (click)="openExportPackageForm(selection.selected, true)"
                            [disableRipple]="true">
                        {{EXPORT_PACKAGE_DATA_ACTION.title}}
                    </button>
                    <ng-template #noActionsAvailable>
                        <button mat-menu-item [disabled]="true" [disableRipple]="true">No actions available</button>
                    </ng-template>
                </mat-menu>
            </div>

            <div class="vertical-line" *ngIf="selection.hasValue()"></div>

            <paginator [viewId]="tableId"
                       [disableNextPage]="disableNextPage"
                       [loading]="!packagesSubscription?.closed">
            </paginator>
        </ng-container>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
