import {Component, inject, Inject, OnDestroy, OnInit} from '@angular/core';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {AppConstants} from '../../app.constants';
import {SortDirection} from '@angular/material/sort';
import {CampaignItemsService} from '../../api/services/campaign-items.service';
import {PublicationsService} from '../../api/services/publications.service';
import {
    BUTTON_TYPE,
    ButtonConfig,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalActionModel,
    FullModalService,
    ISortOptionEvent,
    ITableColumn,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {TableConfigService} from '../../api/services/table-config.service';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {EDataFieldCollectionName} from '../../app.enums';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {SelectionModel} from '@angular/cdk/collections';
import {VariantModel} from '../../models/api/variant.model';
import {PaginatorService} from '../paginator/paginator.service';

export interface IRelinkStickyNoteModalData {
    spreadId: string;
    campaignId: string;
    publicationId: string;
    campaignItem?: CampaignItemModel;
    variant?: VariantModel;
}

@Component({
    selector: 'rl-relink-sticky-note',
    templateUrl: './relink-sticky-note.component.html',
    styleUrls: ['./relink-sticky-note.component.scss'],
    providers: [PaginatorService]
})
export class RelinkStickyNoteComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'relink-sticky-table';

    public permanentColumns: ITableColumn[] = [{
        title: '',
        selectionColumnTitle: 'Thumbnail',
        key: 'thumb',
        size: EColumnSize.SMALL,
        type: EColumnType.THUMBNAIL,
        format: (value) => value ? value : AppConstants.ICONS.IMAGE_MAIN,
        clickable: true
    }];

    private defaultColumns: ITableColumn[] = [{
        title: 'Briefing Item ID',
        key: 'briefingItemId',
        size: EColumnSize.SMALL,
        sortProperty: 'briefingItemId'
    }, {
        title: 'Date created',
        key: 'createdAt',
        size: EColumnSize.SMALL,
        sortProperty: 'createdAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
    }, {
        title: 'Date modified',
        key: 'updatedAt',
        size: EColumnSize.SMALL,
        sortProperty: 'updatedAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
    }];

    public ESelectionMode = ESelectionMode;
    public columns: ITableColumn[];

    public pageSizeOptions = AppConstants.PAGE_SIZE_OPTIONS;
    public pageSize = this.pageSizeOptions[0];
    public campaignItems: CampaignItemModel[];
    public totalItemCount: number;
    public pageIndex = 0;
    public sortProperty: string;
    public sortOrder: SortDirection;

    public relinkButton: ButtonConfig;
    public selection = new SelectionModel<string>();
    private onDestroySubject = new Subject<void>();
    public campaignItemsSubscription: Subscription;
    private dataFieldsComponentUtil: DataFieldsComponentUtil;

    constructor(private publicationsService: PublicationsService,
                private tableConfigService: TableConfigService,
                private campaignItemsService: CampaignItemsService,
                private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsApiService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IRelinkStickyNoteModalData) {
        super(userSettingsStorageService);

        this.dataFieldsComponentUtil = inject(DataFieldsComponentUtil);
    }

    /**
     * Lifecycle method onInit
     */
    public ngOnInit(): void {
        this.initializeTable();
        this.initButtons();

        if (this.modalData.campaignItem) {
            this.selection.select(this.modalData.campaignItem._id);
        }

        this.selection.changed
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.onSelectionChanged());

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getAssignedCampaignItems();
            });

        this.resetPageIndex();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Initializes the table, first gets columns from Table Service / API then gets campaign items from API
     * Called by ngOnInit
     */
    private initializeTable(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (dataFields) => {
                    this.columns = [
                        ...this.permanentColumns,
                        ...this.dataFieldsComponentUtil.getDataFieldsColumnSelection(dataFields, false, this.modalData.variant?.key),
                        ...this.defaultColumns
                    ];
                },
                error: Toaster.handleApiError
            });
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.relinkButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        const relinkAction = new FullModalActionModel(this.relinkButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        relinkAction.observable.subscribe(() => this.onRelinkButtonClick());

        const actions = [cancelAction, relinkAction];
        this.fullModalService.setModalActions(actions);
    }

    /**
     * Gets the assigned campaign items for the current spread
     */
    public getAssignedCampaignItems(): void {
        this.campaignItemsSubscription = this.publicationsService.getCampaignItemsForPublicationItem(this.modalData.publicationId,
            this.modalData.spreadId,
            undefined,
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            this.sortProperty,
            this.sortOrder).subscribe({
            next: (result: ARPagedResponseDataModel<CampaignItemModel>) => {
                this.campaignItems = result.items;
                this.totalItemCount = result.total;
            },
            error: Toaster.handleApiError
        });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.resetPageIndex();
    }

    private resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.tableId, AppConstants.PAGE_INDEX_DEFAULT); // reset pageIndex
    }

    /**
     * Responder to Relink button
     */
    public onRelinkButtonClick(): void {
        this.relinkButton.loading = true;
        this.selection.hasValue() ? this.relinkSticky() : this.unlinkSticky();
    }

    /**
     * Call fullModalService.close() with campaignItem to link to the sticky. Parent will handle API call
     */
    private relinkSticky(): void {
        this.campaignItemsService.getDetails(this.modalData.campaignId, this.selection.selected[0])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (campaignItem: CampaignItemModel) => {
                    this.relinkButton.loading = false;
                    this.fullModalService.close(campaignItem);
                },
                error: (error: ARApiError) => {
                    this.relinkButton.loading = false;
                    this.fullModalService.close();
                    Toaster.handleApiError(error);
                }
            });
    }

    /**
     * Call fullModalService.close() with empty campaignItem to inform parent that we want to unlink the sticky
     */
    private unlinkSticky(): void {
        this.relinkButton.loading = false;
        this.fullModalService.close(new CampaignItemModel());
    }

    public onSelectionChanged(): void {
        this.relinkButton.disabled = !!this.selection.selected.find(itemId => itemId === this.modalData.campaignItem?._id);
    }
}
