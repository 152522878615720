import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {WorkflowConfigurationModel} from '../../../../models/api/workflow-configuration.model';
import {Toaster} from '../../../../classes/toaster.class';
import {RLCountsModel} from '../../../../api/response/rl-counts.model';
import {RLCountModel} from '../../../../api/response/rl-count.model';
import {TabBarItemModel} from '../../../../models/ui/tab-bar-item.model';
import {ESelectionMode, FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {
    EWorkflowConfigurationProperty
} from './workflow-configuration-detail-property/workflow-configuration-property.config';
import {
    IWorkflowConfigurationStepFormData,
    WorkflowConfigurationStepFormComponent
} from '../../../../forms/workflow-configuration-step-form/workflow-configuration-step-form.component';
import {
    IWorkflowConfigurationTransitionFormData,
    WorkflowConfigurationTransitionFormComponent
} from '../../../../forms/workflow-configuration-transition-form/workflow-configuration-transition-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {WorkflowConfigurationsDetailsService} from './workflow-configurations-details.service';
import {
    IWorkflowConfigurationActionFormData,
    WorkflowConfigurationActionFormComponent
} from '../../../../forms/workflow-configuration-action-form/workflow-configuration-action-form.component';
import {ActivatedRoute} from '@angular/router';
import {CustomWorkflowStepModel} from '../../../../models/api/custom-workflow-step.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {
    IWorkflowConfigurationComponentFormData,
    WorkflowConfigurationComponentFormComponent
} from '../../../../forms/workflow-configuration-component-form/workflow-configuration-component-form.component';

interface IMenuItem {
    title: string;
    property: EWorkflowConfigurationProperty;
}

@Component({
    selector: 'workflow-configuration-details-component',
    templateUrl: 'workflow-configuration-details.component.html',
    styleUrls: ['workflow-configuration-details.component.scss'],
    providers: [WorkflowConfigurationsDetailsService]
})
export class WorkflowConfigurationDetailsComponent extends RLBaseComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    protected readonly ESelectionMode = ESelectionMode;
    private workflowConfigurationId: string;
    public workflowConfiguration: WorkflowConfigurationModel;
    public counts = new RLCountsModel();
    public activeStep: CustomWorkflowStepModel;

    public menuItems: IMenuItem[];

    // tab bar related
    public tabBarItems: TabBarItemModel<EWorkflowConfigurationProperty>[];
    private _selectedTab: TabBarItemModel<EWorkflowConfigurationProperty>;

    public get selectedTab(): TabBarItemModel<EWorkflowConfigurationProperty> {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel<EWorkflowConfigurationProperty>) {
        if (tab !== this._selectedTab && this.tabBarItems.find((t) => t.title === tab.title)) {
            this._selectedTab = tab;
            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
        }
    }

    constructor(private route: ActivatedRoute,
                private fullModalService: FullModalService,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService) {
        super();
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.getData();

        this.workflowConfigurationsDetailsService.workflowConfiguration
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((workflowConfiguration) => {
                this.workflowConfiguration = workflowConfiguration;

                if (this.activeStep) {
                    this.activeStep = this.workflowConfiguration.steps.find((step) => step._id === this.activeStep._id);
                } else {
                    this.setActiveStepId(null);
                }

                this.createFakeCountsAndTransitions();
            });

        this.workflowConfigurationsDetailsService.activeStepId
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((stepId) => {
                this.activeStep = this.workflowConfiguration.steps.find((step) => step._id === stepId);
                this.setupTabBar();
                this.setupMenuItems();
            });
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.workflowConfigurationId = params['workflowConfigurationId'];
        this.workflowConfigurationsDetailsService.setWorkflowConfigurationId(this.workflowConfigurationId);
    }

    private getData(): void {
        this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
    }

    private createFakeCountsAndTransitions(): void {
        this.counts.steps = this.workflowConfiguration.steps.map((step, index) => {
            const countModel = new RLCountModel();
            countModel._id = step._id;
            countModel.count = index !== 0 ? 1 : 0;

            return countModel;
        });
        this.counts.transitions = [];
    }

    public onMenuItemClicked(property: EWorkflowConfigurationProperty): void {
        switch (property) {
            case EWorkflowConfigurationProperty.STEPS:
                this.openCreateWorkflowConfigurationStep();
                return;
            case EWorkflowConfigurationProperty.TRANSITIONS:
                this.openCreateWorkflowConfigurationTransition();
                return;
            case EWorkflowConfigurationProperty.ACTIONS:
                this.openCreateWorkflowConfigurationAction();
                return;
            case EWorkflowConfigurationProperty.COMPONENTS:
                this.openCreateWorkflowConfigurationComponent();
                return;
            default:
                return;
        }
    }

    public openCreateWorkflowConfigurationStep(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalConfig = new FullModalConfig('Add workflow configuration step',
                        'Enter the information to create a new workflow configuration step.',
                        {workflowConfigurationId: workflowConfiguration._id} as IWorkflowConfigurationStepFormData);

                    this.fullModalService.open(WorkflowConfigurationStepFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationTransition(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalData = {
                        workflowConfiguration,
                        workflowConfigurationSteps: workflowConfiguration.steps
                    } as IWorkflowConfigurationTransitionFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration transition',
                        'Enter the information to create a new workflow configuration transition.',modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationTransitionFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationAction(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalData = {
                        workflowConfiguration
                    } as IWorkflowConfigurationActionFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration action',
                        'Enter the information to create a new workflow configuration action.',modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationActionFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationComponent(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (configuration) => {
                    if (this.activeStep) {
                        const step = configuration.steps.find((c) => c._id === this.activeStep._id);
                        if (!step) {
                            Toaster.error('Could not find workflow configuration step');
                            return;
                        }
                    }

                    const modalData = {
                        workflowConfiguration: this.workflowConfiguration,
                        workflowConfigurationStep: this.activeStep
                    } as IWorkflowConfigurationComponentFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration component',
                        'Enter the information to create a new workflow component.', modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationComponentFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public onWorkflowIndicatorClicked(): void {
        // click anywhere except the steps will unset active step
        this.setActiveStepId(null);
    }

    public setActiveStepId(step: CustomWorkflowStepModel): void {
        this.workflowConfigurationsDetailsService.setActiveStepId(step);
    }

    public setupMenuItems(): void {
        this.menuItems = this.activeStep
            ? [{title: 'component', property: EWorkflowConfigurationProperty.COMPONENTS}]
            : [
                {title: 'step', property: EWorkflowConfigurationProperty.STEPS},
                {title: 'component', property: EWorkflowConfigurationProperty.COMPONENTS},
                {title: 'transition', property: EWorkflowConfigurationProperty.TRANSITIONS},
                {title: 'action', property: EWorkflowConfigurationProperty.ACTIONS}];
    }

    public setupTabBar(): void {
        const allowedProperties =
            this.activeStep
                ? [EWorkflowConfigurationProperty.COMPONENTS]
                : Object.values(EWorkflowConfigurationProperty);

        this.tabBarItems = allowedProperties.map((prop, index) => new TabBarItemModel(prop, index, prop));
        this.selectedTab = this.tabBarItems[0];
    }
}
