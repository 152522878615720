import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {SortDirection} from '@angular/material/sort';
import {Cursor} from '../api-cursor';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiAutocompleteRequest} from '../requests/api-autocomplete.request';

@Injectable({providedIn: 'root'})
export class CampaignItemsApiService extends ApiService<CampaignItemModel> {
    constructor() {
        super([ApiConstants.API_GROUP_CAMPAIGN_ITEMS], CampaignItemModel);
    }

    public getCampaignItems(campaignId?: string,
                            publicationId?: string,
                            limit?: number,
                            offset?: number,
                            phraseValue?: string,
                            sortProperty?: string,
                            sortOrder?: SortDirection,
                            filters?: Record<string, any>,
                            cursor?: Cursor,
                            assignedPublicationItems?: boolean): Observable<ARPagedResponseDataModel<CampaignItemModel>> {

        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, sortProperty, sortOrder, cursor, null, filters);

        request.additionalQueryParams.addParam('campaignId', campaignId);
        request.additionalQueryParams.addParam('publicationId', publicationId);
        request.additionalQueryParams.addParam('phrase', phraseValue);

        if (assignedPublicationItems) request.additionalQueryParams.addParam('assignable', assignedPublicationItems);

        return this.apiRequestService.find(request);
    }

    public autocomplete(search: string, campaignId: string): Observable<string[]> {
        const request = new ApiAutocompleteRequest(this.groups);
        request.additionalQueryParams.addParam('search', search);
        request.additionalQueryParams.addParam('campaignId', campaignId);
        return this.apiRequestService.autocomplete(request);
    }
}
