/**
 *  app-relayter-web
 *  Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiConstants} from '../api/api.constant';
import {Injectable} from '@angular/core';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {

    private addWithCredentials(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            // patch the request with https://angular.io/api/common/http/HttpRequest#withCredentials
            withCredentials: true
        });
    }

    /**
     * Configure the request to exchange cookies with this endpoint in certain cases
     * This prevent the app in sending the cookies in every request to our Relayter API
     * Should be sent with outgoing credentials (cookies) (See: https://angular.io/api/common/http/HttpRequest).
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const addCredentialsCalls = [
            `${ApiConstants.API_GROUP_USERS}/${ApiConstants.API_METHOD_LOGIN}`,
            `${ApiConstants.API_GROUP_USERS}/${ApiConstants.API_METHOD_LOGOUT}`,
            ApiConstants.API_METHOD_AUTHORIZE,
            ApiConstants.API_GROUP_EXCHANGE_AUTH0_ID_TOKEN
        ];
        if (addCredentialsCalls.some((path) => request.url.includes(path))) {
            request = this.addWithCredentials(request);
        }
        return next.handle(request);
    }
}
