import {
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    WritableSignal
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {DataFieldModel} from '../../../models/api/data-field.model';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {EDataFieldTypes} from '../../../app.enums';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {distinctUntilChanged} from 'rxjs/operators';

export enum EMasterBriefingDataFieldCopyOption {
    OVERWRITE_WITH_VALUE = 'OVERWRITE_WITH_VALUE',
    SKIP = 'SKIP',
    MERGE = 'MERGE',
    MERGE_WITH_VALUE = 'MERGE_WITH_VALUE'
}

@Component({
  selector: 'data-field-option-form',
  templateUrl: './data-field-option-form.component.html',
  styleUrls: ['./data-field-option-form.component.scss']
})
export class DataFieldOptionFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    @Input() public optionForm: FormGroup;
    @Input() public allDataFields: DataFieldModel[];
    @Input() public index: number;

    @Output() public onDeleteOptionClicked: EventEmitter<number> = new EventEmitter<number>();
    protected readonly EDataFieldTypes = EDataFieldTypes;
    public dataFields: DataFieldModel[];

    public get dataFieldControl(): AbstractControl {
        return this.optionForm.get('dataField');
    }
    public get behaviorControl(): AbstractControl {
        return this.optionForm.get('behavior');
    }

    public behaviorOptions: WritableSignal<IDropdownItem[]> = signal([]);
    private readonly UNIVERSAL_BEHAVIOURS = [
        new DropdownItem('Overwrite with value', EMasterBriefingDataFieldCopyOption.OVERWRITE_WITH_VALUE),
        new DropdownItem('Skip field', EMasterBriefingDataFieldCopyOption.SKIP)];
    private readonly LIST_BEHAVIOURS = [...this.UNIVERSAL_BEHAVIOURS,
        new DropdownItem('Merge', EMasterBriefingDataFieldCopyOption.MERGE),
        new DropdownItem('Merge with value', EMasterBriefingDataFieldCopyOption.MERGE_WITH_VALUE)];

    private setBehaviorItems(dataField: DataFieldModel): void {
        switch(dataField?.getDataType()) {
            case EDataFieldTypes.STRING:
            case EDataFieldTypes.DATE:
            case EDataFieldTypes.NUMBER:
            case EDataFieldTypes.BOOLEAN:
                this.behaviorOptions.set(this.UNIVERSAL_BEHAVIOURS);
                break;
            case EDataFieldTypes.ENUM:
            case EDataFieldTypes.LIST:
                this.behaviorOptions.set(this.LIST_BEHAVIOURS);
                break;
            default:
                this.behaviorOptions.set([]);
        }
    }

    public removeOption() {
        this.onDeleteOptionClicked.emit(this.index);
    }

    public ngOnInit(): void {
        this.dataFields = this.allDataFields;

        // set the initial value
        if (this.dataFieldControl.value) {
            this.setBehaviorItems(this.dataFieldControl.value);
        }

        this.dataFieldControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((newValue) => {
                this.setBehaviorItems(newValue);
                this.behaviorControl.setValue(null);
        });

        this.behaviorControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((newBehavior) => {
            if (newBehavior?.getValue() === EMasterBriefingDataFieldCopyOption.OVERWRITE_WITH_VALUE || newBehavior?.getValue() === EMasterBriefingDataFieldCopyOption.MERGE_WITH_VALUE) {
                if (!this.optionForm.contains('value'))
                    this.optionForm.addControl('value', new FormControl('', Validators.required));
            } else {
                if (this.optionForm.contains('value')) this.optionForm.removeControl('value');
            }
        });
    }

    public onRequestDataFields(event: IDropdownRequestDataEvent): void {
        const regex = new RegExp(event.search, 'i');
        this.dataFields = event.search
            ? this.allDataFields.filter((icon) => icon.getTitle().match(regex)?.length > 0)
            : this.allDataFields;
    }

}
