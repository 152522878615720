// Modules
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSortModule} from '@angular/material/sort';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NUCPipesModule, RDModule} from '@relayter/rubber-duck';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';

// Directives
import {ScrollItemIntoViewDirective} from '../directives/scroll-item-into-view.directive';
import {FullscreenFormDirective} from '../directives/fullscreen-form-directive/fullscreen-form.directive';

// Components
/* eslint-disable max-len */
import {HeaderComponent} from './header/header.component';
import {GridViewComponent} from './grid-view/grid-view.component';
import {CollectionOptionsViewComponent} from './collection-options-view/collection-options-view.component';
import {CollectionViewComponent} from './collection-view/collection-view.component';
import {ExportImageModalViewComponent} from './export-asset-modal-view/export-image-modal-view.component';
import {AccountManagementComponent} from './account-management/account-management.component';
import {MultipleFileInputComponent} from './inputs/multiple-file-input/multiple-file-input.component';
import {ReactiveFileInputComponent} from './inputs/reactive-file-input/reactive-file-input.component';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {ExportCampaignBriefingComponent} from './export-campaign-briefing/export-campaign-briefing.component';
import {JobNotificationComponent} from './notifications/job-notification/job-notification.component';
import {
    NewTemplateEditorComponent
} from '../pages/relayter/templates/new-template-editor/new-template-editor.component';
import {UploadFileComponent} from './upload-file-component/upload-file.component';
import {RelayterVersionComponent} from './relayter-version/relayter-version.component';
import {ListDialogComponent} from './dialog/list/list-dialog.component';
import {FileComponent} from './file/file.component';
import {
    SpreadEditorComponent
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-layout/spread-editor/spread-editor.component';
import {PropertySettingsComponent} from './property-settings/property-settings.component';
import {LibraryDetailComponent} from '../pages/relayter/library-items/library-detail/library-detail.component';
import {UploadAssetComponent} from './upload-asset/upload-asset.component';
import {DataFieldsOverviewComponent} from './data-fields-overview/data-fields-overview.component';
import {DataFieldInputComponent} from './data-field-input/data-field-input.component';
import {UserIconComponent} from './user-icon/user-icon.component';
import {FormBuilderComponent} from '../pages/relayter/data-management/form-builder/form-builder.component';
import {FormFieldComponent} from '../pages/relayter/data-management/form-builder/form-field/form-field.component';
import {
    DropLocationComponent
} from '../pages/relayter/data-management/form-builder/drop-location/drop-location.component';
import {OverlayButtonComponent} from './overlay-button/overlay-button.component';
import {LinkAssetViewComponent} from './link-asset-view/link-asset-view.component';
import {DataFieldDropdownComponent} from './data-filter/data-field-dropdown/data-field-dropdown.component';
import {DataFieldMultiselectComponent} from './data-filter/data-field-multiselect/data-field-multiselect.component';
import {
    TransitionNotificationComponent
} from './notifications/transition-notification/transition-notification.component';
import {InformationDetailsViewComponent} from './information-details-view/information-details-view.component';
import {DataFilterComponent} from './data-filter/data-filter.component';
import {DataFilterMultiselectComponent} from './data-filter/data-filter-multiselect/data-filter-multiselect.component';
import {DataFilterDropdownComponent} from './data-filter/data-filter-dropdown/data-filter-dropdown.component';
import {DataFilterSuggestiveComponent} from './data-filter/data-filter-suggestive/data-filter-suggestive.component';
import {BriefingTableComponent} from './briefing-table/briefing-table.component';
import {SearchableListComponent} from './searchable-list/searchable-list.component';
import {
    NotePreviewComponent
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-layout/spread-editor/note/note-preview/note-preview.component';
import {
    AssignTableActionComponent
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-briefing-actions/assign-table-action/assign-table-action.component';
import {
    StickyListViewComponent
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-preview/custom-workflow-sticky-notes-sidebar-container/sticky-list-view/sticky-list-view.component';
import {
    StickyListDataFilterComponent
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-preview/custom-workflow-sticky-notes-sidebar-container/sticky-list-view/sticky-list-data-filter/sticky-list-data-filter.component';
import {NoteChangesComponent} from './sticky-note/note-changes/note-changes.component';
import {
    LayoutChangeIndicatorComponent
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-layout/layout-change-indicator/layout-change-indicator.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {ApiKeyViewerComponent} from './api-key-viewer/api-key-viewer.component';

import {
    FullScreenContainerComponent
} from '../directives/fullscreen-form-directive/full-screen-container/full-screen-container.component';
import {MultiItemSelectionComponent} from './multi-item-selection/multi-item-selection.component';
import {CdkListboxModule} from '@angular/cdk/listbox';
import {PipesModule} from '../pipes/pipes.module';
import {
    AttachmentProgressBarComponent
} from './sticky-note/sticky-comment/attachment-progess-bar/attachment-progress-bar.component';
import {LastViewedItemsCheckboxComponent} from './last-viewed-items-checkbox/last-viewed-items-checkbox.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DownloadButtonComponent} from './download-button/download-button.component';
import {FileUploadCardViewComponent} from './file-upload-card-view/file-upload-card-view.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {WorkflowIndicatorComponent} from './workflow-indicator/workflow-indicator.component';
import {TransitionComponent} from './workflow-indicator/transition/transition.component';
import {StepComponent} from './workflow-indicator/step/step.component';
import {NoPermissionStateComponent} from './no-permission-state/no-permission-state.component';
import {CampaignItemChangelogComponent} from './campaign-item-changelog/campaign-item-changelog.component';
import {KeyViewerComponent} from './key-viewer/key-viewer.component';
import {MatLegacyOptionModule} from '@angular/material/legacy-core';
import {
    WorkflowConfigurationStepDeletionDialogComponent
} from '../pages/relayter/workflow-configurations/details/workflow-configuration-detail-property/workflow-configuration-step-deletion-dialog/workflow-configuration-step-deletion-dialog.component';
import {
    MasterBriefingsOverviewComponent
} from '../pages/relayter/team-settings/master-briefings-overview/master-briefings-overview.component';
import {ImportFromMasterBriefingComponent} from './import-from-master-briefing/import-from-master-briefing.component';
import {
    DataFieldOptionFormComponent
} from './import-from-master-briefing/datafield-option-form/data-field-option-form.component';
/* eslint-enable max-len */

const componentsList = [
    // directives
    ScrollItemIntoViewDirective,
    FullscreenFormDirective,
    // components
    UserIconComponent,
    NotePreviewComponent,
    SpreadEditorComponent,
    LibraryDetailComponent,
    PropertySettingsComponent,
    UploadFileComponent,
    HeaderComponent,
    GridViewComponent,
    CollectionOptionsViewComponent,
    CollectionViewComponent,
    ExportImageModalViewComponent,
    AccountManagementComponent,
    MultipleFileInputComponent,
    ReactiveFileInputComponent,
    LoadingIndicatorComponent,
    NewTemplateEditorComponent,
    BreadcrumbComponent,
    ExportCampaignBriefingComponent,
    JobNotificationComponent,
    RelayterVersionComponent,
    ListDialogComponent,
    WorkflowConfigurationStepDeletionDialogComponent,
    FileComponent,
    DataFieldsOverviewComponent,
    UploadAssetComponent,
    DataFieldInputComponent,
    FormBuilderComponent,
    FormFieldComponent,
    DropLocationComponent,
    OverlayButtonComponent,
    LinkAssetViewComponent,
    PaginatorComponent,
    DataFieldDropdownComponent,
    DataFieldMultiselectComponent,
    InformationDetailsViewComponent,
    TransitionNotificationComponent,
    DataFilterComponent,
    DataFilterMultiselectComponent,
    DataFilterDropdownComponent,
    DataFilterSuggestiveComponent,
    BriefingTableComponent,
    AssignTableActionComponent,
    SearchableListComponent,
    StickyListViewComponent,
    StickyListDataFilterComponent,
    NoteChangesComponent,
    LayoutChangeIndicatorComponent,
    FullScreenContainerComponent,
    MultiItemSelectionComponent,
    ApiKeyViewerComponent,
    LastViewedItemsCheckboxComponent,
    FileUploadCardViewComponent,
    AttachmentProgressBarComponent,
    DownloadButtonComponent,
    StepComponent,
    TransitionComponent,
    WorkflowIndicatorComponent,
    NoPermissionStateComponent,
    CampaignItemChangelogComponent,
    KeyViewerComponent,
    MasterBriefingsOverviewComponent,
    ImportFromMasterBriefingComponent
];

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MatSortModule,
        MatDatepickerModule,
        MatTooltipModule,
        RDModule,
        MatExpansionModule,
        MatRippleModule,
        ScrollingModule,
        DragDropModule,
        OverlayModule,
        MatMenuModule,
        MatSliderModule,
        MatLegacyOptionModule,
        MatProgressSpinnerModule,
        CdkListboxModule,
        PipesModule,
        NUCPipesModule,
        NgOptimizedImage,
        CommonModule
    ],
    declarations: [
        componentsList,
        DataFieldOptionFormComponent
    ],
    exports: [...componentsList],
    providers: []
})

export class ComponentsModule {
}
