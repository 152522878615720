import {Component} from '@angular/core';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogService
} from '@relayter/rubber-duck';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {Subscription} from 'rxjs';
import {CustomWorkflowService} from '../custom-workflow.service';
import {take, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../../../../../classes/toaster.class';
import {ETransitionStatus} from '../../../../../../models/api/transition-item.model';
import {CursorArray} from '../../../../../../api/api-cursor';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {ITableConfigStorage} from '../../../../../../api/services/table-config.service';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {DataFieldModel} from '../../../../../../models/api/data-field.model';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {DataFieldsComponentUtil} from '../../../../../../classes/data-fields-component.util';
import {MonitoredTransitionsService} from '../../../../../../api/services/monitored-transitions.service';
import {SortDirection} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {DataFieldsApiService} from '../../../../../../api/services/data-fields.api.service';

export enum EListComponentOptions {
    COLUMN_CONFIG = 'COLUMN_CONFIG'
}

@Component({
    selector: 'rl-custom-workflow-list-view-component',
    templateUrl: './custom-workflow-list-view.component.html',
    styleUrls: ['./custom-workflow-list-view.component.scss'],
    providers: [PaginatorService]
})
export class CustomWorkflowListViewComponent extends CustomWorkflowBaseComponent implements ITableConfigStorage {
    public tableId: string;
    public readonly storageKey: string;
    public tableSelectionMode = ESelectionMode.MULTI;

    public columns: ITableColumn[] = [];
    public total: number;
    public items: PublicationItemModel[] = [];
    public tableActions: ITableAction[];

    public pageIndex: number;
    public pageSize: number;
    private cursorArray: CursorArray;
    public disableNextPage: boolean;
    public sortColumn: ITableColumn;
    public sortOrder: SortDirection;

    public selection: SelectionModel<string>;
    public selectedItems: PublicationItemModel[] = [];

    public subscription: Subscription;
    private campaignDataFields: DataFieldModel[];
    private permanentColumns: ITableColumn[] = [{
        title: '',
        selectionColumnTitle: 'Thumbnail',
        key: 'files',
        type: EColumnType.THUMBNAIL,
        format: (value) => PublicationItemModel.getThumbnailUrl(this.activeVariant?._id, value),
        clickable: true
    }, {
        title: 'Publication Item id',
        key: 'publicationItemId',
        sortProperty: 'publicationItemId'
    }];

    private pageNumberColumn = {
        title: 'Page numbers',
        key: 'formattedPageNumbers',
        type: EColumnType.DEFAULT,
        sortProperty: 'firstPageNumber'
    };

    private paginationSubscription: Subscription;
    private selectionSubscription: Subscription;

    constructor(userIsAllowedToPipe: UserIsAllowedToPipe,
                customWorkflowService: CustomWorkflowService,
                publicationService: PublicationsService,
                dialogService: NucDialogService,
                fullModalService: FullModalService,
                userSettingsStorageService: UserSettingsStorageService,
                monitoredTransitionsService: MonitoredTransitionsService,
                private dataFieldService: DataFieldsApiService,
                private paginatorService: PaginatorService,
                private dataFieldsComponentUtil: DataFieldsComponentUtil) {
        super(userIsAllowedToPipe, customWorkflowService, publicationService, dialogService, fullModalService, monitoredTransitionsService);

        this.storageKey = userSettingsStorageService.getPrefixStorageKey();
    }

    public initComponent(): void {
        super.initComponent();

        this.customWorkflowService.transitionItemUpdate$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((transitionItem) => { // Refresh on failed transition
                // Failed items in current step no longer in transition
                const transition = this.workflow.transitions.find(item => item._id === transitionItem.transition);
                if (transition && transitionItem.status === ETransitionStatus.FAILED && this.step._id === transition.from) {
                    this.setPageIndex();
                }
            });
    }

    protected setupData(): void {
        // List views have a component based custom columns configuration
        this.tableId = `custom-workflow-list-view-table-${this.component._id}`;

        // To avoid we have multiple subscriptions open
        if (this.paginationSubscription) this.paginationSubscription.unsubscribe();

        this.paginationSubscription = this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.cursorArray = new CursorArray(pagination.pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.getPublicationItems();
            });

        this.setPageIndex();

        this.selection = new SelectionModel<string>(true, [], true);
        this.selectedItems = []; // reset

        // To avoid we have multiple subscriptions open
        if (this.selectionSubscription) this.selectionSubscription.unsubscribe();

        this.selectionSubscription = this.selection.changed
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((event) => {
                // we only store _ids in this.selection, but we need publicationItems
                this.selectedItems = this.selectedItems.concat(this.items.filter((item) => event.added.includes(item._id)));
                this.selectedItems = this.selectedItems.filter((selectedItem) => !event.removed.includes(selectedItem._id));
        });

        // Only call once
        if (!this.campaignDataFields) {
            this.dataFieldService.getCampaignItemDataFields()
                .pipe(
                    take(1),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe(dataFields => {
                    this.campaignDataFields = dataFields;
                    this.setupTable();
                });
        } else {
            this.setupTable();
        }
    }

    protected refreshData(afterTransition: boolean): void { // called after action is triggered
        afterTransition ? this.setPageIndex() : this.getPublicationItems();
    }

    private getPublicationItems(): void {
        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.subscription = this.publicationService.getItemsForPublication(
            this.publication.getItemModel(),
            this.publication._id,
            this.step?._id,
            this.activeFilters,
            this.pageSize,
            0,
            cursor,
            this.sortColumn?.sortProperty,
            this.sortOrder
        ).subscribe({
            next: result => {
                this.items = result.items;
                this.disableNextPage = !result.hasNext;

                if (this.items.length > 0) {
                    const sortField = this.sortColumn ? this.sortColumn.sortProperty :
                        (this.publication.channelIsPrintPublication() ? 'firstPageNumber' : '_id');
                    this.cursorArray.setCursor(this.pageIndex, sortField, this.items[this.items.length - 1]);
                }
            },
            error: Toaster.handleApiError
        });
    }

    private setupTable(): void {
        // Need to wait for the data fields
        if (!this.campaignDataFields) {
            return;
        }

        // table columns
        this.columns = this.publication.channelIsPrintPublication() ? this.permanentColumns.concat(this.pageNumberColumn) : this.permanentColumns;
        const columnConfig = this.component.options?.find((option) => option.name === EListComponentOptions.COLUMN_CONFIG);
        this.columns = this.columns.concat(this.dataFieldsComponentUtil.getDataFieldsColumnList(this.campaignDataFields, columnConfig,
            this.activeVariant?.key));
        this.tableActions = this.getTableActions();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const action = this.allowedActions.find((allowedAction) => allowedAction.title === event.action.title);
        this.handleAction(action, [event.item as PublicationItemModel]);
    }

    protected activeVariantChanged() {
        this.setupTable();
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onSortOptionChange(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column) {
            this.sortColumn = sortEvent.column;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortColumn = null;
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public handleMultiSelectionAction(action: CustomWorkflowActionModel): void {
        this.handleAction(action, this.selectedItems);
        this.selection.clear();
        this.selectedItems = [];
    }
}
