import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ARApiError, ARLogger} from '@relayter/core';
import {TIME_OUT_TYPES, Toaster} from '../../classes/toaster.class';
import {SortDirection} from '@angular/material/sort';
import {JobModel} from '../../models/api/job.model';
import {CampaignService, ECampaignJobTypes, IExportCampaignItemsJobData} from '../../api/services/campaigns.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, IListBoxItem, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {ErrorConstants} from '../../api/error.constants';
import {VariantModel} from '../../models/api/variant.model';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {VariantService} from '../../api/services/variant.service';
import {forkJoin, Subject} from 'rxjs';
import {EDataFieldCollectionName} from '../../app.enums';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IExportCampaignBriefingData {
    campaignId: string;
    sortProperty: string;
    sortOrder: SortDirection;
}

enum EExportType {
    XLS = 'XLS',
    CSV = 'CSV',
    XLIFF = 'XLIFF'
}

interface IExportType extends IListBoxItem {
    name: string;
    exportType: EExportType;
    description: string;
    icon: string;
}

@Component({
    selector: 'rl-export-campaign-briefing-component',
    templateUrl: 'export-campaign-briefing.component.html',
    styleUrls: ['export-campaign-briefing.component.scss']
})

export class ExportCampaignBriefingComponent implements OnInit, OnDestroy {
    public exportFileTypes: IExportType[] = [
        {
            name: 'EXCEL',
            exportType: EExportType.XLS,
            description: 'Export campaign briefing to the Excel file format',
            icon: '/assets/images/icon_xls.svg'
        },
        {
            name: 'CSV',
            exportType: EExportType.CSV,
            description: 'Export campaign briefing to a CSV file format',
            icon: '/assets/images/icon_csv.svg'
        },
        {
            name: 'XLIFF',
            exportType: EExportType.XLIFF,
            description: 'Export campaign briefing to a XLIFF file format',
            icon: '/assets/images/icon_xliff.svg'
        }
    ];

    public isLoading: boolean = false;

    private downloadButton: ButtonConfig;

    public variantEnabled: boolean;
    public variants: VariantModel[];
    private onDestroySubject = new Subject<void>();
    public selectedVariant: VariantModel;
    public formGroup: UntypedFormGroup;

    constructor(private campaignService: CampaignService,
                private fullModalService: FullModalService,
                private variantService: VariantService,
                private dataFieldsService: DataFieldsApiService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IExportCampaignBriefingData) {
    }

    public ngOnInit(): void {
        this.getData();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private getData(): void {
        forkJoin({
            variantsData: this.variantService.getVariants(this.modalData.campaignId),
            dataFields: this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        }).pipe(takeUntil(this.onDestroySubject))
            .subscribe(({dataFields, variantsData}) => {
                this.variants = variantsData.items;
                this.variantEnabled = dataFields.some(field => field.enableVariants);
                if (this.variants?.length > 0) {
                    this.selectedVariant = this.variants[0];
                }
                if (this.variantEnabled && this.variants.length > 0) this.selectedVariant = this.variants[0];

                this.initForm();
                this.initButtons();
            }, Toaster.handleApiError);
    }

    private initForm(): void {
        this.formGroup = new UntypedFormGroup({
            exportType: new UntypedFormControl(null, Validators.required)
        });

        if (this.variantEnabled) {
            this.formGroup.addControl('variant', new UntypedFormControl(this.selectedVariant, Validators.required));
        }
    }

    private initButtons(): void {
        this.downloadButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Export', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancel = new FullModalActionModel(cancelButton);
        const download = new FullModalActionModel(this.downloadButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        download.observable.subscribe(() => this.onDownloadButtonClicked());

        const actions = [cancel, download];
        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === 'VALID'),
            takeUntil(this.onDestroySubject)
        ).subscribe((isValid: boolean) => this.downloadButton.disabled = !isValid);
    }

    public onDownloadButtonClicked(): void {
        this.downloadButton.loading = true;

        const selectedExportType = this.formGroup.value.exportType;
        const selectedVariant = this.formGroup.value.variant;

        // TODO: Nothing happened with sort....
        const jobData = {
            campaignId: this.modalData.campaignId,
            exportType: selectedExportType.exportType,
            variantKey: selectedVariant?.key,
            sort: this.modalData.sortProperty,
            sortType: this.modalData.sortOrder ? this.modalData.sortOrder.toUpperCase() : undefined
        } as IExportCampaignItemsJobData;

        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_EXPORT_BRIEFING_JOB, jobData).subscribe(
            (scheduleJob: JobModel) => {
                ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                this.downloadButton.loading = false;
                this.fullModalService.close();
            },
            (err: ARApiError) => {
                err.code !== ErrorConstants.API_ERROR_CODES.NOT_FOUND_BRIEFING ?
                    Toaster.handleApiError(err) :
                    // As an exception override time out to make it clearer to the user that there are no briefing items found to export
                    Toaster.warn(err.message, null, {timeout: TIME_OUT_TYPES.LONG});

                this.downloadButton.loading = false;
                this.fullModalService.close();
            }
        );
    }

    public onVariantChanged(variant: VariantModel): void {
        this.selectedVariant = variant;
    }
}
