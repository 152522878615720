<div class="header-row">
    <h1>Master page overview</h1>
    <nuc-button-bar>
        <nuc-button-primary
            *ngIf="permissions.POST_MASTER_PAGE_JOBS | userIsAllowedTo"
            text="Add master page"
            (click)="openMasterPageModal()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="(permissions.GET_MASTER_PAGES | userIsAllowedTo); else noPermissionState">
    <nuc-table [useButtonBar]="true"
               [useEmptyState]="true"
               [loading]="!masterPageSubscription?.closed"
               [columns]="columns"
               [items]="items"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selectionMode]="ESelectionMode.EXPAND"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">
        <nuc-input class="search"
                   button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   placeholder="Search master pages by name"
                   (debounceValueChanged)="searchValueChanged()">
        </nuc-input>
        <paginator button-bar-right
                   [loading]="!masterPageSubscription?.closed"
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>

<ng-template #masterPageUsageOverviewDialog>
    <div *ngIf="totalTemplates > 0" class="usage-container"
         [style.height.px]="120 + ((totalTemplates > 5 ? 5 : totalTemplates) * 32)">
        <nuc-table [useButtonBar]="true"
                   [items]="templates"
                   [tableId]="usageTableId"
                   [columns]="templateColumns"
                   [loading]="!masterPagesUsingImageSubscription?.closed"
                   [prefixStorageKey]="storageKey">
            <paginator button-bar-right
                       [viewId]="usageTableId"
                       [disableNextPage]="disableNextPageTemplates"
                       [pageSizeOptions]="pageSizeOptionsTemplates"
                       [loading]="!masterPagesUsingImageSubscription?.closed">
            </paginator>
        </nuc-table>
    </div>

</ng-template>
