/**
 * workflow-filters.util
 * app-relayter-web
 * Created by marcelhoogesteger on 15/08/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {CustomWorkflowFilterOptionModel} from '../../../../../models/api/custom-workflow-filter-option.model';
import {CustomWorkflowFilterModel} from '../../../../../models/api/custom-workflow-filter.model';
import {IQueryParam} from '../../../../../classes/query-params';
import {IFilterValue} from './custom-workflow-preview/custom-workflow-preview.component';

export class WorkflowFiltersUtil {

    public static fromActionOptionFilter(filterItems: IFilterValue[]): IQueryParam[] {
        const filters: IQueryParam[] = [];
        for (const filterItem of filterItems) {
            if (filterItem.values.length > 0) {
                filters.push({queryParam: filterItem.queryParam, value: filterItem.values});
            }
        }
        return filters;
    }

    /**
     * Get query param filters from the workflow filter options
     * @param {Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>} filterMap
     * @returns {IQueryParam[]}
     */
    public static fromActiveFilters(filterMap: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>): IQueryParam[] {
        const filters: IQueryParam[] = [];
        if (filterMap) {
            filterMap.forEach((values: CustomWorkflowFilterOptionModel[], key: CustomWorkflowFilterModel) => {
                if (values.length > 0) {
                    filters.push({queryParam: key.queryParam, value: values.map((value: CustomWorkflowFilterOptionModel) => value.key)});
                }
            });
        }

        return filters;
    }
}
