import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserModel} from '../../../../models/api/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {RolesService} from '../../../../api/services/roles.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {UserService} from '../../../../api/services/users.service';
import {RoleDetailContainerService} from '../role-detail-container.service';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {ESortOrder, ISortOptionEvent, ITableColumn} from '@relayter/rubber-duck';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';

@Component({
    selector: 'rl-role-users-component',
    templateUrl: 'role-users.component.html',
    styleUrls: ['role-users.component.scss'],
    providers: [PaginatorService]
})

export class RoleUsersComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'role-users-overview';
    public roleId: string;
    public items: UserModel[] = [];
    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            sortProperty: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            sortProperty: 'email'
        }
    ];

    public isLoading: boolean = false;
    public pageIndex: number;
    public pageSize: number;
    public total: number;
    private onDestroySubject = new Subject<void>();
    public usersSubscription = new Subscription();
    public disableNextPage = true;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private rolesService: RolesService,
                private roleDetailContainerService: RoleDetailContainerService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.roleId = this.roleDetailContainerService.getRoleId();

        this.subscribeToRouteParams();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();

                this.getUsersForRole();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private subscribeToRouteParams(): void {
        this.route.params.pipe(takeUntil(this.onDestroySubject)).subscribe(params => {
            const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            const sortProperty = params['sortProperty'];
            const sortOrder = params['sortOrder'] ? params['sortOrder'] : null;
            const searchValue = params['search'] ? params['search'] : null;

            // Only on change get new data
            if (pageIndex !== this.pageIndex ||
                sortProperty !== this.sortProperty ||
                sortOrder !== this.sortOrder ||
                searchValue !== this.searchValue) {
                this.pageIndex = pageIndex;
                this.sortProperty = sortProperty;
                this.sortOrder = sortOrder;
                this.searchValue = searchValue;

                this.setPageIndex(this.pageIndex);
            }

            this.updateUrl();
        });
    }

    private updateUrl(): void {
        this.router.navigate([this.getMatrixUrl()], {relativeTo: this.route, replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    /**
     * Get users for role with roleId
     */
    private getUsersForRole(): void {
        if (!this.roleId || !this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ROLE_USERS)) return;

        if (this.usersSubscription && !this.usersSubscription.closed) {
            this.usersSubscription.unsubscribe();
        }

        if (this.roleId) {
            this.usersSubscription = this.rolesService.getUsersForRole(this.roleId,
                this.pageSize,
                (this.pageIndex - 1) * this.pageSize,
                this.sortProperty,
                this.sortOrder,
                this.searchValue
            ).pipe(takeUntil(this.onDestroySubject))
                .subscribe( // Front end page index starts from 1 instead of 0, api starts from 0.
                (users: ARPagedResponseDataModel<UserModel>) => {
                    this.total = users.total;
                    this.items = users.items;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.total;
                },
                Toaster.handleApiError
            );
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }
}
