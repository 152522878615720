import {RLBaseComponent} from './rl-base.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {ITableConfigStorage} from '../../api/services/table-config.service';
import {SortDirection} from '@angular/material/sort';

export abstract class RLTableComponent extends RLBaseComponent implements ITableConfigStorage {
    public abstract tableId: string;
    public readonly storageKey: string;
    public searchValue: string = '';
    public sortProperty: string;
    public sortOrder: SortDirection;
    // Filters setup
    public filterValues: Record<string, any>;

    protected constructor(private userSettingsStorageService: UserSettingsStorageService) {
        super();
        this.storageKey = userSettingsStorageService.getPrefixStorageKey();
    }

    public getRequestOptions(): Record<string, any> {
        return {
            searchValue: this.searchValue,
            sortProperty: this.sortProperty,
            sortOrder: this.sortOrder,
            filterValues: this.filterValues
        };
    }
}
