/**
 * custom-workflow-action.model
 * app-relayter-web
 * Created by borisnelissen on 12/02/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {autoserialize} from 'cerialize';
import {EDataFieldTypes} from '../../app.enums';
import {ITableItem} from '@relayter/rubber-duck';

export class CustomWorkflowFilterModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public queryParam: string;
    @autoserialize public custom: boolean;
    @autoserialize public dataType: EDataFieldTypes;
    @autoserialize public items: Record<string, any>;
}
