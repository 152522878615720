import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService} from '@relayter/rubber-duck';
import {ERulesetPropertyType, RulePropertyModel} from '../../../models/api/rule-property.model';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {BooleanOption, ConditionType, FormatOption, FormatRulesetConstants, PropertyOperator} from '../format-ruleset.constants';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {RuleConditionModel} from '../../../models/api/rule-condition.model';
import {ValueModel} from '../models/api/ruleset-value.model';
import {v4} from 'uuid';
import {IFormatRulesetItemFormComponentData} from '../format-ruleset-item-form/format-ruleset-item-form.component';
import {EDataCollectionName, EDataFieldTypes, RULESET_OPERATORS} from '../../../app.enums';
import {IFormatRulesetAssetItemFormComponentData} from '../format-ruleset-asset-item-form/format-ruleset-asset-item-form.component';
import {FormatRulesetAssetItemModel} from '../models/api/format-ruleset-asset-item.model';
import {format as dateFormatter} from 'date-fns-tz/esm';
import {nl as DEFAULT_LOCALE} from 'date-fns/locale';
import {ARLogger} from '@relayter/core';
import {AppConstants} from '../../../app.constants';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {Toaster} from '../../../classes/toaster.class';
import {MinOptionalNumberValidator} from '../../../classes/validators/min-optional-number.validator';
import {ConditionGroupsModel} from '../../../models/api/condition-groups.model';
import {ConditionGroupModel} from '../../../models/api/condition-group.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ConditionGroup, ConditionGroups} from './condition-group-form/condition-group-form.component';

interface FormGroupNameMap {
    formGroup: FormGroup;
    operator: string;
    name: string;
}
export enum ERulesetContext {
    ITEMS,
    ASSET_ITEMS
}

export interface IFormatRulesetComponentData {
    context: ERulesetContext;
}

@Directive()
export abstract class BaseRulesetItemFormComponent implements OnInit, OnDestroy {
    public nameControl = new FormControl('', Validators.required);
    public itemControl = new FormControl(null, Validators.required);
    public conditions: FormGroupNameMap[] = [];
    public conditionGroups = new ConditionGroups(null, [(control: AbstractControl) => {
        const groups = control['groups'] as FormArray;
        for (const group of groups.controls) {
            const rules = group['rules'] as FormArray;
            for (const rule of rules.controls) {
                if (rule.invalid) {
                    return {message: 'All groups need to be valid.'};
                }
            }
        }
        return null;
    }]);

    public valuesGroup = new FormGroup({});
    public values: FormGroupNameMap[] = [];

    protected onDestroySubject = new Subject<void>();
    private saveButtonConfig: ButtonConfig;

    public ruleSetProperties: RulePropertyModel[];
    public valueProperties: RulePropertyModel[];

    public formGroup: FormGroup;
    public modalData: IFormatRulesetItemFormComponentData | IFormatRulesetAssetItemFormComponentData;

    public tags: DropdownItem<string>[] = [];
    public DATE_FORMAT = FormatOption.DATE_FORMAT.getValue();
    public TO_STRING = FormatOption.TO_STRING.getValue();

    public layerOptions: DropdownItem<string>[];
    private layerProperty: string;
    public searchLayer: string;
    public totalLayers: number;

    public static GROUP_OPTIONS: IDropdownItem[] = [
        new DropdownItem('AND', RULESET_OPERATORS.AND, false, 'nucicon_code-and'),
        new DropdownItem('OR', RULESET_OPERATORS.OR, false, 'nucicon_code-or')];

    protected constructor(protected fullModalService: FullModalService,
                          protected dataCollectionService: DataCollectionService) {
    }

    // TODO: Line up DropDownItems
    private static getConditionValue(condition?: RuleConditionModel, dataType?: string):
        boolean | string | number | Date | DropdownItem<string | number | boolean> {
        switch (condition?.type) {
            case ConditionType.LEADING_LENGTH.getValue():
            case ConditionType.LEADING_LENGTH_GREATER_THAN.getValue():
            case ConditionType.LENGTH_GREATER_THAN.getValue():
            case ConditionType.LENGTH.getValue():
            case ConditionType.LOWER_OR_EQUAL.getValue():
            case ConditionType.LOWER_THAN.getValue():
            case ConditionType.GREATER_OR_EQUAL.getValue():
            case ConditionType.GREATER_THAN.getValue():
                return condition.value;
            case ConditionType.NOT_EQUALS.getValue():
            case ConditionType.EQUALS.getValue():
            case ConditionType.INCLUDES.getValue():
            case ConditionType.NOT_INCLUDES.getValue():
                // Prevent compiler error, but value is always not a Date for type ENUM
                if (dataType === EDataFieldTypes.ENUM && !(condition.value instanceof Date)) {
                    if (condition.operator === PropertyOperator.LENGTH.getValue()) {
                        return condition.value;
                    } else {
                        return new DropdownItem(condition.value as string, condition.value);
                    }
                } else if (dataType === EDataFieldTypes.BOOLEAN) {
                    return condition.value ? BooleanOption.TRUE : BooleanOption.FALSE;
                }
                return condition.value;
            case ConditionType.EXISTS.getValue():
            case ConditionType.NOT_EXISTS.getValue():
                break;
        }
    }

    public ngOnInit(): void {
        this.layerProperty = this.modalData.context === ERulesetContext.ITEMS ? 'items.layer' : 'assetItems.layer';

        this.getLayers();
        this.initButtons();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        this.saveButtonConfig =
            new ButtonConfig(BUTTON_TYPE.PRIMARY, this.modalData.item ? 'Save' : 'Create', false,
                false, this.formGroup.status !== 'VALID');
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const saveAction = new FullModalActionModel(this.saveButtonConfig);
        const cancelAction = new FullModalActionModel(cancelButtonConfig);
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveRule());

        this.fullModalService.setModalActions(actions);
    }

    protected initForm(): void {
        this.valueProperties = this.modalData.ruleSetProperties;
        // TODO: Use new data structure, now it is converted to the old structure for conditions
        this.ruleSetProperties = this.convertRulesetProperties(this.valueProperties);
        if (this.modalData.item) {

            if (!(this.modalData.item instanceof FormatRulesetAssetItemModel)) {
                this.modalData.item.values.forEach((value) => {
                    this.addValueGroup(value);
                });
            }
            this.conditionGroups.setOperator(BaseRulesetItemFormComponent.GROUP_OPTIONS.find(
                option => option.getValue() === this.modalData.item.conditions.operator));
            this.modalData.item.conditions.groups.forEach((condition) => {
                const conditionGroup = new ConditionGroup(BaseRulesetItemFormComponent.GROUP_OPTIONS.find(
                    option => option.getValue() === condition.operator));
                for (const rule of condition.rules) {
                    this.addConditionGroup(rule, conditionGroup);
                }
                this.conditionGroups.addGroup(conditionGroup);
            });
        }

        this.saveButtonConfig.disabled = this.formGroup.status !== 'VALID';

        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            takeUntil(this.onDestroySubject)
        ).subscribe((valid) => this.saveButtonConfig.disabled = !valid);

        this.itemControl.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((libraryItem: any) => {
            this.tags = libraryItem?.tags.map((tag) => new DropdownItem<string>(tag, tag)) || [];
            this.values.forEach((item) => {
                const tagControl = item.formGroup.get('tag');
                const tagValue = tagControl.value?.getValue();

                if (!this.tags.find((tag) => tagValue === tag.getValue())) {
                    tagControl.patchValue(null);
                }
            });
        });
    }

    public searchLayers(searchValue): void {
        if (this.searchLayer !== searchValue) {
            this.searchLayer = searchValue;
            this.layerOptions = [];

            this.getLayers();
        }
    }

    public getLayers(offset = 0): void {
        this.dataCollectionService.getDataCollectionValues(EDataCollectionName.INDESIGN_RULE_SET,
            this.layerProperty, null, AppConstants.PAGE_SIZE_DEFAULT, offset, this.searchLayer)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((results) => {
                const layers = results.items.map(item => new DropdownItem<string>(item.title, item.value));
                this.layerOptions = this.layerOptions?.concat(layers) || layers;
                this.totalLayers = results.total;
            }, Toaster.handleApiError);
    }

    protected getConditions(): ConditionGroupsModel {
        return new ConditionGroupsModel(this.conditionGroups.operator.value.getValue(),
            this.conditionGroups.groups.controls.map((group: ConditionGroup) => {
                return new ConditionGroupModel(group.operator.value.getValue(),
                    group.rules.controls.map((rule) => {
                        const property = rule.value.property.getValue();
                        const operator = rule.value.operator?.getValue();
                        const type = rule.value.type?.getValue();
                        const value = rule.value.value instanceof DropdownItem ?
                            rule.value.value.getValue() : rule.value.value;
                        const dataType = rule.value.property.getDataType() === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : null;

                        return new RuleConditionModel(property,
                            type,
                            value,
                            operator,
                            dataType
                        );
                    }));
            }));
    }

    protected getValues(): ValueModel[] {
        return this.values.map((valueMap) => {
            return new ValueModel(valueMap.formGroup.value.tag.getValue(), valueMap.formGroup.value.property.reduce((acc, value) => {
                    if (value instanceof RulePropertyModel) {
                        if (!acc) {
                            return value.getValue();
                        }
                        return `${acc}.${value.getValue()}`;
                    }
                    if (value !== null) {
                        if (!acc) {
                            return `${value - FormatRulesetConstants.MIN_VALUE_ARRAY_INDEX}`;
                        } else {
                            return `${acc}.${value - FormatRulesetConstants.MIN_VALUE_ARRAY_INDEX}`;
                        }
                    }
                    return acc;
                }, ''),
                valueMap.formGroup.value.format ? valueMap.formGroup.value.format.getValue() : undefined,
                valueMap.formGroup.value.formatString ? valueMap.formGroup.value.formatString : undefined);
        });
    }

    public addConditionGroup(condition?: RuleConditionModel, parent?: ConditionGroup): void {

        const property = this.ruleSetProperties.find(prop => prop.getValue() === condition?.property);
        const conditionGroup = new FormGroup({
            property: new FormControl(property, Validators.required),
            operator: new FormControl(PropertyOperator.getByValue(condition?.operator)),
            type: new FormControl(ConditionType.getByValue(condition?.type)),
            value: new FormControl(BaseRulesetItemFormComponent.getConditionValue(condition, property?.dataType.type)),
        }, [(control: AbstractControl) => {

            if (control.value.property?.isArray && !control.value.operator) {
                return {valueRequired: 'Operator is required for this property'};
            }

            if (control.value.operator?.typeRequired && !control.value.type) {
                return {valueRequired: 'Type is required for this operator'};
            }

            if (control.value.type?.valueRequired && (control.value.value === undefined || control.value.value === null ||
                control.value.value === '')) {
                return {valueRequired: 'Value is required for this type'};
            }

            return null;
        }, Validators.required]);

        parent.addRule(conditionGroup);
    }

    public addValueGroup(value?: ValueModel): void {
        const name = `value-${v4()}`;

        const valueGroup = new FormGroup({
            tag: new FormControl(null, Validators.required),
            property: new FormArray([], Validators.required),
            format: new FormControl(null),
            formatString: new FormControl(null)
        }, [(control: AbstractControl) => {
            const formatValue = control.value.format?.getValue();
            switch (formatValue) {
                case this.DATE_FORMAT: {
                    // Format string required
                    if (control.value.formatString === undefined || control.value.formatString === null || control.value.formatString === '') {
                        return {valueRequired: 'Format string is required for the date format'};
                    }

                    // Valid format string
                    try {
                        const options = {
                            timeZone: AppConstants.DEFAULT_TIMEZONE,
                            locale: DEFAULT_LOCALE,
                            useAdditionalWeekYearTokens: true,
                            useAdditionalDayOfYearTokens: true
                        };
                        dateFormatter(new Date(), control.value.formatString, options);
                    } catch (error) {
                        ARLogger.error(error.message);
                        return {invalidFormatString: 'Format string is not valid'};
                    }
                    break;
                }
                case this.TO_STRING: {
                    // Separator required
                    if (control.value.formatString === undefined || control.value.formatString === null || control.value.formatString === '') {
                        return {valueRequired: 'Separator is required for the to string format'};
                    }
                    break;
                }
            }

            if (control.value.property && control.value.property[control.value.property.length - 2]?.isArray &&
                !control.value.property[control.value.property.length - 1] && formatValue !== this.TO_STRING) {
                return {valueRequired: 'For array properties without selected index the \'To string\' formatter is required'};
            }

            return null;
        }, Validators.required]);

        this.valuesGroup.addControl(name, valueGroup);
        this.values.push({name, formGroup: valueGroup} as FormGroupNameMap);

        if (value) {
            const propertyPatchValue = this.getPropertyPatchValue(value.property);
            propertyPatchValue.forEach((property, index) => {
                const validators = [];
                if (!isNaN(+property)) {
                    validators.push(MinOptionalNumberValidator(1));
                    if (propertyPatchValue[index + 1]) {
                        validators.push(Validators.required);
                    }
                } else {
                    validators.push(Validators.required);
                }

                (valueGroup.get('property') as FormArray).push(new FormControl('', validators));
            });
            valueGroup.patchValue({
                tag: this.tags.find((tag) => tag.getValue() === value.tag),
                property: propertyPatchValue,
                format: FormatOption.getByValue(value.format),
                formatString: value.formatString
            }, {emitEvent: false});
        }
    }

    public addNewConditionGroup(): void {
        const newConditionGroup = new ConditionGroup();
        this.addConditionGroup(null, newConditionGroup);
        this.conditionGroups.addGroup(newConditionGroup);
    }

    private getPropertyPatchValue(value: string): (RulePropertyModel | number)[] {
        const values: (RulePropertyModel | number)[] = [];
        const properties = value.split('.');
        let searchProperties = this.valueProperties;
        let searchValue = '';
        for (const property of properties) {
            searchValue = searchValue ? `${searchValue}.${property}` : property;
            if (isNaN(+property)) {
                const rulesetProperty = searchProperties.find((valueProperty) => valueProperty.getValue() === searchValue);
                if (rulesetProperty) {
                    searchProperties = rulesetProperty.properties || [];
                    searchValue = '';
                    values.push(rulesetProperty);
                    if (rulesetProperty.isArray) {
                        values.push(null);
                    }
                }
            } else {
                searchValue = '';
                values.splice(values.length - 1, 1, +(property) + FormatRulesetConstants.MIN_VALUE_ARRAY_INDEX);
            }
        }

        return values;
    }

    public deleteValueClicked(name: string) {
        this.values = this.values.filter((group) => group.name !== name);
        this.valuesGroup.removeControl(name);
    }

    private convertRulesetProperties(properties: RulePropertyModel[], start: RulePropertyModel[] = [],
                                     previous: RulePropertyModel = null): RulePropertyModel[] {
        return properties.reduce((acc, property) => {
            const ruleSetProperty = new RulePropertyModel();
            ruleSetProperty.name = property.name;
            ruleSetProperty.key = property.key;
            const ruleSetProperties = property.properties;
            ruleSetProperty.type = property.type;
            ruleSetProperty.isArray = property.isArray;
            ruleSetProperty.dataType = property.dataType;

            if (previous) {
                ruleSetProperty.name = `${previous.name}.${ruleSetProperty.name}`;
                // For array entities, we only look at the first entry for conditions
                ruleSetProperty.key = `${previous.key}${previous.type === 'entity' && previous.isArray ? '.0' : ''}.${ruleSetProperty.key}`;
            }
            if (ruleSetProperties) {
                // When this entity is an array, we can apply array operators and the property needs its own entry in the list
                if (ruleSetProperty.isArray && ruleSetProperty.type === ERulesetPropertyType.ENTITY) {
                    acc.push(ruleSetProperty);
                }
                acc = this.convertRulesetProperties(ruleSetProperties, acc, ruleSetProperty);
            } else {
                acc.push(ruleSetProperty);
            }
            return acc;
        }, start);
    }

    /**
     * @override
     * @protected
     */
    protected abstract saveRule();
}
