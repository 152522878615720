import {inject} from '@angular/core';
import {CampaignModel} from '../../../models/api/campaign.model';
import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {CampaignService} from '../../../api/services/campaigns.service';
import {PublicationModel} from '../../../models/api/publication.model';
import {Observable, of} from 'rxjs';
import {PublicationsService} from '../../../api/services/publications.service';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {Toaster} from '../../../classes/toaster.class';

export const campaignResolver: ResolveFn<CampaignModel> =
    (route: ActivatedRouteSnapshot) => {
        const router = inject(Router);
        return inject(CampaignService).getCampaignById(route.paramMap.get('campaign_id'))
            .pipe(
                catchError((error) => {
                    router.navigate([AppConstants.CAMPAIGN_PATH]);
                    Toaster.handleApiError(error);
                    return of(null);
                }));
    };

export const publicationResolver: ResolveFn<PublicationModel> =
    (route: ActivatedRouteSnapshot): Observable<PublicationModel> => {
        const router = inject(Router);
        const campaignId = route.parent.paramMap.get('campaign_id');
        const publicationId = route.paramMap.get('publication_id');
        return inject(PublicationsService).getPublicationDetails(campaignId, publicationId)
            .pipe(
                catchError((error) => {
                    router.navigate([AppConstants.CAMPAIGN_PATH, campaignId, AppConstants.CONTEXT_URL.PUBLICATIONS]);
                    Toaster.handleApiError(error);
                    return of(null);
                }));
    }
