<ng-container *ngIf="permissions.GET_PACKAGES | userIsAllowedTo; else noPermission">
    <ng-container *ngIf="package">
        <div class="header">
            <h1>{{package.name}}</h1>
            <nuc-button-bar>
                <nuc-button-secondary
                    *ngIf="permissions.PUT_PACKAGES_ITEMS | userIsAllowedTo"
                    text="Refresh package data"
                    (click)="openRefreshConfirmationDialog()">
                </nuc-button-secondary>
            </nuc-button-bar>
        </div>

        <nuc-table [items]="items"
                   [columns]="columns"
                   [selectionMode]="selectionMode"
                   [loading]="!subscription?.closed"
                   [useEmptyState]="true"
                   emptyStateTitle="No package items"
                   [tableId]="tableId"
                   useButtonBar="true"
                   [prefixStorageKey]="storageKey"
                   [actions]="allowedActions"
                   [editMode]="!tableLocked"
                   (itemEdited)="onItemEdited($event)"
                   (sortOptionChange)="onSortOptionChanged($event)"
                   (actionClicked)="handleAction($event)">
            <ng-container button-bar-left>
                <nuc-button-secondary *ngIf="permissions.PUT_PACKAGES_ITEMS | userIsAllowedTo"
                                      [icon]="tableLocked ? 'nucicon_lock_fill': 'nucicon_unlock'"
                                      [disabled]="!items?.length || !subscription?.closed"
                                      (mouseenter)="unlockTableTooltipText = tableLocked ? 'Unlock table' : 'Lock table'"
                                      (click)="tableLocked = !tableLocked"
                                      [nucTooltip]="unlockTableTooltipText">
                </nuc-button-secondary>
            </ng-container>
            <ng-container button-bar-right>
                <nuc-badge-warning @unlockedBadgeAnimation class="table-unlocked-label" *ngIf="!tableLocked">Table data is unlocked</nuc-badge-warning>
                <paginator [viewId]="tableId"
                           [disableNextPage]="!hasNextPage"
                           [loading]="!subscription?.closed">
                </paginator>
            </ng-container>
        </nuc-table>
    </ng-container>

</ng-container>

<ng-template #noPermission>
    <nuc-empty-state-component
        imageUrl="assets/images/empty_states/no_permission.svg"
        title="No permission"
        subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
