import {Component, Input, OnInit} from '@angular/core';
import {EToastType, ToastDataModel} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {ETransitionStatus, TransitionItemModel} from '../../../models/api/transition-item.model';
import {MonitoredTransitionsService} from '../../../api/services/monitored-transitions.service';
import {startWith} from 'rxjs/operators';
import {UserService} from '../../../api/services/users.service';
import {UserModel} from '../../../models/api/user.model';
import {ErrorConstants} from '../../../api/error.constants';

@Component({
    selector: 'transition-notification',
    styleUrls: ['transition-notification.component.scss'],
    templateUrl: 'transition-notification.component.html',
})
export class TransitionNotificationComponent implements OnInit {
    @Input() public transitionInput: TransitionItemModel;

    private toastData: ToastDataModel;
    private user: UserModel;

    /**
     * @param {MonitoredTransitionsService} monitoredTransitionsService
     * @param {UserService} userService
     */
    constructor(private monitoredTransitionsService: MonitoredTransitionsService,
                private userService: UserService) {
        this.user = this.userService.getLoggedInUser();
    }

    /**
     * Angular lifecycle method
     * Starts the monitoring of the transition
     */
    public ngOnInit(): void {
        this.toastData = new ToastDataModel(EToastType.NEUTRAL, 'Transition', 'Queued', true);
        // Only show toasts for the current user
        if (this.transitionInput.user === this.user._id) {
            Toaster.openToast(this.toastData);
        }

        this.monitoredTransitionsService.getTransitionMonitor(this.transitionInput._id)
            .pipe(startWith(this.transitionInput))
            .subscribe({
                next: (transitionItem) => this.updateToastData(transitionItem),
                error: (error) => ARLogger.error(`transitionNotification: ${error}`),
                complete: () => this.monitoredTransitionsService.removeMonitoredTransition(this.transitionInput._id)
            });
    }

    private updateToastData(transitionItem: TransitionItemModel): void {
        switch (transitionItem.status) {
            case ETransitionStatus.QUEUED:
                this.toastData.type = EToastType.NEUTRAL;
                this.toastData.message = 'Successfully scheduled transition of publication item(s)';
                this.toastData.loading = true;
                break;
            case ETransitionStatus.IN_PROGRESS:
                this.toastData.type = EToastType.NEUTRAL;
                this.toastData.message = 'Transition in progress';
                this.toastData.loading = true;
                break;
            case ETransitionStatus.FAILED:
                // TODO: We need to find a better way to distinguish user errors (404) and inter server errors (500)
                this.toastData.type = EToastType.ERROR;
                this.toastData.title = transitionItem.error?.title || this.toastData.title;
                this.toastData.message = transitionItem.error?.message || 'Transition failed';
                // Toaster will disappear when there is useful error message to show
                this.toastData.loading = !(this.toastData.message === 'Transition failed' ||
                    this.toastData.message === ErrorConstants.INTERNAL_SERVER_ERROR_MESSAGE);
                break;
            case ETransitionStatus.DONE:
                // When transition is DONE and started by the user, show notification
                // For all other users let them know there is a transition done, and they should refresh the publication items
                if (transitionItem.user === this.user._id) {
                    this.toastData.type = EToastType.SUCCESS;
                    this.toastData.loading = false;
                    this.toastData.message ='Transition completed';
                } else {
                    this.monitoredTransitionsService.workflowStepItemsChanged(transitionItem);
                }
                break;
            default:
                ARLogger.error(`TransitionNotification: Unhandled transition status ${transitionItem.status}`);
        }
    }
}
