<ng-container [ngSwitch]="notification.type">

    <ng-container *ngSwitchCase="ENotificationType.DOWNLOAD">
        <div class="icon"
             [class.failed]="notification.data.jobStatus === EJobStatus.FAILED"
             [class.done]="notification.data.jobStatus === EJobStatus.DONE"
             [class.loading]="notification.data.jobStatus === EJobStatus.IN_PROGRESS">

            <div class="background"></div>

            <i class="nucicon_download"></i>
        </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <rl-user-icon-component [user]="notification.sender ? notification.sender : {fullName: 'Placeholder H.'}"></rl-user-icon-component>
    </ng-container>
</ng-container>

<div>
    <p class="body-strong">{{notification.title}}</p>
    <p class="body-strong">{{notification.createdAt | RLDatePipe: showFullDate ? RLDatePipe.dateFormats.NOTIFICATION_FULL : RLDatePipe.dateFormats.NOTIFICATION_TIME}}</p>

    <ng-container *ngIf="selected; else closed">
        <p>{{notification.message}}</p>
        <p *ngFor="let details of notification.data?.details" class="detail">{{details}}</p>
        <p *ngIf="notification.type === ENotificationType.DOWNLOAD && notification.data.jobStatus === EJobStatus.DONE">
            Download expires: {{notification.expires | expiresIn}}</p>
    </ng-container>

    <ng-template #closed>
        <p>{{notification.message | firstSentence}}</p>
    </ng-template>

</div>

<div class="actions">

    <ng-container *ngFor="let action of notification.actions">

        <nuc-button-secondary *ngIf="action.type === ENotificationActionType.DOWNLOAD; else defaultAction"
                              [icon]="action.type | notificationIcon"
                              [disabled]="!action.url"
                              [nucTooltip]="action.title"
                              (click)="actionClicked.emit({notification: notification, action: action}); $event.stopPropagation()">
        </nuc-button-secondary>

        <ng-template #defaultAction>
            <nuc-button-secondary [icon]="action.type | notificationIcon"
                                  [nucTooltip]="action.title"
                                  (click)="actionClicked.emit({notification: notification, action: action}); $event.stopPropagation()">
            </nuc-button-secondary>
        </ng-template>

    </ng-container>

</div>


<div *ngIf="notification.status === ENotificationStatus.UNREAD" class="unread-indicator"></div>
<nuc-button-secondary *ngIf="selected && notification.status !== ENotificationStatus.ARCHIVED"
                      (click)="archiveButtonClicked.emit(); $event.stopPropagation()"
                      class="close-button"
                      nucTooltip="Move to archive"
                      icon="nucicon_files_line">
</nuc-button-secondary>
