<div class="container">
    <div class="controls" *ngIf="publicationItems?.length">
        <nuc-button-secondary icon="nucicon_add"
                              nucTooltip="Zoom in"
                              nucTooltipPosition="right"
                              [disabled]="isLoading"
                              (click)="sliderValue = Math.min(sliderValue + ZOOM_STEP, MAX_CARD_SIZE)">
        </nuc-button-secondary>

        <mat-slider vertical="true"
                    [min]="MIN_CARD_SIZE"
                    [max]="MAX_CARD_SIZE"
                    step="1"
                    [value]="sliderValue"
                    (input)="sliderValue = $event.value"
                    [disabled]="isLoading">
        </mat-slider>

        <nuc-button-secondary icon="nucicon_remove"
                              nucTooltip="Zoom out"
                              nucTooltipPosition="right"
                              [disabled]="isLoading"
                              (click)="sliderValue = Math.max(sliderValue - ZOOM_STEP, MIN_CARD_SIZE)">
        </nuc-button-secondary>

        <nuc-button-secondary icon="nucicon_images_line"
                              nucTooltip="Default size"
                              nucTooltipPosition="right"
                              [disabled]="isLoading"
                              (click)="sliderValue = DEFAULT_CARD_SIZE">
        </nuc-button-secondary>
    </div>

    <div class="items-container">
        <nuc-card-view *ngFor="let item of publicationItems"
                       [hoverImage]="false"
                       [hoverCard]="true"
                       [itemWidth]="sliderValue"
                       [title]="item | publicationItemDisplay:EPublicationDisplayProperties.TITLE"
                       [subTitle]="item | publicationItemDisplay:EPublicationDisplayProperties.SUBTITLE"
                       [image]="item.getVariantFiles(activeVariant?._id)?.thumb">
            <div content>
                <ng-container *ngIf="!item.inTransition && item.step && item.step.name; else inTransition">
                    <span>Step: {{item.step.name}}</span>
                </ng-container>
                <ng-template #inTransition>
                    <ng-container *ngIf="item.inTransition || !item.step">
                        <span>In Transition</span>
                    </ng-container>
                </ng-template>
            </div>
            <div footer class="footer">
                <nuc-button-secondary *ngIf="arrangePagesAction"
                                      icon="nucicon_compare" nucTooltip="Arrange pages"
                                      (click)="openArrangeForm(item)">
                </nuc-button-secondary>

                <ng-container *ngIf="item.step && !item.inTransition">
                    <nuc-button-secondary
                        *ngFor="let action of itemTransitionActions[item._id]"
                        [icon]="action.icon" [nucTooltip]="action.title"
                        (click)="handleAction(action, [item])">
                    </nuc-button-secondary>
                </ng-container>
            </div>
        </nuc-card-view>
    </div>

    <div class="detail-container" *ngIf="swapping">
        <div class="detail-header">
            <p class="section-header">Move items</p>
            <nuc-button-secondary (click)="closeArrangeForm()" icon="nucicon_close"></nuc-button-secondary>
        </div>

        <form [formGroup]="formGroup">
            <nuc-form-field label="Which item">
                <nuc-dropdown formControlName="item"
                              [total]="total"
                              (requestData)="getPublicationItems()"
                              [items]="publicationItems"
                              placeholder="Choose item"
                              required>
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="To where">
                <nuc-dropdown formControlName="where"
                              [total]="total"
                              (requestData)="getPublicationItems()"
                              [items]="publicationItems"
                              placeholder="Choose location"
                              required>
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="How">
                <nuc-radiobutton formControlName="moveType"
                                 [dataItems]="moveTypes"
                                 radiobuttonPosition="vertical"
                                 required>
                </nuc-radiobutton>
            </nuc-form-field>

        </form>

        <nuc-button-bar>
            <nuc-button-secondary
                text="Cancel"
                [disabled]="arrangePagesJobSubscription && !arrangePagesJobSubscription.closed"
                (click)="closeArrangeForm()">
            </nuc-button-secondary>
            <nuc-button-primary text="Confirm"
                                [loading]="arrangePagesJobSubscription && !arrangePagesJobSubscription.closed"
                                [disabled]="!formGroup.valid || (arrangePagesJobSubscription && !arrangePagesJobSubscription.closed) || itemControl.value === whereControl.value"
                                (click)="onRearrangeClicked()">
            </nuc-button-primary>
        </nuc-button-bar>
    </div>
</div>

<nuc-button-bar class="button-bar-footer">
    <nuc-button-secondary *ngIf="publicationItems.length"
                          nucTooltip="Show more available items"
                          text="Show more"
                          (click)="getPublicationItems()"
                          [loading]="isLoading"
                          [disabled]="!hasNext || isLoading">
    </nuc-button-secondary>
</nuc-button-bar>

<nuc-empty-state-component
    *ngIf="!publicationItems.length && !isLoading"
    imageUrl="assets/images/empty_states/overview.svg"
    title="No publication items"
    subtitle="{{!!modalData | conditionalValuePipe:'You have to create items first.':'Move publication items to this step.'}}">
</nuc-empty-state-component>

<rl-loading-indicator [hide]="!isLoading || publicationItems.length > 0"></rl-loading-indicator>
