/**
 *  error-formatter-util
 *  app-relayter
 *  Created by nandamhuis on 11/07/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

import {ARApiError} from '@relayter/core';
import {ErrorConstants} from '../api/error.constants';
import {AppConstants} from '../app.constants';
import {environment} from '../../environments/environment';

export class ErrorFormatterUtil {

    public static formatError(error: ARApiError): string {
        if (error?.details) return error.details;

        const key = ErrorFormatterUtil.getErrorKeyByValue(error.code);
        const lang = localStorage.getItem(AppConstants.LOCALSTORAGE_LOCALE) || environment.DEFAULT_LOCALE;
        const localeErrors = ErrorConstants.API_ERROR_MESSAGES[lang];
        return localeErrors && localeErrors[key] ? localeErrors[key] : ErrorConstants.INTERNAL_SERVER_ERROR_MESSAGE;
    }

    public static getErrorKeyByValue(value: number): string {
        return Object.keys(ErrorConstants.API_ERROR_CODES).find((key) => ErrorConstants.API_ERROR_CODES[key] === value);
    }
}
