import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ETabFormGroup} from '../assign-to-package-form.component';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {PackageModel} from '../../../../../../../../models/api/package.model';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {PackagesService} from '../../../../../../../../api/services/packages.service';
import {UserIsAllowedToPipe} from '../../../../../../../../pipes/user-is-allowed-to.pipe';
import {Subject, Subscription} from 'rxjs';
import {ESelectionMode, ITableColumn, ISortOptionEvent, ESortOrder} from '@relayter/rubber-duck';
import {AssignToPackageService} from '../assign-to-package.service';
import {takeUntil} from 'rxjs/operators';
import {RLTableComponent} from '../../../../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../../../../api/services/user-settings-storage.service';
import {SelectionModel} from '@angular/cdk/collections';
import {PaginatorService} from '../../../../../../../../components/paginator/paginator.service';
import {CursorArray} from '../../../../../../../../api/api-cursor';

@Component({
    selector: 'assign-to-package-packages-form-component',
    templateUrl: 'assign-to-package-packages-form.component.html',
    styleUrls: ['assign-to-package-packages-form.component.scss'],
    providers: [PaginatorService]
})

export class AssignToPackagePackagesFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    public static readonly CONTROL_NAME = ETabFormGroup.PACKAGES;
    public readonly ESelectionMode = ESelectionMode;
    public readonly tableId = 'assign-to-package-packages-table';

    @Input() public form: UntypedFormGroup;
    @Input() public campaignId: string;
    @Input() public publicationItemId: string;

    public packagesSubscription: Subscription;

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name'
        },
        {
            title: 'Package setup',
            key: 'packageSetup.name',
            sortProperty: 'packageSetup.name',
            sortDuplicates: true
        },
        {
            title: 'Description',
            key: 'description',
            sortProperty: 'description',
            sortDuplicates: true
        },
    ];

    public selection = new SelectionModel<string>();
    public selectedIds: string[] = [];
    private get selectedId(): string {
        return this.selection.hasValue() ? this.selection.selected[0] : null;
    }

    public packages: PackageModel[];
    public total: number;
    public pageIndex: number;
    public pageSize: number;
    private sortColumn: ITableColumn;
    private apiCursor: CursorArray;
    public hasNext: boolean;

    public get viewId(): string {
        return this.tableId;
    }

    private onDestroySubject = new Subject<void>();

    constructor(private assignToPackageService: AssignToPackageService,
                private packagesService: PackagesService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initializeSelection();

        this.assignToPackageService.campaignPackage$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe({
            next: (campaignPackage) => this.initializeSelection(campaignPackage ? [campaignPackage._id] : []),
            error: Toaster.handleApiError
        });

        this.initForm();

        this.paginatorService.setPageIndex(this.viewId, 1); // trigger getting data

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getPackages();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initForm(): void {
        if (!this.form.contains(AssignToPackagePackagesFormComponent.CONTROL_NAME)) {
            this.form.addControl(AssignToPackagePackagesFormComponent.CONTROL_NAME, new UntypedFormControl(this.selectedId, Validators.required));
        }
    }

    /**
     * Get paged packages from the api
     */
    private getPackages(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGES)) {
            if (this.packagesSubscription) {
                this.packagesSubscription.unsubscribe();
            }

            const cursor = this.apiCursor.getCursor(this.pageIndex);
            this.packagesSubscription = this.packagesService.getPackagesForCampaign(this.campaignId,
                this.pageSize, null, cursor, this.sortColumn?.sortProperty, this.sortOrder, this.searchValue,
                this.publicationItemId, false)
                .subscribe({
                    next: (res: ARPagedResponseDataModel<PackageModel>) => {
                        this.packages = res.items;
                        if (this.packages.length > 0) {
                            const item = this.packages[this.packages.length -1];
                            this.apiCursor.setCursor(this.pageIndex, this.sortColumn?.sortProperty, item);
                        }
                        this.hasNext = res.hasNext;
                    },
                    error: (err: ARApiError) => Toaster.handleApiError(err)
                });
        }
    }

    private initializeSelection(initialValues: string[] = []): void {
        this.selection = new SelectionModel<string>(false, initialValues);
        this.selection.changed.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.onSelectionChanged());
    }

    public onSortOptionChange(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortColumn = sortEvent.column;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortColumn = null;
            this.sortOrder = null;
        }
        this.paginatorService.setPageIndex(this.viewId, 1);
    }

    public onSelectionChanged(): void {
        this.selectedIds = this.selection.hasValue() ? this.selection.selected : [];
        this.form.get(AssignToPackagePackagesFormComponent.CONTROL_NAME).setValue(this.selectedId);
        this.assignToPackageService.setCampaignPackage(this.packages.find(campaignPackage => campaignPackage._id === this.selectedId));
    }

    public onSearchBarValueUpdated(): void {
        this.paginatorService.setPageIndex(this.viewId, 1);
    }
}
