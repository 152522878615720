import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AssetModel} from '../../../models/api/asset.model';
import {EAssetDisplayProperties} from '../../../pipes/asset-display.pipe';
import {IUploadUpdate} from '../../../components/upload-file-component/upload-file.component';
import {ETabFormGroup} from '../asset-edit-form.component';
import {EUploadStatus}
    from '../../../components/upload-file-component/upload.model';
import {FileTypeUtil} from '../../../classes/file-type.util';

@Component({
    selector: 'asset-assets-form',
    templateUrl: './asset-assets-form.component.html',
    styleUrls: ['./asset-assets-form.component.scss']
})
export class AssetAssetsFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public asset: AssetModel;
    @Output() public assetUploadUpdate = new EventEmitter<IUploadUpdate>();

    public formGroup: UntypedFormGroup;

    public fileTypeCategories = FileTypeUtil.DOWNLOAD_CATEGORIES;

    public EAssetDisplayProperties = EAssetDisplayProperties;

    public ngOnInit(): void {
        this.initForm();
    }

    public initForm(): void {
        if (!this.form.contains(ETabFormGroup.ASSET_EDIT_ASSET)) {
            this.formGroup = new UntypedFormGroup({
                uploadedAsset: new UntypedFormControl()
            });
            this.form.addControl(ETabFormGroup.ASSET_EDIT_ASSET, this.formGroup);
        } else {
            this.formGroup = this.form.get(ETabFormGroup.ASSET_EDIT_ASSET) as UntypedFormGroup;
        }
    }

    public onUploadUpdate(uploadUpdate: IUploadUpdate): void {
        this.formGroup?.get('uploadedAsset')
            .setValue(uploadUpdate && uploadUpdate.status === EUploadStatus.Done ? uploadUpdate : null);
        this.assetUploadUpdate.next(uploadUpdate);
    }

}
