<multi-item-selection-component *ngIf="allColumns"
                                [tableId]="tableId"
                                [columns]="allColumns"
                                [loading]="!assetsSubscription?.closed"
                                [selectedItems]="selectedAssets"
                                (selectedItemsChange)="onSelectionChanged($event)"
                                (onSortOptionChange)="onSortOptionChanged($event)"
                                [items]="allAssets"
                                [disableNextPage]="disableNextPage"
                                [disablePageSizeOptions]="searching"
                                [noResultsTitle]="searching
                                                    ? 'No asset matches your search criteria'
                                                    : showLinkedAssetsOnly
                                                        ? 'There are no assets linked to selected products'
                                                        : 'There are no assets available to add'"
                                [readOnly]="readonly"
                                [actions]="actionTypes"
                                (actionClickedEvent)="actionClicked($event)"
                                selectionTitle="assets">
    <ng-container button-bar-left>
        <nuc-input prefix="nucicon_search" class="search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   [placeholder]="'Search'">
        </nuc-input>
        <nuc-checkbox text="Show assets from linked products" (dataChange)="onCheckboxValueChange($event)"></nuc-checkbox>
        <div class="vertical-line"></div>
        <rl-data-filter
            [addDataFieldsContext]="filterContext"
            [filters]="dataFilters">
        </rl-data-filter>
    </ng-container>
</multi-item-selection-component>
