<ng-container *ngIf="form; else loading">
    <div class="variant-row" *ngIf="variantEnabled">
        <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant</nuc-label>
        <div class="variant-selection">
            <p>Show the variants data fields in: </p>
            <nuc-dropdown class="variant"
                          placeholder="Select variant"
                          [nullOption]=false
                          [formControl]="variantFormControl"
                          [items]="variants">
            </nuc-dropdown>
        </div>
        <hr>
    </div>

    <div class="form-row" *ngFor="let row of form.rows">
        <rl-data-field-input *ngFor="let field of row.fields"
                             [selectedVariant]="variantFormControl.value"
                             [dataField]="field.dataField"
                             [options]="field.options"
                             [controlName]="variantFormControl.value && field.dataField.enableVariants
                                ? field.dataField.fieldName +  '.' + variantFormControl.value.getValue()
                                : field.dataField.fieldName"
                             [form]="dummyFormGroup">
        </rl-data-field-input>

    </div>
</ng-container>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
