import {Component, Inject, Input, OnInit} from '@angular/core';
import {UserModel} from '../../models/api/user.model';
import {UserService} from '../../api/services/users.service';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {AppConstants} from '../../app.constants';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {RolesService} from '../../api/services/roles.service';
import {RoleModel} from '../../models/api/role.model';
import {TeamAccountService} from '../../api/services/team-account.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';

export interface ITransferOwnershipFormData {
    owner: UserModel;
}

@Component({
    selector: 'rl-transfer-ownership-form',
    templateUrl: './transfer-ownership-form.component.html',
    styleUrls: ['./transfer-ownership-form.component.scss']
})
export class TransferOwnershipFormComponent implements OnInit {
    @Input()
    public owner: UserModel;
    public transferOwnershipForm: UntypedFormGroup;
    public validationMessages: Record<string, any>;

    public roles: RoleModel[] = [];
    public users: UserModel[] = [];
    public totalUsers: number;
    public limit: number = AppConstants.PAGE_SIZE_DEFAULT;

    private saveButton: ButtonConfig;

    public usersSubscription: Subscription;
    public rolesSubscription: Subscription;

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private rolesService: RolesService,
                private teamAccountService: TeamAccountService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: ITransferOwnershipFormData) {
        this.owner = modalData.owner;
    }

    /**
     * On Init get users get roles and init form
     */
    public ngOnInit(): void {
        this.getUsers(this.limit, 0);
        this.getRoles();
        this.initModalButtons();
        this.initForm();
    }

    /**
     * Init form fields and validation messages
     */
    public initForm(): void {
        this.transferOwnershipForm = this.fb.group({
            newOwner: ['', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            newRoles: ['', RLValidatorConstants.VALIDATOR_SETS.REQUIRED]
        });

        this.validationMessages = {
            newOwner: RLValidatorConstants.MESSAGE_SETS.REQUIRED,
            newRoles: RLValidatorConstants.MESSAGE_SETS.REQUIRED
        };

        this.transferOwnershipForm.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    /**
     * Get users paged
     * @param {number} limit
     * @param {number} offset
     */
    public getUsers(limit: number, offset: number): void {
        this.usersSubscription = this.userService.getUsers(null, limit, offset).subscribe(
            (res: ARPagedResponseDataModel<UserModel>) => {
                this.users = this.users.concat(res.items.filter((user) => user._id !== this.owner._id));
                // totalUsers should take into account that the owner is filtered out, thus res.total - 1
                this.totalUsers = res.total - 1;
            },
            (err: ARApiError) => Toaster.handleApiError(err)
        );
    }

    /**
     * Get roles for this team
     */
    public getRoles(): void {
        this.rolesSubscription = this.rolesService.getRoles().subscribe(
            (res: ARPagedResponseDataModel<RoleModel>) => this.roles = res.items,
            (err: ARApiError) => Toaster.handleApiError(err)
        );
    }

    /**
     * on Submit, start api call to transfer ownership
     */
    public onSubmit(): void {
        const newUserId = this.transferOwnershipForm.value.newOwner._id;
        const formerOwerRoleIds = this.transferOwnershipForm.value.newRoles.map((role) => role._id);
        this.saveButton.loading = true;
        this.teamAccountService.transferTeamOwnership(newUserId, formerOwerRoleIds).subscribe(
            () => {
                this.saveButton.loading = false;
                Toaster.success('Ownership has been transferred');
                this.fullModalService.close(true);
            },
            (err: ARApiError) => {
                this.saveButton.loading = false;
                Toaster.handleApiError(err);
                this.fullModalService.close(false);
            }
        );
    }

    private initModalButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Confirm', null, null, true);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.onSubmit());

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }
}
