<div class="header-row">
    <h1>Format rulesets</h1>

    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_FORMAT_RULESET | userIsAllowedTo"
                            text="Create format ruleset"
                            (click)="openForm()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_FORMAT_RULESETS | userIsAllowedTo; else noPermissionState">
    <nuc-table [items]="items"
               [columns]="columns"
               [loading]="!subscription?.closed"
               [actions]="actions"
               [selectionMode]="ESelectionMode.EMIT"
               [tableId]="tableId"
               [useButtonBar]="true"
               [useEmptyState]="true"
               [emptyStateTitle]="search ? 'No matching results.' : 'This section is empty. Add the first item.'"
               [prefixStorageKey]="storageKey"
               (itemClicked)="onTableRowClicked($event)"
               (actionClicked)="handleTableRowAction($event)"
               (sortOptionChange)="onSortOptionChanged($event)">
        <nuc-input button-bar-left
                   [data]="search"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search format rulesets by name">
        </nuc-input>
        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="!hasNext"
                   [loading]="!subscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
