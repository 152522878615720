// Modules
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RDModule} from '@relayter/rubber-duck';
import {ComponentsModule} from '../../../../components/components.module';
import {CommonModule} from '@angular/common';
import {TemplateDetailComponent} from './template-detail.component';
import {TemplatePresetComponent} from './template-preset/template-preset.component';
import {TemplateSizeComponent} from './template-size/template-size.component';
import {TemplateContentComponent} from './template-content/template-content.component';
import {TemplateVariantComponent} from './template-variant/template-variant.component';
import {TemplateVariantPresetComponent} from './template-variant/preset/template-variant-preset.component';

// Pages

const components = [
    TemplateDetailComponent,
    TemplatePresetComponent,
    TemplateSizeComponent,
    TemplateContentComponent,
    TemplateVariantComponent,
    TemplateVariantPresetComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        RDModule,
    ],
    providers: [],
    declarations: components,
    exports: components
})

export class TemplateDetailsModule {
}
