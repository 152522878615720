import {DestroyRef, inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {WorkflowConfigurationModel} from '../../../../models/api/workflow-configuration.model';
import {catchError, tap} from 'rxjs/operators';
import {AppConstants} from '../../../../app.constants';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../classes/toaster.class';
import {Router} from '@angular/router';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {WorkflowConfigurationsService} from '../../../../api/services/workflow-configurations.service';
import {CustomWorkflowStepModel} from '../../../../models/api/custom-workflow-step.model';

@Injectable()
export class WorkflowConfigurationsDetailsService extends RLBaseComponent {
    private workflowConfigurationService = inject(WorkflowConfigurationsService);
    private userIsAllowedToPipe = inject(UserIsAllowedToPipe);
    private router = inject(Router);
    private destroyRef = inject(DestroyRef);
    public workflowConfiguration = new ReplaySubject<WorkflowConfigurationModel>(1);
    public activeStepId = new ReplaySubject<string>(1);

    private workflowConfigurationId: string;

    public setWorkflowConfigurationId(workflowConfigurationId: string): void {
        this.workflowConfigurationId = workflowConfigurationId;
    }

    public getWorkflowConfigurationObservable(): Observable<WorkflowConfigurationModel> {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_CONFIGURATION)) return;

        return this.workflowConfigurationService.findOne(this.workflowConfigurationId)
            .pipe(
                catchError((error) => {
                    this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS, AppConstants.CONTEXT_URL.CONFIGURATIONS]);
                    return throwError(() => error);
                }),
                tap(workflowConfiguration => {
                    this.workflowConfiguration.next(workflowConfiguration);
                }),
                takeUntilDestroyed(this.destroyRef)
            );
    }

    public refreshWorkflowConfiguration() {
        this.getWorkflowConfigurationObservable()
            .subscribe({error: Toaster.handleApiError});
    }

    public setActiveStepId(step: CustomWorkflowStepModel): void {
        this.activeStepId.next(step?._id);
    }
}
