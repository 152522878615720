import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {EDataFieldTypes} from '../../app.enums';
import {filter, finalize, map, switchMap, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../classes/toaster.class';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EFormContext, FormDetailModel, FormFieldModel} from '../../models/api/form.model';
import {FormService} from '../../api/services/form.service';
import {VariantModel} from '../../models/api/variant.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {IDataFieldsModel} from '../../models/interfaces/datafields-model.interface';

@Component({
    selector: 'data-fields-information-form',
    templateUrl: './data-fields-information-form.component.html',
    styleUrls: ['./data-fields-information-form.component.scss']
})
export class DataFieldsInformationFormComponent implements OnInit, OnDestroy {
    @Input() public form: UntypedFormGroup;
    @Input() public dataFields: Record<string, any>;
    @Output() public dataFieldsChange = new EventEmitter<Record<string, any>>();
    @Input() public variants: VariantModel[];
    @Input() public formContext: EFormContext;
    @Input() public readonly = false;
    @Input() public isNew = false;

    public variantFormControl: UntypedFormControl;
    public variantEnabled = false;

    public formConfig: FormDetailModel;
    public dataFieldsGroup: UntypedFormGroup;
    public formSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    constructor(private formService: FormService) {
    }

    public ngOnInit(): void {
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initForm(): void {
        if (!this.form.contains('variant')) {
            this.variantFormControl = new UntypedFormControl();
            this.form.addControl('variant', this.variantFormControl);
        } else {
            this.variantFormControl = this.form.get('variant') as UntypedFormControl;
        }

        if (!this.form.contains('dataFields')) {
            this.getForm(true);
        } else {
            this.dataFieldsGroup = this.form.get('dataFields') as UntypedFormGroup;
            this.getForm(false);
        }
    }

    private getForm(initControls: boolean): void {
        this.formSubscription = this.formService.getForms(this.formContext)
            .pipe(
                map((result) => result.items.find((item) => item.context === this.formContext)),
                filter((form) => !!form),
                switchMap((form) => this.formService.getForm(form._id)),
                finalize(() => this.dataFieldsGroup.valueChanges.subscribe(dataFields =>
                    this.dataFieldsChange.emit(DataFieldsComponentUtil.getBodyForDataFields(dataFields, !this.isNew)))),
                takeUntil(this.onDestroySubject)
            ).subscribe({
                next: (formConfig) => {
                    this.formConfig = formConfig;
                    // check if any data fields with variant enabled
                    this.variantEnabled = this.formConfig.rows.some((row) => row.fields.some((field) => field.dataField.enableVariants))
                        && !!this.variants?.length;
                    if (initControls) {
                        this.addControls();
                        // only first time patch the value for variant form control
                        if (this.variantEnabled) this.variantFormControl.patchValue(this.variants[0]);
                    } else if (this.dataFields) {
                        const data = {
                            dataFields: DataFieldsComponentUtil.dataFieldsFromModelData(this.formConfig,
                                {dataFields: this.dataFields} as IDataFieldsModel, this.variants)
                        };
                        this.form.patchValue(data);
                        if (this.variantEnabled) this.variantFormControl.patchValue(this.variants[0]);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private addControls(): void {
        // Add data fields group
        this.dataFieldsGroup = new UntypedFormGroup({});
        this.form.addControl('dataFields', this.dataFieldsGroup);

        this.formConfig.rows.forEach((row) =>
            row.fields.forEach((field) => {
                const fieldName = field.dataField.fieldName;
                if (this.variantEnabled && field.dataField.enableVariants) {
                    this.variants.forEach((variant) => {
                        const controlName = fieldName + '.' + variant.key; // example: control name is MB-description.f3xyz4xy
                        this.dataFieldsGroup.addControl(controlName, DataFieldsInformationFormComponent.getFormControl(field));
                    });
                } else { // example: control name is MB-body_pos
                    this.dataFieldsGroup.addControl(fieldName, DataFieldsInformationFormComponent.getFormControl(field));
                }
            }));

        if (this.dataFields) {
            const data = {
                dataFields: DataFieldsComponentUtil.dataFieldsFromModelData(this.formConfig,
                    {dataFields: this.dataFields} as IDataFieldsModel, this.variants)
            };
            this.form.patchValue(data);
        }
    }

    private static getFormControl(field: FormFieldModel): UntypedFormControl {
        return field.dataField.dataType.type === EDataFieldTypes.ENUM && field.dataField.dataType.enumeration.multiSelect
            ? new UntypedFormControl({value: [], disabled: !field.options.editable})
            : new UntypedFormControl({value: null, disabled: !field.options.editable});
    }
}
