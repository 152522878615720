<form *ngIf="formGroup" [formGroup]="formGroup">
    <div class="basic-information">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Publication type">
            <nuc-dropdown formControlName="publicationType"
                          [total]="publicationTypes.length"
                          [items]="publicationTypes"
                          placeholder="Select a publication types">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Layout">
            <nuc-dropdown formControlName="layout"
                          [total]="layouts.length"
                          [items]="layouts"
                          placeholder="Select a layout">
            </nuc-dropdown>
        </nuc-form-field>
    </div>

    <div class="identifier-setting" formGroupName="identifierSetting">

        <hr />

        <h2>Identifier</h2>

        <p>Define the identifier for this workflow configuration</p>

        <nuc-form-field label="Join">
            <nuc-input formControlName="join" placeholder="Enter a separator to join the fragments" required></nuc-input>
        </nuc-form-field>

        <div class="sequence-control">
            <nuc-form-field label="Sequence"></nuc-form-field>
            <!--text and textPosition is used to fix styling-->
            <nuc-checkbox formControlName="sequence" text=" " textPosition="start"></nuc-checkbox>
        </div>
    </div>

    <div class="fragments-container">

        <div class="fragments-header">

            <div class="fragments-info">
                <h2>Fragments</h2>
                <p>Define the fragments of the identifier</p>
            </div>

            <nuc-button-secondary text="Add fragment" icon="nucicon_add" iconPosition="end" (click)="addFragment()"></nuc-button-secondary>
        </div>

        <div class="fragment" *ngFor="let fragmentGroup of identifierSettingFormGroup.controls.fragments.controls; let index = index">

            <workflow-identifier-setting-fragment-form-component
                [formGroup]="fragmentGroup"
                [properties]="allowedProperties"
                [fragmentTypeOptions]="fragmentTypeOptions">
            </workflow-identifier-setting-fragment-form-component>

            <nuc-button-bar>
                <div></div>
                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteFragment(index)"></nuc-button-secondary>
            </nuc-button-bar>

        </div>
    </div>
</form>
