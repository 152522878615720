<form *ngIf="formGroup" [formGroup]="formGroup">
    <div>
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Title">
            <nuc-input formControlName="title" placeholder="Enter title"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Action type">
            <nuc-dropdown formControlName="type"
                          [total]="actionTypeOptions.length"
                          [items]="actionTypeOptions"
                          placeholder="Select a action type">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Transition">
            <nuc-dropdown formControlName="transition"
                          [searchable]="true"
                          (requestData)="searchTransitions($event)"
                          [total]="transitions.length"
                          [items]="transitionOptions"
                          placeholder="Select a transition">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Icon">
            <nuc-dropdown formControlName="icon"
                          [nullOption]="false"
                          [searchable]="true"
                          (requestData)="searchIcons($event)"
                          [total]="nucIcons.length"
                          [items]="nucIconOptions"
                          placeholder="Select a icon">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Permissions">
            <nuc-dropdown-multiselect
                formControlName="permissions"
                [items]="permissions"
                placeholder="Select permissions">
            </nuc-dropdown-multiselect>
        </nuc-form-field>

        <ng-container *ngIf="modalData.componentAction && formGroup.get('type').value">
            <nuc-form-field label="Note status from">
                <nuc-dropdown-multiselect formControlName="from"
                                          [items]="noteStatus"
                                          placeholder="Select note status from">
                </nuc-dropdown-multiselect>
            </nuc-form-field>

            <nuc-form-field label="Note status to">
                <nuc-dropdown formControlName="to"
                              [total]="noteStatus.length"
                              [items]="noteStatus"
                              placeholder="Select a not status to">
                </nuc-dropdown>
            </nuc-form-field>
        </ng-container>
    </div>
</form>
