import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormControl, FormControlStatus, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {
    ComponentTypeConfig,
    CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS,
    CustomWorkflowComponentModel,
    EComponentTypeContext
} from '../../models/api/custom-workflow-component.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {Toaster} from '../../classes/toaster.class';

export interface IWorkflowConfigurationComponentFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    component?: CustomWorkflowComponentModel;
    workflowConfigurationStep?: CustomWorkflowStepModel;
}

@Component({
    selector: 'workflow-configuration-component-form-component',
    templateUrl: 'workflow-configuration-component-form.component.html',
    styleUrls: ['workflow-configuration-component-form.component.scss']
})
export class WorkflowConfigurationComponentFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public permissions: DropdownItem<string>[];
    public formGroup: FormGroup;
    private saveButton: ButtonConfig;
    private workflowConfiguration: WorkflowConfigurationModel;
    private workflowConfigurationStep: CustomWorkflowStepModel;
    private component: CustomWorkflowComponentModel;
    public allComponentTypes: IDropdownItem<ComponentTypeConfig>[];
    public componentTypes: IDropdownItem<ComponentTypeConfig>[];

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationComponentFormData) {
    }

    public ngOnInit(): void {
        this.setupData();
        this.initForm();
        this.initModalButtons();
    }

    public setupData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.component = this.modalData.component || new CustomWorkflowComponentModel();
        this.workflowConfigurationStep = this.modalData.workflowConfigurationStep;
        this.allComponentTypes = CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS
                .filter((config) => {
                    return (!config.publicationTypes || config.publicationTypes.includes(this.workflowConfiguration.publicationType.name))
                        && config.context === (this.workflowConfigurationStep ? EComponentTypeContext.STEP : EComponentTypeContext.WORKFLOW)
                })
                .map(config => new DropdownItem<ComponentTypeConfig>(config.type, config));
        this.componentTypes = this.allComponentTypes;
    }

    public onRequestComponentTypes(request: IDropdownRequestDataEvent): void {
        const regex = new RegExp(request.search, 'i');
        this.componentTypes = request.search
            ? this.allComponentTypes.filter((componentType) =>
                componentType.getTitle().match(regex)?.length > 0)
            : this.allComponentTypes;
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false);
        this.saveButton.disabled = this.formGroup.status !== 'VALID';

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => {
            this.saveWorkflowConfigurationComponent();
        });
        this.fullModalService.setModalActions([cancelAction, saveAction]);

        if (this.workflowConfigurationStep) {
            this.fullModalService.setDescription(`Enter the information to create a new component for step: ${this.workflowConfigurationStep.name}.`);
        }
    }

    private initForm(): void {
        const selectedComponentType = this.componentTypes.find((componentType) =>
            this.component?.componentType === componentType.getTitle());

        this.formGroup = new FormGroup<any>({
            name: new FormControl(this.component.name, Validators.required),
            componentType: new FormControl(selectedComponentType, [Validators.required, this.noDeprecatedComponentType()])
        });
        this.listenToFormChanges();
    }

    private noDeprecatedComponentType(): ValidatorFn {
        return (formControl: FormControl): ValidationErrors => {
            if (this.component?.componentType === formControl.getRawValue()?.getTitle()) {
                return null;
            }
            if (formControl.getRawValue()?.getValue()?.canBeReplacedBy) {
                return {canBeReplaceBy: `${formControl.getRawValue().getTitle()} is deprecated, please use ${formControl.getRawValue().getValue()?.canBeReplacedBy} component instead.`}
            }
            return null;
        }
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.saveButton.disabled = status !== 'VALID');
    }

    private saveWorkflowConfigurationComponent(): void {
        const component = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            componentType: this.formGroup.value.componentType.getTitle()
        }, this.component._id);


        this.saveButton.loading = true;
        const observable = this.component._id
            ? this.workflowConfigurationService.patchWorkflowConfigurationComponent(
                this.workflowConfiguration._id, this.component._id, component, this.workflowConfigurationStep?._id)
            : this.workflowConfigurationService.createWorkflowConfigurationComponent(
                this.workflowConfiguration._id, component, this.workflowConfigurationStep?._id)

        observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                error: (error) => {
                    this.saveButton.loading = false;
                    Toaster.handleApiError(error);
                },
                next: (workflowConfiguration) => {
                    this.fullModalService.close(workflowConfiguration);
                    Toaster.success(`Component ${this.component._id ? 'updated' : 'created'} successfully`);
                }
            });
    }
}
