/**
 *  error.constants
 *  app-relayter-web
 *  Created by nandamhuis on 11/07/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {Injectable} from '@angular/core';

@Injectable()
export class ErrorConstants {
    public static readonly INTERNAL_SERVER_ERROR_MESSAGE = 'Something went wrong';
    public static readonly ERROR_MESSAGES = {
        BAD_REQUEST_FILE_TYPE: 'Invalid file type',
        BAD_REQUEST_EXPORT_TYPE_NOT_ALLOWED: 'File cannot be exported to given type',
        BAD_REQUEST_TAG: 'Invalid tag',
        BAD_REQUEST_EAN: 'Invalid EAN code',
        BAD_REQUEST_MALLFORMED: 'Malformed data',
        BAD_REQUEST_CAMPAIGN_DATE: 'Invalid campaign dates',
        BAD_REQUEST_ROLE: 'There is already a role with this name',
        BAD_REQUEST_CAMPAIGN: 'Invalid campaign',
        BAD_REQUEST_USER: 'Invalid user',
        BAD_REQUEST_EMAIL: 'Invalid email',
        BAD_REQUEST_TEAM: 'Invalid team',
        BAD_REQUEST_BRAND: 'Invalid brand',
        BAD_REQUEST_PRODUCT_IN_USE: 'Product in use',
        BAD_REQUEST_INVALID_OFFER: 'Invalid offer',
        BAD_REQUEST_INVALID_STATUS: 'Invalid status',
        BAD_REQUEST_INVALID_STATUS_TRANSITION: 'Invalid status transition',
        BAD_REQUEST_UNEVEN_NUMBER_OF_PAGES: 'Uneven number of pages',
        BAD_REQUEST_INDESIGN_JOB_ALREADY_IN_PROGRESS: 'The item is already being generated',
        BAD_REQUEST_INVALID_IDML_AND_PDF: 'Please provide both an idml and a pdf file',
        BAD_REQUEST_CAMPAIGN_ITEM_NOT_INSIDE_GROUPED_AREAS: 'A campaign item you are trying to add to a ' +
            'correction is not contained in areas in the same group.',
        BAD_REQUEST_DUPLICATE_LIBRARY_NAME: 'An InDesign library with this name already exists',
        BAD_REQUEST_DUPLICATE_PUBLICATIONS_NOT_ALLOWED: 'A publication with that name already exists',
        BAD_REQUEST_DUPLICATE_PACKAGE_SETUP_NOT_ALLOWED: 'A package setup with that name already exists',
        BAD_REQUEST_PACKAGE_SETUP_VALIDATION_FAILED: 'Package setup validation failed',
        BAD_REQUEST_DATATYPE_VALUE_STRING_REQUIRED: 'Data type string is required',
        BAD_REQUEST_DUPLICATE_DATA_FIELD_NAME_NOT_ALLOWED: 'A package setup material data field with that name already exists',
        BAD_REQUEST_DUPLICATE_PACKAGE_RULE_NAME_NOT_ALLOWED: 'A package setup rule with that name already exists',
        BAD_REQUEST_PACKAGE_SETUP_RULE_VALIDATION_FAILED: 'Package setup rule validation failed',
        BAD_REQUEST_PACKAGE_SETUP_IN_USE: 'You can\'t delete this package setup. It is used within a campaign',
        BAD_REQUEST_INVALID_PAGE_SIZE_FOR_TEMPLATE: 'The page size of the template should be equal to that of the master page',
        BAD_REQUEST_DUPLICATE_WORKFLOW_AUTOMATION_NOT_ALLOWED: 'A workflow automation with that name already exists',
        BAD_REQUEST_INDESIGN_LIBRARY_WITH_RULE_SET_IN_USE: 'You can\'t remove a library that is used by a template',
        BAD_REQUEST_DUPLICATE_SIGN_OFF_USER: 'User already signed off this publication item',
        BAD_REQUEST_DATA_FIELD_OUT_OF_RANGE: 'The datafield you try to update is already at the start or end of the list',
        BAD_REQUEST_CAMPAIGN_ITEM_ALREADY_ASSIGNED: 'The briefing item you want to assign is already assigned.',
        BAD_REQUEST_USER_ALREADY_IN_TEAM: 'User\'s email is already in your team.',
        BAD_REQUEST_USER_ALREADY_IN_OTHER_TEAM: 'User\'s email is already in use for another team.',
        BAD_REQUEST_INDESIGN_RULE_SET_ASSET_ITEM_WIDTH_REQUIRED: 'Width is required and cannot be 0',
        BAD_REQUEST_INDESIGN_RULE_SET_ASSET_ITEM_HEIGHT_REQUIRED: 'Height is required and cannot be 0',
        BAD_REQUEST_DUPLICATE_PERMISSIONS_NOT_ALLOWED: 'Permission already exists for this role',
        BAD_REQUEST_DUPLICATE_FORMAT_RULESET_NOT_ALLOWED: 'A format ruleset with that name already exists',
        BAD_REQUEST_DUPLICATE_ROLES_NOT_ALLOWED: 'Role already exists for this role',
        BAD_REQUEST_INVALID_INDESIGN_RULE_SET_ITEM_RULE_CONDITION: 'Invalid indesign rule set item rule condition',
        BAD_REQUEST_INVALID_WORKFLOW_AUTOMATION_CONDITION: 'Invalid workflow automation condition',
        BAD_REQUEST_INVALID_PACKAGE_SETUP_CONDITION: 'Invalid package setup condition',
        BAD_REQUEST_INVALID_INDESIGN_RULE_SET_ITEM_RULE_VALUE: 'Invalid indesign rule set item rule value',
        BAD_REQUEST_BRIEFING_EXPORT_NO_VARIANT_KEY: 'Variants are enabled for briefing items, please provide a variant for the briefing export',
        BAD_REQUEST_BRIEFING_EXPORT_NO_VARIANTS_ENABLED:
            'Variants are not enabled for briefing items, please don\'t provide a variant for the briefing export',
        BAD_REQUEST_INVALID_LIBRARY_ITEMS_FOR_TEMPLATE:
            'The libraries of this template do not have all the same library items',
        BAD_REQUEST_INVALID_PACKAGE_TYPE: 'Invalid package type',
        BAD_REQUEST_INVALID_PACKAGE_EXPORT_FORMAT: 'Invalid package export format',
        BAD_REQUEST_NO_PACKAGES_TO_EXPORT: 'No packages to export',
        BAD_REQUEST_INVALID_JOB_DATA: 'Invalid job data',
        BAD_REQUEST_VARIANT_NOT_ALLOWED: 'Not all variants are allowed',
        BAD_REQUEST_TRANSITION_IN_USE: 'The workflow configuration transition is in use in an active item transition',
        FORBIDDEN_DELETE_SELF: 'Can\'t delete self',
        FORBIDDEN_DELETE_OWNER: 'Can\'t delete owner',
        FORBIDDEN_EDIT_ROLE: 'Can not edit role',
        FORBIDDEN_LIMIT_OF_GENERAL: 'Limit of plan reached',
        FORBIDDEN_LIMIT_OF_PLAN_ASSETS: 'Limit of plan assets reached',
        FORBIDDEN_LIMIT_OF_PLAN_USERS: 'Limit of plan users reached',
        FORBIDDEN_ROLE_NOT_EMPTY: 'Can\'t delete role if there still users in it',
        FORBIDDEN_CONFIG: 'Not allowed to fetch config',
        FORBIDDEN_INVALID_IDML: 'Not a valid IDML file',
        FORBIDDEN_DUPLICATE_KEY: 'Duplicate record in collection',
        INVALID_STICKY_NOTE_STATUS: 'Note doesn\'t have a valid status to do this action',
        FORBIDDEN_NOT_ALLOWED_TO_DELETE_CAMPAIGN_ITEM_IN_USE: 'Briefing item is still used in a publication. Please, unassign it before deleting',
        COULD_NOT_SAVE: 'Problem saving',
        COULD_NOT_UPDATE: 'Problem updating',
        COULD_NOT_DELETE: 'Problem deleting',
        COULD_NOT_READ: 'Problem reading',
        NOT_FOUND_EXPORT_SIZE: 'Export size not found',
        NOT_FOUND_CAMPAIGN: 'Campaign not found',
        NOT_FOUND_PRODUCT: 'Product not found',
        NOT_FOUND_ROLE: 'Role not found',
        NOT_FOUND_PUBLICATION: 'Publication not found',
        NOT_FOUND_PLAN: 'Plan not found',
        NOT_FOUND_INVITE: 'Invite with token not found',
        NOT_FOUND_TEAM: 'Team not found',
        NOT_FOUND_TAGS: 'Tags not found',
        NOT_FOUND_RESETPASSWORD: 'Reset password not found',
        NOT_FOUND_BILLING: 'Billing not found',
        NOT_FOUND_USER: 'User not found',
        NOT_FOUND_PRODUCTS: 'Products not found',
        NOT_FOUND_OFFER_TYPE: 'Offer type not found',
        NOT_FOUND_CHANNEL: 'Channel not found',
        NOT_FOUND_CAMPAIGN_ITEM: 'Campaign item not found',
        NOT_FOUND_MENU_ITEMS: 'Menu items not found',
        NOT_FOUND_CHANNELS: 'Channels not found',
        NOT_FOUND_ASSETS: 'Assets not found',
        NOT_FOUND_NOTIFICATION_MESSAGE: 'Notification message not found',
        NOT_FOUND_PERMISSIONS: 'Permissions not found',
        NOT_FOUND_TEMPLATES: 'Templates not found',
        NOT_FOUND_PAGE: 'Could not find page',
        NOT_FOUND_ASSET_FORMAT: 'Could not retrieve asset format',
        NOT_FOUND_ASSET_IDENTIFY: 'Could not retrieve asset identify information',
        NOT_FOUND_WORKFLOW: 'Workflow could not be found',
        NOT_FOUND_PUBLICATION_ITEM: 'No publication item found',
        NOT_FOUND_PACKAGE_SETUP: 'Package setup could not be found',
        NOT_FOUND_WORKFLOW_AUTOMATION: 'Workflow automation could not be found',
        NOT_FOUND_WORKFLOW_AUTOMATION_RULE: 'Workflow automation rule could not be found',
        NOT_FOUND_VARIANT: 'Variant not found',
        NOT_FOUND_EXPORT_NOTES: 'No notes to export',
        UNAUTHORIZED_WRONG_CREDENTIALS: 'Wrong credentials',
        SESSION_REVOKED: 'There is another session active with this account. You will now be logged out.',
        BAD_REQUEST_DUPLICATE_VARIANTS_NOT_ALLOWED: 'A variant with that name already exists',
        BAD_REQUEST_DUPLICATE_DATA_FIELD_NOT_ALLOWED: 'A data field with that name already exists',
        BAD_REQUEST_FILE_NOT_FOUND_S3: 'File not found.',
        BAD_REQUEST_DUPLICATE_ASSET_NOT_ALLOWED: 'An asset with that name already exists',
        BAD_REQUEST_DUPLICATE_TEMPLATE_NOT_ALLOWED: 'A template with that name already exists',
        BAD_REQUEST_DUPLICATE_CAMPAIGNS_NOT_ALLOWED: 'A campaign with that name already exists',
        BAD_REQUEST_INVALID_STICKY_NOTE_ACTION: 'Invalid note action',
        SERVICE_UNAVAILABLE: 'Service Unavailable'
    };

    public static readonly API_ERROR_CODES = {
        BAD_REQUEST_FILE_TYPE: 1000,
        BAD_REQUEST_EXPORT_TYPE_NOT_ALLOWED: 1003,
        BAD_REQUEST_TAG: 1005,
        BAD_REQUEST_EAN: 1006,
        BAD_REQUEST_MALLFORMED: 1007,
        BAD_REQUEST_CAMPAIGN_DATE: 1008,
        BAD_REQUEST_ROLE: 1009,
        BAD_REQUEST_CAMPAIGN: 1010,
        BAD_REQUEST_USER: 1011,
        BAD_REQUEST_EMAIL: 1012,
        BAD_REQUEST_TEAM: 1013,
        BAD_REQUEST_PRODUCT_IN_USE: 1015,
        BAD_REQUEST_INVALID_OFFER: 1016,
        BAD_REQUEST_INVALID_STATUS: 1017,
        BAD_REQUEST_INVALID_STATUS_TRANSITION: 1018,
        BAD_REQUEST_UNEVEN_NUMBER_OF_PAGES: 1019,
        BAD_REQUEST_INDESIGN_JOB_ALREADY_IN_PROGRESS: 1020,
        BAD_REQUEST_INVALID_IDML_AND_PDF: 1021,
        BAD_REQUEST_CAMPAIGN_ITEM_NOT_INSIDE_GROUPED_AREAS: 1041,
        BAD_REQUEST_DUPLICATE_LIBRARY_NAME: 1104,
        BAD_REQUEST_DUPLICATE_PUBLICATIONS_NOT_ALLOWED: 1064,
        BAD_REQUEST_DUPLICATE_CAMPAIGNS_NOT_ALLOWED: 1065,
        BAD_REQUEST_DUPLICATE_DATA_FIELD_NOT_ALLOWED: 1080,
        BAD_REQUEST_PACKAGE_SETUP_VALIDATION_FAILED: 1159,
        BAD_REQUEST_DATATYPE_VALUE_STRING_REQUIRED: 1094, // TODO renumber
        BAD_REQUEST_INVALID_JOB_DATA: 1097,
        BAD_REQUEST_DUPLICATE_DATA_FIELD_NAME_NOT_ALLOWED: 1161,
        BAD_REQUEST_DUPLICATE_PACKAGE_RULE_NAME_NOT_ALLOWED: 1096,
        BAD_REQUEST_PACKAGE_SETUP_RULE_VALIDATION_FAILED: 1162,
        BAD_REQUEST_DUPLICATE_PACKAGE_SETUP_NOT_ALLOWED: 1163,
        BAD_REQUEST_FILE_NOT_FOUND_S3: 1102,
        BAD_REQUEST_PACKAGE_SETUP_IN_USE: 1115,
        BAD_REQUEST_INVALID_PAGE_SIZE_FOR_TEMPLATE: 1118,
        BAD_REQUEST_DUPLICATE_WORKFLOW_AUTOMATION_NOT_ALLOWED: 1124,
        BAD_REQUEST_INDESIGN_LIBRARY_WITH_RULE_SET_IN_USE: 1121,
        BAD_REQUEST_DUPLICATE_SIGN_OFF_USER: 1178,
        BAD_REQUEST_INDESIGN_RULE_SET_ASSET_ITEM_WIDTH_REQUIRED: 1179,
        BAD_REQUEST_INDESIGN_RULE_SET_ASSET_ITEM_HEIGHT_REQUIRED: 1130,
        BAD_REQUEST_DUPLICATE_FORMAT_RULESET_NOT_ALLOWED: 1148,
        BAD_REQUEST_DUPLICATE_PERMISSIONS_NOT_ALLOWED: 1149,
        BAD_REQUEST_DUPLICATE_ROLES_NOT_ALLOWED: 1150,
        BAD_REQUEST_INVALID_INDESIGN_RULE_SET_ITEM_RULE_CONDITION: 1151,
        BAD_REQUEST_INVALID_WORKFLOW_AUTOMATION_CONDITION: 1152,
        BAD_REQUEST_INVALID_PACKAGE_SETUP_CONDITION: 1153,
        BAD_REQUEST_DATA_FIELD_OUT_OF_RANGE: 1131,
        BAD_REQUEST_CAMPAIGN_ITEM_ALREADY_ASSIGNED: 1138,
        BAD_REQUEST_USER_ALREADY_IN_TEAM: 1140,
        BAD_REQUEST_USER_ALREADY_IN_OTHER_TEAM: 1141,
        BAD_REQUEST_DUPLICATE_VARIANTS_NOT_ALLOWED: 1143,
        BAD_REQUEST_INVALID_INDESIGN_RULE_SET_ITEM_RULE_VALUE: 1156,
        BAD_REQUEST_BRIEFING_EXPORT_NO_VARIANT_KEY: 1180,
        BAD_REQUEST_BRIEFING_EXPORT_NO_VARIANTS_ENABLED: 1181,
        BAD_REQUEST_INVALID_LIBRARY_ITEMS_FOR_TEMPLATE: 1182,
        BAD_REQUEST_INVALID_PACKAGE_TYPE: 1183,
        BAD_REQUEST_INVALID_PACKAGE_EXPORT_FORMAT: 1184,
        BAD_REQUEST_NO_PACKAGES_TO_EXPORT: 1185,
        BAD_REQUEST_VARIANT_NOT_ALLOWED: 1186,
        BAD_REQUEST_DUPLICATE_ASSET_NOT_ALLOWED: 1202,
        BAD_REQUEST_DUPLICATE_TEMPLATE_NOT_ALLOWED: 1203,
        BAD_REQUEST_INVALID_STICKY_NOTE_ACTION: 1205,
        BAD_REQUEST_TRANSITION_IN_USE: 1226,
        FORBIDDEN_DELETE_SELF: 2001,
        FORBIDDEN_DELETE_OWNER: 2002,
        FORBIDDEN_EDIT_ROLE: 2003,
        FORBIDDEN_LIMIT_OF_GENERAL: 2004,
        FORBIDDEN_LIMIT_OF_PLAN_ASSETS: 2005,
        FORBIDDEN_LIMIT_OF_PLAN_USERS: 2006,
        FORBIDDEN_ROLE_NOT_EMPTY: 2007,
        FORBIDDEN_CONFIG: 2008,
        FORBIDDEN_INVALID_IDML: 2009,
        FORBIDDEN_DUPLICATE_KEY: 2010,
        INVALID_STICKY_NOTE_STATUS: 2011,
        FORBIDDEN_NOT_ALLOWED_TO_DOWNLOAD: 2016,
        FORBIDDEN_NOT_ALLOWED_TO_DELETE_CAMPAIGN_ITEM_IN_USE: 2019,
        USER_LIMIT_REACHED_ERROR: 2027,
        COULD_NOT_SAVE: 3001,
        COULD_NOT_UPDATE: 3002,
        COULD_NOT_DELETE: 3003,
        COULD_NOT_READ: 3004,
        NOT_FOUND_EXPORT_SIZE: 4001,
        NOT_FOUND_CAMPAIGN: 4002,
        NOT_FOUND_PRODUCT: 4003,
        NOT_FOUND_ROLE: 4004,
        NOT_FOUND_PUBLICATION: 4005,
        NOT_FOUND_PLAN: 4006,
        NOT_FOUND_INVITE: 4007,
        NOT_FOUND_TEAM: 4008,
        NOT_FOUND_TAGS: 4009,
        NOT_FOUND_RESETPASSWORD: 4010,
        NOT_FOUND_BILLING: 4011,
        NOT_FOUND_USER: 4013,
        NOT_FOUND_PRODUCTS: 4014,
        NOT_FOUND_OFFER_TYPE: 4015,
        NOT_FOUND_CHANNEL: 4016,
        NOT_FOUND_CAMPAIGN_ITEM: 4017,
        NOT_FOUND_MENU_ITEMS: 4018,
        NOT_FOUND_CHANNELS: 4019,
        NOT_FOUND_ASSETS: 4020,
        NOT_FOUND_NOTIFICATION_MESSAGE: 4021,
        NOT_FOUND_PERMISSIONS: 4023,
        NOT_FOUND_TEMPLATES: 4024,
        NOT_FOUND_PAGE: 4026,
        NOT_FOUND_ASSET_FORMAT: 4029,
        NOT_FOUND_ASSET_IDENTIFY: 4030,
        NOT_FOUND_BRIEFING: 4048,
        NOT_FOUND_WORKFLOW: 4073,
        NOT_FOUND_PUBLICATION_ITEM: 4082,
        NOT_FOUND_PACKAGE_SETUP: 4091,
        NOT_FOUND_WORKFLOW_AUTOMATION: 4102,
        NOT_FOUND_WORKFLOW_AUTOMATION_RULE: 4103,
        NOT_FOUND_VARIANT: 4109,
        NOT_FOUND_EXPORT_NOTES: 4110,
        WRONG_CREDENTIALS: 5000,
        UNAUTHORIZED_WRONG_CREDENTIALS: 5001,
        SESSION_REVOKED: 5005,
        SERVICE_UNAVAILABLE: 503,
        NOT_FOUND_DEVICE_CODE: 4119
    };

    public static readonly API_ERROR_MESSAGES = {
        'en-US': ErrorConstants.ERROR_MESSAGES,
        'nl-NL': ErrorConstants.ERROR_MESSAGES
    };

}
