import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Checks if the field has a minimal value, when filled in
 * @param {number} minValue
 * @returns {object | null}
 * @constructor
 */
export function MinOptionalNumberValidator(minValue: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        if (!isNaN(+control.value) && control.value !== null && control.value < minValue) {
            return {minOptionalNumber: true};
        }
        return null;
    };
}
