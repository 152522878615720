<div class="container">
    <nuc-input [formControl]="searchInput" prefix="nucicon_search" placeholder="Search page"></nuc-input>
    <div class="options">
        <ng-container *ngIf="options?.length > 0; else otherState" >
            <mat-option *ngFor="let option of filteredOptions | async" (click)="clicked(option)">{{option.getTitle()}}</mat-option>
        </ng-container>

        <ng-template #otherState>
            <nuc-empty-state-component *ngIf="options && options.length === 0; else loading"
                                       imageUrl="assets/images/empty_states/data-management.svg"
                                       title="No items available"
                                       [small]="true">
            </nuc-empty-state-component>

            <ng-template #loading>
                <rl-loading-indicator></rl-loading-indicator>
            </ng-template>
        </ng-template>
    </div>
</div>
