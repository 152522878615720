<form *ngIf="formGroup" [formGroup]="formGroup">
    <div>
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Icon">
            <nuc-dropdown formControlName="icon"
                          [nullOption]="false"
                          [searchable]="true"
                          (requestData)="searchIcons($event)"
                          [total]="nucIcons.length"
                          [items]="nucIconOptions"
                          placeholder="Select a icon">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Permissions">
            <nuc-dropdown-multiselect
                formControlName="permissions"
                [items]="permissions"
                placeholder="Select permissions">
            </nuc-dropdown-multiselect>
        </nuc-form-field>

        <div formGroupName="schedule">
            <nuc-form-field label="Schedule (hours)">
                <nuc-input
                    type="number"
                    [min]="0"
                    formControlName="hours"
                    placeholder="Hours before deadline, items should be past this step">
                </nuc-input>
            </nuc-form-field>
        </div>
    </div>
</form>
