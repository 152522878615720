<form *ngIf="formGroup" [formGroup]="formGroup">
    <div>
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Component type">
            <nuc-dropdown
                searchable="true"
                formControlName="componentType"
                placeholder="Choose a component type"
                nullOption="false"
                [items]="componentTypes"
                (requestData)="onRequestComponentTypes($event)"
                [total]="componentTypes.length">
            </nuc-dropdown>
            <!--Temporarily shows suggestive component for deprecated component-->
            <ng-container *ngIf="formGroup.controls.componentType.getRawValue()?.getValue()?.canBeReplacedBy">
                <nuc-error *ngIf="formGroup.controls.componentType.errors?.canBeReplaceBy">
                    {{formGroup.controls.componentType.errors.canBeReplaceBy}}
                </nuc-error>
            </ng-container>
        </nuc-form-field>
    </div>
</form>
