import {AfterViewInit, Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DataFilterDropdownComponent} from '../data-filter-dropdown/data-filter-dropdown.component';

@Component({
    selector: 'rl-data-filter-suggestive',
    templateUrl: './data-filter-suggestive.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DataFilterSuggestiveComponent),
        multi: true
    }]
})
export class DataFilterSuggestiveComponent extends DataFilterDropdownComponent implements ControlValueAccessor, AfterViewInit {
    public onSearchTermChanged(search: string): void {
        this.search = search;

        this.getDataValues(0);
    }
}
