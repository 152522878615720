<ng-container *ngIf="selectedPropertyConfig">
    <nuc-table *ngIf="selectedPropertyConfig.tableView else otherView"
               [useButtonBar]="true"
               [useEmptyState]="true"
               [items]="mainEntity[selectedPropertyConfig.property]"
               [columns]="selectedPropertyConfig.tableView.columns"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selectionMode]="ESelectionMode.EMIT"
               [actions]="selectedPropertyConfig.tableView.actions"
               (actionClicked)="handleTableRowAction($event)">
    </nuc-table>

    <ng-template #otherView>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/empty-states-workflow.svg"
                                   [small]="true"
                                   title="Not implemented yet">
        </nuc-empty-state-component>
    </ng-template>
</ng-container>
