import {Component, Input, OnInit} from '@angular/core';
import {FormDetailModel} from '../../../../../models/api/form.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {VariantModel} from '../../../../../models/api/variant.model';

@Component({
    selector: 'rl-form-preview',
    templateUrl: './form-preview.component.html',
    styleUrls: ['./form-preview.component.scss']
})
export class FormPreviewComponent implements OnInit {
    @Input() public form: FormDetailModel;
    @Input() public variants: VariantModel[];
    public selectedVariant: VariantModel;

    public dummyFormGroup: UntypedFormGroup;
    public variantEnabled = false;
    public variantFormControl = new UntypedFormControl(); // we always have this control

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.dummyFormGroup = new UntypedFormGroup({});
        // check if any data fields with variant enabled
        this.variantEnabled = this.form.rows.some((row) => row.fields.some((field) => field.dataField.enableVariants))
            && !!this.variants?.length;

        if (this.variantEnabled) this.variantFormControl.patchValue(this.variants[0]);
        this.addFormControls();
    }

    private addFormControls(): void {
        this.form.rows.forEach((row) =>
            row.fields.forEach((field) => {
                const fieldName = field.dataField.fieldName;
                if (this.variantEnabled && field.dataField.enableVariants) {
                    this.variants.forEach((variant) => {
                        this.dummyFormGroup.addControl(fieldName + '.' + variant.key,
                            new UntypedFormControl({value: null, disabled: !field.options.editable}));
                    });
                } else {
                    this.dummyFormGroup.addControl(fieldName,
                        new UntypedFormControl({value: null, disabled: !field.options.editable}));
                }
            })
        );
    }
}
