import {Component} from '@angular/core';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {FullModalConfig, FullModalService, NucDialogService} from '@relayter/rubber-duck';
import {AddPageFormComponent, IAddPageFormData} from '../../../../../../forms/add-page-form/add-page-form.component';
import {CustomWorkflowService} from '../custom-workflow.service';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {CustomWorkflowOptionModel} from '../../../../../../models/api/custom-workflow-option.model';
import {ETemplateType} from '../../../../templates/template-detail/template-size/template-size.model';
import {MonitoredTransitionsService} from '../../../../../../api/services/monitored-transitions.service';

enum EComponentActions {
    ADD_PAGES = 'ADD_PAGES',
}

enum EComponentActionOptions {
    TEMPLATE_FILTER_CAMPAIGN_TAGS = 'TEMPLATE_FILTER_CAMPAIGN_TAGS'
}

interface IPageType {
    title: string;
    page: number;
    type: ETemplateType;
}

@Component({
    selector: 'rl-custom-workflow-add-pages-component',
    templateUrl: './custom-workflow-add-pages.component.html',
    styleUrls: ['./custom-workflow-add-pages.component.scss']
})
export class CustomWorkflowAddPagesComponent extends CustomWorkflowBaseComponent {
    public addPagesAction: CustomWorkflowActionModel;
    public templateFilterCampaignTagsOption: CustomWorkflowOptionModel;
    public readonly pageTypes: IPageType[] = [
        {title: 'Single page template', type: ETemplateType.Single, page: 1},
        {title: 'Spread template', type: ETemplateType.Spread, page: 2}
    ];

    private get templateFilterCampaignTags(): string[] {
        return this.templateFilterCampaignTagsOption?.value === true ? this.campaign.tags : [];
    }

    constructor(userIsAllowedToPipe: UserIsAllowedToPipe,
                customWorkflowService: CustomWorkflowService,
                publicationService: PublicationsService,
                dialogService: NucDialogService,
                fullModalService: FullModalService,
                monitoredTransitionsService: MonitoredTransitionsService) {
        super(userIsAllowedToPipe, customWorkflowService, publicationService, dialogService, fullModalService, monitoredTransitionsService);
    }

    protected updateActions(): void {
        super.updateActions();
        this.addPagesAction = this.findWorkflowActionByName(EComponentActions.ADD_PAGES);
        this.templateFilterCampaignTagsOption = this.addPagesAction?.options?.find(option =>
            option.name === EComponentActionOptions.TEMPLATE_FILTER_CAMPAIGN_TAGS);
    }

    protected setupData(): void {}

    protected refreshData(): void {}

    public openAddPageModal(selectedPageType: IPageType): void {
        const modalData: IAddPageFormData = {
            selectedPageType: selectedPageType.page,
            publicationId: this.publication._id,
            transition: this.addPagesAction.transition,
            tagsFilter: this.templateFilterCampaignTags
        };
        const modalConfig = new FullModalConfig('Add page(s)', 'Choose the template and the amount of pages.', modalData);
        this.fullModalService.open(AddPageFormComponent, modalConfig);
    }
}
