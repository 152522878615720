<div class="top">
    <div class="line" [class.inactive]="!hasBackwardsTransition && !hasFlyByTransition"></div>
    <div class="vertical-line" *ngIf="hasBackwardsTransition || hasIncomingBackwardsTransition"></div>
    <div class="line" [class.inactive]="!hasIncomingBackwardsTransition && !hasFlyByTransition"></div>
</div>

<div class="middle">
    <div class="line" [class.inactive]="!hasPrevious"></div>
    <div class="circle" [class.active]="active" matRipple [matRippleColor]="rippleColor">

        <svg x="0px" y="0px" viewBox="0 0 40 40">
            <path class="selected" d="M20,0.57C9.27,0.57,0.57,9.27,0.57,20S9.27,39.43,20,39.43" stroke="#d2d2d2" fill="none"></path>
        </svg>
        <svg  x="0px" y="0px" viewBox="0 0 40 40">
            <path d="M20,0.57c10.73,0,19.43,8.7,19.43,19.43S30.73,39.43,20,39.43" fill="none" stroke="#d2d2d2"></path>
        </svg>

        <svg class="foreground" x="0px" y="0px" viewBox="0 0 40 40">
            <path class="selected" d="M20,0.57C9.27,0.57,0.57,9.27,0.57,20S9.27,39.43,20,39.43" stroke="#41B888" fill="none"></path>
        </svg>

        <svg class="foreground" x="0px" y="0px" viewBox="0 0 40 40" >
            <path d="M20,0.57c10.73,0,19.43,8.7,19.43,19.43S30.73,39.43,20,39.43" fill="none" stroke="#41B888"></path>
        </svg>

        <div class="icon">
            <i [ngClass]="step.icon" [class.active]="active"></i>
        </div>
    </div>
    <div class="line" [class.inactive]="!hasNext"></div>
    <div class="counter" *ngIf="itemCount">{{itemCount}}</div>
</div>

<div class="bottom">
    <div class="label">
        <div class="text-center" [class.active]="active">{{step.name | titlecase}}</div>
    </div>
</div>
