import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {SortDirection} from '@angular/material/sort';
import {Cursor} from '../api-cursor';
import {ETransitionStatus, TransitionItemModel} from '../../models/api/transition-item.model';
import {ApiPagedRequest} from '../requests/api-paged.request';

@Injectable({
    providedIn: 'root'
})
export class TransitionItemsService extends ApiService<TransitionItemModel> {
    constructor() {
        super([ApiConstants.API_GROUP_TRANSITION_ITEMS], WorkflowConfigurationModel);
    }

    public findActiveTransitions(transitionId?: string, publicationId?, limit?: number, offset?: number, sortProperty?: string,
                                 sortOrder?: SortDirection, cursor?: Cursor,
                                 search?: string): Observable<ARPagedResponseDataModel<TransitionItemModel>> {
        const filters: Record<string, any> = {};
        if (transitionId) {
            filters.transitionId = transitionId;
        }
        if (publicationId) {
            filters.publicationId = publicationId;
        }
        const status = [
            ETransitionStatus.QUEUED,
            ETransitionStatus.IN_PROGRESS
        ];
        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, sortProperty, sortOrder, cursor, search, filters);
        request.additionalQueryParams.addParam('status', status);
        return this.apiRequestService.find(request);
    }

}
