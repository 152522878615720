import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../api/services/users.service';
import {UserModel} from '../../../../models/api/user.model';
import {Toaster} from '../../../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {AppConstants} from '../../../../app.constants';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    ESelectionMode,
    ISortOptionEvent,
    ESortOrder,
    ITableColumn,
    EColumnSize
} from '@relayter/rubber-duck';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {GroupService} from '../../../../api/services/group.service';
import {GroupModel, GroupPatchModel, GroupPostModel} from '../../../../models/api/group.model';
import {SortDirection} from '@angular/material/sort';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {SelectionModel} from '@angular/cdk/collections';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';

export interface IGroupFormComponentData {
    group?: GroupModel;
}

@Component({
    selector: 'rl-group-form-component',
    templateUrl: 'group-form.component.html',
    styleUrls: ['group-form.component.scss'],
    providers: [PaginatorService]
})

export class GroupFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'group-form-table';
    public formGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required)
    });

    public userSubscription: Subscription;
    public users: UserModel[] = [];
    public totalUsers: number;
    public pageIndex = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;
    public sortOrder: SortDirection;
    public sortProperty: string;
    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            size: EColumnSize.LARGE,
            sortProperty: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            size: EColumnSize.BASE,
            sortProperty: 'email'
        },
        {
            title: 'Roles',
            key: 'roles',
            size: EColumnSize.LARGE,
            format: (roles) => roles?.map(role => role.name).join(', ')
        }
    ];

    public TABLE_SELECTION_TYPES = AppConstants.TABLE_SELECTION_TYPES;
    private saveConfig: ButtonConfig;
    public ESelectionMode = ESelectionMode;
    public selection = new SelectionModel<string>(true, [], false);
    private onDestroySubject = new Subject<void>();
    public disableNextPage = true;

    constructor(private userService: UserService,
                private groupService: GroupService,
                private fullModalService: FullModalService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IGroupFormComponentData) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();

        this.paginatorService.getPagination(this.tableId).subscribe((result: { pageIndex: number; pageSize: number }) => {
            this.pageIndex = result.pageIndex;
            this.pageSize = result.pageSize;

            this.getUsers();
        });

        this.setPageIndex();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.saveGroup());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }

    private initForm(): void {
        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveConfig.disabled = !valid);

        if (this.modalData.group) {
            this.formGroup.patchValue(this.modalData.group);
            this.selection.select(...this.modalData.group.users);
        }
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    public getUsers(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        this.userSubscription = this.userService.getUsers(null, this.pageSize, (this.pageIndex - 1) * this.pageSize,
            this.sortProperty, this.sortOrder, this.searchValue).subscribe(
            (result) => {
                this.users = result.items;
                this.totalUsers = result.total;
                this.disableNextPage = this.pageSize * this.pageIndex >= this.totalUsers;
            },
            (error) => Toaster.handleApiError(error)
        );
    }

    public saveGroup(): void {
        // When updating existing group do PATCH, else do POST
        if (this.modalData.group) {
            const group = new GroupPatchModel(this.formGroup.value.name, this.selection.selected);
            this.groupService.patchGroup(this.modalData.group._id, group).subscribe(
                (result) => {
                    Toaster.success('Content group updated successfully');
                    this.fullModalService.close(result, false);
                },
                (error) => Toaster.handleApiError(error));
        } else {
            const group = new GroupPostModel(this.formGroup.value.name, this.selection.selected);
            this.groupService.postGroup(group).subscribe(
                (result) => {
                    Toaster.success('Content group created successfully');
                    this.fullModalService.close(result, false);
                },
                (error) => Toaster.handleApiError(error));
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }
}
