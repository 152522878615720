import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DataFieldDataTypeModel} from './data-field-data-type.model';

export enum ERulesetPropertyType {
    FIELD = 'field',
    ENTITY = 'entity'
}

export class RulePropertyModel implements IDropdownItem {
    @autoserialize public name: string;
    @autoserialize public key: string;
    @autoserialize public type: ERulesetPropertyType;
    @autoserialize public isArray: boolean;
    @autoserializeAs(RulePropertyModel) public properties: RulePropertyModel[];
    @autoserializeAs(DataFieldDataTypeModel) public dataType: DataFieldDataTypeModel;
    @autoserialize public enableVariants: boolean = false;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this.key;
    }

    public get icon(): string {
        return this.enableVariants ? 'nucicon_variant' : '';
    }

    public getDataType(): string {
        return this.dataType.type;
    }
}
