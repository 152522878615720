<form *ngIf="formGroup; else error" [formGroup]="formGroup">
    <div class="row">
        <div class="left">
            <p class="title">
                #{{valueIndex + 1}}
            </p>
        </div>
        <div class="right">
            <ng-content></ng-content>
            <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteClicked.emit()"></nuc-button-secondary>
        </div>
    </div>

    <div class="row">
        <nuc-form-field label="Property">
            <nuc-dropdown [items]="propertyOptions"
                          [total]="propertyOptions.length"
                          [searchable]="true"
                          (requestData)="searchProperties($event)"
                          formControlName="property"
                          placeholder="Choose the property"></nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field *ngIf="formGroup.get('property').value?.isArray" label="Operator">
            <nuc-dropdown [items]="operators" [total]="operators.length" formControlName="operator" placeholder="Choose the operator"></nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field *ngIf="conditionTypes?.length" label="Type">
            <nuc-dropdown [items]="conditionTypes" [total]="conditionTypes.length" formControlName="type" placeholder="Choose type"></nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field *ngIf="inputType" [ngSwitch]="inputType" label="Value">
            <nuc-input *ngSwitchCase="'number'" formControlName="value" placeholder="Define the number value" type="number"></nuc-input>
            <nuc-input *ngSwitchCase="'string'" formControlName="value" placeholder="Define the string value" type="string"></nuc-input>
            <nuc-dropdown  *ngSwitchCase="'dropdown'"
                           [items]="dropdownItems"
                           [total]="dropdownItems.length"
                           formControlName="value"
                           [placeholder]="'Select option'">
            </nuc-dropdown>
            <nuc-datepicker *ngSwitchCase="'date'" formControlName="value"></nuc-datepicker>
        </nuc-form-field>
    </div>
</form>

<ng-template #error>
    <p>Please pass a FormGroup as Input</p>
</ng-template>
