import {ARUtils} from '@relayter/core';
import {GetPropertyPipe} from '@relayter/rubber-duck';

export class ModelUtil {
    /**
     * Create api body for post/patch requests
     *
     * @param {Record<string, any>} model
     * @param {string} [itemId]
     * @returns {Record<string, any>}
     */
    public static createApiBody(model: Record<string, any>, itemId?: string) {
        return Object.keys(model).reduce((item, key) => {
            const value = GetPropertyPipe.transform(model, key);
            if (ARUtils.isObject(value) && !(value instanceof Date)) {
                item[key] = ModelUtil.createApiBody(value, itemId);
            } else {
                if (value) {
                    item[key] = value;
                } else if (itemId) {
                    item[key] = null;
                }
            }
            return item;
        }, {});
    }
}
