import {Component, Input} from '@angular/core';
import {CustomWorkflowStepModel} from '../../../models/api/custom-workflow-step.model';

@Component({
    selector: 'rl-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent {

    @Input() public active: boolean = false;
    @Input() public hasNext: boolean = false;
    @Input() public hasPrevious: boolean = false;
    @Input() public step: CustomWorkflowStepModel;

    @Input() public hasBackwardsTransition: boolean = false;
    @Input() public hasIncomingBackwardsTransition: boolean = false;
    @Input() public hasFlyByTransition: boolean = false;

    @Input() public itemCount: number;

    public rippleColor = 'rgba(0,0,0,0.03)';
}
