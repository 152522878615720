import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EDataFieldCollectionName} from '../../app.enums';
import {
    ESelectionMode,
    ESortOrder,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    ITableItemPatchModel,
    TableComponent
} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {unlockedBadgeAnimation} from '../../animations/unlocked-badge-animation';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {forkJoin, Subscription} from 'rxjs';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {CursorArray} from '../../api/api-cursor';
import {CampaignItemsService} from '../../api/services/campaign-items.service';
import {Toaster} from '../../classes/toaster.class';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {ARLogger, ARUtils} from '@relayter/core';
import {TableInlineEditingUtil} from '../../classes/table-inline-editing.util';
import {PaginatorService} from '../paginator/paginator.service';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {SelectionModel} from '@angular/cdk/collections';
import {VariantModel} from '../../models/api/variant.model';
import {DataFieldModel} from '../../models/api/data-field.model';
import {VariantService} from '../../api/services/variant.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {BriefingTableData} from './briefing-table.data';
import {IDataFieldFilterOptions} from '../../api/services/data-fields.service';

export interface IBriefingTableOptions {
    inlineEditing?: boolean;
}

@Component({
    selector: 'briefing-table',
    templateUrl: './briefing-table.component.html',
    styleUrls: ['./briefing-table.component.scss'],
    animations: [unlockedBadgeAnimation.unlockBadge],
    providers: [AdvancedFiltersDataService, PaginatorService],
    host: {
        '[class.no-table]': '!campaignItems?.length'
    }
})
export class BriefingTableComponent extends RLTableComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public EDataFieldCollectionName = EDataFieldCollectionName;

    public tableLocked = true;
    public unlockTableTooltipText = 'Unlock table';

    @Output() public actionClicked: EventEmitter<IActionClickEvent> = new EventEmitter();
    @Output() public itemsChanged: EventEmitter<CampaignItemModel[]> = new EventEmitter();

    public columns: ITableColumn[];
    public ESelectionMode = ESelectionMode;

    public campaignItemsSubscription: Subscription;

    // Pagination and query params
    public pageIndex = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSizeOptions =
        AppConstants.PAGE_SIZE_OPTIONS.map((option) => new DropdownItem(`${option}`, option));
    public pageSize = this.pageSizeOptions[0].getValue();
    public totalItemCount = 0;
    public hasNext: boolean;

    public campaignItems: CampaignItemModel[] = [];
    @Input() public campaignId: string;
    @Input() public publicationId: string;
    @Input() public actions: ITableAction[] = [];
    @Input() public tableId: string;
    @Input() public briefingTableOptions: IBriefingTableOptions = {};

    public defaultColumns: ITableColumn[] = BriefingTableData.DefaultColumns;
    @Input() public additionalColumns: ITableColumn[] = [];
    @Input() public stickyHeader = true;
    @Input() public isLoading: boolean = false;
    @Input() public selectionMode: ESelectionMode = ESelectionMode.EXPAND;
    @Input() public selection: SelectionModel<string>;
    @Input() public assignableItems = false;

    private sortColumn: ITableColumn;
    public viewId: string;
    private cursorArray: CursorArray;

    public autocompleteValues: IDropdownItem[] = [];
    public phraseValue: string;

    // Filters setup
    public filtersSet: boolean;

    @ViewChild(TableComponent) public tableComponent: TableComponent;

    // Variants
    public variants: VariantModel[] = [];
    public variantEnabled = false;
    public selectedVariant: VariantModel;
    public requestOptions: IDataFieldFilterOptions;
    public dataFields: DataFieldModel[];
    private dataFieldsComponentUtil: DataFieldsComponentUtil;

    constructor(private userIsAllowedToPipe: UserIsAllowedToPipe,
                private variantService: VariantService,
                private campaignItemApiService: CampaignItemsApiService,
                private campaignItemService: CampaignItemsService,
                private dataFieldsService: DataFieldsApiService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
        this.dataFieldsComponentUtil = inject(DataFieldsComponentUtil);
    }

    public ngOnInit(): void {
        this.viewId = this.tableId;
        this.defaultColumns = [...this.defaultColumns, ...this.additionalColumns];
        this.requestOptions = {campaignId: this.campaignId};

        this.cursorArray = new CursorArray(this.pageIndex);

        forkJoin([
            this.variantService.getVariants(this.campaignId),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantData, dataFields]) => {
                    this.variants = variantData.items;
                    this.variantEnabled = this.variants.length && dataFields.some(field => field.enableVariants);
                    if (this.variants?.length > 0) {
                        this.selectedVariant = this.variants[0];
                    }
                    this.dataFields = dataFields;
                    this.updateColumns();
                },
                error: Toaster.handleApiError
            });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (filters: Record<string, any>) => {
                    this.filterValues = filters;
                    this.resetPageIndex();
                }
            );

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.cursorArray = new CursorArray(pagination.pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getCampaignItems();
            });
    }

    public onSearchTermChanged(search: string): void {
        this.autocompleteValues = [];
        if (search) {
            this.campaignItemApiService.autocomplete(search, this.campaignId).subscribe({
                next: (values) => {
                    this.autocompleteValues = values.map(v => {
                        return new DropdownItem(v, v);
                    });
                },
                error: Toaster.handleApiError
            });
        }
    }

    public onSearchSelectionChanged(values: IDropdownItem<string>[]): void {
        const value = values.map(v => v.getValue()).join('|');
        if (this.phraseValue !== value) {
            this.phraseValue = value;
            this.resetPageIndex();
        }
    }

    private resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.viewId, 1); // reset pageIndex
    }

    public onItemEdited(patchObj: ITableItemPatchModel): void {
        const {property, value} = TableInlineEditingUtil.getPropertyAndValue(patchObj);
        const patchColumn = this.columns.find(column => column.key === property);
        const itemToUpdate = this.campaignItems.find((item) => item._id === patchObj._id);
        // store previous value to reset in case of error
        let valueBackup = property.split('.').reduce((o, p) => o && o[p], itemToUpdate);
        // Variants are objects, and we don't want a reference but a copy of the value
        if (patchColumn.variantKey && ARUtils.isObject(valueBackup)) {
            valueBackup = Object.assign({}, valueBackup);
        }
        // modify front-end object to show user changes
        TableInlineEditingUtil.fillObjectFromPath(property, value, itemToUpdate, patchColumn?.variantKey);

        // create a patchBody containing only the property to update. Example: {dataFields: {some-prop: 'some value'}}
        const updateBody = TableInlineEditingUtil.fillObjectFromPath(property, value, {}, patchColumn?.variantKey);
        this.campaignItemService.updateCampaignItem(this.campaignId, patchObj._id, updateBody)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    ARLogger.debug('CampaignBriefingComponent: Campaign item edited inline');
                },
                error: (error) => {
                    // Reset to previous value in case of error
                    TableInlineEditingUtil.fillObjectFromPath(property, valueBackup, itemToUpdate);
                    Toaster.handleApiError(error);
                }
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortColumn = event.column?.sortProperty ? event.column : null;
        this.sortProperty = this.sortColumn?.sortProperty || '';
        this.sortOrder = (this.sortColumn?.sortProperty && event.sortOrder === ESortOrder.ASC) ? 'asc' : 'desc';
        this.resetPageIndex();
    }

    public getCampaignItems(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEMS)) {
            this.campaignItemsSubscription?.unsubscribe();

            const cursor = this.cursorArray.getCursor(this.pageIndex);
            this.campaignItemsSubscription = this.campaignItemApiService.getCampaignItems(this.campaignId, this.publicationId,
                this.pageSize, 0, this.phraseValue, this.sortProperty, this.sortOrder, this.filterValues, cursor, this.assignableItems)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.campaignItems = result.items;
                        this.hasNext = result.hasNext;
                        if (this.campaignItems.length > 0) {
                            const item = this.campaignItems[this.campaignItems.length - 1];
                            this.cursorArray.setCursor(this.pageIndex, this.sortProperty, item);
                        }

                        this.itemsChanged.emit(this.campaignItems);
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public hasNextItemForCampaignItem(campaignItemId: string): boolean {
        const itemIndex = this.campaignItems.findIndex(item => item._id === campaignItemId);
        // Not found
        if (itemIndex === -1) {
            return false;
        }

        // Item before last item
        if (itemIndex < (this.campaignItems.length - 1)) {
            return true;
        }

        // Item is last item
        if (itemIndex === (this.campaignItems.length - 1)) {
            return this.hasNext;
        }

        return false;
    }

    public selectVariant(variant: VariantModel): void {
        if (this.selectedVariant !== variant) {
            this.selectedVariant = variant;
            this.updateColumns();
        }
    }

    private updateColumns(): void {
        this.columns = [
            ...BriefingTableData.PermanentColumns,
            ...this.dataFieldsComponentUtil.getDataFieldsColumnSelection(this.dataFields, true, this.selectedVariant?.key),
            ...this.defaultColumns
        ];
    }
}
