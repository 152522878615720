import {ARLogger} from '@relayter/core';
import {SocketRoom} from './socket-room';
import {Socket} from 'socket.io-client';
import {ESocketAction} from './constants/socket-actions';
import {Observable, Subject} from 'rxjs';
import {ISocketMessageBody} from './socket.service';
import {filter, takeUntil} from 'rxjs/operators';

export class SocketRoomManager {
    private rooms: SocketRoom[] = [];
    private _socket: Socket;

    constructor(private messageSubject: Subject<ISocketMessageBody>) {}

    public set socket(socket: Socket) {
        this._socket = socket;
    }

    private getRoom(roomId: string): SocketRoom {
        return this.rooms.find(room => room.roomId === roomId);
    }

    public rejoinRooms(): void {
        for (const room of this.rooms) {
            this.joinSocketRoom(room.roomId);
        }
    }

    public clear(): void {
        for (const room of this.rooms) {
            this.leaveRoom(room.roomId);
        }
        this.rooms = [];
    }

    /**
     * Adds the room (if not already managed) and joins it on the server socket
     * Returns an observable to subscribe on to receive the messages published for this room
     */
    public joinRoom(roomId: string): Observable<ISocketMessageBody> {
        let room = this.getRoom(roomId);
        if (!room) {
            room = new SocketRoom(roomId);
            this.rooms.push(room);
            room.obs = this.messageSubject.pipe(
                filter((update) => update.room === roomId),
                takeUntil(room.obsDestroy)
            );
        }

        this.joinSocketRoom(roomId);

        return room.obs;
    }

    /**
     * Sends a 'join-room' event to the server socket
     * @param {string} roomId
     */
    private joinSocketRoom(roomId: string): void {
        // Best effort join (connection may be down when we call this) if no connection is up we will retry joining after authentication
        if (this._socket) {
            this._socket.emit(ESocketAction.JOIN_ROOM, roomId, (ack) => this.messageSubject.next(ack));
        } else {
            ARLogger.info(`SocketRoomManager: tried to join the room ${roomId} but there is no socket`);
        }

    }

    /**
     * Leaves the room on the server socket and completes the observable for this room
     */
    public leaveRoom(roomId: string): void {
        if (this._socket) {
            this._socket.emit(ESocketAction.LEAVE_ROOM, roomId);
        } else {
            ARLogger.info(`SocketRoomManager: tried to leave the room ${roomId} but there is no socket`);
        }

        const roomToLeave = this.getRoom(roomId);
        if (!roomToLeave) {
            ARLogger.warn(`Called leaveRoom with roomId: ${roomId}, but we were never in the room`);
            return;
        }

        roomToLeave.destroyRoom();

        // Remove room from the managed list
        this.rooms = this.rooms.filter(room => room.roomId !== roomId);
    }
}
