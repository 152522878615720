import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ProductModel} from '../../../models/api/product.model';
import {AppConstants} from '../../../app.constants';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {EColumnDataType, ESortOrder, ISortOptionEvent, ITableColumn, ITableItem} from '@relayter/rubber-duck';
import {ProductService} from '../../../api/services/products.service';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../../app.enums';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {takeUntil, tap} from 'rxjs/operators';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TableConfigService} from '../../../api/services/table-config.service';
import {AdvancedFiltersDataService} from '../../../api/services/advanced-filters.data-service';

import {
    CAMPAIGN_ITEM_PRODUCTS_SELECTED_ICON_COLUMN,
    CAMPAIGN_ITEM_PRODUCTS_TABLE_DEFAULT_COLUMNS
} from './campaign-item-products-table-columns';
import {DataFieldsComponentUtil} from '../../../classes/data-fields-component.util';
import {CursorArray} from '../../../api/api-cursor';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {DataFilterModel} from '../../../models/ui/data-filter.model';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {DataFieldModel} from '../../../models/api/data-field.model';
import {ApiConstants} from '../../../api/api.constant';

@Component({
    selector: 'campaign-item-products-form',
    templateUrl: './campaign-item-products-form.component.html',
    styleUrls: ['./campaign-item-products-form.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class CampaignItemProductsFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'campaign-item-products-table';
    @Input() public readonly = false;
    @Input() public form: UntypedFormGroup;

    @Input() public selectedProducts: ProductModel[];
    @Output() public selectedProductsChange = new EventEmitter<ProductModel[]>();

    public EDataFieldCollectionName = EDataFieldCollectionName;

    private onDestroySubject = new Subject<void>();

    public productsSubscription: Subscription;

    public products: ProductModel[] = [];
    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public disableNextPage: boolean = true;
    public pageSizeOptions: DropdownItem<number>[] =
        AppConstants.PAGE_SIZE_OPTIONS.map((pageSize) => new DropdownItem(`${pageSize}`, pageSize));
    public disableSort = false;

    public allColumns: ITableColumn[];
    public allSelectedColumns: ITableColumn[];

    public searching: boolean = false;

    public dataFilters: DataFilterModel[] = [new DataFilterModel('RAN', 'ran', EDataFieldTypes.STRING)];

    private cursorArray: CursorArray;
    private sortColumn: ITableColumn;

    private dataFieldsComponentUtil: DataFieldsComponentUtil;

    constructor(private productsService: ProductService,
                private dataFieldsService: DataFieldsApiService,
                private filtersDataService: AdvancedFiltersDataService,
                private tableConfigService: TableConfigService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);

        this.dataFieldsComponentUtil = inject(DataFieldsComponentUtil);
    }

    public ngOnInit(): void {
        this.initForm();

        this.getTableData();

        this.cursorArray = new CursorArray(this.pageIndex, true);

        this.filtersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((values) => {
                this.filterValues = values;

                this.setPageIndex();
            });

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }

                this.getProducts();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initForm(): void {
        this.form.contains('products') ?
            this.selectedProducts = this.form.get('products').value :
            this.form.addControl('products', new UntypedFormControl(this.selectedProducts));
    }

    private getTableData(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (dataFields) => {
                    this.initializeTable(dataFields);
                    this.setPageIndex();
                },
                error: (err) => Toaster.handleApiError(err)
            });
    }

    private initializeTable(dataFields: DataFieldModel[]): void {
        this.allColumns = [
            ...CAMPAIGN_ITEM_PRODUCTS_TABLE_DEFAULT_COLUMNS,
            ...this.dataFieldsComponentUtil.getDataFieldsColumnSelection(dataFields)
        ];

        this.allSelectedColumns = [...this.allColumns, CAMPAIGN_ITEM_PRODUCTS_SELECTED_ICON_COLUMN]
            .map(column => ({...column, sortProperty: undefined}));
    }

    private setPageIndex(pageIndex = 1): void {
        this.cursorArray.reset(pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getProducts(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PRODUCTS)) {
            const cursor = this.cursorArray.getCursor(this.pageIndex);

            // prevent showing results from older slower calls
            if (this.productsSubscription) {
                this.productsSubscription.unsubscribe();
            }

            this.productsSubscription = this.productsService.getData(
                this.sortOrder === 'desc', !this.searchValue ? this.sortProperty : null, this.searchValue,
                this.pageSize, 0, this.filterValues, cursor)
                .pipe(
                    tap(() => {
                        this.searching = !!this.searchValue || (this.filterValues && Object.values(this.filterValues).length > 0);
                    }),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe(
                    (result: ARPagedResponseDataModel<ProductModel>) => {
                        this.products = result.items;

                        if (result.items.length > 0) {
                            this.disableNextPage = !result.hasNext;
                            this.setCursor();
                        }
                    },
                    (err: ARApiError) => Toaster.handleApiError(err)
                );
        }
    }

    public onSelectionChanged(selectedProducts: ITableItem[]): void {
        const products = selectedProducts as ProductModel[];
        this.selectedProductsChange.emit(products);
        this.form.get('products').setValue(products);
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.searchValue) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, this.sortColumn?.sortDuplicates);
        }
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.products[this.products.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sortProperty, this.products[this.products.length - 1]);
        }
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.searching = !!this.searchValue;
            this.disableSort = !!this.searchValue;

            this.setPageIndex();
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column?.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.sortColumn = sortEvent.column;

        if (!this.disableSort) this.setPageIndex();
    }
}
