import {AfterViewInit, Component, forwardRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {IDataFieldFilterOptions} from '../../../api/services/data-fields.service';
import {takeUntil} from 'rxjs/operators';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {DataFilterModel} from '../../../models/ui/data-filter.model';
import {AppConstants} from '../../../app.constants';

@Component({
    selector: 'rl-data-filter-dropdown',
    templateUrl: './data-filter-dropdown.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DataFilterDropdownComponent),
        multi: true
    }]
})
export class DataFilterDropdownComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    protected onDestroySubject = new Subject<void>();

    public dropdownItems: IDropdownItem[] = [];
    public total: number;
    protected search: string;

    @Input() public dataFilter: DataFilterModel;
    @Input() public requestProperties: IDataFieldFilterOptions = {};

    @ViewChild('dropDown', {static: true}) public dropDown: ControlValueAccessor;

    constructor(private dataCollectionService: DataCollectionService) {}

    public ngAfterViewInit(): void {
        this.getDataValues(0);
    }

    public getDataValues(offset = 0): void {
        this.dataCollectionService.getDataCollectionValues(this.dataFilter.collectionName,
            this.dataFilter.property, this.dataFilter.requestOptions, AppConstants.PAGE_SIZE_MAX, offset, this.search,
            null, this.dataFilter.workflowFilterId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(results => {
                if (offset === 0) {
                    this.dropdownItems = [];
                    if (!this.search) {
                        this.dropdownItems.push(new DropdownItem('Empty', 'undefined'));
                    }
                }
                this.total = results.total;
                this.dropdownItems = this.dropdownItems.concat(results.items.map((value) => {
                    return new DropdownItem(value.displayName, value.key);
                }));
            });
    }

    public getItems(event: {offset: number; limit: number}): void {
        this.getDataValues(event.offset);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    // ControlValueAccessor interface functions are delegated to DropdownComponent.
    // Proper Component inheritance doesn't exist in angular
    public writeValue(obj: any): void {
        this.dropDown.writeValue(obj);
    }

    public registerOnChange(fn: any): void {
        this.dropDown.registerOnChange(fn);
    }

    public registerOnTouched(fn: any): void {
        this.dropDown.registerOnTouched(fn);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.dropDown.setDisabledState(isDisabled);
    }
}
