import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA, NucIcons} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {Toaster} from '../../classes/toaster.class';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {PermissionsService} from '../../api/services/permissions.service';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';

export interface IWorkflowConfigurationStepFormData {
    workflowConfigurationId: string;
    workflowConfigurationStep: CustomWorkflowStepModel;
}

@Component({
    selector: 'workflow-configuration-step-form-component',
    templateUrl: 'workflow-configuration-step-form.component.html',
    styleUrls: ['workflow-configuration-step-form.component.scss']
})
export class WorkflowConfigurationStepFormComponent implements OnInit, OnDestroy {
    public readonly nucIcons: DropdownItem<string>[];
    public permissions: DropdownItem<string>[];
    public nucIconOptions: DropdownItem<string>[] = [];
    public formGroup: FormGroup;
    private saveButton: ButtonConfig;

    private onDestroySubject = new Subject<void>();
    private workflowConfigurationId: string;
    private workflowConfigurationStep: CustomWorkflowStepModel;

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private permissionService: PermissionsService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationStepFormData) {
        this.nucIcons = [...NucIcons].sort().map(nucIcon =>
            new DropdownItem(
                nucIcon,
                `nucicon_${nucIcon}`,
                null,
                `nucicon_${nucIcon}`
            ));
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationStep());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initForm(): void {
        const stepIcon = this.nucIcons.find(icon => icon.getValue() === this.workflowConfigurationStep.icon);
        const permissions = this.permissions.filter(permission => this.workflowConfigurationStep.permissions.includes(permission.getValue()));

        this.formGroup = new FormGroup<any>({
            name: new FormControl(this.workflowConfigurationStep.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            icon: new FormControl(stepIcon, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            permissions: new FormControl(permissions),
            schedule: new FormGroup({
                hours: new FormControl()
            })
        });

        this.formGroup.get('schedule').patchValue(this.workflowConfigurationStep.schedule);
        this.listenToFormChanges();
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((status: string) => this.saveButton.disabled = status !== 'VALID');
    }

    private initData(): void {
        this.workflowConfigurationId = this.modalData.workflowConfigurationId;
        this.workflowConfigurationStep = this.modalData.workflowConfigurationStep || new CustomWorkflowStepModel();

        this.nucIconOptions = [...this.nucIcons];

        this.permissionService.getAllPermissions('key', 'asc')
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (permissions) => {
                    this.permissions = permissions.map(permission => new DropdownItem(
                        permission._id,
                        permission._id
                    ));

                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private saveWorkflowConfigurationStep(): void {
        const step = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            icon: this.formGroup.value.icon.getValue(),
            permissions: this.formGroup.value.permissions?.map(permission => permission.getValue()) || [],
            schedule: {
                hours: this.formGroup.value.schedule.hours
            }
        }, this.workflowConfigurationStep._id);

        if (this.workflowConfigurationStep._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationStep(this.workflowConfigurationId, this.workflowConfigurationStep._id, step)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Step updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationStep(this.workflowConfigurationId, step)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Step created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public searchIcons(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.nucIconOptions = [];
        const regex = new RegExp(event.search, 'i');
        this.nucIconOptions = this.nucIcons.filter((icon) => icon.getTitle().match(regex)?.length > 0);
    }
}
