import {autoserialize} from 'cerialize';
import {EDataFieldTypes} from '../../app.enums';

export class RuleConditionModel {
    @autoserialize public property: string;
    @autoserialize public operator?: string;
    @autoserialize public type: string;
    @autoserialize public dataType?: EDataFieldTypes;
    @autoserialize public value?: boolean | string | number | Date;

    constructor(property?: string, type?: string, value?: boolean | string | number | Date,  operator?: string, dataType?: EDataFieldTypes) {
        this.property = property;

        if (value !== null) this.value = value;
        if (type) this.type = type;
        if (operator) this.operator = operator;
        if (dataType) this.dataType = dataType;
    }
}
