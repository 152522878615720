<nuc-suggestive-input
    #dropDown
    [options]="dropdownItems"
    prefix="nucicon_search"
    [total]="total"
    [enableNewValue]="false"
    (searchTermChange)="onSearchTermChanged($event)"
    (requestData)="getItems($event)"
    [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase) + '(s)'">
</nuc-suggestive-input>
