import {AfterViewInit, Component, DestroyRef, inject, Inject, OnInit, ViewChild} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    EColumnDataType,
    ESelectionMode,
    ESortOrder,
    FullModalActionModel,
    FullModalService,
    ISortOptionEvent,
    ITableColumn,
    ITableItem,
    NUC_FULL_MODAL_DATA,
    TabBarComponent
} from '@relayter/rubber-duck';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {CursorArray} from '../../api/api-cursor';
import {forkJoin, Subscription} from 'rxjs';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../app.enums';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {PaginatorService} from '../paginator/paginator.service';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {Toaster} from '../../classes/toaster.class';
import {DataFieldModel} from '../../models/api/data-field.model';
import {ApiConstants} from '../../api/api.constant';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {tap} from 'rxjs/operators';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {BriefingTableData} from '../briefing-table/briefing-table.data';
import {
    CampaignService,
    ECampaignJobTypes,
    ICopyMasterBriefingToCampaignJobData
} from '../../api/services/campaigns.service';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {
    FormArray,
    FormControl,
    FormControlStatus,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';

export interface IImportFromMasterBriefingComponentData {
    campaignId: string;
}

@Component({
  selector: 'import-from-master-briefing-component',
  templateUrl: './import-from-master-briefing.component.html',
  styleUrls: ['./import-from-master-briefing.component.scss'],
  providers: [PaginatorService, AdvancedFiltersDataService]
})
export class ImportFromMasterBriefingComponent extends RLTableComponent implements OnInit, AfterViewInit {
    @ViewChild(TabBarComponent) public tabBar: TabBarComponent;

    private destroyRef = inject(DestroyRef);
    public readonly campaignId: string;
    public readonly tableId = 'import-from-master-briefing-table';

    public selectedBriefings: CampaignItemModel[];
    public masterBriefings: CampaignItemModel[] = [];

    public masterBriefingSubscription: Subscription;

    public selectionMode = ESelectionMode.MULTI;
    public columns: ITableColumn[];

    public pageIndex: number;
    public pageSize: number;

    private sortColumn: ITableColumn;
    public disableSort = false;

    public disableNextPage = true;
    private cursorArray: CursorArray;

    public searching = false;
    public filterContext = EDataFieldCollectionName.CAMPAIGN_ITEM;
    public dataFilters: DataFilterModel[] = [];

    private dataFieldComponentUtil: DataFieldsComponentUtil;
    public dataFields: DataFieldModel[];

    public variants: VariantModel[];
    public selectedVariant: VariantModel;
    public variantEnabled: boolean;

    private importButton: ButtonConfig;

    public autocompleteValues: IDropdownItem[] = [];

    public tabs: TabBarItemModel[] = [new TabBarItemModel('Selection', 0), new TabBarItemModel('Data Field options', 1)]
    public activeTab: TabBarItemModel = this.tabs[0] as TabBarItemModel;
    private prevNextModalAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Next'));

    public dataFieldCopyOptionsForm = new FormArray([], [this.optionsValidator()]);

    private optionsValidator(): ValidatorFn {
        return (controls: any): ValidationErrors | null => {
            const rawValues = controls.getRawValue();
            rawValues.forEach((option: Record<string, any>, index: number) => {
                const duplicateDataFields = !!rawValues.find((value: Record<string, any>, i: number) => {
                    return value.dataField && option.dataField && value.dataField._id === option.dataField._id && index !== i;
                });
                const control = controls.at(index);

                if (duplicateDataFields) {
                    control.setErrors({duplicateDataFields: {value: 'Duplicate field not allowed.'}});
                } else {
                    control.setErrors(null);
                }
            })
            return;
        };
    }

    constructor(private paginatorService: PaginatorService,
                private dataFieldsApiService: DataFieldsApiService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                private campaignItemsApiService: CampaignItemsApiService,
                private variantService: VariantService,
                userSettingsStorageService: UserSettingsStorageService,
                private fullModalService: FullModalService,
                private campaignService: CampaignService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IImportFromMasterBriefingComponentData) {
        super(userSettingsStorageService);
        this.dataFieldComponentUtil = inject(DataFieldsComponentUtil);
        this.campaignId = this.modalData.campaignId;
    }

    public ngAfterViewInit(): void {
        this.tabBar.activeTabChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.prevNextModalAction.button.text = this.tabBar.activeTabIndex === 0 ? 'Next' : 'Previous';
        })
    }

    public ngOnInit(): void {
        this.cursorArray = new CursorArray(this.pageIndex);
        this.setModalActions();
        this.getVariantsAndDataFields();
        this.listenToAdvancedFilter();
        this.listenToPagination();

        this.dataFieldCopyOptionsForm.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((newStatus: FormControlStatus) => {
            this.importButton.disabled = newStatus !== 'VALID' || !this.selectedBriefings?.length;
        });

    }

    private setModalActions(): void {
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        cancelAction.observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullModalService.close(false, true));
        this.importButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Import', false, false, true);
        const importAction = new FullModalActionModel(this.importButton);
        importAction.observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.scheduleImportJob())

        this.prevNextModalAction.observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.activeTab = this.activeTab === this.tabs[0] ? this.tabs[1] : this.tabs[0]);
        this.fullModalService.setModalActions([cancelAction, this.prevNextModalAction, importAction]);
    }

    private getVariantsAndDataFields(): void {
        forkJoin([
            this.variantService.getVariants(this.campaignId),
            this.dataFieldsApiService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantData, dataFields]) => {
                    this.variants = variantData.items;
                    this.variantEnabled = this.variants.length && dataFields.some(field => field.enableVariants);
                    if (this.variants?.length > 0) {
                        this.selectedVariant = this.variants[0];
                    }
                    this.dataFields = dataFields;
                    this.updateColumns();
                },
                error: Toaster.handleApiError
            });
    }

    private listenToAdvancedFilter(): void {
        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (filterValues) => {
                    if (this.filterValues !== filterValues) {
                        this.filterValues = filterValues;
                        this.setPageIndex();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }
                this.getMasterBriefings();
            });
    }

    private updateColumns(): void {
        this.columns = [
            ...BriefingTableData.PermanentColumns,
            ...this.dataFieldComponentUtil.getDataFieldsColumnSelection(this.dataFields, false, this.selectedVariant?.key),
            ...BriefingTableData.DefaultColumns
        ];
    }

    private setPageIndex(pageIndex = 1): void {
        this.cursorArray.reset(pageIndex, this.sortColumn?.sortDuplicates, this.sortColumn?.dataType);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getMasterBriefings(): void {

        this.masterBriefingSubscription?.unsubscribe()
        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.masterBriefingSubscription =
            this.campaignItemsApiService.getCampaignItems(undefined, undefined,
                this.pageSize, 0, this.searchValue, this.sortProperty, this.sortOrder, this.filterValues, cursor)
                .pipe(
                    tap(() => {
                        this.searching = !!this.searchValue;
                    }),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: (res: ARPagedResponseDataModel<CampaignItemModel>) => {
                        this.masterBriefings = res.items;
                        this.disableNextPage = !res.hasNext;

                        if (res.items.length > 0) {
                            this.setCursor();
                        }
                    },
                    error: (err: ARApiError) => {
                        this.searching = false;
                        Toaster.handleApiError(err);
                    }
                });
    }

    public onSelectionChanged(selectedBriefings: ITableItem[]): void {
        this.selectedBriefings = selectedBriefings as CampaignItemModel[];
        this.importButton.disabled = !this.selectedBriefings.length || this.dataFieldCopyOptionsForm.status !== 'VALID';
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.searchValue) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, this.sortColumn?.sortDuplicates);
        }
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.masterBriefings[this.masterBriefings.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sortProperty, this.masterBriefings[this.masterBriefings.length - 1]);
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column?.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.sortColumn = sortEvent.column;

        // Ignore sort events when sort disabled
        if (!this.disableSort) this.setPageIndex();
    }

    public selectVariant(variant: IDropdownItem): void {
        if (this.selectedVariant !== variant) {
            this.selectedVariant = variant as VariantModel;
            this.updateColumns();
        }
    }

    private scheduleImportJob(): void {
        this.importButton.loading = true;

        const dataFieldCopyOptions = this.dataFieldCopyOptionsForm.getRawValue().map((option) => {
            const jobDataOption = {
                dataFieldId: option.dataField._id,
                behavior: option.behavior.value
            };
            if (option.value) {
                switch (option.dataField.getDataType()) {
                    case EDataFieldTypes.LIST:
                    case EDataFieldTypes.ENUM:
                        jobDataOption['value'] = option.value.map((item) => item.value);
                        break;
                    case EDataFieldTypes.BOOLEAN:
                        jobDataOption['value'] = option.value.value;
                        break;
                    default:
                        jobDataOption['value'] = option.value;
                        break;
                }

            }
            return jobDataOption;
        });

        const jobData = {
            campaignId: this.campaignId,
            campaignItemIds: this.selectedBriefings.map((briefing) => briefing._id),
            dataFieldCopyOptions
        } as ICopyMasterBriefingToCampaignJobData;

        this.campaignService.postJob(ECampaignJobTypes.COPY_MASTER_BRIEFING_TO_CAMPAIGN_JOB, jobData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.fullModalService.close(result); // for later monitor the job
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.importButton.loading = false;
                }
            });
    }

    public onSearchTermChanged(search: string): void {
        this.autocompleteValues = [];
        if (search) { // we search master briefing
            this.campaignItemsApiService.autocomplete(search, null).subscribe({
                next: (values) => {
                    this.autocompleteValues = values.map((v) => {
                        return new DropdownItem(v, v);
                    });
                },
                error: Toaster.handleApiError
            });
        }
    }

    public onSearchSelectionChanged(values: IDropdownItem<string>[]): void {
        const value = values.map((v) => v.getValue()).join('|');
        if (this.searchValue !== value) {
            this.searchValue = value;
            this.setPageIndex();
        }
    }

    public deleteOption(index: number) {
        this.dataFieldCopyOptionsForm.removeAt(index);
    }

    protected addOption() {
        const optionGroup = new FormGroup({
            dataField: new FormControl('', {validators: [Validators.required]}),
            behavior: new FormControl( '', {validators: [Validators.required]})
        });
        this.dataFieldCopyOptionsForm.push(optionGroup);
    }
}
