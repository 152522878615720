import {concat, Observable, of} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {AppConstants} from '../../app.constants';
import {reduce, switchMap} from 'rxjs/operators';
import {BaseApiRequestService} from './base-api-request.service';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {Injectable} from '@angular/core';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {ApiPostRequest} from '../requests/api-post.request';
import {ApiDeleteRequest} from '../requests/api-delete.request';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {ApiPutRequest} from '../requests/api-put.request';
import {JobModel} from '../../models/api/job.model';
import {ApiAutocompleteRequest} from '../requests/api-autocomplete.request';

@Injectable({
    providedIn: 'root'
})
export class ApiRequestService extends BaseApiRequestService {
    public findOne<T extends IResponseModel>(request: ApiDetailsRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public find<T extends IResponseModel>(request: ApiPagedRequest): Observable<ARPagedResponseDataModel<T>> {
        return new Observable((obs) => {
            this.handlePagedResponse<T>(request.options, obs, request.responseModel);
        });
    }

    // TODO: Should also work for cursor requests
    public findAll<T extends IResponseModel>(request: ApiPagedRequest): Observable<T[]> {
        return this.find(request).pipe(
            switchMap((result) => {
                const observables = [of(result)];
                const totalPages = Math.ceil(result.total / AppConstants.PAGE_SIZE_MAX);

                for (let page = 0; page < totalPages - 1; page++) {
                    const clonedRequest = request.clone() as ApiPagedRequest;
                    clonedRequest.offset = (page + 1) * AppConstants.PAGE_SIZE_MAX;
                    observables.push(this.find(clonedRequest));
                }

                return concat(...observables);
            }),
            reduce((acc, val) => acc.concat(val.items), [])
        );
    }

    public autocomplete(request: ApiAutocompleteRequest): Observable<string[]> {
        return new Observable((obs) => {
            this.handleStringArrayResponse(request.options, obs);
        });
    }

    public create<T extends IResponseModel>(request: ApiPostRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public patch<T extends IResponseModel>(request: ApiPatchRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public put<T extends IResponseModel>(request: ApiPutRequest):  Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public deleteOne(request: ApiDeleteRequest):  Observable<boolean> {
        return new Observable((obs) => {
            this.handleNoErrorResponse(request.options, obs);
        });
    }

    public postJob(request: ApiPostJobRequest): Observable<JobModel> {
        return new Observable((obs) => {
            this.handleDetailResponse<JobModel>(request.options, obs, JobModel);
        });
    }
}
