<ng-container *ngIf="permissions.GET_WORKFLOW_AUTOMATION_DETAILS | userIsAllowedTo; else noPermissionEmptyState">
    <ng-container *ngIf="workflowAutomation; else loadingIndicator">
        <div class="header">
            <h1>{{workflowAutomation.name}}</h1>

            <nuc-button-bar>
                <nuc-button-primary *ngIf="permissions.POST_WORKFLOW_AUTOMATION_RULES | userIsAllowedTo"
                                    text="Add automation rule"
                                    (click)="onAddClicked()">
                </nuc-button-primary>
            </nuc-button-bar>
        </div>

        <div class="workflow-automation-info">
            <nuc-label>Description</nuc-label>
            <p>{{workflowAutomation.description}}</p>
        </div>

        <ng-container *ngIf="permissions.GET_WORKFLOW_AUTOMATION_RULES | userIsAllowedTo; else noPermissionEmptyState">
            <nuc-table [useButtonBar]="true"
                       [useEmptyState]="true"
                       [items]="items"
                       [columns]="columns"
                       [loading]="!serviceSubscription?.closed"
                       [actions]="actions"
                       [tableId]="tableId"
                       [prefixStorageKey]="storageKey"
                       (actionClicked)="handleTableRowAction($event)"
                       (sortOptionChange)="onSortOptionChanged($event)">
                <paginator button-bar-right
                           [viewId]="tableId"
                           [disableNextPage]="!hasNext"
                           [loading]="!serviceSubscription?.closed">
                </paginator>
            </nuc-table>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
