<form [formGroup]="formGroup">
    <div class="card-view-container">
        <div class="label">
            <p class="section-header">File type</p>
            <p>Select the file type for your image</p>
        </div>

        <div class="card-view">
            <div class="card-content">
                <div>
                    <p class="body-strong">JPEG</p>
                    <p>Optimal compression</p>
                </div>

                <div>
                    <nuc-radiobutton [data]="jpegType" [dataItems]="dataItems" (click)="onFileTypeClicked(fileType.JPEG)"></nuc-radiobutton>
                </div>
            </div>
        </div>

        <div class="card-view">
            <div class="card-content">
                <div>
                    <p class="body-strong">PNG</p>
                    <p>Best compressed to quality ratio</p>
                </div>

                <div>
                    <nuc-radiobutton [data]="pngType" [dataItems]="dataItems" (click)="onFileTypeClicked(fileType.PNG)"></nuc-radiobutton>
                </div>
            </div>
        </div>
    </div>

    <div class="card-view-container">
        <div class="label">
            <p class="section-header">Size</p>
            <p>Select the size for your image</p>
        </div>

        <div class="card-view">
            <div class="card-content">
                <div>
                    <p class="body-strong">Original size</p>
                    <p>{{image.width}} px - {{image.height}} px</p>
                </div>

                <div>
                    <nuc-radiobutton [data]="originalSize" [dataItems]="dataItems" (click)="onSizeTypeClicked(sizeType.ORIGINAL)"></nuc-radiobutton>
                </div>
            </div>

        </div>

        <div class="card-view">
            <div class="card-content">
                <div>
                    <p class="body-strong">Custom size</p>
                    <p>Custom file size with the aspect ratio locked</p>
                </div>

                <div>
                    <nuc-radiobutton [data]="customSize " [dataItems]="dataItems" (click)="onSizeTypeClicked(sizeType.CUSTOM)"></nuc-radiobutton>
                </div>
            </div>

            <div class="size-options">
                <nuc-form-field label="Width">
                    <nuc-input formControlName="width" type="number"></nuc-input>
                </nuc-form-field>

                <nuc-form-field label="Height">
                    <nuc-input formControlName="height" type="number"></nuc-input>
                </nuc-form-field>
            </div>
        </div>
    </div>
</form>
