import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormArray, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {Toaster} from '../../classes/toaster.class';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {LayoutModel} from '../../models/api/layout.model';
import {ChannelModel} from '../../models/api/channel.model';
import {LayoutsService} from '../../api/services/layouts.service';
import {PublicationTypesService} from '../../api/services/publication-types.service';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
    CustomWorkflowIdentifierSettingFragmentModel,
    CustomWorkflowIdentifierSettingModel,
    EIdentifierSettingFragmentType
} from '../../models/api/custom-workflow-identifier-setting.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {FormArrayMinLengthValidator} from '../../classes/validators/form-array-min-length.validator';

export interface IWorkflowConfigurationFormData {
    workflowConfiguration: WorkflowConfigurationModel;
}

@Component({
    selector: 'workflow-configuration-form-component',
    templateUrl: 'workflow-configuration-form.component.html',
    styleUrls: ['workflow-configuration-form.component.scss']
})
export class WorkflowConfigurationFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public formGroup: FormGroup;
    public identifierSettingFormGroup: UntypedFormGroup;

    private saveButton: ButtonConfig;

    public workflowConfiguration: WorkflowConfigurationModel;
    public identifierSetting: CustomWorkflowIdentifierSettingModel;
    public publicationTypes: ChannelModel[];
    public layouts: LayoutModel[];
    public fragmentTypeOptions: DropdownItem<string>[]
        = Object.keys(EIdentifierSettingFragmentType).map((type) => new DropdownItem(type, type));
    public allowedProperties: RulePropertyModel[];

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private publicationTypesService: PublicationTypesService,
                private layoutService: LayoutsService,
                private propertyService: PropertyService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationFormData) {
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfiguration());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.identifierSetting = this.workflowConfiguration.identifierSetting || this.getDefaultIdentifierSetting();
        const propertyContext = this.workflowConfiguration.publicationType.name === EPublicationType.PRINT_MAGAZINE
            ? EPropertyContext.WORKFLOW_IDENTIFIER_SETTING_PRINT : EPropertyContext.WORKFLOW_IDENTIFIER_SETTING;

        forkJoin([
            this.layoutService.find(),
            this.publicationTypesService.find(),
            this.propertyService.getProperties(propertyContext)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([layoutsResult, publicationTypesResults, properties]) => {
                    this.publicationTypes = publicationTypesResults.items;
                    this.layouts = layoutsResult.items;
                    this.allowedProperties = properties;

                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {

        this.identifierSettingFormGroup = new UntypedFormGroup({
            join: new FormControl(this.identifierSetting.join, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            sequence: new FormControl(this.identifierSetting.sequence),
            fragments: new FormArray((this.identifierSetting.fragments).map(fragment => {
                const foundType = this.fragmentTypeOptions.find((type) => fragment.type === type.getValue());

                const formGroup = new UntypedFormGroup({
                    type: new FormControl(foundType, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                });

                if (foundType.getValue() === EIdentifierSettingFragmentType.FIXED) {
                    formGroup.addControl('fixedValue', new UntypedFormControl(fragment.fixedValue));
                }

                if (foundType.getValue() === EIdentifierSettingFragmentType.DERIVED) {
                    const selectedProperty = this.allowedProperties.find((prop) => fragment.property === prop.key);
                    formGroup.addControl('property', new UntypedFormControl(selectedProperty, RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
                }
                return formGroup;
            }), FormArrayMinLengthValidator(1))
        });

        this.formGroup = new FormGroup<any>({
            name: new FormControl(this.workflowConfiguration?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            publicationType: new FormControl({
                value: this.workflowConfiguration?.publicationType,
                disabled: !!this.workflowConfiguration
            }, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            layout: new FormControl(this.workflowConfiguration?.layout),
            identifierSetting: this.identifierSettingFormGroup
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: string) => this.saveButton.disabled = status !== 'VALID');
    }

    private saveWorkflowConfiguration(): void {
        const identifierSetting = {
            join: this.identifierSettingFormGroup.value.join,
            sequence: this.identifierSettingFormGroup.value.sequence,
            fragments: this.identifierSettingFormGroup.value.fragments.map((fragmentGroup: Record<string, any>) => {
                const type = fragmentGroup.type.getValue();
                const fragment: CustomWorkflowIdentifierSettingFragmentModel = {type};
                if (type === EIdentifierSettingFragmentType.FIXED) {
                    fragment.fixedValue = fragmentGroup.fixedValue;
                }
                if (type === EIdentifierSettingFragmentType.DERIVED) {
                    fragment.property = fragmentGroup.property.getValue();
                }
                return fragment;
            })
        };

        const workflow = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            layout: this.formGroup.value.layout?.getValue(),
            identifierSetting
        }, this.workflowConfiguration._id);

        if (this.workflowConfiguration._id) {
            this.workflowConfigurationService.patch(this.workflowConfiguration._id, workflow)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Workflow configuration updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            // Add new workflow configurations not allowed yet
            this.fullModalService.close();
            Toaster.notYetImplementedError();
        }
    }

    public addFragment(): void {
        const formArray = this.identifierSettingFormGroup.controls.fragments as FormArray;
        formArray.push(new UntypedFormGroup({
            type: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
        }));
    }

    public deleteFragment(index: number): void {
        const formArray = this.identifierSettingFormGroup.controls.fragments as FormArray;
        if (formArray.controls[index]) formArray.removeAt(index);
    }

    private getDefaultIdentifierSetting(): CustomWorkflowIdentifierSettingModel {
        const identifierSetting = new CustomWorkflowIdentifierSettingModel('-', true);

        switch (this.workflowConfiguration.publicationType.name) {
            case EPublicationType.POS:
            case EPublicationType.WEB: {
                identifierSetting.fragments.push({
                    type: EIdentifierSettingFragmentType.DERIVED,
                    property: 'campaignItem.briefingItemId'} as CustomWorkflowIdentifierSettingFragmentModel);
                break;
            }
            case EPublicationType.PRINT_MAGAZINE: {
                identifierSetting.fragments.push({type: EIdentifierSettingFragmentType.RANDOM}  as CustomWorkflowIdentifierSettingFragmentModel);
                break;
            }
        }
        return identifierSetting;
    }
}
