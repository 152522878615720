/**
 * Created by nandamhuis on 17/02/2017.
 */

import {Component, Input} from '@angular/core';
import {UserModel} from '../../models/api/user.model';

@Component({
    selector: 'rl-account-management-component',
    templateUrl: 'account-management.component.html',
    styleUrls: ['account-management.component.scss']
})

export class AccountManagementComponent {
    @Input() public user: UserModel;
}
