<div class="area top">
    <div class="label" [class.no-name]="!backwardsTransitionName && hasBackwardsTransition">
        <div class="transition-name">{{backwardsTransitionName}}</div>
    </div>

    <div class="wrapper" *ngIf="backwardCount">
        <div class="loading-indicator"></div>
        <mat-progress-spinner
            class="spinner"
            mode="determinate"
            [value]="backwardProgress"
            diameter="22"></mat-progress-spinner>
        <div class="counter"
             [nucTooltip]="backwardCountOverview"
             [nucTooltipPosition]="'below'">{{backwardCount}}</div>
    </div>

</div>

<div class="area middle">
    <div class="line" [class.inactive]="!hasNext"></div>

    <div class="wrapper" *ngIf="forwardCount">
        <div class="loading-indicator"></div>
        <mat-progress-spinner
            class="spinner"
            mode="determinate"
            [value]="forwardProgress"
            diameter="22"></mat-progress-spinner>
        <div class="counter"
             [nucTooltip]="forwardCountOverview"
             [nucTooltipPosition]="'below'">{{forwardCount}}</div>
    </div>

</div>

<div class="area bottom"></div>
